import { UploadOutlined } from '@ant-design/icons'
import { Button, Checkbox, Form, Select, Space, Spin, Switch, Upload, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import CustomBadge from '../../../../components/CustomBadge'
import CategoryService from '../../../../services/CategoryService'
import ProductService from '../../../../services/ProductService'

const AddProduct = () => {
  const [fileList, setFileList] = useState([])
  const [uploading, setUploading] = useState(false)
  const [enableRowOptions, setEnableRowOptions] = useState(false)

  const [rowOptions] = useState([
    'KURSI PENGEMUDI',
    'PER BARIS',
    'BAGASI',
    'FULLSET',
    'FULLSET TANPA BAGASI',
  ])
  const [form] = Form.useForm()
  const navigate = useNavigate()

  const [data, setData] = useState([])

  useEffect(() => {
    getData()
  }, [])

  const props = {
    accept: '.xls,.xlsx',
    multiple: true,
    onRemove: (file) => {
      const index = fileList.indexOf(file)
      const newFileList = fileList.slice()
      newFileList.splice(index, 1)

      if (newFileList.length === 0) {
        form.setFieldsValue({ files: undefined })
      }

      setFileList(newFileList)
    },
    beforeUpload: (file) => {
      setFileList((fileList) => [...fileList, file])
      return false
    },
    fileList,
  }

  const getData = async () => {
    const response = await CategoryService.list()

    try {
      if (response.status === 200) {
        setData(response.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const onSubmit = async (values) => {
    // console.log('values', values)
    const formData = new FormData()

    fileList.forEach((file) => {
      formData.append('files', file)
    })

    Object.entries(values).forEach(([key, value]) => {
      if (value !== undefined && !['files', 'enable_row_selected'].includes(key))
        formData.append(key, value)

      if (value !== undefined && key === 'enable_row_selected') {
        if (Array.isArray(value)) {
          value.map((v, i) => formData.append(`${key}[${i}]`, v))
        } else {
          formData.append(key + '[0]', [value])
        }
      }
    })

    try {
      setUploading(true)
      const response = await ProductService.save(formData)
      if (response.status === 200) {
        message.success('Import successfully.')
        setFileList([])
        navigate('/master-product/products')
      }
    } catch (error) {
      message.error('Import failed.')
      console.log(error)
    } finally {
      setUploading(false)
    }
  }

  const content = (
    <div className="section-form-product mt-10">
      <Form
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 12 }}
        layout="horizontal"
        // style={{ maxWidth: 600 }}
        form={form}
        onFinish={onSubmit}
      >
        <Form.Item
          label="File Template (xls/xlsx)"
          name="files"
          required
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Upload {...props} required>
            <Button icon={<UploadOutlined />}>Pilih File</Button>
          </Upload>
        </Form.Item>

        <Form.Item
          label="Kategori Produk"
          name="category_id"
          required
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select placeholder="Pilih Kategori Produk">
            {data?.map((item, index) => (
              <Select.Option key={index} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Ada Baris ?" name="enable_row">
          <Switch
            checkedChildren="Ya"
            unCheckedChildren="Tidak"
            onChange={(v) => setEnableRowOptions(v)}
          />
        </Form.Item>
        {enableRowOptions && (
          <>
            <div className="section-checkbox-row">
              <Form.Item label="Pilihan Baris" name="enable_row_selected">
                <Checkbox.Group options={rowOptions} id="row-product"></Checkbox.Group>
              </Form.Item>
            </div>
          </>
        )}
        <Form.Item label="Ready Stock ?" name="is_ready_stock">
          <Switch checkedChildren="Ya" unCheckedChildren="Tidak" />
        </Form.Item>
        <Form.Item label="Universal ?" name="is_universal">
          <Switch checkedChildren="Ya" unCheckedChildren="Tidak" />
        </Form.Item>
        <Form.Item label="Variasi 1" name="enable_variant_1">
          <Switch checkedChildren="Aktif" unCheckedChildren="Nonaktif" />
        </Form.Item>
        <Form.Item label="Variasi 2" name="enable_variant_2">
          <Switch checkedChildren="Aktif" unCheckedChildren="Nonaktif" />
        </Form.Item>
        <Form.Item label="Design" name="enable_design">
          <Switch checkedChildren="Aktif" unCheckedChildren="Nonaktif" />
        </Form.Item>
        <Form.Item label="Logo" name="enable_logo">
          <Switch checkedChildren="Aktif" unCheckedChildren="Nonaktif" />
        </Form.Item>
        <Form.Item label="Addon" name="enable_addon">
          <Switch checkedChildren="Aktif" unCheckedChildren="Nonaktif" />
        </Form.Item>
        <Form.Item label="Jenis Transmisi" name="enable_transmission">
          <Switch checkedChildren="Aktif" unCheckedChildren="Nonaktif" />
        </Form.Item>
        <Form.Item label="APAR" name="enable_fire_extinguisher">
          <Switch checkedChildren="Aktif" unCheckedChildren="Nonaktif" />
        </Form.Item>
        <Form.Item label="Lubang Pengunci" name="enable_lock_hole">
          <Switch checkedChildren="Aktif" unCheckedChildren="Nonaktif" />{' '}
        </Form.Item>

        <Form.Item>
          <Space className="flex justify-end mt-10">
            <Button
              className="border-0 text-white text-lg h-fit font-medium bg-rose-500"
              onClick={() => navigate('/master-product/products')}
            >
              Batalkan
            </Button>

            <Button
              className="border-0 text-white text-lg h-fit font-medium bg-cyan-500"
              htmlType="submit"
            >
              Simpan
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  )

  return (
    <div className="bg-white subpixel-antialiased shadow-white-500/40 shadow-sm rounded-2xl h-fit w-full md:overflow-visible pt-4 md:pt-4">
      <div className="section-product px-2 md:px-4 pb-2">
        <CustomBadge title="Import Produk" titleParent="Produk" />
        {uploading ? (
          <Spin tip="Uploading..." size="small">
            {content}
          </Spin>
        ) : (
          content
        )}
      </div>
    </div>
  )
}

export default AddProduct
