import { request } from '../config'

// versi function
function RoleService() {
  // Get Data for Datatable
  const getDatatable = async (params) => {
    const response = await request.get(`/roles/pagination`, { params })
    return response.data
  }

  // Show List
  const list = async () => {
    const response = await request.get(`/roles/list`)
    return response
  }

  // Show detail
  const findById = async (roles_id) => {
    const response = await request.get(`/roles/${roles_id}`)
    return response.data
  }

  const save = async (data) => {
    const response = await request.post(`/roles`, data)
    return response.data
  }

  // update Data
  const update = async (roles_id, data) => {
    const response = await request.put(`/roles/${roles_id}`, data)
    return response.data
  }

  const remove = async (roles_id) => {
    const response = await request.delete(`/roles/${roles_id}`)
    return response.data
  }

  return {
    getDatatable,
    list,
    findById,
    save,
    update,
    remove,
  }
}

export default RoleService()
