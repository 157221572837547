import { Button, Form, Input, Modal } from 'antd'
import React from 'react'
import Swal from 'sweetalert2'
import MaterialVariantService from '../../../../services/MaterialVariantService'

const AddMaterialVariant = (props) => {
  const { id: productId, isModalAddOpen, setIsModalAddOpen, getData } = props

  const [form] = Form.useForm()

  const onSubmit = async (values) => {
    const data = {
      product_id: parseInt(productId),
      name: values.name,
    }

    const response = await MaterialVariantService.save(data, productId)
    try {
      if (response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Berhasil',
          text: 'Berhasil menambahkan bahan variasi',
        })
      }
      form.resetFields()
      getData()
      setIsModalAddOpen(false)
    } catch (error) {
      console.log(error)
    }
  }

  const handleCancel = () => {
    form.resetFields()
    setIsModalAddOpen(false)
  }

  return (
    <Modal
      forceRender
      title="Tambah Bahan Variasi"
      footer={false}
      open={isModalAddOpen}
      onCancel={handleCancel}
    >
      <Form form={form} onFinish={onSubmit} layout="vertical">
        <Form.Item label="Nama Bahan" name="name">
          <Input className="rounded-md h-10" placeholder="Isi bahan" />
        </Form.Item>

        <Form.Item>
          <div className="flex justify-end gap-3">
            <Button className="bg-rose-500 text-white rounded-lg" onClick={() => handleCancel()}>
              Batalkan
            </Button>
            <Button htmlType="submit" className="bg-cyan-500 text-white rounded-lg">
              Simpan
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default AddMaterialVariant
