import React, { useEffect, useState } from 'react'
import CustomBadge from '../../../components/CustomBadge'
import { Button, Card, Form, Select } from 'antd'
import OrderService from '../../../services/OrderService'
import LeadsService from '../../../services/LeadsService'
import moment from 'moment'
import FormLeadsNonForm from './Modal/FormLeadsNonForm'
import ability from '../../../ability/ability'

const LeadsNonForm = () => {
  const [form] = Form.useForm()

  const [loading, setLoading] = useState(false)

  const [unitOptions, setUnitOptions] = useState([])
  const [picOptions, setPicOptions] = useState([])
  const [periodOptions, setPeriodOptions] = useState([])

  const [user_id, setUserId] = useState(null)
  const [unit_id, setUnitId] = useState(null)
  const [period, setPeriod] = useState(null)
  const [isOpen, setIsOpen] = useState(false)
  const [date, setDate] = useState('')
  const [selectedUserId, setSelectedUserId] = useState('')

  const [data, setData] = useState(null)

  useEffect(() => {
    getUnitOptions()
    getPeriodOptions()
  }, [])

  const getUnitOptions = async () => {
    setLoading(true)
    try {
      const response = await OrderService.getOrderFilterByUnit()
      setUnitOptions(
        response.data.map((filter) => ({
          value: filter.id,
          label: filter.name,
        }))
      )
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const getPeriodOptions = async () => {
    try {
      const response = await LeadsService.getPeriodOptions()
      if (response.status === 200) {
        setPeriodOptions(response.data.map((item) => ({ value: item.value, label: item.name })))
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleUnit = (value) => {
    try {
      if (value) {
        getPicOptions(value)
        form.resetFields(['user_id'])
      } else {
        form.resetFields(['user_id'])
        setPicOptions([])
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getPicOptions = async (unit_id) => {
    setLoading(true)
    try {
      const response = await OrderService.getOrderFilterByPic(unit_id)
      setPicOptions(
        response.data.map((filter) => ({
          value: filter.id,
          label: filter.name,
        }))
      )
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const onValuesChange = (changedValues, allValues) => {
    setUserId(allValues.user_id)
    setUnitId(allValues.unit_id)
    setPeriod(allValues.period)
  }

  const getData = async () => {
    setLoading(true)

    const payload = {
      unit_id,
      user_id,
      period,
    }

    try {
      const response = await LeadsService.getDataLeadsNonForm(payload)

      if (response.status === 200) {
        setData(response.data)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const renderColumn = (text, column, user_id = null, total = false) => {
    if (typeof text === 'number') {
      let date = moment(data.header[column + 1], 'DD-MM-YYYY').format('YYYY-MM-DD')
      // let today = moment().format('YYYY-MM-DD');
      const clickable = column >= 2 && !total

      let style = {}
      if (clickable) {
        style = { ...style, cursor: 'pointer' }
      }

      return (
        <td
          key={column}
          className="border text-end p-3"
          style={style}
          onClick={() => {
            if (clickable) {
              setIsOpen(true)
              setDate(date)
              setSelectedUserId(user_id)
            }
          }}
        >
          {total ? <strong>{text}</strong> : <>{text}</>}
        </td>
      )
    } else if (typeof text === 'string') {
      return (
        <td
          key={column}
          className="border p-3"
          style={column === 0 ? { position: 'sticky', left: 0, backgroundColor: 'white' } : {}}
        >
          {total ? <strong>{text}</strong> : text}
        </td>
      )
    }
  }

  return (
    <>
      <CustomBadge formOrder title="Leads Non Form" titleParent="Leads" />

      <Card className="md:ml-3">
        <Form layout="vertical" form={form} onFinish={getData} onValuesChange={onValuesChange}>
          <div className="grid md:grid-cols-3 gap-4">
            <Form.Item label="Unit" name="unit_id" required>
              <Select
                allowClear
                className="w-full"
                placeholder="Pilih Unit"
                options={unitOptions}
                onChange={handleUnit}
              />
            </Form.Item>

            <Form.Item label="List PIC" name="user_id">
              <Select allowClear className="w-full" placeholder="Pilih PIC" options={picOptions} />
            </Form.Item>

            <Form.Item label="Periode" name="period" required>
              <Select className="w-full" placeholder="Pilih Periode" options={periodOptions} />
            </Form.Item>
          </div>

          <div className="flex justify-end gap-2">
            <Form.Item>
              <Button className="text-white bg-rose-600 border-0">Batal</Button>
            </Form.Item>

            <Form.Item>
              <Button
                htmlType="submit"
                loading={loading}
                className="text-white bg-[#013a63] border-0"
              >
                Cari
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Card>

      {data && (
        <Card className="antialiased border-0 relative my-4 md:ml-3">
          <div className="flex justify-between items-center mb-10">
            <h2 className="text-lg font-medium text-[#013a63]">Laporan Leads Non Form</h2>
          </div>

          <div className="overflow-x-auto">
            <table className="border-collapse table-auto w-full">
              <thead>
                <tr className="text-xs">
                  {data.header
                    .filter((v, i) => i > 0)
                    .map((h, i) => {
                      return (
                        <th
                          className="border p-3"
                          style={
                            i === 0
                              ? {
                                  position: 'sticky',
                                  left: 0,
                                  backgroundColor: 'white',
                                  minWidth: '20rem',
                                }
                              : i === 1
                              ? {
                                  minWidth: '10rem',
                                }
                              : {}
                          }
                          key={i}
                        >
                          {h}
                        </th>
                      )
                    })}
                </tr>
              </thead>

              <tbody>
                {data.content?.map((c, i) => {
                  let id = c[0]
                  return (
                    <tr className="text-xs" key={i}>
                      {c.filter((v, i) => i > 0).map((coo, j) => renderColumn(coo, j, id))}
                    </tr>
                  )
                })}
                <tr>
                  {data.footer
                    .filter((v, i) => i > 0)
                    .map((f, i) => renderColumn(f, i, null, true))}
                </tr>
              </tbody>
            </table>
          </div>
        </Card>
      )}

      {ability.can('update', 'Leads Non Form') && (
        <FormLeadsNonForm
          open={isOpen}
          setIsOpen={setIsOpen}
          date={date}
          selectedUserId={selectedUserId}
          getData={getData}
        />
      )}
    </>
  )
}

export default LeadsNonForm
