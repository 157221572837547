import { Button, Form, Input, Modal } from 'antd'
import React from 'react'
import Swal from 'sweetalert2'
import LogoService from '../../../../services/LogoService'

const AddLogo = (props) => {
  const { id, isModalAddOpen, handleCancel, setIsModalAddOpen, getData } = props

  const [form] = Form.useForm()

  const onSubmit = async (values) => {
    const data = {
      name: values.name,
      product_id: parseInt(id),
    }

    const response = await LogoService.save(id, data)
    try {
      if (response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Berhasil',
          text: 'Berhasil menambahkan Logo',
        })
      }
      getData()
      setIsModalAddOpen(false)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Modal forceRender title="Tambah Logo Produk" footer={false} open={isModalAddOpen} onCancel={handleCancel}>
      <Form className="mt-10" form={form} onFinish={onSubmit} layout="vertical">
        <Form.Item
          label="Nama"
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input className="rounded-md h-10" placeholder="Logo" />
        </Form.Item>

        <Form.Item>
          <div className="flex justify-end gap-3">
            <Button
              className="bg-rose-500 text-white rounded-lg"
              onClick={() => setIsModalAddOpen(false)}
            >
              Batalkan
            </Button>
            <Button htmlType="submit" className="bg-cyan-500 text-white rounded-lg">
              Simpan
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default AddLogo
