import { Button, Form, Modal, notification, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import OrderService from '../../../../services/OrderService'

const ChangeStatus = (props) => {
  const { open, toggle, row } = props
  const [itemId, setItemId] = useState(null)
  const [statusOptions, setStatusOptions] = useState(null)
  const [form] = Form.useForm()

  useEffect(() => {
    setItemId(row?.item_id)
    form.resetFields()
  }, [row, form])

  useEffect(() => {
    const getStatusOptions = async () => {
      const response = await OrderService.getStatusListForChangeStatus()
      try {
        setStatusOptions(
          response.data.map((filter) => ({
            value: filter.id,
            label: filter.name,
          }))
        )
      } catch (error) {
        console.log(error)
      }
    }

    if (open) {
      getStatusOptions()
    }
  }, [open])

  const handleCancel = () => {
    form.resetFields()
    setItemId(null)
    toggle()
  }

  const onFinish = async (values) => {
    const body = {
      status_type_id: values.status_type_id,
      item_id: itemId,
    }
    const response = await OrderService.changeStatus(body)

    try {
      if (response.status === 200) {
        notification.success({
          message: 'Berhasil',
          description: 'Berhasil diganti',
          placement: 'top',
        })
      }
      form.resetFields()
      setItemId(null)
      toggle()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Modal forceRender open={open} centered={true} onCancel={handleCancel} footer={false}>
      {row && (
        <h2 className="text-xl text-gray-800 font-medium">Ganti Status Produksi {row.item_id}</h2>
      )}

      <Form
        form={form}
        onFinish={onFinish}
        className="section-form-recipient mt-10"
        layout="vertical"
      >
        <div className="grid md:grid-cols-2 gap-3">
          <Form.Item
            label="Status"
            name="status_type_id"
            required
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select placeholder="--Status Produksi--" options={statusOptions} />
          </Form.Item>
        </div>

        <div className="flex justify-end gap-2 mt-10">
          <Button className="border-0 bg-rose-500 text-white w-32" onClick={() => handleCancel()}>
            Cancel
          </Button>
          <Form.Item>
            <Button htmlType="submit" className="border-0 bg-cyan-500 text-white w-32">
              Simpan
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  )
}

export default ChangeStatus
