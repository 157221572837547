/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Form, Input, Modal, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import { HexColorPicker } from 'react-colorful'
import Swal from 'sweetalert2'
import ColorVariantService from '../../../../services/ColorVariantService'

const EditColorVariantModal = (props) => {
  const {
    id: fiberVariantId,
    isModalEditOpen,
    handleCancel,
    setIsModalEditOpen,
    getData,
    dataEditModal,
  } = props

  const [form] = Form.useForm()
  const [color, setColorVariant] = useState(dataEditModal.hex_code)

  useEffect(() => {
    form.setFieldsValue({
      ...dataEditModal,
    })

    setColorVariant(dataEditModal.hex_code)
  }, [dataEditModal])

  const onHandleChangeColorVariant = (e) => {
    setColorVariant(e)
  }

  const onSubmit = async (values) => {
    const data = {
      fiber_variant_id: parseInt(fiberVariantId),
      name: values.name,
      hex_code: color,
    }

    const response = await ColorVariantService.update(data, fiberVariantId, dataEditModal.id)
    try {
      if (response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Berhasil',
          text: 'Berhasil update warna produk',
        })
      }
      getData()
      setIsModalEditOpen(false)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <Modal
        forceRender
        title="Edit Warna Variasi"
        footer={false}
        open={isModalEditOpen}
        onCancel={handleCancel}
      >
        <Form form={form} onFinish={onSubmit} layout="vertical">
          <Form.Item label="Warna" name="name">
            <Input className="rounded-md h-10" placeholder="Isi nama warna" />
          </Form.Item>

          <Form.Item label="Hex Code">
            <Space>
              <HexColorPicker color={color} onChange={onHandleChangeColorVariant} />
              <h2>{color}</h2>
            </Space>
          </Form.Item>

          <Form.Item>
            <div className="flex justify-end gap-3">
              <Button className="bg-rose-500 text-white rounded-lg" onClick={handleCancel}>
                Batalkan
              </Button>
              <Button htmlType="submit" className="bg-cyan-500 text-white rounded-lg">
                Simpan
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default EditColorVariantModal
