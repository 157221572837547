import { request } from '../config'

// versi function
function FiberService() {
  // Get Data for Datatable
  const getDatatable = async (params, material_id) => {
    const response = await request.get(`/materials/${material_id}/fibers/pagination`, { params })
    return response.data
  }

  // insert data
  const save = async (data, material_id) => {
    const response = await request.post(`/materials/${material_id}/fibers`, data)
    return response
  }

  // update Data
  const update = async (data, material_id, fiber_id) => {
    const response = await request.put(`/materials/${material_id}/fibers/${fiber_id}`, data)

    return response.data
  }

  // remove data
  const remove = async (material_id, fiber_id) => {
    const response = await request.delete(`/materials/${material_id}/fibers/${fiber_id}`)

    return response.data
  }

  return {
    getDatatable,
    save,
    update,
    remove,
  }
}

export default FiberService()
