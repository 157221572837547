import { Button, Form, Input, Modal, Select, notification } from 'antd'
import React, { useEffect, useState } from 'react'
import RegionalService from '../../../services/RegionalService'
import CustomerService from '../../../services/CustomerService'
import LeadsService from '../../../services/LeadsService'

const state = {
  address: '',
  province_id: '',
  district_id: '',
  subdistrict_id: '',
  postal_code: '',
}

const Purchased = (props) => {
  const { open, toggle, row } = props

  const [form] = Form.useForm()

  const [lead_id, setLeadId] = useState(row?.id)
  const [values, setValues] = useState(state)

  const [loading, setLoading] = useState(false)

  const [customerType, setCustomerType] = useState([])
  const [provinces, setProvinces] = useState([])
  const [district, setDistrict] = useState([])
  const [subdistricts, setSubdistricts] = useState([])

  useEffect(() => {
    setLeadId(row?.id)
  }, [row])

  useEffect(() => {
    if (open) {
      getProvince()
      getDataCustomerTypeList()
    }
  }, [open])

  const getDataCustomerTypeList = async () => {
    try {
      setLoading(true)
      const { data } = await CustomerService.customerTypeList()
      setCustomerType(data.map((item) => ({ label: item.name, value: item.id })))
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const getProvince = async () => {
    try {
      const response = await RegionalService.province()
      setProvinces(response.data.map((item) => ({ label: item.name, value: item.id })))
    } catch (error) {
      console.log(error)
    }
  }

  const provinceChange = async (value) => {
    setValues({ ...values, district_id: '', province_id: value })
    try {
      const response = await RegionalService.districtByProvince(value)
      setDistrict(response.data.map((item) => ({ label: item.name, value: item.id })))
      form.resetFields(['district_id', 'subdistrict_id'])
    } catch (error) {
      console.log(error)
    }
  }

  const districtChange = async (value) => {
    setValues({ ...values, subdistricts_id: '', district_id: value })

    try {
      const response = await RegionalService.subdistrictByDistrict(value)
      setSubdistricts(response.data.map((item) => ({ label: item.name, value: item.id })))
      form.resetFields(['subdistrict_id'])
    } catch (error) {
      console.log(error)
    }
  }

  const onFinish = async (values) => {
    setLoading(true)
    const payload = {
      customer_type_id: values.customer_type_id,
      address: values.address,
      province_id: values.province_id,
      district_id: values.district_id,
      subdistrict_id: values.subdistrict_id,
      postal_code: values.postal_code,
    }

    try {
      const response = await LeadsService.changeStatusPurchased(lead_id, payload)

      if (response.status === 200) {
        notification.success({
          message: 'Berhasil',
          description: 'Data Purchased berhasil disimpan',
        })
        setLoading(false)
        toggle()
        form.resetFields()
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  return (
    <Modal
      title="Form Tandai Sudah Beli"
      open={open}
      onCancel={() => {
        toggle()
        form.resetFields()
      }}
      footer={false}
    >
      <Form form={form} layout="vertical" onFinish={onFinish} className="mt-5">
        <Form.Item
          name="customer_type_id"
          label="Jenis Customer"
          required
          rules={[
            {
              required: true,
              message: 'Jenis Customer harus diisi',
            },
          ]}
        >
          <Select placeholder="Pilih Jenis Customer" options={customerType} />
        </Form.Item>

        <Form.Item
          name="province_id"
          label="Provinsi"
          required
          rules={[
            {
              required: true,
              message: 'Provinsi harus diisi',
            },
          ]}
        >
          <Select placeholder="Pilih Provinsi" options={provinces} onChange={provinceChange} />
        </Form.Item>

        <Form.Item
          name="district_id"
          label="Kota / Kabupaten"
          required
          rules={[
            {
              required: true,
              message: 'Kota / Kabupaten harus diisi',
            },
          ]}
        >
          <Select
            placeholder="Pilih Kota / Kabupaten"
            options={district}
            onChange={districtChange}
          />
        </Form.Item>

        <Form.Item
          name="subdistrict_id"
          label="Kecamatan"
          required
          rules={[
            {
              required: true,
              message: 'Kecamatan harus diisi',
            },
          ]}
        >
          <Select placeholder="Pilih Kecamatan" options={subdistricts} />
        </Form.Item>

        <Form.Item
          label="Kode Pos"
          name="postal_code"
          required
          rules={[
            {
              required: true,
              min: 5,
            },
          ]}
        >
          <Input maxLength={5} placeholder="123xx" />
        </Form.Item>

        <Form.Item
          name="address"
          label="Alamat"
          required
          rules={[
            {
              required: true,
              message: 'Alamat harus diisi',
            },
          ]}
        >
          <Input.TextArea placeholder="Alamat" rows={3} />
        </Form.Item>

        <div className="flex justify-end gap-2">
          <Form.Item>
            <Button className="bg-rose-500 text-white" onClick={toggle}>
              Batal
            </Button>
          </Form.Item>

          <Form.Item>
            <Button loading={loading} className="bg-[#205295] text-white" htmlType="submit">
              Simpan
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  )
}

export default Purchased
