import { Button, DatePicker, Form, Input, Select } from 'antd'
import moment from 'moment'
import 'moment/locale/id'
import React, { useEffect, useState } from 'react'
import AsyncSelect from 'react-select/async'
import CustomBadge from '../../../../components/CustomBadge'
import CustomerService from '../../../../services/CustomerService'
import OrderService from '../../../../services/OrderService'
import RegionalService from '../../../../services/RegionalService'
import AddCustomer from './Modal/AddCustomer'
import { phoneCodes } from '../../../../helpers/phoneCode'

const CustomerForm = (props) => {
  const {
    form,
    setValidateForm,
    setCustomer_id,
    setCustomer_type_id,
    setOrderDate,
    resetCourier,
    setValuePlatform,
    setMaxShippingDate,
    invoice_required,
    setInvoiceRequired,
    prefix,
    setPrefix,
  } = props

  const [orderSources, setOrderSources] = useState([])
  const [platforms, setPlatforms] = useState([])
  const [district, setDistrict] = useState([])
  const [provinces, setProvinces] = useState([])
  const [subdistricts, setSubdistricts] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    getProvince()
    getPlatforms()
    getOrderSources()
  }, [])

  const loadCustomer = async (searchCustomer) => {
    if (searchCustomer.length >= 2) {
      const response = await CustomerService.searchCustomer({
        filter: searchCustomer,
      })
      let options = []
      for (let row of response.data) {
        options.push({
          value: row.id,
          label: row.name + ' | ' + row.phone,
        })
      }
      return options
    }
  }

  const handleChangePrefix = (value) => {
    setPrefix(value)
  }

  const prefixSelector = (
    <Select
      value={prefix}
      onChange={handleChangePrefix}
      style={{ width: 'fit-content' }}
      showSearch
      filterOption={(input, option) =>
        (option?.searchable ?? '').toLowerCase().includes(input.toLowerCase())
      }
      options={phoneCodes.map((item) => ({
        value: item.dial_code,
        label: `${item.emoji} ${item.dial_code}`,
        searchable: `${item.name} ${item.dial_code}`,
        key: item.code,
      }))}
    />
  )

  const onChangeCustomer = async ({ value }) => {
    const { data } = await CustomerService.findCustomerById(value)

    const matchedPrefix = phoneCodes.find((code) => data.phone.startsWith(code.dial_code))

    const customer = {
      value: data.id,
      label: data.name + ' | ' + data.phone,
    }

    setPrefix(matchedPrefix?.dial_code)
    setValidateForm(customer)
    setCustomer_id(data.id)
    setCustomer_type_id(data.customer_type.id)
    provinceChange(data.province.id)
    districtChange(data.district.id)

    form.setFieldsValue({
      ...data,
      customer: customer,
      phone: data.phone.replace(matchedPrefix.dial_code, ''),
      province_id: data.province.id,
      district_id: data.district.id,
      subdistrict_id: data.subdistrict.id,
    })
  }

  const renderCustomer = async (customer_id) => {
    const { data } = await CustomerService.findCustomerById(customer_id)

    const customer = {
      value: data.id,
      label: data.name + ' | ' + data.phone,
    }

    setValidateForm(customer)
    setCustomer_id(data.id)
    setCustomer_type_id(data.customer_type.id)
    provinceChange(data.province.id)
    districtChange(data.district.id)

    form.setFieldsValue({
      ...data,
      customer: customer,
      phone: data.phone
        .replace(/[^0-9]/gi, '')
        .replace(/^0/gi, '')
        .replace(/^62/gi, ''),
      province_id: data.province.id,
      district_id: data.district.id,
      subdistrict_id: data.subdistrict.id,
    })
  }

  const getPlatforms = async () => {
    const response = await OrderService.getPlatforms()
    try {
      if (response.status === 200) {
        setPlatforms(
          response.data.map((platform) => ({
            value: platform.name,
            label: platform.name,
            invoice: platform.invoice_number_required,
          }))
        )
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getOrderSources = async () => {
    const response = await OrderService.getOrderSources()
    try {
      if (response.status === 200) {
        setOrderSources(
          response.data.map((source) => ({
            value: source.name,
            label: source.name,
          }))
        )
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getProvince = async () => {
    const response = await RegionalService.province()
    try {
      if (response.status === 200) {
        setProvinces(
          response.data.map((province) => ({
            value: province.id,
            label: province.name,
          }))
        )
      }
    } catch (error) {
      console.log(error)
    }
  }

  const provinceChange = (value) => {
    if (value) {
      RegionalService.districtByProvince(value)
        .then((response) => {
          setDistrict(
            response.data.map((district) => ({
              value: district.id,
              label:
                district.type === 'Kabupaten'
                  ? `Kab. ${district.name}`
                  : `${district.type} ${district.name}`,
            }))
          )
        })
        .catch((error) => {
          console.log(error)
        })

      // RESET KOTA/KABUPATEN DAN KECAMATAN
      // KETIKA ADA PERUBAHAN PROVINSI
      form.setFieldsValue({
        district_id: undefined,
        subdistrict_id: undefined,
      })
      resetCourier()
    } else {
      // RESET KOTA/KABUPATEN DAN KECAMATAN
      // KETIKA PROVINSI BELUM DIPILIH
      form.setFieldsValue({
        district_id: undefined,
        subdistrict_id: undefined,
      })
      resetCourier()
      return value
    }
  }

  const districtChange = (value) => {
    if (value) {
      RegionalService.subdistrictByDistrict(value)
        .then((response) => {
          setSubdistricts(
            response.data.map((subdistrict) => ({
              value: subdistrict.id,
              label: subdistrict.name,
            }))
          )
        })
        .catch((err) => {
          console.log(err)
        })

      // RESET KECAMATAN
      // KETIKA ADA PERUBAHAN KOTA/KABUPATEN
      form.setFieldsValue({
        subdistrict_id: undefined,
      })
      resetCourier()
    } else {
      // RESET KECAMATAN
      // KETIKA KOTA/KABUPATEN BELUM DIPILIH
      form.setFieldsValue({
        subdistrict_id: undefined,
      })
      resetCourier()
      return value
    }
  }

  const handleSubdistrictsChange = (value) => {
    if (value) {
      resetCourier()
    } else {
      resetCourier()
      return value
    }
  }

  const handleDeliverOrderDate = (_, dateStrings) => {
    setMaxShippingDate(dateStrings)
  }

  const handleDateChange = (_, dateStrings) => {
    setOrderDate(dateStrings)
  }

  const handlePlatformChange = (value, option) => {
    setValuePlatform(value && value)
    setInvoiceRequired(option && option.invoice)

    // Reset field payment status ketika value platform kosong
    if (value === undefined) {
      form.setFieldsValue({
        payment_status_id: undefined,
      })
    }
  }

  const disabledDeliveryOrderDate = (current) => {
    return moment().add(-1, 'days') >= current || moment().add(-1, 'days') > current
  }

  const disabledDate = (current) => {
    return moment().add(-2, 'days') >= current || moment().add(0, 'days') < current
  }

  const showModal = () => {
    setIsModalOpen(true)
  }
  const handleOk = () => {
    setIsModalOpen(false)
  }

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <div className="md:col-span-1 bg-white shadow-white-500/40 shadow-sm rounded-lg h-fit w-full md:overflow-visible mb-5 md:mb-0 pt-4 md:pt-4">
          <div className="section-data-order px-3">
            <CustomBadge titleOrder title="Data Pemesan" />
          </div>

          <div className="section-form-order px-5 pb-3 mt-5">
            <Form.Item
              label="Nama / No. Telepon"
              name="customer"
              rules={[
                {
                  required: true,
                  message: 'Customer wajib diisi',
                },
              ]}
              required
            >
              <AsyncSelect
                placeholder="Nama Pelanggan"
                loadOptions={loadCustomer}
                onChange={onChangeCustomer}
              />
            </Form.Item>

            <Button
              onClick={showModal}
              className="border-0 rounded-md w-fit h-fit text-xs text-white font-medium bg-green-500 my-3"
            >
              Tambah Pelanggan
            </Button>

            <Form.Item
              label="Sumber Order"
              name="order_source"
              required
              rules={[
                {
                  required: true,
                  message: 'Sumber order wajib diisi',
                },
              ]}
            >
              <Select
                allowClear
                showSearch
                options={orderSources}
                placeholder="Pilih Sumber Order"
              />
            </Form.Item>

            <Form.Item
              label="Platform"
              name="platform"
              required
              rules={[
                {
                  required: true,
                  message: 'Platform wajib diisi',
                },
              ]}
            >
              <Select
                allowClear
                showSearch
                options={platforms}
                placeholder="Pilih Platform"
                onChange={handlePlatformChange}
              />
            </Form.Item>

            {invoice_required && (
              <>
                <Form.Item
                  label="No. Invoice"
                  name="invoice_marketplace"
                  required
                  rules={[
                    {
                      required: true,
                      message: 'No. Invoice wajib diisi',
                    },
                  ]}
                >
                  <Input placeholder="INV/XX/YY/ZZ/123" />
                </Form.Item>

                <Form.Item
                  label="Maksimal Tanggal Pengiriman"
                  name="max_shipping_date"
                  rules={[
                    {
                      required: true,
                      message: 'Tanggal pengiriman wajib diisi',
                    },
                  ]}
                  required
                >
                  <DatePicker
                    showTime={{
                      format: 'HH:mm',
                    }}
                    className="w-full"
                    disabledDate={disabledDeliveryOrderDate}
                    onChange={handleDeliverOrderDate}
                  />
                </Form.Item>
              </>
            )}

            <Form.Item
              label="Tanggal Order"
              name="order_date"
              rules={[
                {
                  required: true,
                  message: 'Tanggal order wajib diisi',
                },
              ]}
              required
            >
              <DatePicker
                disabledDate={disabledDate}
                onChange={handleDateChange}
                className="w-full"
              />
            </Form.Item>
          </div>
        </div>

        <div className="md:col-span-2 bg-white shadow-white-500/40 shadow-sm rounded-lg h-fit w-full md:overflow-visible pt-4 md:pt-4">
          <div className="section-recipient-data px-3">
            <CustomBadge titleOrder title="Data Penerima" />
          </div>

          <div className="section-form-recipient px-5 pb-3 mt-5">
            <div className="grid md:grid-cols-2 gap-3">
              <Form.Item
                label="Nama"
                name="name"
                required
                rules={[
                  {
                    required: true,
                    message: 'Nama customer wajib diisi',
                  },
                ]}
              >
                <Input placeholder="Nama Customer" />
              </Form.Item>

              <Form.Item
                label="No. Telepon"
                name="phone"
                required
                rules={[
                  {
                    required: true,
                    message: 'No. Telepon wajib diisi',
                  },
                ]}
              >
                <Input
                  className="w-full"
                  placeholder="8123xxx"
                  addonBefore={prefixSelector}
                  onChange={(e) =>
                    form.setFieldsValue({
                      ...form.getFieldsValue,
                      phone: e.target.value
                        .replace(/[^0-9]/gi, '')
                        .replace(/^0/gi, '')
                        .replace(/^62/gi, ''),
                    })
                  }
                />
              </Form.Item>

              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    type: 'email',
                    message: 'E-mail tidak sesuai format!',
                  },
                  {
                    required: true,
                  },
                ]}
                required
              >
                <Input placeholder="email@example.com" />
              </Form.Item>

              <Form.Item
                label="Kode Pos"
                name="postal_code"
                required
                rules={[
                  {
                    required: true,
                    message: 'Kode pos wajib diisi',
                  },
                ]}
              >
                <Input placeholder="123xx" />
              </Form.Item>

              <Form.Item
                label="Provinsi"
                name="province_id"
                required
                rules={[
                  {
                    required: true,
                    message: 'Provinsi wajib diisi',
                  },
                ]}
              >
                <Select
                  placeholder="Pilih Provinsi"
                  onChange={provinceChange}
                  options={provinces}
                  allowClear
                  showSearch
                />
              </Form.Item>

              <Form.Item
                label="Kota / Kabupaten"
                name="district_id"
                required
                rules={[
                  {
                    required: true,
                    message: 'Kota/Kabupaten wajib diisi',
                  },
                ]}
              >
                <Select
                  placeholder="Pilih Kota / Kabupaten"
                  onChange={districtChange}
                  options={district}
                  allowClear
                />
              </Form.Item>

              <Form.Item
                label="Kecamatan"
                name="subdistrict_id"
                required
                rules={[
                  {
                    required: true,
                    message: 'Kecamatan wajib diisi',
                  },
                ]}
              >
                <Select
                  placeholder="Pilih Kecamatan"
                  onChange={handleSubdistrictsChange}
                  options={subdistricts}
                  allowClear
                />
              </Form.Item>

              <Form.Item
                label="Alamat Pengiriman"
                name="address"
                required
                rules={[
                  {
                    required: true,
                    message: 'Alamat wajib diisi',
                  },
                  {
                    message: 'Maksimal Alamat 200 karakter',
                    max: 200,
                  },
                ]}
              >
                <Input.TextArea
                  placeholder="Jl. Ngawen No.29, Kranggahan II, Kelurahan Trihanggo (Kecamatan dst tidak perlu dimasukkan di sini)"
                  rows={4}
                />
              </Form.Item>
            </div>
          </div>
        </div>
      </div>

      <AddCustomer
        handleOk={handleOk}
        isModalOpen={isModalOpen}
        district={district}
        provinces={provinces}
        subdistricts={subdistricts}
        prefix={prefix}
        setPrefix={setPrefix}
        setIsModalOpen={setIsModalOpen}
        provinceChange={provinceChange}
        districtChange={districtChange}
        renderCustomer={renderCustomer}
      />
    </>
  )
}

export default CustomerForm
