import { Modal } from 'antd'
import React from 'react'

const DetailMaterial = (props) => {
  const { isModalDetailOpen, handleCancel, childData } = props

  return (
    <Modal
      forceRender
      title="Detail Addon Produk"
      footer={false}
      open={isModalDetailOpen}
      onCancel={handleCancel}
    >
      <div className="flex justify-between items-center mt-7">
        <div className="text-base text-gray-800 font-medium list-none leading-8">
          <li>Addon :</li>
          <li>Harga :</li>
        </div>

        <div className="text-base text-end text-gray-500 font-medium list-none leading-8">
          <li>{childData.name}</li>
          <li>{childData.price}</li>
        </div>
      </div>
    </Modal>
  )
}

export default DetailMaterial
