/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Input, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import Swal from 'sweetalert2'
import ability from '../../../../ability/ability'
import BButton from '../../../../components/BButton'
import CContainer from '../../../../components/CContainer'
import CustomBadge from '../../../../components/CustomBadge'
import CustomPagination from '../../../../components/CustomPagination'
import VehicleService from '../../../../services/VehicleService'
import AddVehicleClass from './Modal/AddVehicleClass'
import DetailVehicleClass from './Modal/DetailVehicleClass'
import EditVehicleClass from './Modal/EditVehicleClass'

const VehicleClass = () => {
  const navigate = useNavigate()

  const [selectedRow, setSelectedRow] = useState(null)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [total, setTotal] = useState(0)
  const [params, setParams] = useState({
    page: 1,
    per_page: 20,
    sort: 'name',
    order: 'asc',
    filter: '',
  })
  const [modal, setModal] = useState({
    isAddModalOpen: false,
    isDetailModalOpen: false,
    isUpdateModalOpen: false,
  })

  useEffect(() => {
    setStateParams()
  }, [])

  useEffect(() => {
    getData()
  }, [params])

  const getData = async () => {
    setLoading(true)
    const response = await VehicleService.getDatatableVehicleClass(params)
    try {
      if (response.status === 200) {
        setLoading(false)
        setData(response.data)
        setTotal(response.meta.total)
        setQueryString()
      }
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const onSearch = (filter) => setParams({ ...params, filter })

  const onPageChange = (page, pageSize) => {
    const per_page = pageSize
    setParams({ ...params, page, per_page })
  }

  const onTableChange = (_pagination, _filters, sorter) => {
    let { columnKey: sort, order } = sorter
    sort = order ? sort : 'id' // jika order undefined default sort adalah column `id` dan order by `id` desc
    order = order === 'ascend' ? 'asc' : 'desc'
    setParams({ ...params, sort, order })
  }

  const setStateParams = () => {
    const qs = new URLSearchParams(window.location.search)
    setParams({
      page: qs.get('page') || params.page,
      per_page: qs.get('per_page') || params.per_page,
      sort: qs.get('sort') || params.sort,
      order: qs.get('order') || params.order,
      filter: qs.get('filter') || params.filter,
    })
  }

  const setQueryString = () => {
    const newParams = {}

    Object.keys(params).forEach((key) => {
      if (params[key]) {
        newParams[key] = params[key]
      }
    })

    const qs = new URLSearchParams(newParams)

    navigate({
      pathname: window.location.pathname,
      search: '?' + qs,
    })
  }

  const deleteData = async (id) => {
    Swal.fire({
      title: 'Do you want delete this vehicle class?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await VehicleService.removeVehicleClass(id)
        if (response.status === 200) {
          Swal.fire('Berhasil!', '', 'success')
        }
        getData()
      }
    })
  }

  const toggleModal = (name) => {
    setModal((prev) => ({ ...prev, [name]: !prev[name] }))
  }

  const columns = [
    {
      title: 'Nomor',
      dataIndex: 'no',
      key: 'no',
    },
    {
      title: 'Nama',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <div className="antialiased flex gap-2 w-28">
          <Button
            className="border-0 text-white bg-cyan-500 rounded-md font-medium"
            onClick={() => {
              toggleModal(`isDetailModalOpen`)
              setSelectedRow(record)
            }}
          >
            <i className="fa-solid fa-circle-info mr-2" /> Detail
          </Button>

          {ability.can('update', 'Vehicle Class') && (
            <Button
              className="border-0 text-white bg-yellow-500 rounded-md font-medium"
              onClick={() => {
                toggleModal(`isUpdateModalOpen`)
                setSelectedRow(record)
              }}
            >
              <i className="fa-solid fa-pen-to-square mr-2" /> Edit
            </Button>
          )}

          {ability.can('delete', 'Vehicle Class') && (
            <Button
              className="border-0 text-white bg-gradient-to-r from-[#e52d27] to-[#EA384D] rounded-md font-medium"
              onClick={() => deleteData(record.id)}
            >
              <i className="fa-solid fa-trash-can mr-2" /> Hapus
            </Button>
          )}
        </div>
      ),
    },
  ]

  return (
    <>
      <CContainer>
        <div className="section-product px-2 md:px-4">
          <CustomBadge title="Jenis Kendaraan" titleParent="Produk" />

          <h2 className="text-xl text-gray-700 font-semibold mt-7">Jenis Kendaraan</h2>

          <div className="flex justify-between items-center mt-8">
            <div className="search-user">
              <Input.Search
                className="w-44 md:w-80"
                placeholder="Search"
                allowClear
                size="medium"
                onSearch={onSearch}
              />
            </div>

            {ability.can('create', 'Vehicle Class') && (
              <BButton onClick={() => toggleModal('isAddModalOpen')}>Tambah</BButton>
            )}
          </div>
        </div>

        <div className="table-section-product relative w-full mt-5">
          <Table
            rowKey="id"
            size="large"
            loading={loading}
            dataSource={data}
            columns={columns}
            pagination={false}
            scroll={{ x: true }}
            onChange={onTableChange}
            tableLayout="auto"
          />
        </div>

        <div className="section-pagination flex justify-center md:block px-2 py-8 md:p-4">
          <CustomPagination {...params} onPageChange={onPageChange} total={total} />
        </div>
      </CContainer>

      <AddVehicleClass
        open={modal.isAddModalOpen}
        toggle={() => {
          toggleModal('isAddModalOpen')
          getData()
        }}
      />

      <EditVehicleClass
        open={modal.isUpdateModalOpen}
        row={selectedRow}
        toggle={() => {
          toggleModal('isUpdateModalOpen')
          getData()
        }}
      />

      <DetailVehicleClass
        open={modal.isDetailModalOpen}
        row={selectedRow}
        toggle={() => {
          toggleModal('isDetailModalOpen')
        }}
      />
    </>
  )
}

export default VehicleClass
