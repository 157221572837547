/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { Button, Form, Input, Modal } from 'antd'
import Swal from 'sweetalert2'
import AddonsService from '../../../../services/AddonsService'

const EditAddons = (props) => {
  const { id, isModalEditOpen, handleCancel, setIsModalEditOpen, getData, childData } = props

  const [form] = Form.useForm()

  useEffect(() => {
    form.setFieldsValue({
      ...childData,
    })
  }, [childData])

  const onSubmit = async (values) => {
    const data = {
      name: values.name,
      price: values.price,
      product_id: parseInt(id),
    }

    const response = await AddonsService.update(id, childData.id, data)
    try {
      if (response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Berhasil',
          text: 'Berhasil update addon produk',
        })
      }
      getData()
      setIsModalEditOpen(false)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Modal forceRender title="Edit Addon Produk" footer={false} open={isModalEditOpen} onCancel={handleCancel}>
      <Form className="mt-10" form={form} onFinish={onSubmit} layout="vertical">
        <Form.Item label="Addon" name="name">
          <Input className="rounded-md h-10" placeholder="Silahkan isi addon" />
        </Form.Item>

        <Form.Item label="Harga" name="price">
          <Input className="rounded-md h-10" placeholder="Silahkan isi harga addon" />
        </Form.Item>

        <Form.Item>
          <div className="flex justify-end gap-3">
            <Button className="bg-rose-500 text-white rounded-lg" onClick={handleCancel}>
              Batalkan
            </Button>
            <Button htmlType="submit" className="bg-cyan-500 text-white rounded-lg">
              Simpan
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default EditAddons
