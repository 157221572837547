import React from 'react'

const CustomBadge = (props) => {
  if (props.formOrder) {
    return (
      <div className="relative list-none antialiased flex items-center -mt-12 rounded-2xl w-fit h-16 px-5">
        <li className="text-lg text-white font-semibold">
          {props.title}
          <span>
            <i className="fa-solid fa-house-user mx-3" />
          </span>{' '}
          <span className="text-base">{props.titleParent}</span>
        </li>
      </div>
    )
  }

  if (props.titleDashboard) {
    return (
      <div className="relative list-none antialiased flex justify-center items-center shadow-lg -mt-8 bg-[#074173] rounded-md w-fit h-10 px-8">
        <li className="text-lg text-white font-semibold">{props.title}</li>
      </div>
    )
  }

  if (props.titleOrder) {
    return (
      <div className="relative list-none antialiased flex justify-center items-center shadow-blue-300/40 shadow-lg -mt-9 bg-[#074173] rounded-md w-fit h-10 px-8">
        <li className="text-lg text-white font-semibold">{props.title}</li>
      </div>
    )
  }

  return (
    <div className="relative list-none antialiased flex items-center shadow-blue-300/40 shadow-lg -mt-12 bg-[#074173] rounded-2xl w-fit h-16 px-5">
      <li className="text-base text-white font-semibold">
        {props.title}
        <span>
          <i className="fa-solid fa-house-user mx-3" />
        </span>{' '}
        <span className="text-sm">{props.titleParent}</span>
      </li>
    </div>
  )
}

export default CustomBadge
