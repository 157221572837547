import { request } from '../config'

// versi function
function ColorVariantService() {
  // Get Data for Datatable
  const getDatatable = async (params, fiber_variant_id) => {
    const response = await request.get(
      `/fiber-variants/${fiber_variant_id}/color-variants/pagination`,
      {
        params,
      }
    )
    return response.data
  }

  // insert data
  const save = async (data, fiber_variant_id) => {
    const response = await request.post(`/fiber-variants/${fiber_variant_id}/color-variants`, data)
    return response
  }

  // update Data
  const update = async (data, fiber_variant_id, color_variant_id) => {
    const response = await request.put(
      `/fiber-variants/${fiber_variant_id}/color-variants/${color_variant_id}`,
      data
    )

    return response.data
  }

  // remove data
  const remove = async (fiber_variant_id, color_variant_id) => {
    const response = await request.delete(
      `/fiber-variants/${fiber_variant_id}/color-variants/${color_variant_id}`
    )

    return response.data
  }

  return {
    getDatatable,
    save,
    update,
    remove,
  }
}

export default ColorVariantService()
