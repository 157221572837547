/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import OrderService from '../../../../services/OrderService'

const Evidences = (props) => {
  const { open, toggle, row } = props

  const [data, setData] = useState({})
  const [itemId, setItemId] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setItemId(row?.item_id)
  }, [row])

  useEffect(() => {
    if (itemId) getDetailItemOrder()
  }, [itemId])

  const getDetailItemOrder = async () => {
    setLoading(true)
    const response = await OrderService.getDataByItem(itemId)
    try {
      if (response.status === 200) {
        setData(response.data)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const handleCancel = () => {
    toggle()
  }

  const handleOk = () => {
    toggle()
  }

  return (
    <Modal
      open={open}
      width={700}
      onCancel={handleCancel}
      onOk={handleOk}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Close
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
          Ok
        </Button>,
      ]}
    >
      <div className="grid md:grid-cols-2 gap-4">
        {data.order?.payment_histories.map((history) => {
          return history.evidences?.map((item, idx) => (
            <>
              <img
                key={idx}
                alt=""
                src={item.image}
                className="w-full"
                onClick={() => window.open(item.image, '_blank')}
              />
            </>
          ))
        })}
      </div>
    </Modal>
  )
}

export default Evidences
