import { request } from '../config'

// versi function
function PriceService() {
  const getDatatable = async (product_id, params) => {
    const response = await request.get(`/products/${product_id}/variants/pagination`, { params })
    return response.data
  }

  const update = async (products_id, variant_id, data) => {
    const response = await request.put(`/products/${products_id}/variants/${variant_id}`, data)
    return response.data
  }

  return {
    getDatatable,
    update,
  }
}

export default PriceService()
