/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Button, Input, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import CustomBadge from '../../../components/CustomBadge'
import CustomPagination from '../../../components/CustomPagination'
import RoleService from '../../../services/RoleService'

const { Search } = Input

const RolePermission = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [total, setTotal] = useState(0)
  const [params, setParams] = useState({
    page: 1,
    per_page: 10,
    sort: 'id',
    order: 'asc',
    filter: '',
  })

  useEffect(() => {
    setStateParams()
  }, [])

  useEffect(() => {
    getData()
  }, [params])

  const onSearch = (filter) => setParams({ ...params, filter })

  const onPageChange = (page, pageSize) => {
    const per_page = pageSize
    setParams({ ...params, page, per_page })
  }

  const onTableChange = (_pagination, _filters, sorter) => {
    let { columnKey: sort, order } = sorter
    sort = order ? sort : 'id' // jika order undefined default sort adalah column `id` dan order by `id` desc
    order = order === 'ascend' ? 'asc' : 'desc'
    setParams({ ...params, sort, order })
  }

  const setStateParams = () => {
    const qs = new URLSearchParams(window.location.search)
    setParams({
      page: qs.get('page') || params.page,
      per_page: qs.get('per_page') || params.per_page,
      sort: qs.get('sort') || params.sort,
      order: qs.get('order') || params.order,
      filter: qs.get('filter') || params.filter,
    })
  }

  const setQueryString = () => {
    const newParams = {}

    Object.keys(params).forEach((key) => {
      if (params[key]) {
        newParams[key] = params[key]
      }
    })

    const qs = new URLSearchParams(newParams)

    navigate({
      pathname: window.location.pathname,
      search: '?' + qs,
    })
  }

  const getData = async () => {
    try {
      setLoading(true)
      const response = await RoleService.getDatatable(params)
      if (response.status === 200) {
        setLoading(false)
        setData(response.data)
        setTotal(response.meta.total)
        setQueryString()
      }
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const managePermissions = (id) => {
    navigate(`/roles/${id}`)
  }

  const manageRoleStatus = (id) => {
    navigate(`/role-statuses/${id}`)
  }

  const columns = [
    {
      title: 'Nomor',
      dataIndex: 'no',
      key: 'no',
    },
    {
      title: 'Nama',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
    },
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      render: (_, record) => (
        <>
          <Button
            className="border-rose-500 text-rose-500 font-medium rounded-lg"
            onClick={() => managePermissions(record.id)}
          >
            <i className="fa-regular fa-pen-to-square mr-3" /> Manage Permissions
          </Button>{' '}
          &nbsp;
          <Button
            className="border-blue-500 text-blue-500 font-medium rounded-lg"
            onClick={() => manageRoleStatus(record.id)}
          >
            <i className="fa-regular fa-pen-to-square mr-3" /> Manage Status Flow
          </Button>
        </>
      ),
    },
  ]

  return (
    <div className="bg-white shadow-white-500/40 shadow-sm rounded-2xl h-fit w-full md:overflow-visible pt-4 md:pt-4">
      <div className="section-role px-2 md:px-4">
        <CustomBadge title="Role Permissions" titleParent="Master Data" />

        <h2 className="text-xl text-gray-700 mt-7">Tabel Role Permissions</h2>

        <div className="search-user mt-5">
          <Search
            className="w-80"
            placeholder={params.filter || 'Search'}
            allowClear
            size="medium"
            defaultValue={params.filter}
            onSearch={onSearch}
          />
        </div>
      </div>

      <div className="table-section-product relative w-full mt-5">
        <Table
          onChange={onTableChange}
          loading={loading}
          rowKey="id"
          columns={columns}
          dataSource={data}
          pagination={false}
          scroll={{ x: true }}
          tableLayout="auto"
        />
      </div>

      <div className="section-pagination flex justify-center md:block px-2 py-8 md:p-4">
        <CustomPagination {...params} onPageChange={onPageChange} total={total} />
      </div>
    </div>
  )
}

export default RolePermission
