/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Modal, Table, Timeline } from 'antd'
import moment from 'moment'
import 'moment/locale/id'
import React, { useEffect, useState } from 'react'
import { UNIVERSAL } from '../../../../constants'
import OrderService from '../../../../services/OrderService'

const DetailOrder = (props) => {
  const { open, toggle, row } = props

  const [data, setData] = useState({})
  const [isUniversal, setIsUniversal] = useState(false)
  const [itemId, setItemId] = useState(row?.item_id)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setItemId(row?.item_id)
  }, [row])

  useEffect(() => {
    if (itemId && open) {
      const getDetailItemOrder = async () => {
        setLoading(true)
        try {
          if (itemId) {
            const response = await OrderService.getDataByItem(itemId)
            if (response.status === 200) {
              setData(response.data)
              setIsUniversal(response.data.vehicle.vehicle_model.manufacturer.id === UNIVERSAL)
            }
          } else {
            toggle()
          }
        } catch (error) {
          console.log(error)
        } finally {
          setLoading(false)
        }
      }
      getDetailItemOrder()
    }
  }, [itemId, open])

  const handleCancel = () => {
    toggle()
  }

  const handleOk = () => {
    toggle()
  }

  const columns = [
    {
      title: 'Waktu Perubahan',
      dataIndex: 'updated_at',
      key: 'time',
      width: 200,
      render: (text) => moment(text).format('DD-MMM-YYYY, h:mm:ss'),
    },
    {
      title: 'Estimasi Awal',
      dataIndex: 'prev_estimation',
      key: 'prev_estimation',
      width: 150,
      render: (text) => moment(text).format('DD-MMM-YYYY'),
    },
    {
      title: 'Estimasi Baru',
      dataIndex: 'new_estimation',
      key: 'new_estimation',
      width: 150,
      render: (text) => moment(text).format('DD-MMM-YYYY'),
    },
    {
      title: 'Alasan Perubahan',
      dataIndex: 'reason',
      key: 'reason',
    },
  ]

  return (
    <Modal
      width={800}
      open={open}
      onCancel={handleCancel}
      onOk={handleOk}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Batal
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
          Ok
        </Button>,
      ]}
    >
      <h2 className="text-lg font-semibold">Detail Pesanan</h2>

      <div className="section-detail-order grid md:grid-cols-2 gap-2 mt-5">
        <div>
          <h2 className="font-semibold leading-0">Order</h2>
          <table>
            <tbody>
              <tr>
                <td width="33%" className="font-bold">
                  No Order
                </td>
                <td width="5%">:</td>
                <td className="text-left font-bold">{data.order?.order_number}</td>
              </tr>
              <tr>
                <td width="33%">Dealmaker</td>
                <td width="5%">:</td>
                <td className="text-left">{data.order?.dealmaker.name}</td>
              </tr>
              <tr>
                <td width="33%">Produk</td>
                <td width="5%">:</td>
                <td className="text-left">{data.product?.name}</td>
              </tr>
              <tr>
                <td width="33%">Baris</td>
                <td width="5%">:</td>
                <td className="text-left">{data.variant?.row}</td>
              </tr>
              <tr>
                <td width="33%">Mobil</td>
                <td width="5%">:</td>
                <td className="text-left">{data.vehicle?.vehicle_model?.name}</td>
              </tr>
              <tr>
                <td width="33%">Tahun</td>
                <td width="5%">:</td>
                <td className="text-left">{data.year}</td>
              </tr>
              <tr>
                <td width="33%">Material Dasar</td>
                <td width="5%">:</td>
                <td className="text-left">{data.material?.name}</td>
              </tr>
              <tr>
                <td width="33%">Serat Dasar</td>
                <td width="5%">:</td>
                <td className="text-left">{data.fiber?.name}</td>
              </tr>
              <tr>
                <td width="33%">Warna Dasar</td>
                <td width="5%">:</td>
                <td className="text-left">{data.color?.name}</td>
              </tr>

              <tr>
                <td width="33%">Material Variasi 1</td>
                <td width="5%">:</td>
                <td className="text-left">{data.material_variant_1?.name}</td>
              </tr>
              <tr>
                <td width="33%">Serat Variasi 1</td>
                <td width="5%">:</td>
                <td className="text-left">{data.fiber_variant_1?.name}</td>
              </tr>
              <tr>
                <td width="33%">Warna Variasi 1</td>
                <td width="5%">:</td>
                <td className="text-left">{data.color_variant_1?.name}</td>
              </tr>

              <tr>
                <td width="33%">Material Variasi 2</td>
                <td width="5%">:</td>
                <td className="text-left">{data.material_variant_2?.name}</td>
              </tr>
              <tr>
                <td width="33%">Serat Variasi 2</td>
                <td width="5%">:</td>
                <td className="text-left">{data.fiber_variant_2?.name}</td>
              </tr>
              <tr>
                <td width="33%">Warna Variasi 2</td>
                <td width="5%">:</td>
                <td className="text-left">{data.color_variant_2?.name}</td>
              </tr>

              <tr>
                <td width="33%">Desain</td>
                <td width="5%">:</td>
                <td className="text-left">{data.design?.name}</td>
              </tr>

              <tr>
                <td width="33%">Lubang Pengunci</td>
                <td width="5%">:</td>
                <td className="text-left">{data.lock_hole}</td>
              </tr>
              <tr>
                <td width="33%">APAR</td>
                <td width="5%">:</td>
                <td className="text-left">{data.fire_extinguisher}</td>
              </tr>

              <tr>
                <td width="33%">Logo</td>
                <td width="5%">:</td>
                <td className="text-left">{data.logo}</td>
              </tr>
              <tr>
                <td width="33%">Jenis Transmisi</td>
                <td width="5%">:</td>
                <td className="text-left">{data.transmission_type}</td>
              </tr>
            </tbody>
          </table>

          <h2 className="font-semibold leading-0 mt-3">Pembayaran</h2>
          <table>
            <tbody>
              <tr>
                <td width="33%">Status Bayar</td>
                <td width="5%">:</td>
                <td className="text-left">{data.order?.payment_status.name}</td>
              </tr>
              <tr>
                <td width="33%">Pembayaran Via</td>
                <td width="5%">:</td>
                <td className="text-left">
                  {data.order?.payment_histories.map((ph) => (
                    <>
                      {ph.payment_channel?.name}
                      <br />
                    </>
                  ))}
                </td>
              </tr>
            </tbody>
          </table>

          <h2 className="font-semibold leading-0 mt-3">Produksi</h2>
          <table>
            <tbody>
              <tr>
                <td width="33%">Status Saat Ini</td>
                <td width="5%">:</td>
                <td className="text-left">{data.status?.name}</td>
              </tr>
              <tr>
                <td width="33%">Catatan Produksi</td>
                <td width="5%">:</td>
                <td className="text-left">{data.production_note}</td>
              </tr>
              <tr>
                <td width="33%" className="text-red-500 font-bold">
                  Estimasi Selesai Produksi
                </td>
                <td width="5%">:</td>
                <td className="text-left">
                  {data.estimation ? moment(data.estimation).format('DD-MMM-YYYY') : '-'}
                </td>
              </tr>
              <tr>
                <td width="33%">Alasan Estimasi Berubah</td>
                <td width="5%">:</td>
                <td className="text-left">{data.estimation_note}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div>
          <h2 className="font-semibold leading-0">Customer</h2>
          <table>
            <tbody>
              <tr>
                <td width="33%">Nama</td>
                <td width="5%">:</td>
                <td className="text-left">{data.order?.customer?.name}</td>
              </tr>
              <tr>
                <td width="33%">Email</td>
                <td width="5%">:</td>
                <td className="text-left">{data.order?.customer?.email}</td>
              </tr>
              <tr>
                <td width="33%">Telepon</td>
                <td width="5%">:</td>
                <td className="text-left">{data.order?.customer?.phone}</td>
              </tr>
              <tr>
                <td width="33%">Jenis Customer</td>
                <td width="5%">:</td>
                <td className="text-left">{data.order?.customer_type?.name}</td>
              </tr>
            </tbody>
          </table>

          <h2 className="font-semibold leading-0 mt-3">Alamat Pengiriman</h2>
          <table>
            <tbody>
              <tr>
                <td width="33%">Nama Penerima</td>
                <td width="5%">:</td>
                <td className="text-left">{data.order?.shipment.name}</td>
              </tr>
              <tr>
                <td width="33%">Telepon</td>
                <td width="5%">:</td>
                <td className="text-left">{data.order?.shipment.phone}</td>
              </tr>
              <tr>
                <td width="33%">Email Penerima</td>
                <td width="5%">:</td>
                <td className="text-left">{data.order?.shipment.email}</td>
              </tr>
              <tr>
                <td width="33%">Alamat</td>
                <td width="5%">:</td>
                <td className="text-left">{data.order?.shipment.address}</td>
              </tr>
              <tr>
                <td width="33%">Kecamatan</td>
                <td width="5%">:</td>
                <td className="text-left">{data.order?.shipment.subdistrict.name}</td>
              </tr>
              <tr>
                <td width="33%">Kabupaten/Kota</td>
                <td width="5%">:</td>
                <td className="text-left">
                  {data.order?.shipment.district.type} {data.order?.shipment.district.name}
                </td>
              </tr>
              <tr>
                <td width="33%">Provinsi</td>
                <td width="5%">:</td>
                <td className="text-left">{data.order?.shipment.province.name}</td>
              </tr>
              <tr>
                <td>Kode Pos</td>
                <td width="5%">:</td>
                <td className="text-left">{data.order?.shipment.postal_code}</td>
              </tr>
              <tr>
                <td width="33%">
                  <strong>Kurir</strong>
                </td>
                <td width="5%">:</td>
                <td className="text-left">
                  <strong>
                    {[data.order?.shipment.courier_name, data.order?.shipment.courier_service]
                      .filter((e) => e)
                      .join(' - ')}
                  </strong>
                </td>
              </tr>
              <tr>
                <td width="33%">
                  <strong>Resi</strong>
                </td>
                <td width="5%">:</td>
                <td className="text-left">
                  <strong>{data.awb}</strong>
                </td>
              </tr>
              <tr>
                <td width="33%">
                  <strong>Maksimal Tanggal Pengiriman</strong>
                </td>
                <td width="5%">:</td>
                <td className="text-left">
                  <strong>
                    {data.order?.max_shipping_date ? (
                      <>{moment(data.order.max_shipping_date).format('LLLL')} WIB</>
                    ) : (
                      '-'
                    )}
                  </strong>
                </td>
              </tr>
              <tr>
                <td className="text-blue-500 font-bold">Estimasi Selesai Dealmaker</td>
                <td width="5%">:</td>
                <td className="text-left">
                  {data.estimation_dealmaker
                    ? moment(data.estimation_dealmaker).format('DD-MMM-YYYY')
                    : '-'}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {data.images?.length > 0 && (
        <h2 className="text-lg font-semibold leading-0 my-5">Gambar Pesanan</h2>
      )}
      {data.images?.map((item) => (
        <img src={item.image} alt="" />
      ))}

      {data.estimation_histories?.length > 0 && (
        <>
          <h2 className="text-lg font-semibold leading-0 my-5">Riwayat Perubahan Estimasi</h2>
          <div className="table-section-product relative w-full">
            <Table
              rowKey="id"
              columns={columns}
              dataSource={data?.estimation_histories}
              tableLayout="auto"
              pagination={false}
            />
          </div>
        </>
      )}

      {data.histories?.length > 0 && (
        <h2 className="text-lg font-semibold leading-0 my-5">Riwayat Produksi</h2>
      )}
      <Timeline mode="left">
        {isUniversal
          ? data.histories
              ?.filter((history) => history.status_type_id !== 3)
              .map((item, key) => (
                <Timeline.Item key={key} label={moment(item.created_at).format('LLLL [WIB]')}>
                  <h3 className="text-base font-semibold">{item.status.name}</h3>
                  <p className="mt-2">{item.user.name}</p>
                  <div>
                    {item.images.map((value, key) => (
                      <img
                        onClick={() => window.open(value.image, '_blank')}
                        style={{ display: 'inline' }}
                        src={value.image}
                        alt=""
                        key={key}
                        className="w-24"
                      />
                    ))}
                  </div>
                </Timeline.Item>
              ))
          : data.histories?.map((item, key) => (
              <Timeline.Item key={key} label={moment(item.created_at).format('LLLL [WIB]')}>
                <h3 className="text-base font-semibold">{item.status.name}</h3>
                <p className="mt-2">{item.user.name}</p>
                <div>
                  {item.images.map((value, key) => (
                    <img
                      onClick={() => window.open(value.image, '_blank')}
                      style={{ display: 'inline' }}
                      src={value.image}
                      alt=""
                      key={key}
                      className="w-24"
                    />
                  ))}
                </div>
              </Timeline.Item>
            ))}
      </Timeline>
    </Modal>
  )
}

export default DetailOrder
