/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Input, Space, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import Swal from 'sweetalert2'
import BButton from '../../../components/BButton'
import CustomBadge from '../../../components/CustomBadge'
import CustomPagination from '../../../components/CustomPagination'
import CategoryService from '../../../services/CategoryService'

const { Search } = Input

const Category = () => {
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [total, setTotal] = useState(0)
  const [params, setParams] = useState({
    page: 1,
    per_page: 10,
    sort: 'id',
    order: 'desc',
    filter: '',
  })

  useEffect(() => {
    setStateParams()
  }, [])

  useEffect(() => {
    getData()
  }, [params])

  const getData = async () => {
    setLoading(true)
    const response = await CategoryService.getDatatable(params)
    try {
      if (response.status === 200) {
        setLoading(false)
        setData(response.data)
        setTotal(response.meta.total)
        setQueryString()
      }
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const onSearch = (filter) => setParams({ ...params, filter })

  const onPageChange = (page, pageSize) => {
    const per_page = pageSize
    setParams({ ...params, page, per_page })
  }

  const onTableChange = (_pagination, _filters, sorter) => {
    let { columnKey: sort, order } = sorter
    sort = order ? sort : 'id' // jika order undefined default sort adalah column `id` dan order by `id` desc
    order = order === 'ascend' ? 'asc' : 'desc'
    setParams({ ...params, sort, order })
  }

  const setStateParams = () => {
    const qs = new URLSearchParams(window.location.search)
    setParams({
      page: qs.get('page') || params.page,
      per_page: qs.get('per_page') || params.per_page,
      sort: qs.get('sort') || params.sort,
      order: qs.get('order') || params.order,
      filter: qs.get('filter') || params.filter,
    })
  }

  const setQueryString = () => {
    const newParams = {}

    Object.keys(params).forEach((key) => {
      if (params[key]) {
        newParams[key] = params[key]
      }
    })

    const qs = new URLSearchParams(newParams)

    navigate({
      pathname: window.location.pathname,
      search: '?' + qs,
    })
  }

  const addCategories = () => {
    navigate(`/master-product/categories/add`)
  }

  const editDataSection = (record) => {
    navigate(`/master-product/categories/${record.id}`)
  }

  const deleteData = (categoryId) => {
    Swal.fire({
      title: 'Apakah anda yakin ingin menghapus data ini?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await CategoryService.remove(categoryId)
        if (response.status === 200) {
          Swal.fire('Berhasil!', '', 'success')
        }
        getData()
      }
    })
  }

  const columns = [
    {
      title: 'Nomor',
      dataIndex: 'no',
      key: 'no',
    },
    {
      title: 'Nama',
      dataIndex: 'name',
      key: 'name',
      render: (text) => <h2 className="text-gray-800">{text}</h2>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => {
        const iconButton = status
          ? 'fa-solid fa-square-check text-base text-green-500'
          : 'fa-solid fa-circle-xmark text-base text-rose-600'

        return <i className={iconButton} />
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button
            className="text-yellow-500 border-yellow-500 rounded-lg font-medium"
            onClick={() => editDataSection(record)}
          >
            <i className="fa-solid fa-pen-to-square mr-2" /> Edit
          </Button>

          <Button
            className="text-red-500 border-red-500 rounded-lg font-medium"
            onClick={() => deleteData(record.id)}
          >
            <i className="fa-solid fa-trash-can mr-2" /> Delete
          </Button>
        </Space>
      ),
    },
  ]

  return (
    <div className="bg-white shadow-white-500/40 shadow-sm rounded-2xl h-fit w-full md:overflow-visible pt-4 md:pt-4">
      <div className="section-category px-2 md:px-4">
        <CustomBadge title="Kategori" titleParent="Master Data" />

        <h2 className="text-xl text-gray-700 mt-7">Tabel Kategori</h2>

        <div className="flex justify-between items-center mt-8">
          <div className="search-user">
            <Search
              className="search-user md:w-80"
              placeholder="Search"
              allowClear
              size="medium"
              onSearch={onSearch}
            />
          </div>
          <BButton onClick={() => addCategories()}>Tambah</BButton>
        </div>
      </div>

      <div className="table-section-product mt-5">
        <Table
          rowKey="id"
          size="large"
          loading={loading}
          onChange={onTableChange}
          columns={columns}
          dataSource={data}
          pagination={false}
          scroll={{ x: true }}
          tableLayout="auto"
        />
      </div>

      <div className="section-pagination flex justify-center md:block px-2 py-8 md:p-4">
        <CustomPagination {...params} onPageChange={onPageChange} total={total} />
      </div>
    </div>
  )
}

export default Category
