import React, { useState } from 'react'
import CoverMobilIndonesia from './CoverMobilIndonesia'
import CoversuperExcellent from './CoversuperExcellent'
import CoversuperGreat from './CoversuperGreat'
import EmirateCarMat from './EmirateCarMat'
import EmirateSeatCover from './EmirateSeat'
import Starprotection from './Starprotection'
import TeamTable from './TeamTable'

const Scoreboard = () => {
  const [summaryShow, setSummaryShow] = useState({
    stp: false,
    cs_great: false,
    cs_excellent: false,
    cmi: false,
    emirate: false,
    carmat: false,
  })

  function handleSummaryShow(name, value) {
    setSummaryShow({
      ...summaryShow,
      [name]: value,
    })
  }

  return (
    <div className="grid md:grid-cols-2 gap-2 p-3">
      <div>
        <div
          className="cursor-pointer"
          onClick={() => handleSummaryShow('cs_excellent', !summaryShow.cs_excellent)}
        >
          <CoversuperExcellent />
        </div>

        {summaryShow.cs_excellent && (
          <TeamTable type="cs_excellent" name="DAILY COVERSUPER EXCELLENT TEAM DASHBOARD" />
        )}
      </div>

      <div>
        <div
          className="cursor-pointer"
          onClick={() => handleSummaryShow('cs_great', !summaryShow.cs_great)}
        >
          <CoversuperGreat />
        </div>

        {summaryShow.cs_great && (
          <TeamTable type="cs_great" name="DAILY COVERSUPER GREAT TEAM DASHBOARD" />
        )}
      </div>

      <div>
        <div className="cursor-pointer" onClick={() => handleSummaryShow('stp', !summaryShow.stp)}>
          <Starprotection />
        </div>

        {summaryShow.stp && <TeamTable type="stp" name="DAILY STAR PROTECTION DASHBOARD" />}
      </div>

      <div>
        <div className="cursor-pointer" onClick={() => handleSummaryShow('cmi', !summaryShow.cmi)}>
          <CoverMobilIndonesia />
        </div>

        {summaryShow.cmi && <TeamTable type="cmi" name="DAILY COVER MOBIL INDONESIA DASHBOARD" />}
      </div>

      <div>
        <div
          className="cursor-pointer"
          onClick={() => handleSummaryShow('emirate', !summaryShow.emirate)}
        >
          <EmirateSeatCover />
        </div>

        {summaryShow.emirate && (
          <TeamTable type="emirate" name="DAILY EMIRATE SEAT COVER DASHBOARD" />
        )}
      </div>

      <div>
        <div
          className="cursor-pointer"
          onClick={() => handleSummaryShow('carmat', !summaryShow.carmat)}
        >
          <EmirateCarMat />
        </div>

        {summaryShow.carmat && <TeamTable type="carmat" name="DAILY EMIRATE CAR MAT DASHBOARD" />}
      </div>
    </div>
  )
}

export default Scoreboard
