var packageJson = require('../../package.json')
/**
 * VERSI FLOW EMIRATE
 */

export const FLOW_VERSION = packageJson.version

/**
 * UNIT / DIVISI
 */
export const UNIT_SEAT_COVER = 1 // SEAT COVER
export const UNIT_CAR_MAT = 2 // CARMAT SUPER
export const UNIT_KAIZEN = 3 // CARMAT KAIZEN
export const UNIT_AVENGERS = 4 // CARMAT AVENGERS

/**
 * ROLE
 */
export const ROLE_ADMIN = 1
export const ROLE_DEALMAKER = 2
export const ROLE_SHIPPER = 3
export const ROLE_RESEPER = 4
export const ROLE_POTONG = 5
export const ROLE_JAHIT = 6
export const ROLE_QC = 7
export const ROLE_PACKING = 8
export const ROLE_RESI = 9
export const ROLE_ADMIN_PRODUCTION = 10
export const ROLE_FINANCE = 11
export const ROLE_MARKETING = 12
export const ROLE_CS_SUPERVISOR = 13
// export const ROLE_PENJAHIT_EKSTERNAL = 14
// export const ROLE_ADMIN_PRODUCTION_2 = 15
export const ROLE_CS_CARE = 16
export const ROLE_OPERASIONAL = 17
// export const ROLE_AFFILIATE = 18
export const ROLE_POLA = 22
export const ROLE_POLA_POTONG = 23
export const ROLE_SUPPORT_PRODUKSI = 24
export const ROLE_POLA_HUNTER = 25
export const ROLE_CUSTOMER = 100

/*
  |--------------------------------------------------------------------------
  | CUSTOMER TYPE
  |--------------------------------------------------------------------------
  */
export const CUSTOMER_RETAIL = 1
export const CUSTOMER_AGEN = 2

/*
  |--------------------------------------------------------------------------
  | PAYMENT STATUS
  |--------------------------------------------------------------------------
  */
export const PAYMENT_STATUS_UNPAID = 1
export const PAYMENT_STATUS_DP = 2
export const PAYMENT_STATUS_PAID = 3
export const PAYMENT_STATUS_REFUND = 4
export const PAYMENT_STATUS_CANCEL = 5
export const PAYMENT_STATUS_COD_DP = 6
export const PAYMENT_STATUS_MARKETPLACE = 7
export const PAYMENT_STATUS_COD_FULL = 8
export const PAYMENT_STATUS_BILLING = 18

/**
  |--------------------------------------------------------------------------
  | PRODUCTION STATUS
  |--------------------------------------------------------------------------
 */
export const STATUS_UNVERIFIED = 2
export const STATUS_CETAK_LABEL = 30
export const STATUS_CEK_KESESUAIAN_POLA = 3
export const STATUS_ANTRI_POLA = 4
export const STATUS_POLA_SEDANG_DIBUAT = 6
export const STATUS_ANTRI_POTONG = 7
export const STATUS_ANTRI_JAHIT = 9
export const STATUS_ANTRI_QC = 12
export const STATUS_QC_CHECK = 15
export const STATUS_PROSES_PACKING = 18
export const STATUS_SEDANG_DIKIRIM = 20
export const STATUS_DITERIMA = 21
export const STATUS_CANCEL = 22
export const STATUS_PENDING = 27

export const UNIVERSAL = 173

/**
  |--------------------------------------------------------------------------
  | PLATFORM
  |--------------------------------------------------------------------------
 */
export const PLATFORM_REGULAR = 'Regular'
export const PLATFORM_TOKOPEDIA = 'Tokopedia'
export const PLATFORM_SHOPEE = 'Shopee'
export const PLATFORM_TIKTOK = 'TikTok'
export const PLATFORM_LAZADA = 'Lazada'
export const PLATFORM_COD_NINJA = 'COD Ninja'
