/* eslint-disable react-hooks/exhaustive-deps */
import { ExclamationCircleFilled } from '@ant-design/icons'
import { Button, DatePicker, Form, Input, Modal, notification, Select, Table, Tag } from 'antd'
import moment from 'moment'

import React, { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from 'react-router'
import ability from '../../../ability/ability'
import CustomBadge from '../../../components/CustomBadge'
import CustomPagination from '../../../components/CustomPagination'
import {
  PAYMENT_STATUS_BILLING,
  PAYMENT_STATUS_CANCEL,
  PAYMENT_STATUS_COD_DP,
  PAYMENT_STATUS_COD_FULL,
  PAYMENT_STATUS_DP,
  PAYMENT_STATUS_MARKETPLACE,
  PAYMENT_STATUS_PAID,
  PAYMENT_STATUS_REFUND,
  PAYMENT_STATUS_UNPAID,
  STATUS_CEK_KESESUAIAN_POLA,
  STATUS_CETAK_LABEL,
  STATUS_DITERIMA,
  STATUS_PENDING,
  STATUS_POLA_SEDANG_DIBUAT,
  STATUS_PROSES_PACKING,
  STATUS_SEDANG_DIKIRIM,
} from '../../../constants'
import OrderService from '../../../services/OrderService'
import Invoice from './Invoice'
import ChangeEstimation from './Modal/ChangeEstimation'
import ChangeStatus from './Modal/ChangeStatus'
import DetailOrder from './Modal/DetailOrder'
import Evidences from './Modal/Evidences'
import PayoffSet from './Modal/PayoffSet'
import Pending from './Modal/Pending'
import SetEstimation from './Modal/SetEstimation'
import TrackingNumber from './Modal/TrackingNumber'
import VerifyOrder from './Modal/VerifyOrder'
import ImportPayoff from './Modal/ImportPayoff'

const { confirm } = Modal

const TableOrder = () => {
  const navigate = useNavigate()

  const [form] = Form.useForm()

  const [modal, setModal] = useState({
    isVerifyModalOpen: false,
    isInvoiceModalOpen: false,
    isTrackingNumberModalOpen: false,
    isDetailOrderOpen: false,
    isPayoffSetOpen: false,
    isImportPayoffOpen: false,
    isEvindenceModalOpen: false,
    isEstimationModalOpen: false,
    isChangeEstimationModalOpen: false,
    isPendingModalOpen: false,
    isChangeStatusModalOpen: false,
  })
  const [selectedRow, setSelectedRow] = useState(null)
  const [filterColumn, setFilterColumn] = useState([])
  const [unitOptions, setUnitOptions] = useState([])
  const [start_date, setStartDate] = useState('')
  const [end_date, setEndDate] = useState('')
  const [filterPayment, setFilterPayment] = useState([])
  const [filterStatusProduction, setFilterStatusProduction] = useState([])
  const [dataOrder, setDataOrder] = useState([])
  const [loading, setLoading] = useState(false)
  const [total, setTotal] = useState(0)
  const [params, setParams] = useState({
    page: 1,
    per_page: 20,
    filter_column: '',
    start_date: '',
    end_date: '',
    sort: 'id',
    order: 'desc',
    filter_text: '',
    payment_status_id: null,
    status_type_id: null,
    unit_id: null,
  })

  useEffect(() => {
    setStateParams()
  }, [])

  useEffect(() => {
    getDataOrder()
    getDataFilterByUnit()
    getDataFilterByColumn()
    getDataFilterByPayment()
    getDataFilterByStatusProduction()
  }, [params])

  const isMobileView = useMediaQuery({ query: '(max-width: 750px)' })

  const getDataOrder = async () => {
    setLoading(true)
    const response = await OrderService.getDatatableOrder(params)
    try {
      if (response.status === 200) {
        setLoading(false)
        setDataOrder(response.data)
        setTotal(response.meta.total)
        setQueryString()
      }
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const onPageChange = (page, pageSize) => {
    const per_page = pageSize
    setParams({ ...params, page, per_page })
  }

  const onTableChange = (_pagination, _filters, sorter) => {
    let { columnKey: sort, order } = sorter
    sort = order ? sort : 'id' // jika order undefined default sort adalah column `id` dan order by `id` desc
    order = order === 'ascend' ? 'asc' : 'desc'
    setParams({ ...params, sort, order })
  }

  const setStateParams = () => {
    const qs = new URLSearchParams(window.location.search)

    setParams({
      page: qs.get('page') || params.page,
      per_page: qs.get('per_page') || params.per_page,
      start_date: qs.get('start_date') || params.start_date,
      end_date: qs.get('end_date') || params.end_date,
      unit_id: qs.get('unit_id') || params.unit_id,
      payment_status_id: qs.get('payment_status_id') || params.payment_status_id,
      status_type_id: qs.get('status_type_id') || params.status_type_id,
      sort: qs.get('sort') || params.sort,
      order: qs.get('order') || params.order,
      filter_text: qs.get('filter_text') || params.filter_text,
      filter_column: qs.get('filter_column') || params.filter_column,
    })
  }

  const setQueryString = () => {
    const newParams = {}

    Object.keys(params).forEach((key) => {
      if (params[key]) {
        newParams[key] = params[key]
      }
    })

    const qs = new URLSearchParams(newParams)

    navigate({
      pathname: window.location.pathname,
      search: '?' + qs,
    })
  }

  const getDataFilterByPayment = async () => {
    const response = await OrderService.getOrderFilterByPayment()
    try {
      setFilterPayment(
        response.data.map((filter) => ({
          value: filter.value,
          label: filter.name,
        }))
      )
    } catch (error) {
      console.log(error)
    }
  }

  const getDataFilterByStatusProduction = async () => {
    const response = await OrderService.getOrderFilterByStatusProduction()
    try {
      setFilterStatusProduction(
        response.data.map((filter) => ({
          value: filter.value,
          label: filter.name,
        }))
      )
    } catch (error) {
      console.log(error)
    }
  }

  const getDataFilterByColumn = async () => {
    const response = await OrderService.getOrderFilterByColumn()
    try {
      setFilterColumn(
        response.data.map((filter) => ({
          value: filter.value,
          label: filter.name,
        }))
      )
    } catch (error) {
      console.log(error)
    }
  }

  const dateFilterChange = (_, dateStrings) => {
    setStartDate(dateStrings && dateStrings[0])
    setEndDate(dateStrings && dateStrings[1])
  }

  const nextStatus = async (item_id, next_status_id) => {
    const res = await OrderService.nextStatus({ item_id, next_status_id })
    if (res.status === 200) {
      getDataOrder()
    }
  }

  const continueToPrevious = async (item_id) => {
    const res = await OrderService.continueToPrevious({ item_id })
    if (res.status === 200) {
      getDataOrder()
    }
  }

  const deleteOrder = async (orderId) => {
    Modal.confirm({
      title: 'Yakin menghapus order ini?',
      content: 'Mohon dicek kembali order yang mau dihapus.',
      okText: 'Ya',
      okType: 'danger',
      cancelText: 'Tidak',
      async onOk() {
        try {
          const response = await OrderService.deleteOrder(orderId)
          if (response.status === 200) {
            notification.success({
              message: 'Berhasil',
              description: 'Berhasil dihapus, jika ingin restore silakan hubungi Admin',
              placement: 'top',
            })
            getDataOrder()
          }
        } catch (error) {
          console.log(error)
        }
      },
    })
  }

  const toggleModal = (name) => {
    setModal((prev) => ({ ...prev, [name]: !prev[name] }))
  }

  const showConfirm = (itemId, nextStatusId, nextStatusName) => {
    if (nextStatusId === STATUS_CETAK_LABEL) {
      toggleModal('isVerifyModalOpen')
    } else if (nextStatusId === STATUS_POLA_SEDANG_DIBUAT) {
      toggleModal('isEstimationModalOpen')
    } else if (nextStatusId === STATUS_SEDANG_DIKIRIM) {
      toggleModal('isTrackingNumberModalOpen')
    } else {
      let title = (
        <p>
          Apakah anda yakin melanjutkan ke
          <span className="text-cyan-500">{nextStatusName}</span>?
        </p>
      )
      // khusus ketika cetak label diklik, nextnya kan cek kesesuaian pola,
      // tapi kalau ready stock akan ke proses packing,
      // jadi tidak perlu dicantumkan next statusnya, cukup konfirmasi saja button current statusnya
      if (nextStatusId === STATUS_CEK_KESESUAIAN_POLA) {
        title = <p>Apakah anda yakin Cetak Label ?</p>
      }

      confirm({
        title,
        icon: <ExclamationCircleFilled />,
        cancelText: 'Batal',
        okText: 'Ya',
        // content: 'Some descriptions',
        onOk() {
          nextStatus(itemId, nextStatusId)
          if (nextStatusId === STATUS_CEK_KESESUAIAN_POLA) {
            window.open(`/print/label/${itemId}`, '_blank')
          }
        },
        onCancel() {
          console.log('Cancel')
        },
      })
    }
  }

  const pendingIfNoPattern = async (itemId) => {
    const body = {
      reason: 'POLA TIDAK TERSEDIA',
      item_id: itemId,
    }
    const response = await OrderService.pending(body)

    try {
      if (response.status === 200) {
        getDataOrder()
      }
    } catch (error) {
      console.log(error)
    }
  }

  const columns = [
    {
      title: 'ID Produksi',
      dataIndex: 'item_id',
      key: 'item_id',
      fixed: isMobileView ? '' : 'left',
    },
    {
      title: 'No Order',
      dataIndex: 'order_number',
      key: 'order_number',
      // sorter: true,
      fixed: isMobileView ? '' : 'left',
      render: (text, record) => (
        <h2 className="font-semibold text-gray-900 md:w-28">{record.order.order_number}</h2>
      ),
    },
    {
      title: 'Pembayaran',
      dataIndex: 'payment_status',
      key: 'payment_status',
      fixed: isMobileView ? '' : 'left',
      align: 'center',
      render: (text, record) => {
        let color = 'success'
        switch (record.order.payment_status.id) {
          case PAYMENT_STATUS_PAID:
            color = '#87d068'
            break
          case PAYMENT_STATUS_DP:
            color = '#2db7f5'
            break
          default:
            color = '#fc4a1a'
        }

        return <Tag color={color}>{record.order.payment_status.name}</Tag>
      },
    },
    {
      title: 'Dealmaker',
      dataIndex: 'dealmaker',
      key: 'dealmaker',
      render: (_, record) => <h2 className="text-sm">{record.order.dealmaker.name}</h2>,
    },
    {
      title: 'Pemesan',
      dataIndex: 'customer',
      key: 'customer',
      render: (_, record) => <h2 className="text-sm">{record.order.customer.name}</h2>,
    },
    {
      title: 'No Telp Pemesan',
      dataIndex: 'phone',
      key: 'phone',
      render: (_, record) => <h2 className="text-sm">{record.order.customer.phone}</h2>,
    },
    {
      title: 'Penerima',
      dataIndex: 'customer',
      key: 'customer',
      render: (_, record) => <h2 className="text-sm">{record.order.shipment.name}</h2>,
    },
    {
      title: 'No Telp Penerima',
      dataIndex: 'phone',
      key: 'phone',
      render: (_, record) => <h2 className="text-sm">{record.order.shipment.phone}</h2>,
    },
    {
      title: 'Platform',
      dataIndex: 'platform',
      key: 'platform',
      render: (_, record) => <h2 className="text-sm">{record.order.platform}</h2>,
    },
    {
      title: 'Invoice Marketplace',
      dataIndex: 'invoice_marketplace',
      key: 'invoice_marketplace',
      render: (_, record) => <h2 className="text-sm w-20">{record.order.invoice_marketplace}</h2>,
    },
    {
      title: 'Maksimal Tanggal Pengiriman',
      dataIndex: 'max_shipping_date',
      key: 'max_shipping_date',
      render: (_, record) =>
        record.order.max_shipping_date ? (
          <h2 className="font-semibold text-sm w-32">
            {moment(record.order.max_shipping_date).format('LLLL')} WIB
          </h2>
        ) : (
          <h2 className="font-semibold text-sm w-24">-</h2>
        ),
    },
    {
      title: 'Produk',
      dataIndex: 'product',
      key: 'product',
      render: (_, record) => <h2 className="text-sm">{record.product.name}</h2>,
    },
    {
      title: 'Baris',
      dataIndex: 'row',
      key: 'row',
      render: (_, record) => record.variant?.row,
    },
    {
      title: 'Mobil',
      dataIndex: 'vehicle',
      key: 'vehicle',
      render: (_, record) => (
        <h2 className="text-sm">
          {[record.vehicle?.vehicle_model?.manufacturer?.name, record.vehicle?.vehicle_model?.name]
            .filter((e) => e)
            .join(' ')}{' '}
          - {record.year}
        </h2>
      ),
    },
    {
      title: 'Material Dasar',
      dataIndex: 'material',
      key: 'material',
      render: (_, record) => <h2 className="w-20">{record.material?.name}</h2>,
    },
    {
      title: 'Serat',
      dataIndex: 'fiber',
      key: 'fiber',
      render: (_, record) => record.fiber?.name,
    },
    {
      title: 'Warna Dasar',
      dataIndex: 'color',
      key: 'color',
      render: (_, record) => record.color?.name,
    },

    {
      title: 'Material Variasi 1',
      dataIndex: 'material_variant_1',
      key: 'material_variant_1',
      render: (_, record) => <h2 className="w-20">{record.material_variant_1?.name}</h2>,
    },
    {
      title: 'Serat Variasi 1',
      dataIndex: 'fiber_variant_1',
      key: 'fiber_variant_1',
      render: (_, record) => record.fiber_variant_1?.name,
    },
    {
      title: 'Warna Variasi 1',
      dataIndex: 'color_variant_1',
      key: 'color_variant_1',
      render: (_, record) => <h2 className="w-20">{record.color_variant_1?.name}</h2>,
    },

    {
      title: 'Material Variasi 2',
      dataIndex: 'material_variant_2',
      key: 'material_variant_2',
      render: (_, record) => <h2 className="w-20">{record.material_variant_2?.name}</h2>,
    },
    {
      title: 'Serat Variasi 2',
      dataIndex: 'fiber_variant_2',
      key: 'fiber_variant_2',
      render: (_, record) => <h2 className="w-20">{record.fiber_variant_2?.name}</h2>,
    },
    {
      title: 'Warna Variasi 2',
      dataIndex: 'color_variant_2',
      key: 'color_variant_2',
      render: (_, record) => <h2 className="w-20">{record.color_variant_2?.name}</h2>,
    },
    {
      title: 'Design',
      dataIndex: 'design',
      key: 'design',
      render: (_, record) => record.design?.name,
    },
    {
      title: 'Lubang Pengunci',
      dataIndex: 'lock_hole',
      key: 'lock_hole',
      render: (_, record) => record.lock_hole,
    },
    {
      title: 'APAR',
      dataIndex: 'fire_extinguisher',
      key: 'fire_extinguisher',
      render: (_, record) => record.fire_extinguisher,
    },
    {
      title: 'Logo',
      dataIndex: 'logo',
      key: 'logo',
      render: (_, record) => record.logo,
    },
    {
      title: 'Transmisi',
      dataIndex: 'transmission_type',
      key: 'transmission_type',
      render: (_, record) => record.transmission_type,
    },
    {
      title: 'Tambahan Aksesoris',
      dataIndex: 'addons',
      key: 'addons',
      width: 180,
      render: (addons) => <>{addons?.map((item) => item.name).join(', ')}</>,
    },
    {
      title: 'Catatan Produk',
      dataIndex: 'note',
      key: 'note',
      render: (note) => <h2 className="w-48">{note}</h2>,
    },
    {
      title: 'Bonus',
      dataIndex: 'bonus',
      key: 'bonus',
      render: (_, record) => <>{record.order.bonus?.join(', ')}</>,
    },
    {
      title: 'Keterangan Order',
      dataIndex: 'description',
      key: 'description',
      render: (_, record) => (
        <>
          <h2 className="text-sm w-48">{record.order.description}</h2>
        </>
      ),
    },
    {
      title: 'Jasa Pengiriman',
      dataIndex: 'courier_name',
      key: 'courier_name',
      render: (_, record) => (
        <h2 className="text-sm">
          {[record.order.shipment.courier_name, record.order.shipment.courier_service]
            .filter((e) => e)
            .join(' - ')}
        </h2>
      ),
    },
    {
      title: 'Nomor Resi',
      dataIndex: 'awb',
      key: 'awb',
      render: (awb) => <h2 className="w-20">{awb}</h2>,
    },
    {
      title: 'Estimasi Dealmaker',
      dataIndex: 'estimation_dealmaker',
      key: 'estimation_dealmaker',
      width: 180,
      align: 'center',
      render: (estimation_dealmaker) => (
        <>
          <h2 className="text-sm text-blue-500 font-bold">
            {estimation_dealmaker ? moment(estimation_dealmaker).format('DD-MM-YYYY') : ''}
          </h2>
        </>
      ),
    },
    {
      title: 'Estimasi Produksi',
      dataIndex: 'estimation',
      key: 'estimation',
      width: 180,
      align: 'center',
      render: (estimation) => (
        <>
          <h2 className="text-sm text-rose-500 font-bold">
            {estimation ? moment(estimation).format('DD-MM-YYYY') : ''}
          </h2>
        </>
      ),
    },
    {
      title: 'Catatan Estimasi',
      dataIndex: 'estimation_note',
      key: 'estimation_note',
      width: 180,
    },
    {
      title: 'Status Produksi',
      dataIndex: 'status_type',
      key: 'status_type',
      render: (text, record) => {
        const { status } = record

        let changeStatusButton = <></>

        if (
          status.id >= STATUS_CEK_KESESUAIAN_POLA &&
          status.id <= STATUS_PROSES_PACKING &&
          ability.can('change', 'Production Status')
        ) {
          changeStatusButton = (
            <Button
              size="small"
              className="border-0 text-xs text-white bg-orange-500 rounded-md font-medium mt-2"
              onClick={() => {
                setSelectedRow(record)
                toggleModal('isChangeStatusModalOpen')
              }}
            >
              <i className="fas fa-rotate mr-1" />
              Ganti Status
            </Button>
          )
        }

        return (
          <>
            <Tag color="#108ee9">{record.status.name}</Tag>
            <br />
            {changeStatusButton}
          </>
        )
      },
    },
    {
      title: 'Next Progress',
      key: 'next_status',
      // fixed: 'right',
      width: 150,
      render: (_, record) => {
        const status = record.status

        let nextButton = null
        let pendingButton = null
        let continueButton = null

        if (
          status.next_status &&
          ((status?.next_status.id >= STATUS_CEK_KESESUAIAN_POLA &&
            status?.next_status.id <= STATUS_DITERIMA) ||
            status?.next_status.id === STATUS_CETAK_LABEL) &&
          ability.can('update', 'Flow')
        ) {
          nextButton = (
            <Button
              size="small"
              className="border-0 text-xs text-white bg-green-500 rounded-md font-medium"
              onClick={() => {
                setSelectedRow(record)
                showConfirm(record.item_id, status?.next_status?.id, status?.next_status?.name)
              }}
            >
              <i className="fas fa-check mr-1" />
              {status?.next_status?.action || status?.next_status?.name}
            </Button>
          )
        }

        // pending button
        if (
          status.id > STATUS_CEK_KESESUAIAN_POLA &&
          status.id <= STATUS_PROSES_PACKING &&
          ability.can('pending', 'Flow')
        ) {
          pendingButton = (
            <Button
              size="small"
              className="border-0 text-xs text-white bg-orange-500 rounded-md font-medium mt-2"
              onClick={() => {
                setSelectedRow(record)
                toggleModal('isPendingModalOpen')
              }}
            >
              <i className="fa-regular fa-clock mr-1" /> Pending
            </Button>
          )
        }

        // tidak ada pola (pending dengan default reason)
        // hanya muncul ketika status cek kesesuaian pola
        if (status.id === STATUS_CEK_KESESUAIAN_POLA && ability.can('pending', 'Flow')) {
          pendingButton = (
            <Button
              size="small"
              className="border-0 text-xs text-white bg-orange-500 rounded-md font-medium mt-2"
              onClick={() => {
                confirm({
                  title: (
                    <p>
                      Apakah anda yakin
                      <span className="text-cyan-500"> POLA TIDAK TERSEDIA ?</span>
                    </p>
                  ),
                  icon: <ExclamationCircleFilled />,
                  cancelText: 'Batal',
                  okText: 'Ya',
                  onOk() {
                    pendingIfNoPattern(record.item_id)
                  },
                  onCancel() {
                    console.log('Cancel')
                  },
                })
              }}
            >
              <i className="fas fa-xmark mr-1" /> Tidak Ada Pola
            </Button>
          )
        }

        // continue to previous status
        if (status.id === STATUS_PENDING && ability.can('pending', 'Flow')) {
          continueButton = (
            <Button
              size="small"
              className="border-0 text-xs text-white bg-orange-500 rounded-md font-medium mt-2"
              onClick={() => {
                confirm({
                  title: (
                    <p>
                      Apakah anda yakin melanjutkan proses produksinya
                      <span className="text-cyan-500">?</span>
                    </p>
                  ),
                  icon: <ExclamationCircleFilled />,
                  cancelText: 'Batal',
                  okText: 'Ya',
                  onOk() {
                    continueToPrevious(record.item_id)
                  },
                  onCancel() {
                    console.log('Cancel')
                  },
                })
              }}
            >
              Lanjutkan
            </Button>
          )
        }

        return (
          <>
            {continueButton || nextButton}
            <br />
            {pendingButton}
          </>
        )
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <div className="grid md:grid-cols-1 gap-2 w-40">
          <Button
            size="small"
            className="border-0 text-xs text-white bg-cyan-500 rounded-md font-medium"
            onClick={() => {
              setSelectedRow(record)
              toggleModal('isDetailOrderOpen')
            }}
          >
            <i className="fas fa-circle-info mr-2" /> Detail
          </Button>

          <Button
            size="small"
            className="border-0 text-xs text-white bg-lime-500 rounded-md font-medium"
            onClick={() =>
              window.open(
                `https://track.emiratecarinterior.com/?order-number=${record.order.order_number}`
              )
            }
          >
            <i className="fa-solid fa-search mr-2" /> Lacak Order
          </Button>

          {record.status.id > STATUS_CEK_KESESUAIAN_POLA &&
            record.status.id <= STATUS_PROSES_PACKING &&
            ability.can('change', 'Estimation') && (
              <Button
                size="small"
                className="border-0 text-xs text-white bg-yellow-600 rounded-md font-medium"
                onClick={() => {
                  setSelectedRow(record)
                  toggleModal('isChangeEstimationModalOpen')
                }}
              >
                <i className="fa fa-calendar mr-2" /> Ganti Estimasi
              </Button>
            )}

          {[
            PAYMENT_STATUS_DP,
            PAYMENT_STATUS_COD_DP,
            PAYMENT_STATUS_COD_FULL,
            PAYMENT_STATUS_MARKETPLACE,
            PAYMENT_STATUS_BILLING,
          ].includes(record.order.payment_status.id) &&
            ability.can('update', 'Payment') && (
              <Button
                size="small"
                className="border-0 text-xs text-white bg-indigo-600 rounded-md font-medium"
                onClick={() => {
                  setSelectedRow(record)
                  toggleModal('isPayoffSetOpen')
                }}
              >
                <i className="fa-solid fa-money-check-dollar mr-2" /> Set Lunas
              </Button>
            )}

          {record.status.id <= STATUS_SEDANG_DIKIRIM && ability.can('update', 'Order') && (
            <Button
              size="small"
              className="border-0 text-xs text-white bg-yellow-500 rounded-md font-medium"
              onClick={() => navigate(`/table/orders/${record.order_id}`)}
            >
              <i className="fa-solid fa-pen-to-square mr-2" /> Ubah
            </Button>
          )}

          {ability.can('print', 'Label') && (
            <Button
              size="small"
              className="border-0 text-xs text-white bg-blue-500 rounded-md font-medium"
              onClick={() => window.open(`/print/label/${record.item_id}`, '_blank')}
            >
              <i className="fa-solid fa-print mr-2" /> Print Label
            </Button>
          )}

          {ability.can('read', 'Invoice') && (
            <Button
              size="small"
              className="border-0 text-xs text-white bg-orange-500 rounded-md font-medium"
              onClick={() => {
                setSelectedRow(record)
                toggleModal('isInvoiceModalOpen')
              }}
            >
              <i className="fa-solid fa-file-invoice mr-2" /> Invoice
            </Button>
          )}

          {![
            PAYMENT_STATUS_MARKETPLACE,
            PAYMENT_STATUS_UNPAID,
            PAYMENT_STATUS_CANCEL,
            PAYMENT_STATUS_REFUND,
          ].includes(record.order.payment_status.id) &&
            ability.can('read', 'Payment') && (
              <Button
                size="small"
                className="border-0 text-xs text-white bg-purple-500 rounded-md font-medium"
                onClick={() => {
                  setSelectedRow(record)
                  toggleModal('isEvindenceModalOpen')
                }}
              >
                <i className="fa-solid fa-file-contract mr-2" /> Bukti Transfer
              </Button>
            )}

          {record.order.payment_status.id === PAYMENT_STATUS_MARKETPLACE && (
            <Button
              size="small"
              className="border-0 text-xs text-white bg-purple-500 rounded-md font-medium"
              onClick={() => {
                setSelectedRow(record)
                toggleModal('isEvindenceModalOpen')
              }}
            >
              <i className="fa-solid fa-print mr-2" /> Label Marketplace
            </Button>
          )}

          <Button
            size="small"
            className="border-0 text-xs text-white bg-green-500 rounded-md font-medium"
            onClick={() => {
              let text =
                `Halo Mas/Mbak ${record.order.customer.name}, ` +
                `Perkenalkan saya ${record.order.dealmaker.name} dari Official Store Emirate. ` +
                `Untuk orderannya sudah masuk ke sistem kami dengan nomor order ${record.order.order_number}.`

              let url = `https://wa.me/${record.order.customer.phone.replace(
                '+',
                ''
              )}?text=${encodeURIComponent(text)}`

              window.open(url, '_blank')
            }}
          >
            <i className="fa-brands fa-whatsapp mr-2" /> Whatsapp
          </Button>

          {ability.can('delete', 'Order') && (
            <Button
              size="small"
              className="border-0 text-xs text-white bg-rose-500 rounded-md font-medium"
              onClick={() => deleteOrder(record.order.id)}
            >
              <i className="fa-solid fa-trash-can mr-2" /> Hapus
            </Button>
          )}
        </div>
      ),
    },
  ]

  const getDataFilterByUnit = async () => {
    const response = await OrderService.getOrderFilterByUnit()
    try {
      setUnitOptions(
        response.data.map((filter) => ({
          value: filter.id,
          label: filter.name,
        }))
      )
    } catch (error) {
      console.log(error)
    }
  }

  const handleSearch = async (values) => {
    setParams({
      ...params,
      filter_column: values.filter_column ? values.filter_column : '',
      start_date: start_date,
      end_date: end_date,
      filter_text: values.filter_text ? values.filter_text : '',
      payment_status_id: values.payment_status_id ? values.payment_status_id : null,
      status_type_id: values.status_type_id ? values.status_type_id : null,
      unit_id: values.unit_id ? values.unit_id : null,
    })
  }

  const handleRow = (record, index) => {
    if (index % 2 !== 0) {
      return 'bg-slate-100'
    }
  }

  return (
    <>
      <CustomBadge formOrder title="Tabel Order" titleParent="Order" />

      <div className="lg:ml-3">
        <div className="bg-white shadow-white-500/40 shadow-sm rounded-2xl h-fit w-full md:overflow-visible mt-10 mb-10 pt-4 md:pt-4">
          <div className="section-table-orders antialiased px-2 md:px-4">
            <CustomBadge titleOrder title="Pencarian " />

            <Form
              form={form}
              layout="vertical"
              onFinish={handleSearch}
              className="antialiased mt-8"
              size="large"
            >
              <div className="grid md:grid-cols-3 gap-2">
                <Form.Item label="Tanggal Order">
                  <DatePicker.RangePicker
                    placeholder={['Dari', 'Sampai']}
                    onChange={dateFilterChange}
                    className="w-full"
                  />
                </Form.Item>

                <Form.Item name="payment_status_id" label="Pembayaran">
                  <Select placeholder="Semua" options={filterPayment} allowClear />
                </Form.Item>

                <Form.Item name="status_type_id" label="Status Produksi">
                  <Select placeholder="Semua" options={filterStatusProduction} allowClear />
                </Form.Item>

                <Form.Item name="unit_id" label="Unit">
                  <Select placeholder="Pilih Unit" options={unitOptions} allowClear />
                </Form.Item>

                <Form.Item name="filter_column" label="Cari Berdasarkan">
                  <Select placeholder="Pilih Kolom Pencarian" options={filterColumn} allowClear />
                </Form.Item>

                <Form.Item name="filter_text" className="mt-[3rem]">
                  <Input placeholder="keyword" />
                </Form.Item>
              </div>

              <Form.Item className="flex justify-end pb-3">
                <Button className="border-0 bg-cyan-500 text-white w-20" htmlType="submit">
                  Cari
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>

        {ability.can('import', 'Payment') && (
          <div className="flex justify-end">
            <Button
              className="bg-green-500 border-0 text-white"
              onClick={() => {
                toggleModal('isImportPayoffOpen')
              }}
            >
              Import Pelunasan
            </Button>
          </div>
        )}

        <div className="bg-white shadow-white-500/40 shadow-sm rounded-2xl h-fit w-full md:overflow-visible mt-4 relative md:mt-4">
          <div className="table-section-product antialiased relative w-full">
            <Table
              rowKey="item_id"
              size="large"
              loading={loading}
              dataSource={dataOrder}
              columns={columns}
              pagination={false}
              scroll={{ x: 'max-content' }}
              onChange={onTableChange}
              tableLayout="auto"
              rowClassName={handleRow}
            />
          </div>

          <div className="section-pagination flex justify-center md:block px-2 py-8 md:p-4">
            <CustomPagination {...params} onPageChange={onPageChange} total={total} />
          </div>
        </div>
      </div>

      <VerifyOrder
        open={modal.isVerifyModalOpen}
        toggle={() => {
          toggleModal('isVerifyModalOpen')
          getDataOrder()
        }}
        row={selectedRow}
      />

      <Invoice
        open={modal.isInvoiceModalOpen}
        toggle={() => {
          toggleModal('isInvoiceModalOpen')
        }}
        row={selectedRow}
      />

      <SetEstimation
        open={modal.isEstimationModalOpen}
        toggle={() => {
          toggleModal('isEstimationModalOpen')
          getDataOrder()
        }}
        row={selectedRow}
      />

      <ChangeEstimation
        open={modal.isChangeEstimationModalOpen}
        toggle={() => {
          toggleModal('isChangeEstimationModalOpen')
          getDataOrder()
        }}
        row={selectedRow}
      />

      <TrackingNumber
        open={modal.isTrackingNumberModalOpen}
        toggle={() => {
          toggleModal('isTrackingNumberModalOpen')
          getDataOrder()
        }}
        row={selectedRow}
      />

      <Pending
        open={modal.isPendingModalOpen}
        toggle={() => {
          toggleModal('isPendingModalOpen')
          getDataOrder()
        }}
        row={selectedRow}
      />

      <ChangeStatus
        open={modal.isChangeStatusModalOpen}
        toggle={() => {
          toggleModal('isChangeStatusModalOpen')
          getDataOrder()
        }}
        row={selectedRow}
      />

      <DetailOrder
        open={modal.isDetailOrderOpen}
        toggle={() => {
          toggleModal('isDetailOrderOpen')
        }}
        row={selectedRow}
      />

      <Evidences
        open={modal.isEvindenceModalOpen}
        toggle={() => {
          toggleModal('isEvindenceModalOpen')
          getDataOrder()
        }}
        row={selectedRow}
      />

      <PayoffSet
        open={modal.isPayoffSetOpen}
        toggle={() => {
          toggleModal('isPayoffSetOpen')
        }}
        row={selectedRow}
        getDataOrder={getDataOrder}
      />

      <ImportPayoff
        open={modal.isImportPayoffOpen}
        toggle={() => {
          toggleModal('isImportPayoffOpen')
        }}
        row={selectedRow}
        getDataOrder={getDataOrder}
      />
    </>
  )
}

export default TableOrder
