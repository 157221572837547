/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { Button, Form, Image, Input, Modal } from 'antd'

const DetailDesign = (props) => {
  const { isModalDetailOpen, setIsModalDetailOpen, handleCancel, childData } = props
  const [form] = Form.useForm()

  useEffect(() => {
    form.setFieldsValue({
      ...childData,
    })
  }, [childData])

  return (
    <Modal
      forceRender
      title="Detail Design Produk"
      footer={false}
      open={isModalDetailOpen}
      onCancel={handleCancel}
    >
      <Form className="mt-10" form={form} layout="vertical">
        <Form.Item
          label="Nama"
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input className="rounded-md h-10" placeholder="Custom" />
        </Form.Item>

        <Form.Item label="Gambar sebelumnya">
          <Image width={200} src={childData.image} />
        </Form.Item>

        <Form.Item>
          <div className="flex justify-end">
            <Button
              className="bg-rose-500 text-white rounded-lg"
              onClick={() => setIsModalDetailOpen(false)}
            >
              Close
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default DetailDesign
