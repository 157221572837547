/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Input, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import CustomBadge from '../../../components/CustomBadge'
import CustomPagination from '../../../components/CustomPagination'
import PriceService from '../../../services/PriceService'
import UpdatePrice from './UpdatePrice'

const { Search } = Input

const Prices = () => {
  const navigate = useNavigate()
  const { id: productId } = useParams()

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [isModalEditOpen, setIsModalEditOpen] = useState(false)
  const [childData, setChildData] = useState({})
  const [total, setTotal] = useState(0)
  const [product, setProduct] = useState({ name: '' })
  const [params, setParams] = useState({
    page: 1,
    per_page: 10,
    sort: 'id',
    order: 'desc',
    filter: '',
  })

  useEffect(() => {
    setStateParams()
  }, [])

  useEffect(() => {
    getData()
  }, [params])

  const getData = async () => {
    setLoading(true)
    const response = await PriceService.getDatatable(productId, params)
    try {
      if (response.status === 200) {
        setLoading(false)
        setData(response.data)
        setTotal(response.meta.total)
        setProduct(response.product)
        setQueryString()
      }
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const onSearch = (filter) => setParams({ ...params, filter })

  const onPageChange = (page, pageSize) => {
    const per_page = pageSize
    setParams({ ...params, page, per_page })
  }

  const onTableChange = (_pagination, _filters, sorter) => {
    let { columnKey: sort, order } = sorter
    sort = order ? sort : 'id' // jika order undefined default sort adalah column `id` dan order by `id` desc
    order = order === 'ascend' ? 'asc' : 'desc'
    setParams({ ...params, sort, order })
  }

  const setStateParams = () => {
    const qs = new URLSearchParams(window.location.search)
    setParams({
      page: qs.get('page') || params.page,
      per_page: qs.get('per_page') || params.per_page,
      sort: qs.get('sort') || params.sort,
      order: qs.get('order') || params.order,
      filter: qs.get('filter') || params.filter,
    })
  }

  const setQueryString = () => {
    const newParams = {}

    Object.keys(params).forEach((key) => {
      if (params[key]) {
        newParams[key] = params[key]
      }
    })

    const qs = new URLSearchParams(newParams)

    navigate({
      pathname: window.location.pathname,
      search: '?' + qs,
    })
  }

  const showModalEdit = (data) => {
    setChildData({ ...data, productId: productId })
    setIsModalEditOpen(true)
  }

  const handleCancel = () => {
    // setIsModalAddOpen(false)
    setIsModalEditOpen(false)
    // setIsModalDetailOpen(false)
    getData()
  }

  const columns = [
    {
      title: 'Nomor',
      dataIndex: 'no',
      key: 'no',
    },
    {
      title: 'Baris',
      dataIndex: 'row',
      key: 'row',
      render: (text) => <h2 className="text-gray-800">{text || '-'}</h2>,
    },
    {
      title: 'Ukuran',
      dataIndex: 'size',
      key: 'size',
      render: (text) => <h2 className="text-gray-800">{text}</h2>,
    },
    {
      title: 'Berat',
      dataIndex: 'weight',
      key: 'weight',
      render: (text) => (
        <h2 className="text-gray-800 font-medium">
          {new Intl.NumberFormat('id-ID').format(text)} gram
        </h2>
      ),
    },
    {
      title: 'Harga',
      dataIndex: 'price',
      key: 'price',
      render: (text) => (
        <h2 className="text-gray-800 font-medium">
          Rp {new Intl.NumberFormat('id-ID').format(text)}
        </h2>
      ),
    },
    {
      title: 'Harga Agen',
      dataIndex: 'agent_price',
      key: 'agent_price',
      render: (text) => (
        <h2 className="text-gray-800 font-medium">
          Rp {new Intl.NumberFormat('id-ID').format(text)}
        </h2>
      ),
    },
    {
      title: 'Action',
      fixed: 'right',
      render: (cell, record) => {
        return (
          <Button
            className="border-0 text-white bg-yellow-500 rounded-md font-medium"
            onClick={() => showModalEdit(record)}
          >
            <i className="fa-solid fa-pen-to-square mr-2" /> Edit
          </Button>
        )
      },
    },
  ]

  return (
    <div className="bg-white shadow-white-500/40 shadow-sm rounded-2xl h-fit w-full md:overflow-visible pt-4 md:pt-4">
      <div className="section-category px-2 md:px-4">
        <CustomBadge title="Produk" titleParent="Varian Harga" />

        <h2 className="text-xl text-gray-700 mt-7">Tabel Harga Produk {product.name}</h2>

        <div className="flex justify-between items-center mt-8">
          <div className="search-user">
            <Search
              className="search-user md:w-80"
              placeholder="Search"
              allowClear
              size="medium"
              onSearch={onSearch}
            />
          </div>
        </div>
      </div>

      <div className="table-section-product mt-5">
        <Table
          rowKey="id"
          size="large"
          loading={loading}
          onChange={onTableChange}
          columns={columns}
          dataSource={data}
          pagination={false}
          scroll={{ x: true }}
          tableLayout="auto"
        />
      </div>

      <div className="section-pagination flex justify-center md:block px-2 py-8 md:p-4">
        <CustomPagination {...params} onPageChange={onPageChange} total={total} />
      </div>

      <UpdatePrice
        productId={productId}
        childData={childData}
        isModalEditOpen={isModalEditOpen}
        handleCancel={handleCancel}
        setIsModalEditOpen={setIsModalEditOpen}
        getData={getData}
      />
    </div>
  )
}

export default Prices
