import React, { useEffect, useState } from 'react'
import { UploadOutlined } from '@ant-design/icons'
import { Button, DatePicker, Form, Modal, Select, Space, Upload, message } from 'antd'
import PaymentChannelService from '../../../../services/PaymentChannelService'
import moment from 'moment'
import Swal from 'sweetalert2'

const ImportPayoff = (props) => {
  const { open, toggle } = props

  const [form] = Form.useForm()

  const [loading, setLoading] = useState(false)
  const [paymentOptions, setPaymentOptions] = useState([])

  const [paymentDate, setPaymentDate] = useState(null)
  const [excelFile, setExcelFile] = useState(null)
  const [imageFile, setImageFile] = useState(null)
  const [imagePreview, setImagePreview] = useState('')
  const [templateLink, setTemplateLink] = useState('')

  useEffect(() => {
    if (open) {
      getPaymentChannels()
      getTemplateLink()
    }
  }, [open, toggle])

  const getTemplateLink = async () => {
    try {
      setLoading(true)
      const response = await PaymentChannelService.getBatchPaymentTemplate()
      if (response.status === 200) {
        setTemplateLink(response.data.url)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const getPaymentChannels = async () => {
    setLoading(true)
    try {
      const response = await PaymentChannelService.getPaymentChannel()
      if (response.status === 200) {
        setPaymentOptions([
          {
            label: 'Transfer Bank',
            options: response.data.map((channel) => ({
              value: channel.id,
              label:
                channel.name +
                ' - ' +
                channel.account_number +
                ' - ' +
                channel.account_name +
                ` (${channel.unit?.name})`,
              verification_type: channel.verification_type,
            })),
          },
        ])
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  // Fungsi handle tanggal
  const handleDateChange = (date, dateString) => {
    setPaymentDate(dateString)
  }

  // Fungsi handle upload excel
  const handleExcelChange = (info) => {
    if (!excelFile) {
      setExcelFile(info.file)
    } else {
      message.warning('Hanya boleh mengupload 1 file!')
    }
  }

  // Fungsi handle upload image
  const handleImageChange = (info) => {
    if (!imageFile) {
      const file = info.file
      const reader = new FileReader()
      reader.onload = (e) => {
        setImagePreview(e.target.result)
      }
      reader.readAsDataURL(file)
      setImageFile(file)
    } else {
      message.warning('Hanya boleh mengupload 1 gambar!')
    }
  }

  // Fungsi untuk remove file excel
  const removeExcel = () => {
    setExcelFile(null)
  }

  // Fungsi untuk remove file gambar
  const removeImage = () => {
    setImageFile(null)
    setImagePreview('')
  }

  const disabledDate = (current) => {
    return moment().add(-7, 'days') >= current || moment().add(0, 'days') < current
  }

  const closeModal = () => {
    toggle()
    form.resetFields()
    setPaymentDate(null)
    setExcelFile(null)
    setImageFile(null)
    setImagePreview('')
  }

  // Fungsi handle submit
  const handleSubmit = async (values) => {
    if (!paymentDate || !excelFile || !imageFile || !values.payment_channel_id) {
      message.error('Mohon lengkapi semua field!')
      return
    }

    const formData = new FormData()
    formData.append('payment_channel_id', values.payment_channel_id)
    formData.append('payment_date', paymentDate)
    formData.append('template', excelFile)
    formData.append('image', imageFile)

    setLoading(true)
    try {
      const response = await PaymentChannelService.uploadBatchPayment(formData)

      console.log('response', response)

      if (response.status === 200) {
        if (response.data.fails?.length > 0) {
          Swal.fire({
            title: 'Berhasil Import, tapi ada beberapa yang tidak valid!',
            html: `<div class="text-left">${
              response.data.failed
                ? response.data.fails
                    .map((o) =>
                      [o.order_number, o.platform, o.invoice_marketplace]
                        .filter((e) => e)
                        .join(' - ')
                        .concat(` : <div class="font-semibold">${o.error}</div>`)
                    )
                    .join('<br /> <br />')
                : ''
            }</div>`,
            icon: 'warning',
            confirmButtonText: 'OK',
            willClose: () => {
              closeModal()
            },
          })
        } else if (response.data.failed === 0) {
          message.success('Upload berhasil!')
          closeModal()
        }
      } else {
        message.error('Upload failed. Please try again.')
      }
    } catch (error) {
      console.error('Error uploading file:', error)
      message.error('Error uploading file.')
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal
      title="Import Pelunasan"
      className="antialiased"
      open={open}
      onCancel={toggle}
      footer={false}
    >
      <Form form={form} onFinish={handleSubmit} layout="vertical" className="mt-6">
        <Form.Item
          label="Tanggal Pelunasan"
          name="payment_date"
          rules={[
            {
              required: true,
              message: 'Tanggal Pelunasan harus diisi!',
            },
          ]}
          required
        >
          <DatePicker
            className="w-full"
            format="YYYY-MM-DD"
            onChange={handleDateChange}
            disabledDate={disabledDate}
            value={paymentDate ? moment(paymentDate) : null}
          />
        </Form.Item>

        <Form.Item
          label="Metode Pembayaran"
          name="payment_channel_id"
          rules={[
            {
              required: true,
              message: 'Metode Pembayaran harus diisi!',
            },
          ]}
          required
        >
          <Select
            allowClear
            showSearch
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            className="w-full"
            placeholder="Pilih Metode Pembayaran"
            options={paymentOptions}
          />
        </Form.Item>

        <Form.Item
          label={
            <>
              Template File Excel (
              <div
                className="text-red-500 underline hover:text-red-700 cursor-pointer"
                onClick={() => {
                  window.location.href = templateLink
                }}
              >
                Download
              </div>
              )
            </>
          }
          rules={[
            {
              required: true,
              message: 'File Excel harus di Upload!',
            },
          ]}
          required
        >
          <Upload
            accept=".xls, .xlsx"
            beforeUpload={() => false}
            onChange={handleExcelChange}
            fileList={excelFile ? [excelFile] : []}
            onRemove={removeExcel}
          >
            <Button icon={<UploadOutlined />} className="w-52 bg-green-600 border-0 text-white">
              Pilih File Excel
            </Button>
          </Upload>
        </Form.Item>

        <Form.Item
          label="Bukti Pelunasan"
          rules={[
            {
              required: true,
              message: 'Bukti Pelunasan harus di Upload!',
            },
          ]}
          required
        >
          <Upload
            accept="image/*"
            beforeUpload={() => false}
            onChange={handleImageChange}
            fileList={imageFile ? [imageFile] : []}
            onRemove={removeImage}
          >
            <Button icon={<UploadOutlined />} className="w-52">
              Pilih Gambar
            </Button>
          </Upload>

          {/* Preview Gambar */}
          {imagePreview && (
            <div className="mt-3">
              <img src={imagePreview} alt="Preview" className="max-w-full h-auto" />
            </div>
          )}
        </Form.Item>

        <Form.Item>
          <Space className="float-right" direction="horizontal">
            <Button
              size="md"
              key="back"
              className="bg-rose-500 text-white border-0"
              onClick={closeModal}
            >
              Batal
            </Button>

            <Button
              size="md"
              htmlType="submit"
              className={`bg-blue-500 text-white border-0`}
              loading={loading}
            >
              Upload
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default ImportPayoff
