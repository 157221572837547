import { request } from '../config'

// versi function
function MaterialVariantService() {
  // Get Data for Datatable
  const getDatatable = async (params, product_id) => {
    const response = await request.get(`/products/${product_id}/material-variants/pagination`, {
      params,
    })
    return response.data
  }

  // insert data
  const save = async (data, product_id) => {
    const response = await request.post(`/products/${product_id}/material-variants`, data)
    return response
  }

  // update Data
  const update = async (data, product_id, material_variant_id) => {
    const response = await request.put(
      `/products/${product_id}/material-variants/${material_variant_id}`,
      data
    )

    return response.data
  }

  // remove data
  const remove = async (product_id, material_variant_id) => {
    const response = await request.delete(
      `/products/${product_id}/material-variants/${material_variant_id}`
    )

    return response.data
  }

  return {
    getDatatable,
    save,
    update,
    remove,
  }
}

export default MaterialVariantService()
