import React from 'react'
import Login from '../pages/Auth/Login'

const AuthLayout = () => {
  return (
    <div className="h-screen w-full flex items-center justify-center bg-gradient-to-l from-[#E0EAFC] to-[#CFDEF3]">
      <Login />
    </div>
  )
}

export default AuthLayout
