import { request } from '../config'

// versi function
function DealmakerService() {
  // Show List
  const getDataTable = async (params) => {
    const response = await request.get(`/reports/dealmaker`, { params })
    return response.data
  }

  const unit = async () => {
    const response = await request.get(`/units`)
    return response.data
  }

  const picbyUnitId = async (unit_id) => {
    const response = await request.get(`/units/${unit_id}/pic`)
    return response.data
  }

  const exportDataReportDealmaker = async (params) => {
    const response = await request.get('/reports/dealmaker/export', { params })
    return response.data
  }

  return {
    getDataTable,
    unit,
    picbyUnitId,
    exportDataReportDealmaker,
  }
}

export default DealmakerService()
