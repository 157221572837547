import { request } from '../config'

// versi function
function VehicleService() {
  const getDatatable = async (params) => {
    const response = await request.get(`/vehicle/models/pagination`, { params })
    return response.data
  }

  const findByIdVehicleModels = async (vehicle_models_id) => {
    const response = await request.get(`/vehicle/models/${vehicle_models_id}`)
    return response.data
  }

  const updateVehicleModels = async (vehicle_models_id, data) => {
    const response = await request.put(`/vehicle/models/${vehicle_models_id}`, data)
    return response.data
  }

  const removeVehicleModels = async (vehicle_models_id) => {
    const response = await request.delete(`/vehicle/models/${vehicle_models_id}`)
    return response.data
  }

  const save = async (data) => {
    const response = await request.post(`/vehicle/models`, data)
    return response.data
  }

  const getDatatableVehicleClass = async (params) => {
    const response = await request.get(`/vehicle/classes/pagination`, { params })
    return response.data
  }

  const findByIdAndVehicleClass = async (vehicle_class_id, data) => {
    const response = await request.post(`/vehicle/classes/${vehicle_class_id}`, data)
    return response.data
  }

  const updateVehicleClass = async (vehicle_class_id, data) => {
    const response = await request.put(`/vehicle/classes/${vehicle_class_id}`, data)
    return response.data
  }

  const removeVehicleClass = async (vehicle_class_id) => {
    const response = await request.delete(`/vehicle/classes/${vehicle_class_id}`)
    return response.data
  }

  const saveDataVehicleClass = async (data) => {
    const response = await request.post(`/vehicle/classes`, data)
    return response.data
  }

  const getVehicleClass = async () => {
    const response = await request.get(`/vehicle/classes`)
    return response.data
  }

  return {
    getDatatable,
    getVehicleClass,
    findByIdVehicleModels,
    updateVehicleModels,
    removeVehicleModels,
    getDatatableVehicleClass,
    findByIdAndVehicleClass,
    updateVehicleClass,
    removeVehicleClass,
    saveDataVehicleClass,
    save,
  }
}

export default VehicleService()
