import { Button, Checkbox } from 'antd'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import Swal from 'sweetalert2'
import RolePermissionService from '../../../../services/RolePermissionService'

const CheckboxGroup = Checkbox.Group

const ManagePermission = () => {
  const { id } = useParams()
  const [role, setRole] = useState({})
  const [checkedList, setCheckedList] = useState([])
  const [indeterminate, setIndeterminate] = useState(true)
  const [checkAll, setCheckAll] = useState(false)
  const [permissions, setPermissions] = useState([])
  const navigate = useNavigate()

  const onChange = (list) => {
    setCheckedList(list)
    setIndeterminate(!!list.length && list.length < permissions.length)
    setCheckAll(list.length === permissions.length)
  }
  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? permissions.map((p) => p.id) : [])
    setIndeterminate(false)
    setCheckAll(e.target.checked)
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getData = async () => {
    try {
      const response = await RolePermissionService.findById(id)
      if (response.status === 200) {
        setRole(response.data.role)
        const permissions = response.data.permissions
        const checked = permissions.filter((p) => p.is_active).map((p) => p.id)
        setPermissions(permissions)
        setCheckedList(checked)
        setIndeterminate(!!checked.length && checked.length < permissions.length)
        setCheckAll(checked.length === permissions.length)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const onSubmit = async () => {
    console.log(checkedList)
    const data = {
      permissions: permissions
        .filter((p) => checkedList.includes(p.id))
        .map((p) => ({ id: p.id, is_active: true })),
    }

    RolePermissionService.update(role.id, data).then(() => {
      Swal.fire({
        icon: 'success',
        title: 'Berhasil',
        didOpen: navigate('/roles'),
      })
    })
  }

  return (
    <div className="bg-white shadow-white-500/40 shadow-sm rounded-2xl h-fit w-full md:overflow-visible md:p-4">
      <div className="content">
        <div className="relative flex items-center shadow-cyan-300/40 shadow-lg -mt-12 bg-cyan-500 rounded-2xl w-fit h-16 px-5">
          <h2 className="text-base text-white font-medium">
            Manage Permission{' '}
            <span>
              <i className="fa-solid fa-house-user mx-3" />
            </span>{' '}
            <span className="text-sm">Role</span>
          </h2>
        </div>
        <div className="flex items-center mt-8">
          <h2 className="text-xl text-gray-700">Permission untuk {role.name}</h2>
        </div>

        <div className="section-checkbox-permission mt-5 px-10">
          <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
            <h2 className="text-lg text-gray-800 font-medium">Check / Uncheck All</h2>
          </Checkbox>

          {/* <div className="grid md:grid-cols-1 px-10"> */}
          {/* {permissions?.map((item) => (
              <Checkbox defaultChecked={item.is_active} onChange={handleChange}>
                <h2 className='text-base text-gray-800 font-medium'>{item.description} - {item.action}</h2>
              </Checkbox>
            ))} */}

          <CheckboxGroup
            options={permissions.map((item) => ({
              label: `${item.description} - ${item.action}`,
              value: item.id,
              style: {
                width: '50%',
                marginTop: '10px',
                marginBottom: '5px',
                marginLeft: '2rem',
                marginRight: 0,
                fontWeight: '300',
                fontSize: '1rem',
              },
            }))}
            value={checkedList}
            onChange={onChange}
          />
          {/* </div> */}

          <Button
            onClick={onSubmit}
            className="rounded-lg bg-cyan-500 border-0 text-white font-medium mt-5"
          >
            Update
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ManagePermission
