import axios from 'axios'
import Swal from 'sweetalert2'

export const API_URL = process.env.REACT_APP_API_URL

const request = axios.create({
  baseURL: `${API_URL}/api/v1`,
  timeout: 300000,
  headers: {
    'Content-Type': 'application/json',
  },
})

request.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem('token')
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }

    return config
  },
  (error) => Promise.reject(error)
)

request.interceptors.response.use(
  (response) => response,
  async (error) => {
    // console.log(error.message);
    if (error.message === 'Network Error') {
      Swal.fire({
        icon: 'error',
        title: 'Terjadi Kesalahan',
        text: 'Koneksi ke Server Bermasalah',
      })
    } else if (error?.response?.status === 401) {
      Swal.fire({
        icon: 'error',
        title: error.response?.data?.message || error.message,
      })
      setTimeout(() => redirectLogin(), 3000)
    } else if (error?.response?.status === 422) {
      Swal.fire({
        icon: 'error',
        title: 'Validasi Gagal',
        text: error.response?.data?.message,
      })
    } else if (
      error?.response?.status === 400 &&
      error?.response?.data?.message.split(' ').splice(0, 3).join(' ').toLowerCase() ===
        'customer sudah terdaftar.'
    ) {
      throw error
    } else if (error.response?.data) {
      Swal.fire({
        icon: 'error',
        title: 'Terjadi Kesalahan',
        text: error.response.data.message,
      })
    } else if (error.response.status === 400) {
      Swal.fire({
        icon: 'error',
        title: 'Terjadi Kesalahan',
        text: error.response.data.message,
      })
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Terjadi Kesalahan',
        text: error.message,
      })
    }
  }
)

const redirectLogin = () => {
  localStorage.clear()
  window.location.href = '/auth/login'
}

export { request }
