import { request } from '../config'

// versi function
function UserService() {
  // Get Data for Datatable
  const getDatatable = async (params) => {
    const response = await request.get(`/users/pagination`, { params })
    return response.data
  }

  const changeStatus = async (users_id) => {
    const response = await request.put(`/users/change-status/${users_id}`)
    return response.data
  }

  const changeProfile = async (data) => {
    const response = await request.put(`/users/profile`, data)
    return response.data
  }

  const getProfile = async () => {
    const response = await request.get(`/users/profile`)
    return response.data
  }

  // Show List
  const listRole = async () => {
    const response = await request.get(`/roles`)
    return response.data
  }

  const listUnit = async () => {
    const response = await request.get(`/units`)
    return response.data
  }

  // Show detail
  const findById = async (users_id) => {
    const response = await request.get(`/users/${users_id}`)
    return response
  }

  const save = async (data) => {
    const response = await request.post(`/users`, data)
    return response.data
  }

  // update Data
  const update = async (users_id, data) => {
    const response = await request.put(`/users/${users_id}`, data)
    return response
  }

  const remove = async (users_id) => {
    const response = await request.delete(`/users/${users_id}`)
    return response.data
  }

  const getCsLevel = async () => {
    const response = await request.get(`/users/levels`)
    return response.data
  }

  const listUserByUnit = async (unit_id) => {
    const response = await request.get(`/units/${unit_id}/pic`)
    return response.data
  }

  return {
    getDatatable,
    changeStatus,
    getProfile,
    changeProfile,
    listRole,
    listUnit,
    findById,
    save,
    update,
    listUserByUnit,
    remove,
    getCsLevel,
  }
}

export default UserService()
