import { Checkbox, DatePicker, Form, Input, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { formatRp } from '../../../../helpers/formatRp'
import CourierService from '../../../../services/CourierService'
import OrderService from '../../../../services/OrderService'
import ShipperService from '../../../../services/ShipperService'
import { useMediaQuery } from 'react-responsive'
import moment from 'moment'

const SummaryFormOrder = (props) => {
  const {
    form,
    countWeight,
    setCourier_name,
    setCourier_service,
    countSubtotal,
    additional_cost,
    setAdditional_cost,
    discount,
    setDiscount,
    setValueBonus,
    courier_name,
    grandTotal,
    manual_cost,
    setManualCost,
    invoice_required,
    setEstimationDate,
  } = props

  const isMobile = useMediaQuery({
    query: '(max-width: 450px)',
  })

  const { address, courier_code, subdistrict_id } = form.getFieldsValue()

  const [shippers, setShippers] = useState([])
  const [origin_id, setOriginId] = useState(null)
  const [couriers, setCouriers] = useState([])
  const [shippingCost, setShippingCost] = useState([])
  const [bonusOptions, setBonusOptions] = useState([])

  const validatorSelectCourier = !!address && !!origin_id && countSubtotal() !== 0

  useEffect(() => {
    getShipper()
    getCourier()
    getBonus()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getBonus = async () => {
    const response = await OrderService.getBonuses()
    try {
      if (response.status === 200) {
        setBonusOptions(
          response.data.map((bonus) => ({
            value: bonus.name,
            label: bonus.name,
            style: {
              width: '80%',
              marginTop: '10px',
              marginBottom: '5px',
              marginLeft: '0.1rem',
              marginRight: 0,
              textAlign: 'start',
              fontSize: '13px',
            },
          }))
        )
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getShipper = async () => {
    try {
      const response = await ShipperService.list()

      if (response.status === 200) {
        setShippers(
          response.data.map((shipper) => ({
            value: shipper.id,
            label: isMobile ? shipper.unit.name : shipper.name + ' (' + shipper.unit.name + ')',
            origin: shipper.subdistrict.id,
          }))
        )
      }
    } catch (error) {
      console.log(error)
    }
  }

  const shipperChange = (value, options) => {
    setOriginId(options && options.origin)

    if (value) {
      const newOptions = [
        {
          value: 'manual',
          label: 'Input Manual',
        },
        {
          value: 'out_of_town',
          label: 'Pemasangan di Luar Kota',
        },
        {
          value: 'homeservice_outside_the_city',
          label: 'Pemasangan Home Service (Luar Kota)',
        },
        {
          value: 'homeservice_semarang',
          label: 'Pemasangan Home Service Semarang',
        },
        {
          value: 'workshop_outside_the_city',
          label: 'Pemasangan Workshop (Luar Kota)',
        },
        {
          value: 'kantor',
          label: 'Pemasangan di Kantor Jalan Kabupaten',
        },
        {
          value: 'cod',
          label: 'COD di Kantor',
        },
        {
          value: 'homeservice',
          label: 'Pemasangan Home Service',
        },
        {
          value: 'workshop',
          label: 'Pemasangan di Workshop Jalan Wates',
        },
      ]

      setCouriers([...newOptions, ...couriers])
    } else {
      form.resetFields(['courier_code', 'installation', 'shipping_cost', 'service'])
      const options = couriers.filter((item) => item.label !== 'Input Manual')
      setCouriers(options)
    }
  }

  const getCourier = async () => {
    const response = await CourierService.list()
    try {
      if (response.status === 200) {
        setCouriers(
          response.data.map((courier) => ({
            value: courier.courier_code,
            label: courier.name,
          }))
        )
      }
    } catch (error) {
      console.log(error)
    }
  }

  const courierChange = async (value, options) => {
    setCourier_name(options && options.label)
    const data = {
      origin: origin_id,
      destination: subdistrict_id, //DARI FIELD SUBDISTRICT_ID CUSTOMER
      weight: countWeight(),
      courier: value,
    }

    if (
      ![
        'Input Manual',
        'Pemasangan di Luar Kota',
        'Pemasangan Home Service (Luar Kota)',
        'Pemasangan Home Service Semarang',
        'Pemasangan Workshop (Luar Kota)',
        'Pemasangan di Kantor Jalan Kabupaten',
        'COD di Kantor',
        'Pemasangan Home Service',
        'Pemasangan di Workshop Jalan Wates',
      ].includes(value && options.label)
    ) {
      const response = await CourierService.getShippingCost(data)
      try {
        if (response.status === 200) {
          setShippingCost(
            response.data.map((cost) => ({
              value: cost.cost,
              label: cost.service,
            }))
          )
        }
      } catch (error) {
        console.log(error)
      }

      // RESET LAYANAN DAN BIAYA ONGKIR
      // KETIKA ADA PERUBAHAN KURIR
      form.setFieldsValue({
        shipping_cost: undefined,
        service: undefined,
      })
      setManualCost(0)
    } else {
      // RESET LAYANAN DAN BIAYA ONGKIR
      // KETIKA KURIR BELUM DIPILIH
      form.setFieldsValue({
        shipping_cost: undefined,
        service: undefined,
      })
      setManualCost(0)
      return value
    }
  }

  const costChange = (value, options) => {
    setCourier_service(options && options.label)
    form.setFieldsValue({
      shipping_cost: value,
    })
  }

  const handleAdditionalCostChange = (e) => {
    setAdditional_cost(e.target.value.replace(/\D/g, ''))

    if (e.target.value === '' || e.target.value === null) {
      setAdditional_cost(0)
    }
  }

  const handleManualShippingCostChange = (e) => {
    setManualCost(e.target.value.replace(/\D/g, ''))

    if (e.target.value === '' || e.target.value === null) {
      setManualCost(0)
    }
  }

  const handleDiscountChange = (e) => {
    setDiscount(e.target.value.replace(/\D/g, ''))

    if (e.target.value === '' || e.target.value === null) {
      setDiscount(0)
    }
  }

  const handleBonusChange = (values) => {
    setValueBonus(values)
  }

  const handleEstimation = (_, dateStrings) => {
    setEstimationDate(dateStrings)
  }

  const disabledDate = (current) => {
    return moment().add(-1, 'days') >= current || moment().add(31, 'days') < current
  }

  return (
    <div className="bg-white rounded-xl shadow-lg border-0 antialiased relative my-5 p-4 md:p-7">
      <div className="flex justify-between items-center bg-gray-100 p-4 border-y">
        <h2 className="text-sm text-red-500 font-medium">Total Berat</h2>
        <h2 className="text-sm font-medium">{(countWeight() / 1000).toLocaleString('id-ID')} Kg</h2>
      </div>

      <br />

      <div className="flex justify-between items-center p-4">
        <h2 className="text-sm text-red-500 font-medium">Subtotal</h2>
        <h2 className="text-sm font-medium">{formatRp(countSubtotal())}</h2>
      </div>

      <br />

      <div className="bg-gray-100 border-y relative p-4">
        <h2 className="text-sm text-red-500 font-medium">Kurir</h2>

        <div className="grid md:grid-cols-2 gap-2 mt-3">
          <Form.Item
            name="shipper_id"
            required
            rules={[
              {
                required: true,
                message: 'Alamat pengirim wajib diisi',
              },
            ]}
          >
            <Select
              allowClear
              className="w-full"
              placeholder="Alamat Pengirim"
              onChange={shipperChange}
              options={shippers}
            />
          </Form.Item>

          <Form.Item
            name="courier_code"
            required
            rules={[
              {
                required: true,
                message: 'Kurir wajib diisi',
              },
            ]}
          >
            <Select
              showSearch
              allowClear
              placeholder="Kurir"
              options={couriers}
              onChange={courierChange}
              disabled={!validatorSelectCourier}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
            />
          </Form.Item>

          {![
            'Input Manual',
            'Pemasangan di Luar Kota',
            'Pemasangan Home Service (Luar Kota)',
            'Pemasangan Home Service Semarang',
            'Pemasangan Workshop (Luar Kota)',
            'Pemasangan di Kantor Jalan Kabupaten',
            'COD di Kantor',
            'Pemasangan Home Service',
            'Pemasangan di Workshop Jalan Wates',
          ].includes(courier_name) && (
            <>
              <Form.Item
                name="service"
                required
                rules={[
                  {
                    required: true,
                    message: 'Layanan wajib diisi',
                  },
                ]}
              >
                <Select
                  showSearch
                  allowClear
                  placeholder="Layanan"
                  options={shippingCost}
                  onChange={costChange}
                  disabled={!!!courier_code}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                />
              </Form.Item>

              <Form.Item name="shipping_cost">
                <Input prefix="Rp" disabled />
              </Form.Item>
            </>
          )}

          {[
            'Input Manual',
            'Pemasangan di Luar Kota',
            'Pemasangan Home Service (Luar Kota)',
            'Pemasangan Home Service Semarang',
            'Pemasangan Workshop (Luar Kota)',
          ].includes(courier_name) && (
            <>
              <Form.Item
                name="courier_name"
                required
                rules={[
                  {
                    required: true,
                    message: 'Layanan wajib diisi',
                  },
                ]}
              >
                <Input placeholder="Nama Kurir" />
              </Form.Item>

              <Form.Item
                name="service"
                required
                rules={[
                  {
                    required: true,
                    message: 'Layanan wajib diisi',
                  },
                ]}
              >
                <Input placeholder="Nama Service" />
              </Form.Item>

              <Form.Item>
                <Input
                  prefix="Rp"
                  type="text"
                  value={parseInt(manual_cost).toLocaleString('id-ID')}
                  onChange={handleManualShippingCostChange}
                />
              </Form.Item>
            </>
          )}

          {[
            'Pemasangan di Kantor Jalan Kabupaten',
            'COD di Kantor',
            'Pemasangan Home Service',
            'Pemasangan di Workshop Jalan Wates',
          ].includes(courier_name) && (
            <Form.Item>
              <Input
                prefix="Rp"
                type="text"
                value={parseInt(manual_cost).toLocaleString('id-ID')}
                onChange={handleManualShippingCostChange}
              />
            </Form.Item>
          )}

          {invoice_required && (
            <Form.Item name="tracking_number">
              <Input placeholder="Resi Booking Marketplace" />
            </Form.Item>
          )}
        </div>
      </div>

      <br />

      <div className="p-4">
        <Form.Item label={<h2 className="text-sm text-red-500 font-medium">Estimasi Dealmaker</h2>}>
          <DatePicker
            disabledDate={disabledDate}
            onChange={handleEstimation}
            className="w-full"
            placement="bottomLeft"
          />
        </Form.Item>
      </div>

      <br />

      <div className="bg-gray-100 border-y relative p-4">
        <Form.Item label={<h2 className="text-sm text-red-500 font-medium">Diskon</h2>}>
          <Input
            prefix="Rp"
            type="text"
            value={parseInt(discount).toLocaleString('id-ID')}
            onChange={handleDiscountChange}
          />
        </Form.Item>
      </div>

      <br />

      <div className="p-4">
        <Form.Item label={<h2 className="text-sm font-medium text-red-500">Biaya lain-lain</h2>}>
          <Input
            prefix="Rp"
            type="text"
            value={parseInt(additional_cost).toLocaleString('id-ID')}
            onChange={handleAdditionalCostChange}
          />
        </Form.Item>

        <Form.Item name="additional_cost_note">
          <Input.TextArea rows={3} maxLength={250} placeholder="Catatan biaya" />
        </Form.Item>
      </div>

      <br />

      <div className="bg-gray-100 border-y relative p-4">
        <Form.Item
          label={<h2 className="text-sm font-medium text-red-500">Bonus / Keterangan</h2>}
          name="bonus"
        >
          <Checkbox.Group onChange={handleBonusChange} options={bonusOptions} />
        </Form.Item>

        <Form.Item
          name="description"
          rules={[
            {
              message: 'Maksimal Keterangan 200 karakter',
              max: 200,
            },
          ]}
        >
          <Input.TextArea placeholder="Keterangan" rows={4} />
        </Form.Item>
      </div>

      <br />

      <div className="flex justify-between items-center p-4">
        <h2 className="text-base font-medium text-red-500">Grand Total</h2>
        <h2 className="text-base font-semibold">{formatRp(grandTotal())}</h2>
      </div>
    </div>
  )
}

export default SummaryFormOrder
