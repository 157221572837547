/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Input, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import Swal from 'sweetalert2'
import CustomBadge from '../../../components/CustomBadge'
import CustomPagination from '../../../components/CustomPagination'
import ProductService from '../../../services/ProductService'

const Product = () => {
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [total, setTotal] = useState(0)
  const [params, setParams] = useState({
    page: 1,
    per_page: 10,
    sort: 'id',
    order: 'desc',
    filter: '',
  })

  useEffect(() => {
    setStateParams()
  }, [])

  useEffect(() => {
    getData()
  }, [params])

  const getData = async () => {
    setLoading(true)
    const response = await ProductService.getDatatable(params)
    try {
      if (response.status === 200) {
        setLoading(false)
        setData(response.data)
        setTotal(response.meta.total)
        setQueryString()
      }
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const onSearch = (filter) => setParams({ ...params, filter })

  const onPageChange = (page, pageSize) => {
    const per_page = pageSize
    setParams({ ...params, page, per_page })
  }

  const onTableChange = (_pagination, _filters, sorter) => {
    let { columnKey: sort, order } = sorter
    sort = order ? sort : 'id' // jika order undefined default sort adalah column `id` dan order by `id` desc
    order = order === 'ascend' ? 'asc' : 'desc'
    setParams({ ...params, sort, order })
  }

  const setStateParams = () => {
    const qs = new URLSearchParams(window.location.search)
    setParams({
      page: qs.get('page') || params.page,
      per_page: qs.get('per_page') || params.per_page,
      sort: qs.get('sort') || params.sort,
      order: qs.get('order') || params.order,
      filter: qs.get('filter') || params.filter,
    })
  }

  const setQueryString = () => {
    const newParams = {}

    Object.keys(params).forEach((key) => {
      if (params[key]) {
        newParams[key] = params[key]
      }
    })

    const qs = new URLSearchParams(newParams)

    navigate({
      pathname: window.location.pathname,
      search: '?' + qs,
    })
  }

  const addData = () => {
    navigate(`/master-product/products/add`)
  }

  const deleteData = (productId) => {
    Swal.fire({
      title: 'Do you want delete this product?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await ProductService.remove(productId)
        if (response.status === 200) {
          Swal.fire('Berhasil!', '', 'success')
        }
        getData()
      }
    })
  }

  const variantPriceSectionProduct = (id) => {
    navigate(`/master-product/products/${id}/variants`)
  }

  const materialSectionProduct = (id) => {
    navigate(`/master-product/products/${id}/materials`)
  }
  const materialVariantSectionProduct = (id) => {
    navigate(`/master-product/products/${id}/material-variants`)
  }

  const addonSectionProduct = (id) => {
    navigate(`/master-product/products/${id}/addons`)
  }

  const designSectionProduct = (id) => {
    navigate(`/master-product/products/${id}/designs`)
  }

  const logoSectionProduct = (id) => {
    navigate(`/master-product/products/${id}/logos`)
  }

  const columns = [
    {
      title: 'Nomor',
      dataIndex: 'no',
      key: 'no',
    },
    {
      title: 'Nama',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      render: (text) => <li className="list-none font-medium text-gray-800">{text}</li>,
    },
    {
      title: 'Ready Stock',
      dataIndex: 'is_ready_stock',
      key: 'is_ready_stock',
      align: 'center',
      render: (active) => {
        const iconButton = active
          ? 'fa-solid fa-circle-check text-base text-green-500'
          : 'fa-solid fa-circle-xmark'

        return <i className={iconButton} />
      },
    },
    {
      title: 'Variasi 1',
      dataIndex: 'enable_variant_1',
      key: 'enable_variant_1',
      align: 'center',
      render: (active) => {
        const iconButton = active
          ? 'fa-solid fa-circle-check text-base text-green-500'
          : 'fa-solid fa-circle-xmark text-base text-rose-500'

        return <i className={iconButton} />
      },
    },
    {
      title: 'Variasi 2',
      dataIndex: 'enable_variant_2',
      key: 'enable_variant_2',
      align: 'center',
      render: (active) => {
        const iconButton = active
          ? 'fa-solid fa-circle-check text-base text-green-500'
          : 'fa-solid fa-circle-xmark text-base text-rose-500'

        return <i className={iconButton} />
      },
    },
    {
      title: 'Design',
      dataIndex: 'enable_design',
      key: 'enable_design',
      align: 'center',
      render: (active) => {
        const iconButton = active
          ? 'fa-solid fa-circle-check text-base text-green-500'
          : 'fa-solid fa-circle-xmark text-base text-rose-500'

        return <i className={iconButton} />
      },
    },
    {
      title: 'Logo',
      dataIndex: 'enable_logo',
      key: 'enable_logo',
      align: 'center',
      render: (active) => {
        const iconButton = active
          ? 'fa-solid fa-circle-check text-base text-green-500'
          : 'fa-solid fa-circle-xmark text-base text-rose-500'

        return <i className={iconButton} />
      },
    },
    {
      title: 'Transmisi',
      dataIndex: 'enable_transmission',
      key: 'enable_transmission',
      align: 'center',
      render: (active) => {
        const iconButton = active
          ? 'fa-solid fa-circle-check text-base text-green-500'
          : 'fa-solid fa-circle-xmark text-base text-rose-500'

        return <i className={iconButton} />
      },
    },
    {
      title: 'APAR',
      dataIndex: 'enable_fire_extinguisher',
      key: 'enable_fire_extinguisher',
      align: 'center',
      render: (active) => {
        const iconButton = active
          ? 'fa-solid fa-circle-check text-base text-green-500'
          : 'fa-solid fa-circle-xmark text-base text-rose-500'

        return <i className={iconButton} />
      },
    },
    {
      title: 'Lubang Pengunci',
      dataIndex: 'enable_lock_hole',
      key: 'enable_lock_hole',
      align: 'center',
      render: (active) => {
        const iconButton = active
          ? 'fa-solid fa-circle-check text-base text-green-500'
          : 'fa-solid fa-circle-xmark text-base text-rose-500'

        return <i className={iconButton} />
      },
    },
    {
      title: 'Baris',
      dataIndex: 'enable_row',
      key: 'enable_row',
      align: 'center',
      render: (active) => {
        const iconButton = active
          ? 'fa-solid fa-circle-check text-base text-green-500'
          : 'fa-solid fa-circle-xmark text-base text-rose-500'

        return <i className={iconButton} />
      },
    },
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      render: (_, record) => (
        <div className="antialiased grid md:grid-cols-2 gap-2">
          <Button
            className="text-white bg-teal-500 border-teal-500 rounded-md font-medium"
            onClick={() => materialSectionProduct(record.id)}
          >
            <i className="fa-solid fa-box-open mr-2" /> Material Dasar
          </Button>
          {(record.enable_variant_1 || record.enable_variant_2) && (
            <Button
              className="text-white bg-teal-500 border-teal-500 rounded-md font-medium"
              onClick={() => materialVariantSectionProduct(record.id)}
            >
              <i className="fa-solid fa-box-open mr-2" /> Material Variasi
            </Button>
          )}

          <Button
            className="text-white bg-orange-500 rounded-md font-medium"
            onClick={() => addonSectionProduct(record.id)}
          >
            <i className="fa-solid fa-puzzle-piece mr-2" /> Addon
          </Button>

          <Button
            className="border-green-500 text-white bg-green-500 rounded-md font-medium"
            onClick={() => variantPriceSectionProduct(record.id)}
          >
            <i className="fa-solid fa-money-bill-1-wave mr-2" /> Varian Harga
          </Button>

          {record.enable_design && (
            <Button
              className="text-white bg-pink-500 rounded-md font-medium"
              onClick={() => designSectionProduct(record.id)}
            >
              <i className="fa-solid fa-swatchbook mr-2" /> Design
            </Button>
          )}

          {record.enable_logo && (
            <Button
              className="text-white bg-purple-500 rounded-md font-medium"
              onClick={() => logoSectionProduct(record.id)}
            >
              <i className="fa-solid fa-hippo mr-2" /> Logo
            </Button>
          )}

          {/* <Button className="border-0 text-white bg-yellow-500 rounded-md font-medium">
            <i className="fa-solid fa-pen-to-square mr-2" /> Edit
          </Button> */}

          <Button
            className="border-0 text-white bg-gradient-to-r from-[#e52d27] to-[#EA384D] rounded-md font-medium"
            onClick={() => deleteData(record.id)}
          >
            <i className="fa-solid fa-trash-can mr-2" /> Hapus
          </Button>
        </div>
      ),
    },
  ]

  return (
    <div className="bg-white subpixel-antialiased shadow-white-500/40 shadow-sm rounded-2xl h-fit w-full md:overflow-visible pt-4 md:pt-4">
      <div className="section-product px-2 md:px-4">
        <CustomBadge title="Produk" titleParent="Produk" />

        <h2 className="text-xl text-gray-700 font-semibold mt-7">Tabel Produk</h2>

        <div className="flex justify-between items-center mt-8">
          <div className="search-user">
            <Input.Search
              className="w-44 md:w-80"
              placeholder="Search"
              allowClear
              size="medium"
              onSearch={onSearch}
            />
          </div>

          <Button
            className="border-0 text-base text-white font-medium bg-cyan-500 rounded-lg h-fit w-fit"
            onClick={() => addData()}
          >
            <i className="fa-regular fa-upload font-semibold mr-2" /> Import
          </Button>
        </div>
      </div>

      <div className="table-section-product relative w-full mt-5">
        <Table
          rowKey="id"
          size="large"
          loading={loading}
          dataSource={data}
          columns={columns}
          pagination={false}
          scroll={{ x: '100vw' }}
          onChange={onTableChange}
          tableLayout="auto"
        />
      </div>

      <div className="section-pagination flex justify-center md:block px-2 py-8 md:p-4">
        <CustomPagination {...params} onPageChange={onPageChange} total={total} />
      </div>
    </div>
  )
}

export default Product
