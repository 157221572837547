import { Button, DatePicker, Form, Modal, Select, notification } from 'antd'
import React, { useEffect, useState } from 'react'
import LeadsService from '../../../services/LeadsService'

const optionsTreatment = [
  {
    label: 'Follow Up Chat',
    value: 'Follow Up Chat',
  },
  {
    label: 'Follow Up Telepon',
    value: 'Follow Up Telepon',
  },
  {
    label: 'Follow Up Video Call',
    value: 'Follow Up Video Call',
  },
  {
    label: 'Broadcast',
    value: 'Broadcast',
  },
  {
    label: 'Upselling',
    value: 'Upselling',
  },
]

const AddTreatment = (props) => {
  const { open, toggle, row } = props

  const [form] = Form.useForm()

  const [loading, setLoading] = useState(false)
  const [first_chat, setFirstChat] = useState('')
  const [lead_id, setLeadId] = useState(row?.id)

  useEffect(() => {
    setLeadId(row?.id)
  }, [row])

  const onFinish = async (values) => {
    setLoading(true)

    const payload = {
      treatment: values.treatment,
      treatment_date: first_chat,
    }

    try {
      const response = await LeadsService.insert(lead_id, payload)

      if (response.status === 200) {
        notification.success({
          message: 'Berhasil',
          description: 'Treatment berhasil ditambahkan',
        })
      }
      setLoading(false)
      toggle()
      form.resetFields()
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  return (
    <Modal
      title="Form Tambah Treatment"
      open={open}
      onCancel={() => {
        toggle()
        form.resetFields()
      }}
      footer={false}
    >
      <Form form={form} onFinish={onFinish} className="mt-5" layout="vertical">
        <Form.Item
          label="Treatment"
          name="treatment"
          rules={[
            {
              required: true,
              message: 'Treatment harus diisi',
            },
          ]}
        >
          <Select placeholder="Silahkan pilih Treatment" options={optionsTreatment} />
        </Form.Item>

        <Form.Item
          label="Tanggal Treatment"
          name="treatment_date"
          required
          rules={[
            {
              required: true,
              message: 'Tanggal Treatment harus diisi',
            },
          ]}
        >
          <DatePicker
            className="w-full"
            placeholder="Pilih Tanggal"
            format="YYYY-MM-DD"
            onChange={(date, dateString) => setFirstChat(dateString)}
          />
        </Form.Item>

        <div className="flex justify-end gap-2">
          <Form.Item>
            <Button className="bg-rose-500 text-white" onClick={toggle}>
              Batal
            </Button>
          </Form.Item>

          <Form.Item>
            <Button loading={loading} className="bg-[#205295] text-white" htmlType="submit">
              Simpan
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  )
}

export default AddTreatment
