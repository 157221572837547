import React from 'react'

const CoversuperGreat = () => {
  return (
    <div className="bg-red-400 h-fit">
      <table className="w-full border border-white">
        <tbody className="align-middle text-black text-center text-xl font-semibold">
          <tr className="h-14">
            <td className="border" colSpan={4}>
              COVERSUPER GREAT TEAM
            </td>
            <td className="border border-y-white" colSpan={2}>
              BULAN JANUARI
            </td>
          </tr>

          <tr>
            <td rowSpan={1} colSpan={4} className="border text-sm h-12">
              TOTAL OMZET TODAY
            </td>
            <td rowSpan={2} colSpan={2} className="border border-y-white text-2xl h-16">
              RP 43.462.180
            </td>
          </tr>

          <tr>
            <td rowSpan={3} colSpan={4} className="border text-2xl h-16">
              RP 8.955.740
            </td>
          </tr>

          <tr className="h-10">
            <td className="border" rowSpan={1} colSpan={2}>
              Omzet
            </td>
          </tr>

          <tr>
            <td rowSpan={2} colSpan={1}>
              0 %
            </td>
            <td className="border-x" rowSpan={2} colSpan={1}>
              15.84%
            </td>
          </tr>

          <tr className="h-12">
            <td className="border-x" rowSpan={1} colSpan={1}>
              Leads
            </td>
            <td rowSpan={1} colSpan={1}>
              0
            </td>
            <td className="border-x" rowSpan={1} colSpan={1}>
              Deal
            </td>
            <td className="border-r" rowSpan={1} colSpan={1}>
              8
            </td>
          </tr>

          <tr className="h-12 text-sm">
            <td className="border-t border-x" rowSpan={1} colSpan={4}>
              TOTAL OMZET YESTERDAY
            </td>
            <td className="border-x border-t" rowSpan={1} colSpan={1}>
              KONVERSI
            </td>
            <td className="border-x border-t" rowSpan={1} colSpan={1}>
              RASIO ADS
            </td>
          </tr>

          <tr className="h-16">
            <td className="border" rowSpan={3} colSpan={4}>
              RP 12.336.190
            </td>
            <td className="border" rowSpan={1} colSpan={1}>
              RP 0
            </td>
            <td className="border" rowSpan={1} colSpan={1}>
              0
            </td>
          </tr>

          <tr className="h-14 text-base">
            <td className="border" rowSpan={1} colSpan={1}>
              LEADS
            </td>
            <td className="border" rowSpan={1} colSpan={1}>
              COST / LEADS
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default CoversuperGreat
