/* eslint-disable react-hooks/exhaustive-deps */
import { ExclamationCircleFilled } from '@ant-design/icons'
import { Button, Input, Modal, Space, Table, notification } from 'antd'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import BButton from '../../../components/BButton'
import CustomBadge from '../../../components/CustomBadge'
import CustomPagination from '../../../components/CustomPagination'
import PaymentChannelService from '../../../services/PaymentChannelService'
const { confirm } = Modal

const PaymentChannel = () => {
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [total, setTotal] = useState(0)
  const [params, setParams] = useState({
    page: 1,
    per_page: 10,
    sort: 'id',
    order: 'asc',
    filter: '',
  })

  useEffect(() => {
    setStateParams()
  }, [])

  useEffect(() => {
    getData()
  }, [params])

  const getData = async () => {
    setLoading(true)
    const response = await PaymentChannelService.getDatatable(params)
    try {
      if (response.status === 200) {
        setLoading(false)
        setData(response.data)
        setTotal(response.meta.total)
        setQueryString()
      }
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const deletePaymentChannel = async (id) => {
    const response = await PaymentChannelService.remove(id)
    try {
      if (response.status === 200) {
        notification.success({
          message: 'Berhasil',
          description: 'Berhasil menghapus channel pembayaran',
        })
        getData()
      }
    } catch (error) {
      console.log(error)
    }
  }

  const addPaymentChannel = () => {
    navigate('/payment-channels/add')
  }

  const onSearch = (filter) => setParams({ ...params, filter })

  const onPageChange = (page, pageSize) => {
    const per_page = pageSize
    setParams({ ...params, page, per_page })
  }

  const onTableChange = (_pagination, _filters, sorter) => {
    let { columnKey: sort, order } = sorter
    sort = order ? sort : 'id' // jika order undefined default sort adalah column `id` dan order by `id` desc
    order = order === 'ascend' ? 'asc' : 'desc'
    setParams({ ...params, sort, order })
  }

  const setStateParams = () => {
    const qs = new URLSearchParams(window.location.search)
    setParams({
      page: qs.get('page') || params.page,
      per_page: qs.get('per_page') || params.per_page,
      sort: qs.get('sort') || params.sort,
      order: qs.get('order') || params.order,
      filter: qs.get('filter') || params.filter,
    })
  }

  const setQueryString = () => {
    const newParams = {}

    Object.keys(params).forEach((key) => {
      if (params[key]) {
        newParams[key] = params[key]
      }
    })

    const qs = new URLSearchParams(newParams)

    navigate({
      pathname: window.location.pathname,
      search: '?' + qs,
    })
  }

  const columns = [
    {
      title: 'Nomor',
      dataIndex: 'no',
      key: 'no',
      render: (number) => <li className="list-none ml-1">{number}</li>,
    },
    {
      title: 'Logo',
      dataIndex: 'logo',
      key: 'logo',
      render: (logo) => {
        return <img src={logo} width="50px" alt="Logo" />
      },
    },
    {
      title: 'Bank',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      render: (text) => <li className="font-medium text-gray-800">{text}</li>,
    },
    {
      title: 'Nama Rekening',
      dataIndex: 'account_name',
      key: 'account_name',
      sorter: true,
    },
    {
      title: 'Nomor Rekening',
      dataIndex: 'account_number',
      key: 'account_number',
    },
    {
      title: 'Unit',
      dataIndex: 'unit',
      key: 'unit',
      sorter: true,
    },
    {
      title: 'Published',
      dataIndex: 'is_active',
      key: 'is_active',
      align: 'center',
      render: (active) => {
        const iconButton = active
          ? 'fa-solid fa-circle-check text-base text-green-500'
          : 'fa-solid fa-circle-xmark text-base text-rose-500'

        return <i className={iconButton} />
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button
            danger
            onClick={() => {
              confirm({
                title: 'Hapus Channel Pembayaran',
                icon: <ExclamationCircleFilled />,
                content: 'Apakah anda yakin ingin menghapus channel pembayaran ini?',
                okText: 'Ya',
                okType: 'danger',
                cancelText: 'Batal',
                onOk() {
                  deletePaymentChannel(record.id)
                },
              })
            }}
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ]

  return (
    <div className="bg-white shadow-white-500/40 shadow-sm rounded-2xl h-fit w-full md:overflow-visible pt-4 md:pt-4">
      <div className="section-payment px-2 md:px-4">
        <CustomBadge title="Channel Pembayaran" titleParent="Master" />

        <h2 className="text-xl text-gray-800 font-medium mt-10">Tabel Channel Pembayaran</h2>

        <div className="flex justify-between items-center mt-8">
          <div className="search-user">
            <Input.Search
              className="md:w-80"
              placeholder="Search"
              allowClear
              size="medium"
              onSearch={onSearch}
            />
          </div>
          <BButton onClick={addPaymentChannel}>Tambah</BButton>
        </div>
      </div>

      <div className="table-color relative w-full mt-5">
        <Table
          rowKey="id"
          loading={loading}
          size="large"
          onChange={onTableChange}
          columns={columns}
          dataSource={data}
          pagination={false}
          scroll={{ x: true }}
          tableLayout="auto"
        />
      </div>

      <div className="section-pagination px-2 py-8 md:p-4">
        <CustomPagination {...params} onPageChange={onPageChange} total={total} />
      </div>
    </div>
  )
}

export default PaymentChannel
