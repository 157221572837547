import { Button, Form, Input, InputNumber, Modal, Upload } from 'antd'
import React from 'react'
import Swal from 'sweetalert2'
import DesignService from '../../../../services/DesignService'

const AddDesign = (props) => {
  const { id, isModalAddOpen, setIsModalAddOpen, getData } = props

  const [form] = Form.useForm()

  const normFile = (e) => {
    console.log('Upload event:', e)

    if (Array.isArray(e)) {
      return e
    }

    return e?.fileList
  }

  const onSubmit = async (values) => {
    const data = {
      name: values.name,
      image: values.image[0].name,
      product_id: parseInt(id),
      price: values.price, // default
    }

    const response = await DesignService.save(id, data)
    try {
      if (response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Berhasil',
          text: 'Berhasil menambahkan Design',
        })
      }
      getData()
      form.resetFields()
      setIsModalAddOpen(false)
    } catch (error) {
      console.log(error)
    }
  }

  const handleCancel = () => {
    form.resetFields()
    setIsModalAddOpen(false)
  }

  return (
    <Modal
      forceRender
      title="Tambah Design Produk"
      footer={false}
      open={isModalAddOpen}
      onCancel={handleCancel}
    >
      <Form className="mt-10" form={form} onFinish={onSubmit} layout="vertical">
        <Form.Item
          label="Nama"
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input className="rounded-md h-10" placeholder="Custom" />
        </Form.Item>
        <Form.Item
          label="Harga"
          name="price"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber
            className="rounded-md h-10"
            style={{
              width: '100%',
            }}
            defaultValue={0}
            formatter={(value) => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value.replace(/Rp\s?|(,*)/g, '')}
          />
        </Form.Item>

        <Form.Item
          label="Gambar"
          valuePropName="fileList"
          name="image"
          getValueFromEvent={normFile}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Upload
            accept="image/*"
            listType="picture-card"
            name="file"
            beforeUpload={() => {
              return false
            }}
            maxCount={1}
          >
            <div>
              <i className="text-xl fa-solid fa-upload" />
              <h2 className="text-sm text-gray-800 font-medium">Upload</h2>
            </div>
          </Upload>
        </Form.Item>

        <Form.Item>
          <div className="flex justify-end gap-3">
            <Button className="bg-rose-500 text-white rounded-lg" onClick={() => handleCancel()}>
              Batalkan
            </Button>
            <Button htmlType="submit" className="bg-cyan-500 text-white rounded-lg">
              Simpan
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default AddDesign
