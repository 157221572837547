import { Button, Input, Space } from 'antd'
import imageCompression from 'browser-image-compression'
import React, { useRef, useState } from 'react'
import QrReader from 'react-qr-reader'
import Webcam from 'react-webcam'
import Swal from 'sweetalert2'
import CustomBadge from '../../components/CustomBadge'
import ImageService from '../../services/ImageService'

const StatusProduction = () => {
  const camera = useRef(null)

  const [show, setShow] = useState({
    scan: false,
    manual: false,
  })
  const [flow_id, setFlowId] = useState('')
  const [images, setImages] = useState([])
  const [showCamera, setShowCamera] = useState(false)
  const [facingMode, setFacingMode] = useState('user')

  const resetState = () => {
    setShow(false)
    setFlowId('')
    setImages([])
    setShowCamera(false)
  }

  const handleScan = (data) => {
    try {
      if (data) {
        setFlowId(data)
        setShow(false)
      }
    } catch (error) {
      console.log(error)
    }
  }

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl?.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new File([u8arr], filename, { type: mime })
  }

  const switchCamera = () => {
    setFacingMode(facingMode === 'user' ? { exact: 'environment' } : 'user')
  }

  const capture = async () => {
    const imageSrc = camera.current.getScreenshot()

    const file = dataURLtoFile(imageSrc, 'image.png')

    const options = {
      maxSizeMB: 0.3,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    }

    if (file.size / 1024 > 350) {
      const image = await imageCompression(file, options)
      setImages([...images, image])
    } else {
      setImages([...images, file])
    }
  }

  const onSubmit = async () => {
    const formData = new FormData()
    formData.append('item_id', flow_id)
    images?.map((item) => formData.append('images', item))

    try {
      const response = await ImageService.scanBarcode(formData)
      if (response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Berhasil',
          text: 'Berhasil Scan Barcode dan Capture Gambar',
          timer: 1500,
          willClose: () => {
            resetState()
          },
        })
      }
    } catch (error) {
      console.log(error)
      throw new Error('Terjadi kesalahan pada saat capture.')
    }
  }

  const handleDeleteImage = (idx) => {
    const newImages = images.filter((item, index) => index !== idx)
    setImages(newImages)
  }

  const toggleButton = (name) => {
    setShow((prev) => ({ ...prev, [name]: !prev[name] }))
  }

  const handleError = (err) => {
    console.error(err)
  }

  return (
    <div className="bg-white shadow-white-500/40 shadow-sm rounded-2xl h-fit w-full md:overflow-visible pt-4 md:pt-4">
      <div className="section-category px-2 md:px-4 md:pb-5">
        <CustomBadge title="Scan" titleParent="Barcode" />

        <div className="flex justify-center mt-6">
          <Space direction="vertical" className="text-center">
            {!flow_id && !show.manual && (
              <>
                <Button
                  className="text-white bg-indigo-500 w-36 border-0 mb-1"
                  onClick={() => toggleButton('scan')}
                >
                  Scan Barcode
                </Button>
              </>
            )}

            {!flow_id && !show.scan && (
              <>
                <Button
                  className="text-white bg-indigo-500 w-36 border-0 mb-1"
                  onClick={() => toggleButton('manual')}
                >
                  Input manual
                </Button>
              </>
            )}

            {show.manual && (
              <Input
                value={flow_id}
                placeholder="Flow ID Produksi"
                className="w-64 text-center text-base"
                onChange={(e) => {
                  if (/^\d*$/.test(e.target.value)) {
                    setFlowId(e.target.value)
                  }
                }}
              />
            )}

            {flow_id && (
              <>
                <Button
                  className="text-white bg-indigo-500 border-0 mt-1"
                  onClick={() => setShowCamera(!showCamera)}
                >
                  Kamera
                </Button>
              </>
            )}

            {show.scan && (
              <>
                <Input
                  value={flow_id}
                  placeholder="Flow ID Produksi"
                  className="w-64 text-center text-base"
                />

                <QrReader
                  className="w-64 h-64 md:w-96 md:h-96"
                  delay={1000}
                  onScan={handleScan}
                  onError={handleError}
                />
              </>
            )}

            {showCamera && (
              <>
                <Webcam
                  ref={camera}
                  audio={false}
                  screenshotFormat="image/png"
                  videoConstraints={{
                    facingMode: facingMode,
                  }}
                />
                <Space direction="horizontal">
                  {images.length < 3 && (
                    <>
                      <Button className="text-white bg-indigo-500 border-0 mt-1" onClick={capture}>
                        Ambil Gambar
                      </Button>

                      <Button
                        className="block md:hidden text-white bg-cyan-500 border-0 mt-1"
                        onClick={switchCamera}
                      >
                        Switch Kamera
                      </Button>
                    </>
                  )}

                  {images.length > 0 && (
                    <Button className="text-white bg-green-500 border-0 mt-1" onClick={onSubmit}>
                      Submit
                    </Button>
                  )}
                </Space>
              </>
            )}
          </Space>
        </div>

        <div className="grid md:grid-cols-3 gap-10 mt-3">
          {images.map((item, idx) => (
            <div className="relative group">
              <img
                className="object-cover rounded-md filter group-hover:blur-sm"
                key={idx}
                src={URL.createObjectURL(item)}
                alt="img"
              />

              <div className="hidden absolute inset-0 flex items-center justify-center group-hover:flex">
                <i
                  className="fa-solid fa-trash-can text-2xl text-red-500 cursor-pointer"
                  onClick={() => handleDeleteImage(idx)}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default StatusProduction
