import { Button, Col, Divider, Modal, notification, Row, Table, Tag } from 'antd'
import React, { useEffect, useState } from 'react'
import { PAYMENT_STATUS_DP, PAYMENT_STATUS_PAID } from '../../../../constants'
import OrderService from '../../../../services/OrderService'

const VerifyOrder = (props) => {
  const { open, toggle, row } = props
  const [orderId, setOrderId] = useState(null)
  const [loading, setLoading] = useState(false)
  const [order, setOrder] = useState({ grand_total: 0, payment_amount: 0, items: [] })

  useEffect(() => {
    setOrderId(row?.order.id)
  }, [row])

  useEffect(() => {
    async function getData(id) {
      const res = await OrderService.getDataByID(id)
      setOrder(res.data)
    }

    if (orderId) getData(orderId)
  }, [orderId])

  const handleCancel = () => {
    setOrderId(null)
    toggle()
  }

  const handleOk = async () => {
    setLoading(true)
    const response = await OrderService.verifyOrder({ order_id: orderId })

    try {
      if (response.status === 200) {
        notification.success({
          message: 'Berhasil',
          description: 'Verifikasi selesai',
          placement: 'top',
        })
      }
      toggle()
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const getPaymentTag = (payment_status) => {
    let color = 'success'
    switch (payment_status.id) {
      case PAYMENT_STATUS_PAID:
        color = 'success'
        break
      case PAYMENT_STATUS_DP:
        color = 'cyan'
        break
      default:
        color = 'warning'
    }

    return <Tag color={color}>{payment_status.name}</Tag>
  }

  const columns = [
    {
      title: 'Qty',
      dataIndex: 'qty',
      key: 'qty',
    },
    {
      title: 'Produk',
      dataIndex: ['product', 'name'],
      key: 'product.name',
    },
    {
      title: 'Mobil',
      dataIndex: ['vehicle', 'vehicle_model'],
      key: 'vehicle.name',
      render: (vehicle) => {
        return [vehicle.manufacturer?.name, vehicle?.name].filter((e) => e).join(' ')
      },
    },
    {
      title: 'Tahun',
      dataIndex: 'year',
      key: 'year',
    },
    {
      title: 'Warna',
      dataIndex: ['color', 'name'],
      key: 'color.name',
    },
  ]

  return (
    <Modal
      title={`Verifikasi Order ${row?.order?.order_number}`}
      open={open}
      centered={true}
      width="80%"
      cancelText="Batal"
      okText="Verifikasi"
      footer={[
        <Button key="back" onClick={handleCancel}>
          Batal
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
          Verifikasi
        </Button>,
      ]}
      onCancel={handleCancel}
      onOk={handleOk}
    >
      {row && (
        <Row>
          <Col span={12}>
            {row.order?.payment_histories[0]?.evidences[0]?.image && (
              <img
                alt="Bukti Transfer"
                style={{ padding: '5%' }}
                width="100%"
                onClick={() => {
                  window.open(row.order?.payment_histories[0]?.evidences[0]?.image, '_blank')
                }}
                src={row.order?.payment_histories[0]?.evidences[0]?.image}
              />
            )}
          </Col>
          <Col span={12}>
            <Divider />
            <Row>
              <Col span={12}>Nomor Order : {row.order.order_number}</Col>
              <Col span={12}>
                <span className="float-right">
                  Status Bayar : {getPaymentTag(row.order.payment_status)}
                </span>
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col span={24}>
                <h6>Alamat Pengiriman : </h6>
                <strong>{row.order.shipment.name}</strong>
                <div>
                  {row.order.shipment.address +
                    ', ' +
                    row.order.shipment.subdistrict.name +
                    ', ' +
                    row.order.shipment.district.type +
                    ' ' +
                    row.order.shipment.district.name}
                </div>
                <div>
                  {row.order.shipment.province.name} {row.order.shipment.postal_code}
                </div>
                {row.order.shipment.email ? <div>Email: {row.order.shipment.email}</div> : ''}
                <div>Telp: {row.order.shipment.phone}</div>
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col span={24}>
                <Table
                  rowKey={'item_id'}
                  columns={columns}
                  dataSource={order.items}
                  pagination={false}
                ></Table>
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col span={12}>
                <strong>Grand Total</strong>
              </Col>
              <Col span={12} className="text-right">
                <strong>Rp {new Intl.NumberFormat('id-ID').format(order.grand_total)}</strong>
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col span={12}>
                <strong>Sudah Bayar</strong>
              </Col>
              <Col span={12} className="text-right">
                <strong>Rp {new Intl.NumberFormat('id-ID').format(order.payment_amount)}</strong>
              </Col>
            </Row>
            <Divider />
          </Col>
        </Row>
      )}
    </Modal>
  )
}

export default VerifyOrder
