/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Form, Input, Modal } from 'antd'
import React, { useEffect } from 'react'

const DetailVehicleClass = (props) => {
  const { open, toggle, row } = props

  const [form] = Form.useForm()

  useEffect(() => {
    form.setFieldsValue({
      ...row,
    })
  }, [row])

  const handleCancel = () => {
    toggle()
  }

  return (
    <Modal width={380} open={open} onCancel={handleCancel} destroyOnClose={true} footer={false}>
      <h2 className="text-base mb-5">Detail Kelas Kendaraan</h2>

      <Form form={form} layout="vertical">
        <Form.Item label="Kelas Kendaraan" name="name" required>
          <Input disabled placeholder="Isi Jenis Kendaraan" />
        </Form.Item>

        <div className="flex justify-end">
          <Form.Item>
            <Button onClick={handleCancel} className="border-0 bg-cyan-500 text-white">
              Oke
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  )
}

export default DetailVehicleClass
