/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Modal, Table } from 'antd'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useReactToPrint } from 'react-to-print'
import Logo from '../../../../assets/img/logo-emirate.png'
import OrderService from '../../../../services/OrderService'

import 'moment/locale/id'
import {
  PAYMENT_STATUS_DP,
  UNIT_AVENGERS,
  UNIT_CAR_MAT,
  UNIT_KAIZEN,
  UNIT_SEAT_COVER,
} from '../../../../constants'

const Invoice = (props) => {
  const { open, toggle, row } = props

  const invoiceRef = useRef()

  const [data, setData] = useState({})
  const [orderId, setOrderId] = useState(row?.order_id)

  useEffect(() => {
    setOrderId(row?.order_id)
  }, [row])

  useEffect(() => {
    if (orderId) {
      const getDataOrderByNumber = async () => {
        try {
          const response = await OrderService.getDataByID(orderId)
          setData(response.data)
        } catch (error) {
          console.log(error)
        }
      }
      getDataOrderByNumber()
    }
  }, [orderId])

  const handlePrint = useReactToPrint({
    content: () => invoiceRef.current,
    documentTitle: 'Print Invoice',
  })

  const columns = [
    {
      title: 'Qty',
      dataIndex: 'qty',
      key: '1',
    },
    {
      title: 'Pesanan',
      dataIndex: ['product', 'name'],
      key: '2',
      render: (product, record) => (
        <h2 className="text-sm w-72">
          {product} {record.variant?.row} {record.color?.name} :{' '}
          {record.vehicle.vehicle_model.manufacturer.name} {record.vehicle.vehicle_model.name}{' '}
          {record.year}
        </h2>
      ),
    },
    {
      title: 'Harga',
      dataIndex: 'subtotal',
      key: '3',
      align: 'right',
      render: (subtotal) => (
        <h2 className="text-sm">
          {subtotal.toLocaleString('id', {
            style: 'currency',
            currency: 'IDR',
            minimumFractionDigits: 0,
            // maximumFractionDigits: 0
          })}
        </h2>
      ),
    },
    {
      title: 'Total',
      dataIndex: 'subtotal',
      key: '4',
      align: 'right',
      render: (subtotal) => (
        <h2 className="text-sm">
          {subtotal.toLocaleString('id', {
            style: 'currency',
            currency: 'IDR',
            minimumFractionDigits: 0,
            // maximumFractionDigits: 0
          })}
        </h2>
      ),
    },
  ]

  const handleCancel = () => {
    toggle()
  }

  const handleOk = () => {
    toggle()
  }

  const getBackground = (unitId) => {
    switch (unitId) {
      case UNIT_SEAT_COVER:
        return '#120202'
      case UNIT_CAR_MAT:
        return '#20409a'
      case UNIT_KAIZEN:
        return '#F7418F'
      case UNIT_AVENGERS:
        return '#964B00'
      default:
        return '#120202'
    }
  }

  return (
    <Modal
      width={1100}
      open={open}
      onCancel={handleCancel}
      onOk={handleOk}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Close
        </Button>,
      ]}
    >
      <div
        className="relative bg-white antialiased shadow-white-500/40 shadow-sm rounded-lg h-full w-full md:overflow-visible"
        ref={invoiceRef}
      >
        <div>
          <div className="flex gap-8">
            <img className="object-fill w-48 h-10 mt-8" src={Logo} alt="" />

            <div className="mt-2">
              <div className="flex gap-5">
                <i className="fa-solid fa-phone text-sm" />
                <h2 className="text-sm">0813-9101-8298</h2>
              </div>

              <div className="flex gap-5 my-2">
                <i className="fa-solid fa-globe text-sm" />
                <h2 className="text-sm">emiratecarinterior.com</h2>
              </div>

              <div className="flex gap-5">
                <i className="fa-solid fa-location-dot text-sm ml-[3px]" />
                <h2 className="text-sm w-96">
                  {data.shipment?.shipper.address}, {data.shipment?.shipper.subdistrict.name},{' '}
                  {data.shipment?.shipper.district.type +
                    ' ' +
                    data.shipment?.shipper.district.name}
                  , {data.shipment?.shipper.province.name} {data.shipment?.shipper.postal_code}
                </h2>
              </div>
              <hr className="my-3 w-[52rem] border-b border-black" />
            </div>
          </div>

          <div className="grid md:grid-cols-2 mt-3 px-7">
            <div>
              <h2 className="text-lg font-semibold">
                Kepada: <br /> <span className="text-sm font-normal">{data.customer?.name}</span>
              </h2>

              <hr className="w-40 border-b border-black my-1" />

              <div className="flex gap-2 my-2">
                <i className="fa-solid fa-phone text-sm" />
                <h2 className="text-sm">{data.customer?.phone}</h2>
              </div>

              <div className="flex gap-2">
                <i className="fa-solid fa-location-dot text-sm" />
                <h2 className="text-sm">{data.customer?.address}</h2>
              </div>
            </div>

            <div>
              <div className="grid grid-cols-2 gap-2">
                <div>
                  <div className="h-10 bg-gray-200 mb-3"></div>

                  <div className="flex gap-2">
                    <h2 className="text-sm font-semibold">No :</h2>
                    <p className="text-sm font-medium">{data.order_number}</p>
                  </div>

                  <div className="flex gap-2">
                    <h2 className="text-sm font-semibold">Tanggal Order :</h2>
                    <p className="text-sm font-medium">{moment(data.order_date).format('LL')}</p>
                  </div>
                </div>

                <div>
                  <div
                    className={`h-10 bg-[${getBackground(
                      data.unit?.id
                    )}] flex items-center justify-center mb-3`}
                  >
                    <h2 className="text-white text-xl font-semibold">INVOICE</h2>
                  </div>

                  <div className="flex gap-2">
                    <h2 className="text-sm font-semibold">Total Bayar :</h2>
                    <p className="text-sm font-medium">
                      {parseInt(data.grand_total).toLocaleString('id', {
                        style: 'currency',
                        currency: 'IDR',
                        minimumFractionDigits: 0,
                        // maximumFractionDigits: 0
                      })}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="table-section-invoice font-semibold mt-5">
          <Table
            rowKey="item_id"
            dataSource={data.items}
            columns={columns}
            pagination={false}
            tableLayout="auto"
          />
        </div>

        <div className="flex justify-end md:px-3 mt-5 mb-36">
          <div className="w-96">
            <div className="flex justify-between items-center list-none">
              <div className="font-medium leading-10">
                <li>Ongkos Kirim</li>
                <li>Diskon</li>
                {data.payment_status?.id === PAYMENT_STATUS_DP && (
                  <>
                    <li className="text-red-500">Nominal DP</li>
                    <li className="text-red-500">Sisa Tagihan</li>
                  </>
                )}
                <li>Grand Total</li>
              </div>
              <div className="text-right font-medium leading-10">
                <li>
                  {parseInt(data.shipping_cost).toLocaleString('id', {
                    style: 'currency',
                    currency: 'IDR',
                    minimumFractionDigits: 0,
                    // maximumFractionDigits: 0
                  })}
                </li>

                <li>
                  {parseInt(data.discount).toLocaleString('id', {
                    style: 'currency',
                    currency: 'IDR',
                    minimumFractionDigits: 0,
                    // maximumFractionDigits: 0
                  })}
                </li>

                {data.payment_status?.id === PAYMENT_STATUS_DP && (
                  <>
                    <li className="text-red-500">
                      {parseInt(data.payment_amount).toLocaleString('id', {
                        style: 'currency',
                        currency: 'IDR',
                        minimumFractionDigits: 0,
                        // maximumFractionDigits: 0
                      })}
                    </li>

                    <li className="text-red-500">
                      {parseInt(data.grand_total - data.payment_amount).toLocaleString('id', {
                        style: 'currency',
                        currency: 'IDR',
                        minimumFractionDigits: 0,
                        // maximumFractionDigits: 0
                      })}
                    </li>
                  </>
                )}

                <li>
                  {parseInt(data.grand_total).toLocaleString('id', {
                    style: 'currency',
                    currency: 'IDR',
                    minimumFractionDigits: 0,
                    // maximumFractionDigits: 0
                  })}
                </li>
              </div>
            </div>
          </div>
        </div>

        <div className="px-7">
          <div className="w-36 h-4 bg-[#D2A54E]"></div>
        </div>
      </div>

      <Button
        className="border-0 bg-cyan-500 text-white text-base w-32 h-10 my-4"
        onClick={handlePrint}
        // onClick={() =>
        //   setTimeout(() => {
        //     const content = document.getElementById('section-to-print')
        //     const mywindow = window.open('', 'Print')
        //     html2canvas(content).then((canvas) => {
        //       const border = document.createElement('div')
        //       border.setAttribute('id', 'border')
        //       mywindow.document.body.appendChild(border)
        //       mywindow.document.getElementById('border').appendChild(canvas)
        //       mywindow.print()
        //     })
        //   }, 0)
        // }
      >
        Cetak
      </Button>
    </Modal>
  )
}

export default Invoice
