export function formatRp(number = 0) {
  return new Intl.NumberFormat('id-ID', {
    currency: 'IDR',
    style: 'currency',
    maximumFractionDigits: 0,
  }).format(number)
}

export function formatNumberID(value = 0) {
  const number = typeof value === 'string' ? parseFloat(value) : Number(value)
  return new Intl.NumberFormat('id-ID').format(number)
}
