/* eslint-disable react-hooks/exhaustive-deps */
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js'
import React, { useEffect, useState } from 'react'
import { Bar, Line } from 'react-chartjs-2'
import CustomBadge from '../../components/CustomBadge'
import DashboardService from '../../services/DashboardService'

ChartJS.register(
  CategoryScale,
  BarElement,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

const Dashboard = (props) => {
  // const [comparation, setComparation] = useState(null)
  const [data, setData] = useState({
    summary: {
      today_orders: 0,
      today_customers: 0,
      today_omzet: 0,
      today_products: 0,
    },
    charts: {
      omzet: {
        labels: [],
        datasets: [
          {
            data: [],
          },
        ],
      },
      order: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
          },
        ],
      },
    },
  })

  useEffect(() => {
    getData()
    // auto request get data every 5 minutes
    console.log('auto request get data every 5 minutes')
    setInterval(() => {
      getData()
    }, 300000)
  }, [])

  const getData = async () => {
    const response = await DashboardService.getDashboard()
    try {
      if (response.status === 200) {
        setData(response.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className="px-4 md:px-2 mx-auto w-full">
      <CustomBadge formOrder title="Dashboard" titleParent="Home" />

      <div className="my-5">
        <div className="flex flex-wrap">
          <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words bg-white rounded-xl mb-6 xl:mb-0 shadow-lg">
              <div className="flex-auto p-4">
                <div className="flex flex-wrap">
                  <div className="relative w-full pr-4 max-w-full flex-grow flex-1">
                    <h5 className="text-slate-400 uppercase font-bold text-xs">ORDER HARI INI</h5>
                    <span className="font-semibold text-xl text-slate-700">
                      {new Intl.NumberFormat('id-ID').format(data.summary.today_orders)}
                    </span>
                  </div>
                  <div className="relative w-auto pl-4 flex-initial">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 shadow-lg rounded-full bg-orange-500">
                      <i className="fas fa-chart-pie"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words bg-white rounded-xl mb-6 xl:mb-0 shadow-lg">
              <div className="flex-auto p-4">
                <div className="flex flex-wrap">
                  <div className="relative w-full max-w-full flex-grow flex-1">
                    <h5 className="text-slate-400 uppercase font-bold text-xs">
                      PRODUK TERJUAL HARI INI
                    </h5>
                    <span className="font-semibold text-xl text-slate-700">
                      {new Intl.NumberFormat('id-ID').format(data.summary.today_products)}
                    </span>
                  </div>
                  <div className="relative w-auto pl-4 flex-initial">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 shadow-lg rounded-full bg-red-500">
                      <i className="far fa-chart-bar"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words bg-white rounded-xl mb-6 xl:mb-0 shadow-lg">
              <div className="flex-auto p-4">
                <div className="flex flex-wrap">
                  <div className="relative w-full pr-4 max-w-full flex-grow flex-1">
                    <h5 className="text-slate-400 uppercase font-bold text-xs">
                      CUSTOMER HARI INI
                    </h5>
                    <span className="font-semibold text-xl text-slate-700">
                      {new Intl.NumberFormat('id-ID').format(data.summary.today_customers)}
                    </span>
                  </div>
                  <div className="relative w-auto pl-4 flex-initial">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 shadow-lg rounded-full bg-pink-500">
                      <i className="fas fa-users"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words bg-white rounded-xl mb-6 xl:mb-0 shadow-lg">
              <div className="flex-auto p-4">
                <div className="flex flex-wrap">
                  <div className="relative w-full pr-4 max-w-full flex-grow flex-1">
                    <h5 className="text-slate-400 uppercase font-bold text-xs">OMZET HARI INI</h5>
                    <span className="font-semibold text-xl text-slate-700">
                      Rp {new Intl.NumberFormat('id-ID').format(data.summary.today_omzet)}
                    </span>
                  </div>
                  <div className="relative w-auto pl-4 flex-initial">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 shadow-lg rounded-full bg-cyan-500">
                      <i className="fas fa-percent"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div>
        <div className="flex flex-wrap">
          <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words bg-white rounded-xl mb-6 xl:mb-0 shadow-lg">
              <div className="flex-auto p-4">
                <div className="text-center">
                  <h5 className="text-slate-400 uppercase font-bold text-xs">OMZET HARI INI</h5>
                  <span className="font-semibold text-xl text-slate-700">
                    Rp {new Intl.NumberFormat('id-ID').format(data.summary.today_omzet)}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="grid md:grid-cols-2 gap-10 mt-20">
        <div className="bg-gradient-to-r from-[#2F80ED] to-[#2F80ED] rounded-lg p-3">
          <CustomBadge titleDashboard title="Omzet" />

          <div className="flex justify-between items-center mt-8">
            <h6 className="text-white text-sm">Overview</h6>

            <div className="bg-cyan-500 text-white rounded-md py-1 px-2">
              <h2 className="text-sm">Bulan Ini</h2>
            </div>
          </div>

          <Line
            datasetIdKey="id"
            data={data.charts.omzet}
            options={{
              responsive: true,
              plugins: {
                legend: {
                  display: false,
                },
                tooltip: {
                  callbacks: {
                    label: function (context) {
                      let label = context.dataset.label || ''

                      if (label) {
                        label += ': '
                      }
                      if (context.parsed.y !== null) {
                        label += new Intl.NumberFormat('id-ID', {
                          style: 'currency',
                          currency: 'IDR',
                          minimumFractionDigits: 0,
                        }).format(context.parsed.y)
                      }
                      return label
                    },
                  },
                },
              },
              borderColor: '#FFF',
              scales: {
                y: {
                  ticks: {
                    // Include a dollar sign in the ticks
                    callback: function (value, index, values) {
                      return value.toLocaleString('id', {
                        style: 'currency',
                        currency: 'IDR',
                        minimumFractionDigits: 0,
                        // maximumFractionDigits: 0
                      })
                    },
                    color: '#FFF',
                  },
                },
                x: {
                  ticks: {
                    color: '#FFF',
                  },
                },
              },
            }}
            id="chart-sales-dark"
            className="chart-canvas mt-3"
          />
        </div>

        <div className="bg-white rounded-lg p-3">
          <CustomBadge titleDashboard title="Total Order" />

          <h2 className="text-sm mt-9">Performa</h2>

          <Bar
            data={data.charts.order}
            options={{
              plugins: {
                legend: {
                  display: false,
                },
              },
            }}
            className="chart-canvas mt-4"
            id="chart-bars"
          />
        </div>
      </div>
    </div>
  )
}

export default Dashboard
