import { request } from '../config'

// versi function
function UnitService() {
  const all = async () => {
    const response = await request.get(`/units`)
    return response.data
  }

  return {
    all,
  }
}

export default UnitService()
