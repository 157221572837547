import { Button, Checkbox, Form, Modal, Space, Upload } from 'antd'
import imageCompression from 'browser-image-compression'
import React from 'react'
import ImageService from '../../../../../services/ImageService'

const { Dragger } = Upload

const AddAddons = (props) => {
  const { isModalOpen, handleOk, handleCancel, record, productIdx, dataSource, setDataSource } =
    props

  const handleChangeAddon = (e, idx) => {
    const { checked, value } = e.target
    console.log(checked, value)

    if (checked) {
      let addonExisting = dataSource[idx].addons.findIndex(
        (addonExist) => addonExist.id === value.id
      )

      if (addonExisting < 0) {
        dataSource[idx].addons.push({ ...value })
      }
      return setDataSource((dataSource) => [...dataSource])
    } else {
      let addonRemove = dataSource[idx].addons.filter((addonExist) => addonExist.id !== value.id)
      dataSource[idx].addons = addonRemove

      return setDataSource((dataSource) => [...dataSource])
    }
  }

  const handlePaste = async (e) => {
    const items = await (e.clipboardData || e.originalEvent.clipboardData).items
    for (let index in items) {
      const item = items[index]
      if (item.kind === 'file') {
        const blob = item.getAsFile()
        console.log(blob)
        const reader = new FileReader()
        reader.onload = (event) => {
          // console.log(blob.size / 1000000 <= 10)
          if (blob.size / 1000000 <= 10) {
            handleImagePaste(blob)
          }
        } // data url!
        reader.readAsArrayBuffer(blob)
      }
    }
  }

  const handleImagePaste = async (file) => {
    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    }

    if (file.size / 1024 > 550) {
      const image = await imageCompression(file, options)

      const formData = new FormData()
      formData.append('image', image)

      try {
        const response = await ImageService.uploadAddonsImage(formData)
        if (response.status === 200) {
          dataSource[productIdx].images.push(response.data.data.url)

          return setDataSource((dataSource) => [...dataSource])
        }
      } catch (error) {
        console.log(error)
        throw new Error('Terjadi kesalahan pada saat upload bukti transfer.')
      }
    } else {
      const formData = new FormData()
      formData.append('image', file)

      try {
        const response = await ImageService.uploadAddonsImage(formData)
        if (response.status === 200) {
          dataSource[productIdx].images.push(response.data.data.url)

          return setDataSource((dataSource) => [...dataSource])
        }
      } catch (error) {
        console.log(error)
        throw new Error('Terjadi kesalahan pada saat upload bukti transfer.')
      }
    }
  }

  const handleImageChange = async (file, idx) => {
    const imageFile = file.file

    if (imageFile) {
      const formData = new FormData()
      formData.append('image', imageFile)

      try {
        const response = await ImageService.uploadAddonsImage(formData)
        if (response.status === 200) {
          dataSource[idx].images.push(response.data.data.url)

          return setDataSource((dataSource) => [...dataSource])
        }
      } catch (error) {
        console.log(error)
        throw new Error('Terjadi kesalahan pada saat upload gambar product.')
      }
    }
  }

  const handleRemoveImagesAddons = (idx) => {
    const newData = dataSource[productIdx].images.filter((item, index) => index !== idx)
    dataSource[productIdx].images = newData

    return setDataSource((dataSource) => [...dataSource])
  }

  return (
    <Modal title="Addons" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={false}>
      <div onPasteCapture={handlePaste}>
        <Form.Item
          className="mt-10"
          rules={[
            {
              required: true,
            },
          ]}
          required
        >
          <label>Foto Referensi / Produk</label>
          <Dragger
            accept="image/*"
            name="image"
            listType="picture-card"
            className="avatar-uploader"
            beforeUpload={() => {
              return false
            }}
            showUploadList={false}
            onChange={(e) => handleImageChange(e, productIdx)}
          >
            <Space direction="vertical">
              <i className="fa-solid fa-plus text-base text-cyan-600" />
              <h3 className="text-cyan-600 text-base font-normal">Drop files here to upload</h3>
            </Space>
          </Dragger>
        </Form.Item>

        {dataSource[productIdx]?.images.map((item, idx) => (
          <div key={idx} className="flex justify-center items-center h-fit w-36 mb-2 ml-2">
            <img src={item} alt="" />

            <div
              className="h-10 w-10 flex justify-center items-center ml-2 cursor-pointer"
              onClick={() => handleRemoveImagesAddons(idx)}
            >
              <i className="fa-regular fa-trash-can text-red-500 text-base" />
            </div>
          </div>
        ))}

        {record?.addon_options?.map((addon, idx) => {
          let checked = false

          if (dataSource[productIdx] && dataSource[productIdx].addons) {
            let addonExisting = dataSource[productIdx].addons.findIndex(
              (addonExist) => addonExist.id === addon.id
            )
            checked = addonExisting > -1
          }

          return (
            <div className="section-checkbox-form-order">
              <Checkbox
                key={addon.id}
                checked={checked}
                value={addon}
                className="mt-6"
                onChange={(e) => handleChangeAddon(e, productIdx)}
              >
                {addon.name} - Rp {addon.price?.toLocaleString()}
              </Checkbox>
            </div>
          )
        })}
        <div className="flex justify-end gap-2 mt-5">
          <Button className="border-0 bg-cyan-500 text-white font-medium" onClick={handleCancel}>
            Tutup
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default AddAddons
