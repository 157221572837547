/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Card, Col, DatePicker, Form, Row, Select } from 'antd'
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js'
import React, { useEffect, useState } from 'react'

import { Bar, Line } from 'react-chartjs-2'
import LeadsService from '../../../services/LeadsService'
import PlatformCard from './PlatformCard'
import UserService from '../../../services/UserService'
import CustomBadge from '../../../components/CustomBadge'
const { Option } = Select

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const DashboardLeads = () => {
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [unitId, setUnitId] = useState('')
  const [userId, setUserId] = useState('')
  const [unitOptions, setUnitOptions] = useState([])
  const [userOptions, setUserOptions] = useState([])

  const [data, setData] = useState({
    start_date: '2024-02-01',
    end_date: '2024-02-07',
    user_id: null,
    unit_id: null,
    leads: {
      total: {
        budget: '0',
        leads: 5,
        cpl: 0,
      },
      meta: {
        budget: '0',
        leads: 3,
        cpl: 0,
      },
      google: {
        budget: '0',
        leads: 0,
        cpl: 0,
      },
      tiktok: {
        budget: '0',
        leads: 1,
        cpl: 0,
      },
      dm: {
        budget: '0',
        leads: 0,
        cpl: 0,
      },
      messenger: {
        budget: '0',
        leads: 0,
        cpl: 0,
      },
      youtube: {
        budget: '0',
        leads: 0,
        cpl: 0,
      },
      dm_tiktok: {
        budget: null,
        leads: 0,
        cpl: null,
      },
      link_bio: {
        budget: null,
        leads: 0,
        cpl: null,
      },
      share: {
        budget: null,
        leads: 1,
        cpl: null,
      },
      organic: {
        budget: null,
        leads: 0,
        cpl: null,
      },
    },
    charts: {
      leads_summary: {
        labels: [
          'META ADS',
          'GOOGLE ADS',
          'TIKTOK ADS',
          'DM INSTAGRAM',
          'MESSENGER FACEBOOK',
          'YOUTUBE ADS',
          'DM TIKTOK',
          'LINK BIO',
          'SHARE',
          'ORGANIK',
        ],
        datasets: [
          {
            label: 'Leads',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          },
        ],
      },
      leads_daily: {
        labels: [
          '2024-02-01',
          '2024-02-02',
          '2024-02-03',
          '2024-02-04',
          '2024-02-05',
          '2024-02-06',
          '2024-02-07',
        ],
        datasets: [
          {
            type: 'bar',
            borderColor: 'rgb(75, 192, 192)',
            backgroundColor: 'rgba(75, 192, 192, 0.75)',
            label: 'Leads',
            data: [0, 0, 0, 0, 0, 0, 0],
          },
          {
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.75)',
            label: 'Deal',
            data: [0, 0, 0, 0, 0, 0, 0],
          },
        ],
      },
    },
    reasons: [
      {
        name: '',
        qty: 1,
      },
      {
        name: 'No Respon Awal',
        qty: 1,
      },
    ],
    treatments: [
      {
        name: 'Treatment ke-1',
        qty: 1,
      },
    ],
  })

  useEffect(() => {
    getData()
    getUnits()
  }, [])

  const getUnits = async () => {
    const response = await UserService.listUnit()

    if (response.status === 200) {
      console.log(response.data)
      setUnitOptions(response.data.map((unit) => ({ value: unit.id, label: unit.name })))
    }
  }

  const onChangeUnit = (value) => {
    setUnitId(value)
    getUsers(value)
  }

  const getUsers = async (unitId) => {
    try {
      setLoading(true)
      const response = await UserService.listUserByUnit(unitId)
      if (response.status === 200) {
        setUserOptions(response.data)
      }
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const onFinish = (values) => {
    console.log(values)
    getData()
  }

  const getData = async () => {
    try {
      setLoading(true)
      const response = await LeadsService.getDashboard({
        start_date: startDate,
        end_date: endDate,
        unit_id: unitId,
        user_id: userId,
      })
      if (response.status === 200) {
        setData(response.data)
      }
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const dateFilterChange = (_, dateStrings) => {
    setStartDate(dateStrings && dateStrings[0])
    setEndDate(dateStrings && dateStrings[1])
  }

  return (
    <>
      <CustomBadge formOrder title="Dashboard Leads" titleParent="Leads" />

      <div className="md:ml-3 mt-7">
        <Card className="border-0 shadow-lg rounded-xl">
          <Form form={form} layout="vertical" onFinish={onFinish}>
            <div className="grid md:grid-cols-3 gap-4">
              <Form.Item label="Tanggal">
                <DatePicker.RangePicker
                  placeholder={['Dari', 'Sampai']}
                  onChange={dateFilterChange}
                  className="w-full"
                />
              </Form.Item>

              <Form.Item label="Unit">
                <Select
                  allowClear
                  placeholder="Semua"
                  onChange={onChangeUnit}
                  options={unitOptions}
                />
              </Form.Item>

              <Form.Item label="Dealmaker">
                <Select placeholder="Semua" allowClear onChange={(value) => setUserId(value)}>
                  {userOptions.map((d) => (
                    <Option value={d.id}> {d.name} </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>

            <Form.Item>
              <div className="flex justify-end">
                <Button
                  htmlType="submit"
                  loading={loading}
                  className="border-0 text-white text-sm bg-blue-500 float-right"
                  onClick={onFinish}
                >
                  Filter
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Card>

        <br />

        <Row gutter={16}>
          {/* Kartu Pertama */}
          <Col span={12}>
            <Card title="LEADS">
              <Line
                data={data?.charts.leads_daily}
                options={{
                  responsive: true,
                  interaction: {
                    mode: 'index',
                    intersect: true,
                  },
                  plugins: {
                    legend: {
                      position: 'top',
                    },
                    title: {
                      display: false,
                      text: 'Chart.js Line Chart',
                    },
                  },
                }}
                id="chart-leads-dark"
                className="chart-canvas"
              />
            </Card>
          </Col>

          {/* Kartu Kedua */}
          <Col span={12}>
            <Card title="PLATFORM">
              <Bar
                data={data?.charts.leads_summary}
                options={{
                  responsive: true,
                  backgroundColor: 'rgba(53, 162, 235, 0.5)',
                  plugins: {
                    legend: {
                      position: 'top',
                    },
                    title: {
                      display: false,
                      text: 'Chart.js Line Chart',
                    },
                  },
                }}
                className="chart-canvas"
                id="chart-bars"
              />
            </Card>
          </Col>
        </Row>
        <br />

        <Row gutter={16}>
          <PlatformCard title="TOTAL LEADS (All Platform)" data={data?.leads.total} span={24} />
        </Row>

        <br />

        <Row gutter={16}>
          <PlatformCard title="META ADS" data={data?.leads.meta} />
          <PlatformCard title="GOOGLE ADS" data={data?.leads.google} />
          <PlatformCard title="TIKTOK ADS" data={data?.leads.tiktok} />
        </Row>

        <br />

        <Row gutter={16}>
          <PlatformCard title="DM INSTAGRAM ADS" data={data?.leads.dm} />
          <PlatformCard title="MESSENGER FACEBOOK" data={data?.leads.messenger} />
          <PlatformCard title="YOUTUBE ADS" data={data?.leads.youtube} />
        </Row>

        <br />

        <Row gutter={16}>
          <PlatformCard title="DM TIKTOK" onlyLeads data={data?.leads.dm_tiktok} span={6} />
          <PlatformCard title="Link Bio" onlyLeads data={data?.leads.link_bio} span={6} />
          <PlatformCard title="Share" onlyLeads data={data?.leads.share} span={6} />
          <PlatformCard title="Organik" onlyLeads data={data?.leads.organic} span={6} />
        </Row>

        <br />

        <Row gutter={16}>
          <Col span={12}>
            <Card title="BELUM BELI">
              <Bar
                data={{
                  labels: data?.reasons.map((reason) => reason.name),
                  datasets: [
                    {
                      label: 'Reasons',
                      data: data?.reasons.map((reason) => reason.qty),
                      backgroundColor: 'rgba(53, 162, 235, 0.5)',
                      borderColor: 'rgba(53, 162, 235, 0.5)',
                      borderWidth: 1,
                    },
                  ],
                }}
                options={{
                  responsive: true,
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                }}
                className="chart-canvas"
                id="chart-reasons"
              />
            </Card>
          </Col>

          <Col span={12}>
            <Card title="SUDAH BELI">
              <Bar
                data={{
                  labels: data?.treatments.map((treatment) => treatment.name),
                  datasets: [
                    {
                      label: 'Treatments',
                      data: data?.treatments.map((treatment) => treatment.qty),
                      backgroundColor: 'rgba(53, 162, 235, 0.75)',
                      borderColor: 'rgba(53, 162, 235, 0.75)',
                      borderWidth: 1,
                    },
                  ],
                }}
                options={{
                  responsive: true,
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                }}
                className="chart-canvas"
                id="chart-treatments"
              />
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default DashboardLeads
