/* eslint-disable react-hooks/exhaustive-deps */
import { Modal, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import CustomerService from '../../../services/CustomerService'

const OrderCustomer = (props) => {
  const { open, toggle, row } = props

  const [customerId, setCustomerId] = useState(null)
  const [data, setData] = useState([])

  useEffect(() => {
    setCustomerId(row?.id)
  }, [row])

  useEffect(() => {
    if (open && customerId) {
      getListOrderCustomer()
    }
  }, [customerId, open, toggle])

  const getListOrderCustomer = async () => {
    try {
      const response = await CustomerService.getOrderListByCustomer(customerId)
      if (response.status === 200) {
        setData(response.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const columns = [
    {
      title: 'Tanggal',
      dataIndex: 'order_date',
      key: 'order_date',
      width: 150,
      render: (_, record) => (
        <p className="text-center">{moment(record.order_date).format('DD MMMM YYYY')}</p>
      ),
    },
    {
      title: 'Nomor Order',
      dataIndex: 'order_number',
      key: 'order_number',
      width: 150,
      render: (order_number) => (
        <li className="text-gray-800 font-semibold text-sm">{order_number}</li>
      ),
    },
    {
      title: 'Nama Customer',
      dataIndex: 'customer.name',
      key: 'customer.name',
      render: (_, record) => <li className="text-gray-800 text-sm">{record.customer?.name}</li>,
    },
    {
      title: 'Nama Produk (Status)',
      dataIndex: 'product',
      key: 'product',
      sorter: false,
      width: 250,
      render: (_, record) =>
        record.items.map((item) => (
          <li key={item.id} className="text-gray-800 text-sm mb-2">
            {item.product?.name} {item.vehicle?.vehicle_model?.manufacturer?.name}{' '}
            {item.vehicle?.vehicle_model?.name} (
            <span className="font-semibold">{item.status?.name}</span>)
          </li>
        )),
    },
    {
      title: 'Pembayaran',
      dataIndex: 'payment',
      key: 'payment',
      align: 'center',
      render: (_, record) => (
        <li className="text-gray-800 text-sm">{record.payment_status?.name}</li>
      ),
    },
    {
      title: 'Dealmaker',
      dataIndex: 'dealmaker',
      key: 'dealmaker',
      render: (_, record) => <li className="text-gray-800 text-sm">{record.dealmaker?.name}</li>,
    },
  ]

  return (
    <Modal
      forceRender
      width={1000}
      title={`Order ${row?.name}`}
      open={open}
      onCancel={toggle}
      footer={null}
    >
      <div className="antialiased table-section-master-user relative w-full mt-6">
        <Table
          className="antialiased"
          rowKey="id"
          columns={columns}
          dataSource={data}
          pagination={false}
          scroll={{ x: '70vw' }}
          tableLayout="auto"
        />
      </div>
    </Modal>
  )
}

export default OrderCustomer
