import { Button, Form, Modal, Upload, message } from 'antd'
import React, { useState } from 'react'
import Swal from 'sweetalert2'
import LeadsService from '../../../services/LeadsService'

const UploadFile = (props) => {
  const { open, toggle } = props

  const [form] = Form.useForm()
  const [isUploading, setIsUploading] = useState(false)
  const [uploadedFiles, setUploadedFiles] = useState([])

  const normFile = (e) => {
    if (Array.isArray(e)) {
      setUploadedFiles(e) // Set the uploaded files to state
      return e
    }
    return e && e.fileList
  }

  const closeModal = () => {
    form.resetFields()
    setUploadedFiles([]) // Clear uploaded files when closing modal
    props.toggle('isUploadFileModal')
  }

  const handleFileUpload = async (file) => {
    setIsUploading(true)
    try {
      const formData = new FormData()
      formData.append('file', file, file.name)

      const response = await LeadsService.importLeads(formData)
      if (response.status === 200) {
        Swal.fire({
          title: `${
            response.data
              ? `Data berhasil diimport: ${response.data.inserted}`
              : 'Sukses import data'
          }`,
          icon: 'success',
          showConfirmButton: false,
          timer: 3000,
        })
        closeModal()
        // props.refreshData()
      }
    } catch (error) {
      console.error('Error uploading file:', error)
      message.error('Failed to upload file. Please try again.')
    } finally {
      setIsUploading(false)
    }
  }

  const customRequest = async ({ file, onSuccess, onError }) => {
    try {
      await handleFileUpload(file)
      onSuccess()
    } catch (error) {
      console.error('Error uploading file:', error)
      onError(error)
    }
  }

  return (
    <Modal
      title="Upload File"
      open={open}
      onCancel={() => {
        toggle()
        form.resetFields()
      }}
      footer={false}
    >
      <Form form={form} layout="vertical" className="mt-5">
        <Form.Item
          name="upload"
          label="Upload XLS/XLSX File"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          rules={[
            {
              required: true,
              message: 'Please upload an XLS file!',
              type: 'array',
            },
          ]}
        >
          <Upload
            name="file"
            customRequest={customRequest}
            showUploadList={false}
            beforeUpload={(file) => {
              if (!isUploading) {
                const isXLS =
                  file.type ===
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                  file.type === 'application/vnd.ms-excel'
                if (!isXLS) {
                  message.error('You can only upload XLS files!')
                }
                return isXLS
              }
              return false
            }}
            maxCount={1}
            accept=".xls,.xlsx"
          >
            <Button
              loading={isUploading}
              disabled={isUploading}
              className="w-full"
              icon={<i className="fa-solid fa-upload" />}
            >
              Select File
            </Button>
          </Upload>
        </Form.Item>

        {/* Display uploaded files */}
        {uploadedFiles.length > 0 && (
          <div>
            <p>Uploaded Files:</p>
            <ul>
              {uploadedFiles.map((file) => (
                <li key={file.uid}>{file.name}</li>
              ))}
            </ul>
          </div>
        )}

        {/* Add more form fields as needed */}
      </Form>
    </Modal>
  )
}

export default UploadFile
