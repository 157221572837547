import { Form, Input, Select, Table } from 'antd'
import _ from 'lodash'
import React from 'react'
import CustomBadge from '../../../../components/CustomBadge'
import { CUSTOMER_RETAIL } from '../../../../constants'
import { formatRp } from '../../../../helpers/formatRp'

const FormProduct = (props) => {
  const { data, form, dataItems, setDataItems } = props

  const sortByName = (a, b) => {
    let x = a.name.toLowerCase()
    let y = b.name.toLowerCase()
    if (x > y) return 1
    if (x < y) return -1
    return 0
  }

  const handleYearChange = (value, product) => {
    product.year = value?.toString() || ''
  }

  const handleMaterialChange = (value, record, idx) => {
    record.material_id = value || null

    record.fiber_options = record.product.material_options.find((item, index) => item.id === value)

    if (!value) {
      record.fiber_id = null
      record.color_id = null
      form.resetFields([`serat_basic_${idx}`, `color_basic_${idx}`])
    }
  }

  const handleFiberMaterial = (value, record, idx) => {
    record.fiber_id = value || null

    record.color_options = record.fiber_options.fiber_options?.find(
      (item, index) => item.id === value
    ).color_options

    if (!value) {
      record.color_id = null
      form.resetFields([`color_basic_${idx}`])
    }

    record.color_id = null
    form.resetFields([`color_basic_${idx}`])

    setDataItems([...dataItems])
  }

  const handleColorByFiber = (value, product) => {
    product.color_id = value || null
  }

  const handleVariantOneMaterial = (value, record, idx) => {
    record.material_variant_1_id = value || null

    record.fiber_variant_options_1 = record.product.material_variant_options.find(
      (item, index) => item.id === value
    )

    record.fiber_variant_1_id = null
    record.color_variant_1_id = null
    form.resetFields([`serat_variant_1_${idx}`, `color_variant_1_${idx}`])

    setDataItems([...dataItems])
  }

  const handleVariantOneFiberMaterial = (value, product, idx) => {
    product.fiber_variant_1_id = value || null

    product.color_variant_options_1 = product.fiber_variant_options_1.find(
      (item, index) => item.id === value
    ).color_variant_options

    product.color_variant_1_id = null
    form.resetFields([`color_variant_1_${idx}`])

    setDataItems([...dataItems])
  }

  const handleColorVariantOneChange = (value, product) => {
    product.color_variant_1_id = value || null
  }

  const handleVariantTwoMaterial = (value, record, idx) => {
    record.material_variant_2_id = value || null

    record.fiber_variant_options_2 = record.product.material_variant_options.find(
      (item, index) => item.id === value
    )

    record.fiber_variant_2_id = null
    record.color_variant_2_id = null
    form.resetFields([`serat_variant_2_${idx}`, `color_variant_2_${idx}`])

    setDataItems([...dataItems])
  }

  const handleVariantTwoFiberMaterial = (value, record, idx) => {
    record.fiber_variant_2_id = value || null

    record.color_variant_options_2 = record.fiber_variant_options_2.find(
      (item, index) => item.id === value
    ).color_variant_options

    record.color_variant_2_id = null
    form.resetFields([`color_variant_2_${idx}`])

    setDataItems([...dataItems])
  }

  const handleColorVariantTwoChange = (value, product) => {
    product.color_variant_2_id = value || null
  }

  const handleFireExtinguisher = (value, product) => {
    product.fire_extinguisher = value || null
  }

  const handleLockHole = (value, product) => {
    product.lock_hole = value || null
  }

  const handleLogoChange = (value, product) => {
    product.logo = value || null
  }

  const handleTransmissionChange = (value, product) => {
    product.transmission_type = value || null
  }

  const handleNoteProductChange = (e, product) => {
    const value = e.target.value
    product.note = value?.toString() || ''
  }

  const columns = [
    {
      title: 'Produk',
      dataIndex: 'name',
      key: '1',
      render: (name, record) => {
        const text = [
          record.product?.name,
          record.vehicle?.row,
          record.vehicle?.vehicle_model?.manufacturer?.name,
          record.vehicle?.vehicle_model?.name,
        ]
          .filter((e) => e)
          .join(' ')
        return <h2 className="text-sm font-semibold text-gray-800 leading-6 w-48">{text}</h2>
      },
    },
    {
      title: 'Tahun Mobil',
      dataIndex: 'year',
      key: '2',
      render: (year, record) => {
        const start = (record.product_vehicle?.vehicle_model?.year_from || 1990) - 1
        const end = record.product_vehicle?.vehicle_model?.year_to || new Date().getFullYear()
        return (
          <Form.Item>
            <Select
              disabled
              allowClear
              size="medium"
              placeholder="Pilih Tahun"
              value={record.year}
              onChange={(e) => handleYearChange(e, record)}
              options={_.range(end, start, -1).map((item) => ({
                value: item,
                label: item,
              }))}
            />
          </Form.Item>
        )
      },
    },
    {
      title: 'Material',
      dataIndex: 'material',
      key: '3',
      render: (active, record, idx) => {
        form.setFieldsValue({
          [`material_dasar${idx}`]: record.material_id,
          [`material_variant_1_${idx}`]: record.material_variant_1_id,
          [`material_variant_2_${idx}`]: record.material_variant_2_id,
        })

        return (
          <>
            <Form.Item
              name={`material_dasar${idx}`}
              required
              rules={[
                {
                  required: true,
                  message: 'Material Dasar wajib diisi',
                },
              ]}
            >
              <Select
                allowClear
                size="medium"
                placeholder="Pilih Material Dasar"
                onChange={(e) => handleMaterialChange(e, record, idx)}
                options={record.product.material_options?.sort(sortByName).map((item) => ({
                  value: item.id,
                  label: item.name,
                }))}
              />
            </Form.Item>

            <Form.Item name={`material_variant_1_${idx}`}>
              <Select
                allowClear
                size="medium"
                placeholder="Pilih Material Variasi 1"
                disabled={!record?.product?.enable_variant_1}
                onChange={(e) => handleVariantOneMaterial(e, record, idx)}
                options={record.product.material_variant_options?.sort(sortByName).map((item) => ({
                  value: item.id,
                  label: item.name,
                }))}
              />
            </Form.Item>

            <Form.Item name={`material_variant_2_${idx}`}>
              <Select
                allowClear
                size="medium"
                placeholder="Pilih Material Variasi 2"
                disabled={!record?.product?.enable_variant_2}
                onChange={(e) => handleVariantTwoMaterial(e, record, idx)}
                options={record.product.material_variant_options?.sort(sortByName).map((item) => ({
                  value: item.id,
                  label: item.name,
                }))}
              />
            </Form.Item>
          </>
        )
      },
    },
    {
      title: 'Jenis Serat',
      dataIndex: 'fiber_types',
      key: '4',
      render: (active, record, idx) => {
        form.setFieldsValue({
          [`serat_basic_${idx}`]: record.fiber_id,
          [`serat_variant_1_${idx}`]: record.fiber_variant_1_id,
          [`serat_variant_2_${idx}`]: record.fiber_variant_2_id,
        })

        record.fiber_options = record.product.material_options.find(
          (item, index) => item.id === record.material_id
        ).fiber_options

        record.fiber_variant_options_1 = record.product.material_variant_options.find(
          (item, index) => item.id === record.material_variant_1_id
        )?.fiber_variant_options

        record.fiber_variant_options_2 = record.product.material_variant_options.find(
          (item, index) => item.id === record.material_variant_2_id
        )?.fiber_variant_options

        return (
          <>
            <Form.Item name={`serat_basic_${idx}`}>
              <Select
                showSearch
                allowClear
                size="medium"
                placeholder="Pilih Serat Dasar"
                onChange={(e) => handleFiberMaterial(e, record, idx)}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={record.fiber_options?.sort(sortByName).map((item) => ({
                  value: item.id,
                  label: item.name,
                }))}
              />
            </Form.Item>

            <Form.Item name={`serat_variant_1_${idx}`}>
              <Select
                allowClear
                showSearch
                size="medium"
                placeholder="Pilih Serat Variasi 1"
                disabled={!record?.product?.enable_variant_1}
                onChange={(e) => handleVariantOneFiberMaterial(e, record, idx)}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={record.fiber_variant_options_1?.sort(sortByName).map((item) => ({
                  value: item.id,
                  label: item.name,
                }))}
              />
            </Form.Item>

            <Form.Item name={`serat_variant_2_${idx}`}>
              <Select
                allowClear
                showSearch
                size="medium"
                placeholder="Pilih Serat Variasi 2"
                disabled={!record?.product?.enable_variant_2}
                onChange={(e) => handleVariantTwoFiberMaterial(e, record, idx)}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={record.fiber_variant_options_2?.sort(sortByName).map((item) => ({
                  value: item.id,
                  label: item.name,
                }))}
              />
            </Form.Item>
          </>
        )
      },
    },
    {
      title: 'Warna',
      dataIndex: 'color',
      key: '5',
      render: (active, record, idx) => {
        form.setFieldsValue({
          [`color_basic_${idx}`]: record.color_id,
          [`color_variant_1_${idx}`]: record.color_variant_1_id || null,
          [`color_variant_2_${idx}`]: record.color_variant_2_id || null,
        })

        return (
          <>
            <Form.Item name={`color_basic_${idx}`}>
              <Select
                allowClear
                size="medium"
                placeholder="Pilih Warna Dasar"
                onChange={(e) => handleColorByFiber(e, record)}
                options={
                  (record.color_options = record.fiber_options
                    ?.find((item, index) => item.id === record.fiber_id)
                    .color_options.sort(sortByName)
                    .map((item) => ({
                      value: item.id,
                      label: item.name,
                    })))
                }
              />
            </Form.Item>

            <Form.Item name={`color_variant_1_${idx}`}>
              <Select
                allowClear
                size="medium"
                placeholder="Pilih Warna Variasi 1"
                disabled={!record.product?.enable_variant_1}
                onChange={(e) => handleColorVariantOneChange(e, record)}
                options={
                  (record.color_variant_options_1 = record.fiber_variant_options_1
                    ?.find((item, index) => item.id === record.fiber_variant_1_id)
                    ?.color_variant_options?.sort(sortByName)
                    .map((item) => ({
                      value: item.id,
                      label: item.name,
                    })))
                }
              />
            </Form.Item>

            <Form.Item name={`color_variant_2_${idx}`}>
              <Select
                allowClear
                size="medium"
                placeholder="Pilih Warna Variasi 2"
                disabled={!record.product?.enable_variant_2}
                onChange={(e) => handleColorVariantTwoChange(e, record)}
                options={
                  (record.color_variant_options_2 = record.fiber_variant_options_2
                    ?.find((item, index) => item.id === record.fiber_variant_2_id)
                    ?.color_variant_options?.sort(sortByName)
                    .map((item) => ({
                      value: item.id,
                      label: item.name,
                    })))
                }
              />
            </Form.Item>
          </>
        )
      },
    },
    {
      title: 'APAR',
      dataIndex: 'fire_extinguisher',
      key: '6',
      render: (active, record, idx) => {
        // form.setFieldsValue({
        //   [`fire_extinguisher${idx}`]: record.fire_extinguisher,
        // })

        return (
          active && (
            <Form.Item name={`fire_extinguisher${idx}`}>
              <Select
                allowClear
                size="medium"
                placeholder="Pilih APAR"
                disabled={!record.fire_extinguisher}
                defaultValue={record.fire_extinguisher}
                onChange={(e) => handleFireExtinguisher(e, record)}
                options={record.product.fire_extinguisher_options?.sort(sortByName).map((item) => ({
                  value: item.name,
                  label: item.name,
                }))}
              />
            </Form.Item>
          )
        )
      },
    },
    {
      title: 'Lubang Pengunci',
      dataIndex: 'lock_hole',
      key: '7',
      render: (active, record, idx) => {
        // form.setFieldsValue({
        //   [`lock_hole${idx}`]: record.lock_hole,
        // })

        return (
          active && (
            <Form.Item name={`lock_hole${idx}`}>
              <Select
                allowClear
                size="medium"
                placeholder="Pilih Lubang Pengunci"
                disabled={!record.lock_hole}
                defaultValue={record.lock_hole}
                onChange={(e) => handleLockHole(e, record)}
                options={record.product.lock_hole_options?.sort(sortByName).map((item) => ({
                  value: item.name,
                  label: item.name,
                }))}
              />
            </Form.Item>
          )
        )
      },
    },
    {
      title: 'Desain',
      dataIndex: 'design',
      key: '8',
      render: (active, record) => {
        return (
          <Form.Item>
            <Select
              disabled
              allowClear
              size="medium"
              placeholder="Pilih Desain"
              defaultValue={record.design?.name}
            />
          </Form.Item>
        )
      },
    },
    {
      title: 'Logo',
      dataIndex: 'logo',
      key: '9',
      render: (active, record) => {
        return (
          active && (
            <Form.Item>
              <Select
                allowClear
                size="medium"
                placeholder="Pilih Logo"
                defaultValue={record.logo}
                onChange={(e) => handleLogoChange(e, record)}
                options={record.product.logo_options.map((item) => ({
                  value: item.name,
                  label: item.name,
                }))}
              />
            </Form.Item>
          )
        )
      },
    },
    {
      title: 'Jenis Transmisi',
      dataIndex: 'transmission_type',
      key: '10',
      render: (active, record) => {
        return (
          active && (
            <Form.Item>
              <Select
                allowClear
                size="medium"
                placeholder="Pilih Transmisi"
                defaultValue={record.transmission_type}
                onChange={(e) => handleTransmissionChange(e, record)}
                options={record.product.transmission_options.map((item) => ({
                  value: item.name,
                  label: item.name,
                }))}
              />
            </Form.Item>
          )
        )
      },
    },
    {
      title: 'Harga (Rp)',
      dataIndex: 'price',
      key: '11',
      render: (_, record) => (
        <h2 className="text-center leading-10">
          {record.variant && CUSTOMER_RETAIL === data.customer_type.id
            ? formatRp(record.variant?.price) || 0
            : formatRp(record.variant?.agent_price) || 0}
        </h2>
      ),
    },
    {
      title: 'Tambahan (Rp)',
      dataIndex: 'price_addons',
      key: '12',
      render: (_, record) => (
        <h2 className="text-center text-base leading-10">{formatRp(record.price_addons)}</h2>
      ),
    },
    {
      title: 'Penyesuaian Harga (Rp)',
      dataIndex: 'price_adjustment',
      key: '13',
      render: (price_adjustment, record, idx) => {
        return (
          <Form.Item>
            <Input
              disabled
              type="text"
              size="medium"
              placeholder="Penyesuaian harga"
              value={formatRp(price_adjustment)}
              //   onChange={(e) => priceAdjustmentChange(e, idx)}
            />
          </Form.Item>
        )
      },
    },
    {
      title: 'Kuantitas',
      dataIndex: 'qty',
      key: '14',
      render: (qty, record, idx) => {
        return (
          <Form.Item>
            <Input size="medium" className="w-10" value={qty} disabled />
          </Form.Item>
        )
      },
    },
    {
      title: 'Catatan',
      dataIndex: 'note',
      key: '15',
      render: (note, record) => {
        return (
          <Form.Item>
            <Input.TextArea
              placeholder="Catatan"
              defaultValue={record.note}
              onChange={(e) => handleNoteProductChange(e, record)}
            />
          </Form.Item>
        )
      },
    },
    {
      title: 'Total (Rp)',
      dataIndex: 'total_price',
      key: '16',
      render: (total, record) => {
        return (
          <h2 className="text-sm text-center leading-10">
            {record.variant && CUSTOMER_RETAIL === data.customer_type.id
              ? formatRp(
                  (record.price +
                    record.price_addons +
                    record.price_adjustment +
                    record.price_design) *
                    record.qty
                )
              : formatRp(
                  (record.variant.agent_price +
                    record.price_addons +
                    record.price_adjustment +
                    record.price_design) *
                    record.qty
                )}
          </h2>
        )
      },
    },
  ]

  return (
    <div className="bg-white subpixel-antialiased shadow-white-500/40 shadow-sm rounded-lg h-fit w-full md:overflow-visible pt-4 md:pt-4 mt-10">
      <div className="section-form-title-produk px-3">
        <CustomBadge titleOrder title="Produk" />
      </div>

      <div className="table-section-product relative w-full mt-5">
        <Table
          className="antialiased"
          rowKey="item_id"
          dataSource={[...dataItems]}
          columns={columns}
          pagination={false}
          scroll={{ x: '200vw' }}
        />
      </div>
    </div>
  )
}

export default FormProduct
