import { Pagination } from 'antd'
import React from 'react'

export default function CustomPagination(props) {
  let { page, per_page, total, onPageChange } = props

  return (
    <Pagination
      className="mt-3"
      showSizeChanger
      showTotal={(total, range) => {
        return (
          <div className="hidden md:block">
            Showing rows <strong>{range[0]}</strong> to <strong>{range[1]}</strong> of{' '}
            <strong>{total}</strong> results
          </div>
        )
      }}
      onChange={onPageChange}
      pageSizeOptions={['10', '20', '30', '40', '50']}
      pageSize={per_page}
      current={page}
      total={total}
    />
  )
}
