/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import { logout } from '../../store/Auth/action'

const Logout = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    console.log('Logout')
    dispatch(logout())
    navigate('/auth/login')
  }, [])

  return <></>
}

export default Logout
