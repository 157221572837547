import { Menu } from 'antd'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useLocation, useNavigate } from 'react-router'
import ability from '../../ability/ability'
import logo from '../../assets/img/logo-emirate.png'
import { FLOW_VERSION } from '../../constants'
import { routeList } from '../../routes/routeList'

const SideNavbar = ({ sideRef, openSide, trigger }) => {
  const navigate = useNavigate()
  const location = useLocation()

  const [listMenu, setListMenu] = useState([])
  const [menuOpen, setMenuOpen] = useState([])

  const listRoute = routeList().find((o) => o.path === '/')

  const isMobile = useMediaQuery({
    query: '(max-width: 450px)',
  })

  useEffect(() => {
    const dataListMenu = _.filter(listRoute.children, function (value) {
      return value.path !== 'root'
    })
    dataListMenu.forEach((item, index) => {
      item.index = index
    })
    setListMenu(dataListMenu)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const currentLocation = location.pathname
    listMenu.forEach((item) => {
      item.children?.forEach((i) => {
        if (i.key === currentLocation) {
          setMenuOpen([item.index.toString()])
        }
      })
    })
  }, [listMenu]) // eslint-disable-line react-hooks/exhaustive-deps

  // New Menu mengikuti Ant Design versi terbaru
  // dengan menambahkan permission @casl/ability
  const sidebarWithPermission = () => {
    const testing = listMenu.filter(function (item) {
      let childrens = item.children
      if (childrens) {
        let filteredchildren = childrens.filter(function (val) {
          let innerChildren = val.children
          if (innerChildren) {
            let filterInner = innerChildren.filter((tes) => ability.can(tes.actions, tes.subject))
            val.children = filterInner
          }
          return ability.can(val.actions, val.subject)
        })
        item.children = filteredchildren
      }

      return ability.can(item.actions, item.subject)
    })
    return testing
  }

  return (
    <aside
      ref={isMobile ? sideRef : null}
      className={`fixed inset-y-0 flex-1 items-center justify-between block w-[15rem] md:w-[16rem] z-50 p-0 my-4 overflow-y-auto antialiased font-nromal transition-transform duration-200 -translate-x-full bg-white border-0 shadow-xl dark:shadow-none dark:bg-slate-850 max-w-64 ease-nav-brand z-990 ${
        trigger ? 'ml-3 rounded-2xl left-0 translate-x-0' : null
      }`}
    >
      <div
        onClick={() => openSide()}
        className="p-7 font-semibold transition-all duration-200 ease-nav-brand text-center"
      >
        <img className="inline-block" alt="Logo Emirate" src={logo} width={230} />
      </div>

      <hr className="h-px mt-0 bg-transparent bg-gradient-to-r from-transparent via-black/40 to-transparent dark:bg-gradient-to-r dark:from-transparent dark:via-white dark:to-transparent" />

      <Menu
        className="relative text-gray-500"
        onClick={({ key }) => {
          if (key) {
            navigate(key)
          }
        }}
        items={sidebarWithPermission()}
        mode="inline"
        defaultSelectedKeys={[location.pathname]}
        defaultOpenKeys={menuOpen}
      />

      <div className="relative top-10 bottom-0 px-10 mb-2">
        <h2 className="text-base text-gray-800 font-medium">Versi {FLOW_VERSION}</h2>
      </div>
    </aside>
  )
}

export default SideNavbar
