import { request } from '../config'

// versi function
function LogoService() {
  // Get Data for Datatable
  const getDatatable = async (params, products_id) => {
    const response = await request.get(`/products/${products_id}/logo/pagination`, { params })
    return response.data
  }

  // Get Data for Datatable
  const list = async () => {
    const response = await request.get('/categories')

    return response.data
  }

  // Show detail
  const findById = async (id) => {
    const response = await request.get(`/categories/${id}`)

    return response.data
  }

  // insert data
  const save = async (products_id, data) => {
    const response = await request.post(`/products/${products_id}/logo`, data)
    return response
  }

  // update Data
  const update = async (products_id, logo_id, data) => {
    const response = await request.put(`/products/${products_id}/logo/${logo_id}`, data)

    return response.data
  }

  // remove data
  const remove = async (products_id, logo_id) => {
    const response = await request.delete(`/products/${products_id}/logo/${logo_id}`)

    return response.data
  }

  return {
    getDatatable,
    list,
    findById,
    save,
    update,
    remove,
  }
}

export default LogoService()
