import React, { useEffect, useState } from 'react'
import CustomBadge from '../../../components/CustomBadge'
import { Button, Card, DatePicker, Form, Select } from 'antd'
import DealmakerService from '../../../services/DealmakerService'

import * as am4charts from '@amcharts/amcharts4/charts'
import * as am4core from '@amcharts/amcharts4/core'
import am4themes_material from '@amcharts/amcharts4/themes/material'

am4core.useTheme(am4themes_material)

const Dealmaker = () => {
  const [form] = Form.useForm()

  const [loading, setLoading] = useState(false)

  const [unitOptions, setUnitOptions] = useState([])
  const [picOptions, setPicOptions] = useState([])

  const [summary, setSummary] = useState([])
  const [total, setTotal] = useState({})
  const [params, setParams] = useState({
    unit_id: null,
    user_id: null,
    start_date: '',
    end_date: '',
  })

  useEffect(() => {
    getUnitOptions()
  }, [])

  const getUnitOptions = async () => {
    setLoading(true)
    try {
      const response = await DealmakerService.unit()
      setUnitOptions(
        response.data.map((filter) => ({
          value: filter.id,
          label: filter.name,
        }))
      )
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const handleUnit = async (unit_id) => {
    setParams({ ...params, unit_id: unit_id })
    try {
      const response = await DealmakerService.picbyUnitId(unit_id)

      if (response.status === 200) {
        setPicOptions(response.data.map((item) => ({ value: item.id, label: item.name })))
      }
      form.resetFields(['user_id'])
    } catch (error) {
      console.log(error)
    }
  }

  const handleRangePicker = (date, dateString) => {
    const startDate = dateString[0]
    const endDate = dateString[1]

    setParams({ ...params, start_date: startDate, end_date: endDate })
  }

  const createSeries = (chart) => {
    const series = chart.series.push(new am4charts.PieSeries())
    series.dataFields.category = 'name'
    series.dataFields.value = 'value'
    series.labels.template.adapter.add('radius', function (radius, target) {
      if (target.dataItem && target.dataItem.values.value.percent < 10) {
        return 0
      }
      return radius
    })

    series.labels.template.adapter.add('fill', function (color, target) {
      if (target.dataItem && target.dataItem.values.value.percent < 10) {
        return am4core.color('#000')
      }
      return color
    })

    series.labels.template.text = "{category}: {value.percent.formatNumber('#.00')}%"
    series.legendSettings.valueText = "{value.percent.formatNumber('#.00')}%"
  }

  const amchartConfig = async (data) => {
    let productChart = am4core.create('product-charts', am4charts.PieChart)
    const categoryCoverData = data?.category_covers?.map((oke) => ({
      name: oke.name,
      value: oke.qty,
    }))

    productChart.data = categoryCoverData
    const categoryChartTitle = productChart.titles.create()
    categoryChartTitle.text = 'Produk'
    categoryChartTitle.fontSize = 18
    categoryChartTitle.wrap = true
    productChart.paddingTop = 0
    productChart.paddingBottom = 0
    productChart.radius = am4core.percent(50)
    createSeries(productChart)
    productChart.legend = new am4charts.Legend()
    productChart.legend.valueLabels.template.align = 'left'
    productChart.legend.valueLabels.template.textAlign = 'start'
    // productChart.legend.position = 'bottom';

    let platformChart = am4core.create('platform-charts', am4charts.PieChart)
    const platformData = data.platforms.map((platform) => ({
      name: platform.platform,
      value: platform.qty,
    }))

    platformChart.data = platformData
    const platformChartTitle = platformChart.titles.create()
    platformChartTitle.text = 'Platform'
    platformChartTitle.fontSize = 18
    platformChart.paddingTop = 0
    platformChart.paddingBottom = 0
    platformChart.radius = am4core.percent(50)
    createSeries(platformChart)
    platformChart.legend = new am4charts.Legend()
    platformChart.legend.valueLabels.template.align = 'left'
    platformChart.legend.valueLabels.template.textAlign = 'start'
    // platformChart.legend.position = 'bottom';
  }

  const handleExportData = async () => {
    setLoading(true)
    const data = {
      unit_id: params.unit_id,
      start_date: params.start_date,
      end_date: params.end_date,
    }

    try {
      const response = await DealmakerService.exportDataReportDealmaker(data)

      if (response.status === 200) {
        window.open(response.data.url, '_blank')
      }
    } catch (error) {
      setLoading(false)
      console.log()
    } finally {
      setLoading(false)
    }
  }

  const onFinish = async (values) => {
    setLoading(true)

    const data = {
      unit_id: values.unit_id,
      start_date: params.start_date,
      end_date: params.end_date,
    }

    try {
      const response = await DealmakerService.getDataTable(data)
      console.log(response)
      if (response.status === 200) {
        setSummary(response.data.summary)
        setTotal(response.data.total)
        amchartConfig(response.data.charts)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <CustomBadge formOrder title="Dealmaker" titleParent="Laporan" />

      <Card className="md:ml-3">
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <div className="grid md:grid-cols-3 gap-4">
            <Form.Item label="Unit" name="unit_id">
              <Select
                allowClear
                className="w-full"
                placeholder="Pilih Unit"
                options={unitOptions}
                onChange={handleUnit}
              />
            </Form.Item>

            <Form.Item label="List PIC" name="user_id">
              <Select allowClear className="w-full" placeholder="Pilih PIC" options={picOptions} />
            </Form.Item>

            <Form.Item label="Tanggal">
              <DatePicker.RangePicker
                className="w-full"
                placeholder={['Dari', 'Sampai']}
                onChange={handleRangePicker}
              />
            </Form.Item>
          </div>

          <div className="flex justify-end gap-2">
            <Form.Item>
              <Button className="text-white bg-rose-600 border-0">Batal</Button>
            </Form.Item>

            <Form.Item>
              <Button
                htmlType="submit"
                loading={loading}
                className="text-white bg-[#013a63] border-0"
              >
                Cari
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Card>

      {summary.length !== 0 && (
        <>
          <Card className="md:ml-3 my-5 antialiased border-0 shadow-lg relative">
            <div className="flex justify-between items-center mb-10">
              <h2 className="text-lg font-medium text-[#013a63]">Laporan Dealmaker</h2>

              <Button
                loading={loading}
                className="text-white bg-[#013a63] border-0"
                onClick={handleExportData}
              >
                Download
              </Button>
            </div>

            <div className="dealmaker-report-table overflow-x-auto">
              <table className="border-collapse table-auto w-full">
                <thead>
                  <tr>
                    <th>No</th>
                    <th className="min-w-[18rem]">Nama CS</th>
                    <th className="min-w-[8rem]">Biaya Ads</th>
                    <th className="min-w-[8rem]">Budget Ratio</th>
                    <th className="min-w-[7rem]">KPI Leads</th>
                    <th>Leads</th>
                    <th className="min-w-[8rem]">Cost / Lead</th>
                    <th>Closing</th>
                    <th>Konversi</th>
                    <th className="min-w-[8rem]">Cost / Deal</th>
                    <th>KPI Omzet</th>
                    <th>Omzet</th>
                    <th className="min-w-[9rem]">Belum Tercapai</th>
                    <th>Ketercapaian</th>
                  </tr>
                </thead>

                <tbody>
                  {summary.map((report, i) => (
                    <tr
                      className="text-right"
                      key={i}
                      style={{
                        backgroundColor: i % 2 === 0 ? '#F1F6F9' : '#fff',
                      }}
                    >
                      <td className="text-center">{i + 1}</td>
                      <td className="text-left">{report.name}</td>
                      <td>
                        {report.budget?.toLocaleString('id', {
                          style: 'currency',
                          currency: 'IDR',
                          minimumFractionDigits: 0,
                        })}
                      </td>
                      <td>{(report.budget_ratio * 100).toFixed(2)} %</td>
                      <td>{report.kpi_leads}</td>
                      <td>{report.leads}</td>
                      <td>
                        {report.cost_per_leads?.toLocaleString('id', {
                          style: 'currency',
                          currency: 'IDR',
                          minimumFractionDigits: 0,
                        })}
                      </td>
                      <td>{report.deals}</td>
                      <td>{(report.convertion * 100).toFixed(2)} %</td>
                      <td>
                        {report.cost_per_deals?.toLocaleString('id', {
                          style: 'currency',
                          currency: 'IDR',
                          minimumFractionDigits: 0,
                        })}
                      </td>
                      <td>
                        {report.kpi_omzet?.toLocaleString('id', {
                          style: 'currency',
                          currency: 'IDR',
                          minimumFractionDigits: 0,
                        })}
                      </td>
                      <td>
                        {report.omzet?.toLocaleString('id', {
                          style: 'currency',
                          currency: 'IDR',
                          minimumFractionDigits: 0,
                        })}
                      </td>
                      <td>
                        {report.omzet_not_achieved?.toLocaleString('id', {
                          style: 'currency',
                          currency: 'IDR',
                          minimumFractionDigits: 0,
                        })}
                      </td>
                      <td>{(report.omzet_ratio * 100).toFixed(2)} %</td>
                    </tr>
                  ))}

                  {total && (
                    <tr className="border text-right bg-[#9DB2BF]">
                      <th colSpan="2" className="uppercase text-center">
                        total
                      </th>
                      <th>
                        {total.budget?.toLocaleString('id', {
                          style: 'currency',
                          currency: 'IDR',
                          minimumFractionDigits: 0,
                        })}
                      </th>
                      <th>{(total.budget_ratio * 100).toFixed(2)} %</th>
                      <th>{total.kpi_leads}</th>
                      <th>{total.leads}</th>
                      <th>
                        {total.cost_per_leads?.toLocaleString('id', {
                          style: 'currency',
                          currency: 'IDR',
                          minimumFractionDigits: 0,
                        })}
                      </th>
                      <th>{total.deals}</th>
                      <th>{(total.convertion * 100).toFixed(2)} %</th>
                      <th>
                        {total.cost_per_deals?.toLocaleString('id', {
                          style: 'currency',
                          currency: 'IDR',
                          minimumFractionDigits: 0,
                        })}
                      </th>
                      <th>
                        {total.kpi_omzet?.toLocaleString('id', {
                          style: 'currency',
                          currency: 'IDR',
                          minimumFractionDigits: 0,
                        })}
                      </th>
                      <th>
                        {total.omzet?.toLocaleString('id', {
                          style: 'currency',
                          currency: 'IDR',
                          minimumFractionDigits: 0,
                        })}
                      </th>
                      <th>
                        {total.omzet_not_achieved?.toLocaleString('id', {
                          style: 'currency',
                          currency: 'IDR',
                          minimumFractionDigits: 0,
                        })}
                      </th>
                      <th>{(total.omzet_ratio * 100).toFixed(2)} %</th>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </Card>

          <Card className="md:ml-3 mb-5">
            <div
              id="product-charts"
              style={{
                width: '100%',
                height: '70rem',
                fontSize: '10px',
                marginTop: '50px',
              }}
            ></div>
          </Card>
        </>
      )}
    </>
  )
}

export default Dealmaker
