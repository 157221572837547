import { request } from '../config'

// versi function
function CourierService() {
  // Show List
  const getDataTable = async (params) => {
    const response = await request.get(`/couriers/pagination`, { params })
    return response.data
  }

  const list = async () => {
    const response = await request.get(`/couriers`)
    return response.data
  }

  const getShippingCost = async (data) => {
    const response = await request.post(`/shipping-cost`, data)
    return response.data
  }

  const changeStatusCourier = async (courier_id) => {
    const response = await request.put(`/couriers/change-status/${courier_id}`)
    return response.data
  }

  return {
    getDataTable,
    list,
    getShippingCost,
    changeStatusCourier,
  }
}

export default CourierService()
