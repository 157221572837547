/* eslint-disable react-hooks/exhaustive-deps */
import { Button, DatePicker, Form, Input, Select, Space, Upload } from 'antd'
import imageCompression from 'browser-image-compression'
import React, { useEffect, useState } from 'react'
import CustomBadge from '../../../../components/CustomBadge'
import PageLoading from '../../../../components/PageLoading'
import {
  PAYMENT_STATUS_BILLING,
  PAYMENT_STATUS_COD_DP,
  PAYMENT_STATUS_COD_FULL,
  PAYMENT_STATUS_DP,
  PAYMENT_STATUS_MARKETPLACE,
  PAYMENT_STATUS_PAID,
  PLATFORM_COD_NINJA,
  PLATFORM_LAZADA,
  PLATFORM_SHOPEE,
  PLATFORM_TIKTOK,
  PLATFORM_TOKOPEDIA,
} from '../../../../constants'
import ImageService from '../../../../services/ImageService'
import PaymentChannelService from '../../../../services/PaymentChannelService'
import moment from 'moment'

// const { Dragger } = Upload

const PaymentFormOrder = (props) => {
  const {
    loading,
    form,
    setVerification_type,
    evidence,
    setEvidence,
    setPaymentDate,
    valuePlatform,
    setPaymentAmount,
    evidenceImagePaste,
  } = props

  const [payment_status, setPaymentStatus] = useState([])
  const [payment_status_value, setPaymentStatusValue] = useState(null)
  const [payment_channel, setPaymentChannel] = useState([])

  useEffect(() => {
    if (evidenceImagePaste) handleImagePaste(evidenceImagePaste)
  }, [evidenceImagePaste])

  useEffect(() => {
    getPaymentStatus()
    getDataPaymentChannel()
  }, [])

  const getPaymentStatus = async () => {
    const response = await PaymentChannelService.getStatusPayment()

    try {
      if (response.status === 200) {
        setPaymentStatus(
          response.data.map((status) => ({
            value: status.id,
            label: status.name,
          }))
        )
      }
    } catch (error) {
      console.log(error)
    }
  }

  const paymentStatusOption = () => {
    const isMarketplace = [
      PLATFORM_TOKOPEDIA,
      PLATFORM_SHOPEE,
      PLATFORM_TIKTOK,
      PLATFORM_LAZADA,
    ].includes(valuePlatform)

    const isCOD = [PLATFORM_COD_NINJA].includes(valuePlatform)

    if (isMarketplace) {
      return payment_status.filter((payment) =>
        [PAYMENT_STATUS_MARKETPLACE].includes(payment.value)
      )
    }
    if (isCOD) {
      return payment_status.filter((payment) =>
        [PAYMENT_STATUS_COD_DP, PAYMENT_STATUS_COD_FULL].includes(payment.value)
      )
    } else {
      return payment_status.filter((payment) =>
        [PAYMENT_STATUS_DP, PAYMENT_STATUS_PAID, PAYMENT_STATUS_BILLING].includes(payment.value)
      )
    }
  }

  const handlePaymentStatus = (value) => {
    setPaymentStatusValue(value && value)

    if (value === PAYMENT_STATUS_DP) {
      form.setFieldsValue({
        payment_amount: undefined,
      })
    } else {
      form.setFieldsValue({
        payment_amount: 0,
      })
    }
  }

  const getDataPaymentChannel = async () => {
    const response = await PaymentChannelService.getPaymentChannel()

    try {
      if (response.status === 200) {
        setPaymentChannel([
          {
            label: 'Transfer Bank',
            options: response.data.map((channel) => ({
              value: channel.id,
              label:
                channel.name +
                ' - ' +
                channel.account_number +
                ' - ' +
                channel.account_name +
                ' (' +
                channel.unit.name +
                ')',
              verification_type: channel.verification_type,
            })),
          },
        ])
      }
    } catch (error) {
      console.log(error)
    }
  }

  const paymentChannelChange = (value, option) => {
    setVerification_type(option.verification_type)
  }

  const handleImagePaste = async (file) => {
    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    }

    if (file.size / 1024 > 550) {
      const image = await imageCompression(file, options)

      const formData = new FormData()
      formData.append('image', image)

      const response = await ImageService.paymentProof(formData)

      try {
        if (response.status === 200) {
          setEvidence([...evidence, response.data.data.url])
        }
      } catch (error) {
        console.log(error)
        throw new Error('Terjadi kesalahan pada saat upload bukti transfer.')
      }
    } else {
      const formData = new FormData()
      formData.append('image', file)

      const response = await ImageService.paymentProof(formData)

      try {
        if (response.status === 200) {
          setEvidence([...evidence, response.data.data.url])
        }
      } catch (error) {
        console.log(error)
        throw new Error('Terjadi kesalahan pada saat upload bukti transfer.')
      }
    }
  }

  const handleImageChange = async (file) => {
    const imageFile = file.file

    if (imageFile) {
      const formData = new FormData()
      formData.append('image', imageFile)

      const response = await ImageService.paymentProof(formData)

      try {
        if (response.status === 200) {
          setEvidence([...evidence, response.data.data.url])
        }
      } catch (error) {
        console.log(error)
        throw new Error('Terjadi kesalahan pada saat upload bukti transfer.')
      }
    } else {
      setEvidence([...evidence])
    }
  }

  const handleRemoveEvidence = async (image, idx) => {
    const newEvidence = evidence.filter((item, index) => index !== idx)
    setEvidence(newEvidence)
    form.setFieldsValue({
      payment_proof: {},
    })
  }

  const handleDatePaymentChange = (_, dateStrings) => {
    setPaymentDate(dateStrings)
  }

  const disabledPaymentDate = (current) => {
    return moment().add(-10, 'days') >= current || moment().add(0, 'days') < current
  }

  const handlePaymentAmountChange = (e) => {
    setPaymentAmount(e.target.value.replace(/\D/g, ''))

    form.setFieldsValue({
      payment_amount: parseInt(e.target.value.replace(/\D/g, '')).toLocaleString('id-ID'),
    })

    if (e.target.value === '' || e.target.value === null) {
      form.setFieldsValue({
        payment_amount: 0,
      })
    }
  }

  return (
    <div
      // onPasteCapture={handlePaste}
      className="relative bg-white subpixel-antialiased shadow-white-500/40 shadow-sm rounded-lg h-fit w-full md:overflow-visible pt-4 md:pt-4 pb-1 mt-14"
    >
      <div className="section-form-title-payment px-3">
        <CustomBadge titleOrder title="Pembayaran" />
      </div>

      <div className="section-payment-form px-3 mt-5">
        <Form.Item
          label="Status Pembayaran"
          name="payment_status_id"
          rules={[
            {
              required: true,
              message: 'Status pembayaran wajib diisi',
            },
          ]}
          required
        >
          <Select
            placeholder="Pilih Status Pembayaran"
            className="w-full md:w-96"
            onChange={handlePaymentStatus}
            options={paymentStatusOption()}
            allowClear
          />
        </Form.Item>

        {[PAYMENT_STATUS_DP, PAYMENT_STATUS_COD_DP, PAYMENT_STATUS_PAID].includes(
          payment_status_value
        ) && (
          <div className="grid md:grid-cols-3 gap-2">
            <Form.Item
              label="Tanggal Bayar"
              name="payment_date"
              rules={[
                {
                  required: true,
                  message: 'Tanggal Pembayaran harus diisi',
                },
              ]}
              required
            >
              <DatePicker
                onChange={handleDatePaymentChange}
                disabledDate={disabledPaymentDate}
                className="w-full"
              />
            </Form.Item>

            {[PAYMENT_STATUS_PAID].includes(payment_status_value) === false && (
              <Form.Item
                label="Nominal Bayar"
                name="payment_amount"
                rules={[
                  {
                    required: true,
                    message: 'Nominal pembayaran harus diisi',
                  },
                ]}
                required
              >
                <Input prefix="Rp" type="text" onChange={handlePaymentAmountChange} />
              </Form.Item>
            )}

            <Form.Item
              label="Metode Pembayaran"
              name="payment_channel_id"
              rules={[
                {
                  required: true,
                  message: 'Metode pembayaran harus diisi',
                },
              ]}
              required
            >
              <Select
                placeholder="Metode Pembayaran"
                onChange={paymentChannelChange}
                options={payment_channel}
              />
            </Form.Item>
          </div>
        )}

        {/* Khusus COD tanpa UPLOAD */}
        {![PAYMENT_STATUS_COD_FULL, PAYMENT_STATUS_BILLING].includes(payment_status_value) && (
          <Form.Item
            label="Bukti Transfer"
            name="payment_proof"
            // valuePropName="fileList"
            rules={[
              {
                required: true,
                message: 'Bukti Transfer harus di Upload!',
              },
            ]}
            required
          >
            <Upload
              name="image"
              accept="image/*"
              listType="picture-card"
              className="avatar-uploader"
              beforeUpload={() => {
                return false
              }}
              showUploadList={false}
              onChange={handleImageChange}
            >
              <Space direction="vertical">
                <i className="fa-solid fa-plus text-base text-cyan-600" />
                <h3 className="text-cyan-600 text-base font-normal">Upload</h3>
              </Space>
            </Upload>
          </Form.Item>
        )}

        {evidence?.map((item, idx) => (
          <div key={idx} className="flex justify-center items-center h-fit w-36 mb-2 ml-2">
            <img src={item} alt="" />

            <div
              className="h-10 w-10 flex justify-center items-center ml-2 cursor-pointer"
              onClick={() => handleRemoveEvidence(item, idx)}
            >
              <i className="fa-regular fa-trash-can text-red-500 text-base" />
            </div>
          </div>
        ))}

        <Form.Item className="flex justify-end">
          {loading ? (
            <PageLoading />
          ) : (
            <Button htmlType="submit" className="border-0 w-40 bg-green-500 text-base text-white">
              Simpan
            </Button>
          )}
        </Form.Item>

        <h3 className="text-sm">
          (<span className="text-red-500"> * </span>) Wajib diisi
        </h3>
      </div>
    </div>
  )
}

export default PaymentFormOrder
