import React, { useEffect, useState } from 'react'
import CustomBadge from '../../../components/CustomBadge'
import { Button, Card, Form, Select } from 'antd'
import OrderService from '../../../services/OrderService'
import ReportService from '../../../services/ReportService'
import moment from 'moment'

const BudgetRatio = () => {
  const [form] = Form.useForm()

  const [loading, setLoading] = useState(false)

  const [unitOptions, setUnitOptions] = useState([])
  const [picOptions, setPicOptions] = useState([])
  const [periodOptions, setPeriodOptions] = useState([])

  const [user_id, setUserId] = useState(null)
  const [unit_id, setUnitId] = useState(null)
  const [period, setPeriod] = useState(null)

  const [data, setData] = useState(null)

  useEffect(() => {
    getUnitOptions()
    getPeriodOptions()
  }, [])

  const getUnitOptions = async () => {
    setLoading(true)
    try {
      const response = await OrderService.getOrderFilterByUnit()
      setUnitOptions(
        response.data.map((filter) => ({
          value: filter.id,
          label: filter.name,
        }))
      )
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const getPeriodOptions = async () => {
    try {
      const response = await ReportService.getPeriodeOptions()
      if (response.status === 200) {
        setPeriodOptions(response.data.map((item) => ({ value: item.value, label: item.name })))
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleUnit = (value) => {
    try {
      if (value) {
        getPicOptions(value)
        form.resetFields(['user_id'])
      } else {
        form.resetFields(['user_id'])
        setPicOptions([])
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getPicOptions = async (unit_id) => {
    setLoading(true)
    try {
      const response = await OrderService.getOrderFilterByPic(unit_id)
      setPicOptions(
        response.data.map((filter) => ({
          value: filter.id,
          label: filter.name,
        }))
      )
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const toRupiah = (nominal) => {
    return nominal.toLocaleString('id', {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0,
    })
  }

  const jijekDate = (date) => {
    const arrD = date.split('/')
    const hasil = `${arrD[1]}/${arrD[0]}/${arrD[2]}`

    return new Date(hasil)
  }

  const emptyCol = () => {
    return [...new Array(data.content.length * (Object.keys(data.content[0].daily[0]).length + 1))]
      .slice(1)
      .map((a, i) => <td className="border" key={i}></td>)
  }

  const handleExportData = async () => {
    setLoading(true)
    const data = {
      unit_id: unit_id,
      user_id: user_id,
      period: period,
    }

    try {
      const response = await ReportService.exportBudgetRatioReport(data)

      if (response.status === 200) {
        window.open(response.data.url, '_blank')
      }
    } catch (error) {
      setLoading(false)
      console.log()
    } finally {
      setLoading(false)
    }
  }

  const onValuesChange = (changedValues, allValues) => {
    setUserId(allValues.user_id)
    setUnitId(allValues.unit_id)
    setPeriod(allValues.period)
  }

  const onFinish = async ({ unit_id, user_id, period }) => {
    // setLoading(true)
    const payload = {
      unit_id,
      user_id,
      period,
    }

    try {
      const response = await ReportService.getRatioBudgetReport(payload)

      if (response.status === 200) {
        setData(response.data)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <CustomBadge formOrder title="Budget Ratio" titleParent="Laporan" />

      <Card className="md:ml-3">
        <Form layout="vertical" form={form} onFinish={onFinish} onValuesChange={onValuesChange}>
          <div className="grid md:grid-cols-3 gap-4">
            <Form.Item label="Unit" name="unit_id">
              <Select
                allowClear
                className="w-full"
                placeholder="Pilih Unit"
                options={unitOptions}
                onChange={handleUnit}
              />
            </Form.Item>

            <Form.Item label="List PIC" name="user_id">
              <Select allowClear className="w-full" placeholder="Pilih PIC" options={picOptions} />
            </Form.Item>

            <Form.Item label="Periode" name="period">
              <Select className="w-full" placeholder="Pilih Periode" options={periodOptions} />
            </Form.Item>
          </div>

          <div className="flex justify-end gap-2">
            <Form.Item>
              <Button className="text-white bg-rose-600 border-0">Batal</Button>
            </Form.Item>

            <Form.Item>
              <Button
                htmlType="submit"
                loading={loading}
                className="text-white bg-[#013a63] border-0"
              >
                Cari
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Card>

      {data && (
        <Card className="md:ml-3 my-5 antialiased border-0 relative">
          <div className="flex justify-between items-center mb-10">
            <h2 className="text-lg font-medium text-[#013a63]">Laporan Budget Ratio</h2>

            <Button
              loading={loading}
              className="text-white bg-[#013a63] border-0"
              onClick={handleExportData}
            >
              Download
            </Button>
          </div>

          <div className="overflow-x-auto">
            <table className="border-collapse table-auto w-full">
              <thead>
                <tr className="text-sm text-center">
                  <td className="border"></td>
                  {data.content.map((ct, i) => {
                    return (
                      <td
                        key={i}
                        colSpan={Object.keys(data.content[0].daily[0]).length + 1}
                        className="border p-3"
                      >
                        {ct.name}
                      </td>
                    )
                  })}
                </tr>
                <tr className="text-sm text-center font-semibold">
                  <td className="border p-3 min-w-[12rem]">TANGGAL</td>
                  {data.content.map((as, tro) => {
                    return (
                      <React.Fragment key={tro}>
                        <td className="border p-3 min-w-[8rem] ">NO HP</td>
                        <td className="border p-3 min-w-[8rem] ">Total Ads</td>
                        <td className="border p-3 min-w-[8rem] ">META</td>
                        {/* <td className="border p-3 min-w-[8rem] ">
                                IG
                              </td> */}
                        <td className="border p-3 min-w-[8rem] ">GOOGLE</td>
                        <td className="border p-3 min-w-[8rem] ">MESSENGER</td>
                        <td className="border p-3 min-w-[8rem] ">TIKTOK</td>
                        <td className="border p-3 min-w-[8rem] ">YOUTUBE</td>
                        <td className="border p-3 min-w-[8rem] ">DM</td>
                        <td className="border p-3 min-w-[10rem]">LAIN-LAIN</td>
                        <td className="border p-3 min-w-[8rem]">OMZET</td>
                        <td className="border p-3 min-w-[8rem]">RASIO</td>
                      </React.Fragment>
                    )
                  })}
                </tr>
              </thead>

              <tbody>
                {data.days.map((d, i) => {
                  return (
                    <tr key={i} className="text-sm text-center">
                      <td className="border p-4">{moment(jijekDate(d)).format('LL')}</td>
                      {/* <td className="border p-4">{moment(d).format('LL')}</td> */}
                      {data.content.map((b, u) => {
                        return (
                          <React.Fragment key={u}>
                            <td className="border px-5">{b.phone}</td>
                            <td className="border px-5">{toRupiah(b.daily[i].total_budget)}</td>
                            <td className="border px-5">{toRupiah(b.daily[i].budget_fb)}</td>
                            <td className="border px-5">{toRupiah(b.daily[i].budget_google)}</td>
                            <td className="border px-5">{toRupiah(b.daily[i].budget_messenger)}</td>
                            <td className="border px-5">{toRupiah(b.daily[i].budget_tiktok)}</td>
                            <td className="border px-5">{toRupiah(b.daily[i].budget_youtube)}</td>
                            <td className="border px-5">{toRupiah(b.daily[i].budget_dm)}</td>
                            <td className="border px-5">
                              {toRupiah(b.daily[i].additional_budget)}
                            </td>
                            <td className="border px-5">{toRupiah(b.daily[i].omzet)}</td>
                            <td className="border px-5">{`${Number(
                              b.daily[i].budget_ratio * 100
                            ).toFixed(2)} %`}</td>
                          </React.Fragment>
                        )
                      })}
                    </tr>
                  )
                })}

                <tr className="text-sm text-center">
                  <td className="border p-4"></td>
                  {data.content.map((nt, i) => {
                    return (
                      <React.Fragment key={i}>
                        <td className="border p-4"></td>
                        <td className="border p-4">{toRupiah(nt.monthly.total_budget)}</td>
                        <td className="border p-4">{toRupiah(nt.monthly.budget_fb)}</td>
                        <td className="border p-4">{toRupiah(nt.monthly.budget_google)}</td>
                        <td className="border p-4">{toRupiah(nt.monthly.budget_messenger)}</td>
                        <td className="border p-4">{toRupiah(nt.monthly.budget_tiktok)}</td>
                        <td className="border p-4">{toRupiah(nt.monthly.budget_youtube)}</td>
                        <td className="border p-4">{toRupiah(nt.monthly.budget_dm)}</td>
                        <td className="border p-4">{toRupiah(nt.monthly.additional_budget)}</td>
                        <td className="border p-4">{toRupiah(nt.monthly.omzet)}</td>
                        <td className="border p-4">{`${Number(
                          nt.monthly.budget_ratio * 100
                        ).toFixed(2)} %`}</td>
                      </React.Fragment>
                    )
                  })}
                </tr>

                <tr className="text-sm font-semibold">
                  <td className="border p-4">TOTAL BUDGET ADS</td>
                  <td className="border text-end px-4">{toRupiah(data.total.total_budget)}</td>
                  {emptyCol()}
                </tr>

                <tr className="text-sm font-semibold">
                  <td className="border p-4">TOTAL OMZET</td>
                  <td className="border text-end px-4">{toRupiah(data.total.omzet)}</td>
                  {emptyCol()}
                </tr>

                <tr className="text-sm font-semibold">
                  <td className="border p-4">RASIO</td>
                  <td className="border text-end px-4">
                    {`${Number(data.total.budget_ratio * 100).toFixed(2)} %`}
                  </td>
                  {emptyCol()}
                </tr>
              </tbody>
            </table>
          </div>
        </Card>
      )}
    </>
  )
}

export default BudgetRatio
