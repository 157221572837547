import { Table } from 'antd'
import moment from 'moment'
import 'moment/locale/id'
import React from 'react'
import CustomBadge from '../../../../components/CustomBadge'
import { formatRp } from '../../../../helpers/formatRp'

const FormPaymentHistory = ({ data }) => {
  const columns = [
    {
      title: 'Tanggal',
      dataIndex: 'payment_date',
      key: '1',
      render: (payment_date) => moment(payment_date).format('dddd, DD/MM/YYYY'),
    },
    {
      title: 'Status',
      dataIndex: 'payment_status',
      key: '2',
      render: (_, record) => <h2 className="text-sm font-medium">{record.payment_status.name}</h2>,
    },
    {
      title: 'Bukti Pembayaran',
      dataIndex: 'evidences',
      key: '3',
      render: (_, record) => (
        <>
          {record.evidences?.map((evidence) => (
            <img
              key={evidence.image}
              width={75}
              src={evidence.image}
              alt=""
              onClick={() => {
                window.open(evidence.image, '_blank')
              }}
            />
          ))}
        </>
      ),
    },
    {
      title: 'Total',
      dataIndex: 'payment_amount',
      key: '4',
      render: (payment_amount) => formatRp(payment_amount),
    },
    {
      title: 'Bank',
      dataIndex: 'payment_channel',
      key: '5',
      render: (payment_channel, record) =>
        record.payment_channel
          ? `${record.payment_channel?.name} (${[
              record.payment_channel?.account_number,
              record.payment_channel?.account_name,
            ]
              .map((e) => e)
              .join(' - ')})`
          : record.payment_status.name,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: '6',
    },
  ]

  return (
    <div className="bg-white subpixel-antialiased shadow-white-500/40 shadow-sm rounded-lg h-fit w-full md:overflow-visible pt-4 md:pt-4 mt-10 mb-4">
      <div className="section-title-history-payment px-3">
        <CustomBadge titleOrder title="History Pembayaran" />
      </div>

      <div className="table-section-product relative w-full mt-5">
        <Table
          rowKey={'id'}
          dataSource={data.payment_histories}
          columns={columns}
          pagination={false}
          tableLayout="auto"
        />
      </div>
    </div>
  )
}

export default FormPaymentHistory
