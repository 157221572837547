/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Form, Modal } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

import { useNavigate, useParams } from 'react-router'
import CustomBadge from '../../../../components/CustomBadge'
import PageLoading from '../../../../components/PageLoading'
import OrderService from '../../../../services/OrderService'
import RegionalService from '../../../../services/RegionalService'
import FormCustomer from './FormCustomer'
import FormPaymentHistory from './FormPaymentHistory'
import FormProduct from './FormProduct'
import FormSummary from './FormSummary'

const EditOrder = () => {
  const { id } = useParams()
  const [form] = Form.useForm()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({})
  const [dataItems, setDataItems] = useState([])
  const [discount, setDiscount] = useState(0)
  const [district, setDistrict] = useState([])
  const [subdistricts, setSubdistricts] = useState([])
  const [shipping_cost, setShipping_cost] = useState(0)
  const [additional_cost, setAdditional_cost] = useState(0)

  useEffect(() => {
    getDataOrderDetail()
  }, [])

  const getDataOrderDetail = async () => {
    try {
      const response = await OrderService.getDataByID(id)

      if (response.status === 200) {
        setData(response.data)
        setDataItems(
          response.data.items.map((item) => ({
            ...item,
            material_id: item.material.id,
            material_variant_1_id: item.material_variant_1?.id,
            material_variant_2_id: item.material_variant_2?.id,
            fiber_id: item.fiber.id,
            fiber_variant_1_id: item.fiber_variant_1?.id,
            fiber_variant_2_id: item.fiber_variant_2?.id,
            color_id: item.color.id,
            color_variant_1_id: item.color_variant_1?.id,
            color_variant_2_id: item.color_variant_2?.id,
            fiber_options: [],
            fiber_variant_options_1: [],
            fiber_variant_options_2: [],
            color_options: [],
            color_variant_options_1: [],
            color_variant_options_2: [],
            logo: item.logo,
            transmission_type: item.transmission_type,
            fire_extinguisher: item.fire_extinguisher,
            lock_hole: item.lock_hole,
            // fire_extinguisher_id: item.fire_extinguisher?.id,
            // lock_hole_id: item.lock_hole?.id,
          }))
        )
        setDiscount(response.data.discount)
        setAdditional_cost(response.data.additional_cost)
        setShipping_cost(response.data.shipping_cost)

        const customer = {
          value: response.data.id,
          label: response.data.customer.name + ' | ' + response.data.customer.phone,
        }

        provinceChange(response.data.shipment.province.id)
        districtChange(response.data.shipment.district.id)

        form.setFieldsValue({
          ...response.data,
          customer: customer,
          customer_phone: response.data.customer.phone.replace(/[^0-9]+/gi, '').slice(2),
          phone: response.data.shipment.phone.replace(/[^0-9]+/gi, '').slice(2),
          name: response.data.shipment.name,
          postal_code: response.data.shipment.postal_code,
          province_id: response.data.shipment.province.id,
          district_id: response.data.shipment.district.id,
          subdistrict_id: response.data.shipment.subdistrict.id,
          address: response.data.shipment.address,
          order_at: moment(response.data.order_date).format('DD/MM/YYYY'),
          shipper_id: response.data.shipment.shipper.name,
          courier_code: response.data?.shipment?.courier_code,
          courier_name: response.data.shipment.courier_name,
          courier_service: response.data.shipment.courier_service,
          tracking_number: response.data.shipment.tracking_number,
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const provinceChange = (value) => {
    if (value) {
      RegionalService.districtByProvince(value)
        .then((response) => {
          setDistrict(
            response.data.map((district) => ({
              value: district.id,
              label:
                district.type === 'Kabupaten'
                  ? `Kab. ${district.name}`
                  : `${district.type} ${district.name}`,
            }))
          )
        })
        .catch((error) => {
          console.log(error)
        })

      // RESET KOTA/KABUPATEN DAN KECAMATAN
      // KETIKA ADA PERUBAHAN PROVINSI
      form.setFieldsValue({
        district_id: undefined,
        subdistrict_id: undefined,
      })
    } else {
      // RESET KOTA/KABUPATEN DAN KECAMATAN
      // KETIKA PROVINSI BELUM DIPILIH
      form.setFieldsValue({
        district_id: undefined,
        subdistrict_id: undefined,
      })

      return value
    }
  }

  const districtChange = (value) => {
    if (value) {
      RegionalService.subdistrictByDistrict(value)
        .then((response) => {
          setSubdistricts(
            response.data.map((subdistrict) => ({
              value: subdistrict.id,
              label: subdistrict.name,
            }))
          )
        })
        .catch((err) => {
          console.log(err)
        })

      // RESET KECAMATAN
      // KETIKA ADA PERUBAHAN KOTA/KABUPATEN
      form.setFieldsValue({
        subdistrict_id: undefined,
      })
    } else {
      // RESET KECAMATAN
      // KETIKA KOTA/KABUPATEN BELUM DIPILIH
      form.setFieldsValue({
        subdistrict_id: undefined,
      })

      return value
    }
  }

  const onFinish = async (values) => {
    const payload = {
      description: values.description || '',
      bonus: values.bonus || [],
      shipment: {
        name: values.name || '',
        phone: `+62${values.phone}` || '',
        email: values.email || '',
        address: values.address,
        courier_code: values.courier_code,
        courier_name: values.courier_name,
        courier_service: values.courier_service,
      },
      items: dataItems.map((product) => ({
        item_id: product.item_id,
        year: product.year,
        color_id: product.color_id,
        fiber_id: product.fiber_id,
        fiber_variant_1_id: product.fiber_variant_1_id || null,
        fiber_variant_2_id: product.fiber_variant_2_id || null,
        note: product.note,
        color_variant_1_id: product.color_variant_1_id || null,
        color_variant_2_id: product.color_variant_2_id || null,
        material_id: product.material_id || null,
        material_variant_1_id: product.material_variant_1_id || null,
        material_variant_2_id: product.material_variant_2_id || null,
        logo: product.logo || null,
        lock_hole: product.lock_hole || null,
        fire_extinguisher: product.fire_extinguisher || null,
        transmission_type: product.transmission_type || null,
        // logo_id: product.logo_id || null,
        // lock_hole_id: product.lock_hole_id || null,
        // fire_extinguisher_id: product.fire_extinguisher_id || null,
        // transmission_type_id: product.transmission_type_id || null,
      })),
    }

    // console.log(payload)

    setLoading(true)

    try {
      const response = await OrderService.editOrder(payload, id)

      if (response.status === 200) {
        Modal.success({
          title: 'Berhasil',
          content: 'Edit Order berhasil',
        })

        navigate(`/table/orders`)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <CustomBadge formOrder title="Edit Order" titleParent="Order" />

      <Form form={form} onFinish={onFinish} layout="vertical" size="large" className="mt-10">
        <FormCustomer
          form={form}
          district={district}
          subdistricts={subdistricts}
          provinceChange={provinceChange}
          districtChange={districtChange}
        />

        <FormProduct
          form={form}
          data={data}
          dataItems={dataItems}
          setDataItems={setDataItems}
          getDataOrderDetail={getDataOrderDetail}
        />

        <FormSummary
          form={form}
          data={data}
          discount={discount}
          setDiscount={setDiscount}
          additional_cost={additional_cost}
          setAdditional_cost={setAdditional_cost}
          shipping_cost={shipping_cost}
          setShipping_cost={setShipping_cost}
        />

        <FormPaymentHistory data={data} />

        <div className="flex justify-end">
          {loading ? (
            <PageLoading />
          ) : (
            <Button htmlType="submit" className="border-0 w-40 bg-cyan-500 text-white mb-4">
              Update
            </Button>
          )}
        </div>
      </Form>
    </>
  )
}

export default EditOrder
