import React, { useEffect, useState } from 'react'
import CustomBadge from '../../../components/CustomBadge'
import { Button, DatePicker, Form, Input, Select, Table } from 'antd'
import moment from 'moment'
import { useMediaQuery } from 'react-responsive'
import LeadsService from '../../../services/LeadsService'
import { useNavigate } from 'react-router'
import AddTreatment from '../Modal/AddTreatment'
import UpdateLeads from '../Modal/UpdateLeads'
import Purchased from '../Modal/Purchased'
import Swal from 'sweetalert2'
import ability from '../../../ability/ability'
import CustomPagination from '../../../components/CustomPagination'
import UploadFile from '../Modal/UploadFile'

const TableLeads = () => {
  const navigate = useNavigate()
  const [form] = Form.useForm()

  const [selectedRow, setSelectedRow] = useState(null)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [filterOptions, setFilterOptions] = useState([])

  const [total, setTotal] = useState(0)
  const [params, setParams] = useState({
    page: 1,
    per_page: 10,
    sort: 'id',
    order: 'desc',
    filter_column: '',
    filter_text: '',
    start_date: '',
    end_date: '',
  })
  const [modal, setModal] = useState({
    isTreatmentModalOpen: false,
    isUpdateLeadsModalOpen: false,
    isPurchasedModalOpen: false,
    isUploadFileModal: false,
  })

  const isDisplay = useMediaQuery({
    query: '(min-width: 1680px)',
  })

  useEffect(() => {
    getData()
    getDataFilter()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  const getData = async () => {
    try {
      setLoading(true)
      const response = await LeadsService.getDatatable(params)
      if (response.status === 200) {
        setLoading(false)
        setData(response.data)
        setTotal(response.meta.total)
      }
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const getDataFilter = async () => {
    try {
      setLoading(true)
      const response = await LeadsService.dropdownFilter()
      if (response.status === 200) {
        setLoading(false)
        setFilterOptions(response.data.map((item) => ({ label: item.name, value: item.value })))
      }
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const toggleModal = (name) => {
    setModal((prev) => ({ ...prev, [name]: !prev[name] }))
  }

  const onChangeStatusContact = async (id) => {
    LeadsService.changeStatusContact(id).then(() => getData())
  }

  const onChangeStatusCanBeContacted = async (id) => {
    LeadsService.changeCanBeContacted(id).then(() => getData())
  }

  const handleRangePicker = (date, dateString) => {
    const start_date = dateString[0]
    const end_date = dateString[1]

    setParams({ ...params, start_date, end_date })
  }

  const exportData = async () => {
    setLoading(true)

    try {
      const response = await LeadsService.getDatatable({
        ...params,
        start_date: params.start_date,
        end_date: params.end_date,
        filter_column: form.getFieldValue('filter_column'),
        filter_text: form.getFieldValue('filter_text'),
        export: true,
      })

      if (response.status === 200) {
        window.open(response.data.url, '_blank')
        setLoading(false)
        Swal.fire({
          icon: 'success',
          title: 'Data Berhasil Diexport',
        })
      }
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const deleteLeads = (id) => {
    Swal.fire({
      title: 'Anda Yakin?',
      text: 'Leads ini akan dihapus dan tidak dapat dipulihkan.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya',
      cancelButtonText: 'Tidak',
    }).then((result) => {
      if (result.isConfirmed) {
        LeadsService.removeLeads(id).then((res) => {
          if (res.status === 200) {
            Swal.fire({
              icon: 'success',
              title: 'Data Leads Berhasil Dihapus',
            })
          }
          getData()
        })
      }
    })
  }

  const columns = [
    {
      title: 'Tanggal Chat',
      dataIndex: 'first_chat',
      key: 'first_chat',
      sorter: true,
      render: (cell) => (
        <li className="text-gray-800 font-semibold">{moment(cell).format('DD/MM/YYYY')}</li>
      ),
    },
    {
      title: 'Dealmaker',
      dataIndex: ['user', 'name'],
      key: 'dealmaker_name',
    },
    {
      title: 'WA Dealmaker',
      dataIndex: ['dealmaker_phone'],
      key: 'dealmaker_phone',
    },
    {
      title: 'WA Konsumen',
      dataIndex: ['customer_phone'],
      key: 'customer_phone',
    },
    {
      title: 'Nama Konsumen',
      dataIndex: ['customer_name'],
      key: 'customer_name',
    },
    {
      title: 'Merk / Tipe / Tahun',
      dataIndex: ['customer_vehicle'],
      key: 'customer_vehicle',
    },
    {
      title: 'Catatan',
      dataIndex: ['customer_vehicle_note'],
      key: 'customer_vehicle_note',
    },
    {
      title: 'Save Contact?',
      dataIndex: ['contact_saved'],
      key: 'contact_saved',
      align: 'center',
      render: (contact_saved, row) => {
        const iconButton = contact_saved
          ? 'fa-solid fa-circle-check text-lg text-green-600 cursor-pointer'
          : 'fa-solid fa-circle-xmark text-lg text-rose-500 cursor-pointer'

        return <i onClick={() => onChangeStatusContact(row.id)} className={`fas ${iconButton}`}></i>
      },
    },
    {
      title: 'Dapat dihubungi?',
      dataIndex: ['can_be_contacted'],
      key: 'can_be_contacted',
      align: 'center',
      render: (can_be_contacted, row) => {
        const iconButton = can_be_contacted
          ? 'fa-solid fa-circle-check text-lg text-green-600 cursor-pointer'
          : 'fa-solid fa-circle-xmark text-lg text-rose-500 cursor-pointer'

        return (
          <i
            onClick={() => onChangeStatusCanBeContacted(row.id)}
            className={`fas ${iconButton}`}
          ></i>
        )
      },
    },
    {
      title: 'Sudah Beli?',
      dataIndex: ['purchased'],
      key: 'purchased',
      align: 'center',
      render: (purchased) => {
        const iconButton = purchased
          ? 'fa-solid fa-circle-check text-lg text-green-600'
          : 'fa-solid fa-circle-xmark text-lg text-rose-500'

        return <i className={`fas ${iconButton}`}></i>
      },
    },
    {
      title: 'Alasan Belum Beli',
      dataIndex: ['reason'],
      key: 'reason',
    },
    {
      title: 'Platform',
      dataIndex: ['platform'],
      key: 'platform',
    },
    {
      title: 'Treatment',
      dataIndex: ['treatments'],
      key: 'treatments',
      render: (treatments) => {
        return (
          <>
            {treatments.map((t, i) => (
              <span key={i}>
                {new Date(t.treatment_date).toLocaleDateString('id-ID', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                })}{' '}
                - {t.treatment}
                <br />
              </span>
            ))}
          </>
        )
      },
    },

    {
      title: 'Aksi',
      dataIndex: 'action',
      key: 'action',
      render: (cell, row) => {
        return (
          <div className="grid md:grid-cols-1 gap-2 w-40">
            {row.purchased ? null : (
              <>
                <Button
                  icon={<i className="fas fa-plus mr-1" />}
                  className="border-0 text-xs text-white bg-[#074173] rounded-md font-medium"
                  onClick={() => {
                    setSelectedRow(row)
                    toggleModal('isTreatmentModalOpen')
                  }}
                >
                  Tambah Treatment
                </Button>

                <Button
                  size="small"
                  className="border-0 text-xs text-white bg-yellow-500 rounded-md font-medium"
                  onClick={() => {
                    setSelectedRow(row)
                    toggleModal('isUpdateLeadsModalOpen')
                  }}
                >
                  <i className="fa-solid fa-edit mr-2" /> Update Leads
                </Button>

                <Button
                  size="small"
                  className="border-0 text-xs text-white bg-[#0D9276] rounded-md font-medium"
                  onClick={() => {
                    setSelectedRow(row)
                    toggleModal('isPurchasedModalOpen')
                  }}
                >
                  <i className="fas fa-check mr-2" /> Tandai Sudah Beli
                </Button>
              </>
            )}
            {row.customer_phone && (
              <>
                <Button
                  size="small"
                  className="border-0 text-xs text-white bg-green-500 rounded-md font-medium"
                  onClick={() => {
                    let text = [1006].includes(row.user.id)
                      ? `Halo Kak, ` +
                        `Perkenalkan saya Vianna dari Emirate Seat Cover. ` +
                        `Rencana pesan Sarung Jok untuk mobil ${row.customer_vehicle_note} ya kak? `
                      : `Halo Bapak/Ibu ${row.customer_name}, ` +
                        `Perkenalkan saya ${row.user.name} dari Emirate Indonesia. `

                    let url = `https://wa.me/${row.customer_phone.replace(
                      '+',
                      ''
                    )}?text=${encodeURIComponent(text)}`

                    window.open(url, '_blank')
                  }}
                >
                  <i className="fa-brands fa-whatsapp mr-2" /> Whatsapp
                </Button>
              </>
            )}
            {ability.can('delete', 'Leads') && (
              <Button
                size="small"
                className="border-0 text-xs text-white bg-rose-500 rounded-md font-medium"
                onClick={() => deleteLeads(row.id)}
              >
                <i className="fa-solid fa-trash-can mr-2" /> Hapus
              </Button>
            )}
          </div>
        )
      },
    },
  ]

  const onPageChange = (page, pageSize) => {
    const per_page = pageSize
    setParams({ ...params, page, per_page })
  }

  const onTableChange = (_pagination, _filters, sorter) => {
    let { columnKey: sort, order } = sorter
    sort = order ? sort : 'id' // jika order undefined default sort adalah column `id` dan order by `id` desc
    order = order === 'ascend' ? 'asc' : 'desc'
    setParams({ ...params, sort, order })
  }

  const handleFilter = async (values) => {
    const start_date = (await values.rangeDate) ? values?.rangeDate[0].format('YYYY-MM-DD') : ''
    const end_date = (await values.rangeDate) ? values?.rangeDate[1].format('YYYY-MM-DD') : ''

    setParams({
      ...params,
      start_date,
      end_date,
      filter_column: values.filter_column,
      filter_text: values.filter_text,
    })
  }

  const handleRow = (record, index) => {
    if (index % 2 !== 0) {
      return 'bg-slate-200'
    }
  }

  return (
    <>
      <CustomBadge formOrder title="Tabel Leads" titleParent="Leads" />

      <div className="lg:ml-3 bg-white shadow-white-500/40 shadow-sm rounded-2xl h-fit w-full md:overflow-visible mt-10 mb-10 pt-4 md:pt-4">
        <div className="section-table-orders antialiased px-2 md:py-2 md:px-4">
          <Form layout="vertical" form={form} onFinish={handleFilter}>
            <div className="grid md:grid-cols-3 gap-4">
              <Form.Item name="rangeDate" label="Tanggal">
                <DatePicker.RangePicker
                  placeholder={['Dari', 'Sampai']}
                  onChange={handleRangePicker}
                  className="w-full"
                />
              </Form.Item>

              <Form.Item name="filter_column" label="Cari Berdasarkan">
                <Select allowClear options={filterOptions} placeholder="Pilih Kolom Pencarian" />
              </Form.Item>

              <Form.Item name="filter_text" label="Keyword">
                <Input placeholder="keyword" />
              </Form.Item>
            </div>

            <Form.Item>
              <div className="flex justify-end gap-2">
                <Button
                  htmlType="submit"
                  loading={loading}
                  className="border-0 text-white text-sm bg-[#205295] float-right"
                >
                  Cari
                </Button>

                {ability.can('export', 'Leads') && (
                  <Button
                    htmlType="button"
                    onClick={exportData}
                    loading={loading}
                    className="border-0 text-white text-sm bg-[#FFC93C] float-right"
                  >
                    Export
                  </Button>
                )}
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>

      <div className="flex justify-end gap-2">
        {ability.can('create', 'Leads') && (
          <Button className="bg-[#205295] text-white" onClick={() => navigate(`/leads/add`)}>
            Tambah
          </Button>
        )}

        {ability.can('import', 'Leads') && (
          <Button
            className="bg-[#03C988] text-white"
            onClick={() => {
              toggleModal('isUploadFileModal')
            }}
          >
            Upload / Import Leads
          </Button>
        )}
      </div>

      <div className="lg:ml-3 bg-white shadow-white-500/40 shadow-sm rounded-2xl h-fit w-full md:overflow-visible mt-4 relative md:mt-4">
        <div className="table-section-leads antialiased relative w-full">
          <Table
            rowKey="item_id"
            size="large"
            loading={loading}
            dataSource={data}
            columns={columns}
            pagination={false}
            scroll={{ x: isDisplay ? '200vw' : '150vw' }}
            onChange={onTableChange}
            tableLayout="auto"
            rowClassName={handleRow}
          />
        </div>

        <div className="section-pagination flex justify-center md:block px-2 py-8 md:p-4">
          <CustomPagination {...params} onPageChange={onPageChange} total={total} />
        </div>
      </div>

      <AddTreatment
        open={modal.isTreatmentModalOpen}
        toggle={() => {
          toggleModal('isTreatmentModalOpen')
          getData()
        }}
        row={selectedRow}
      />

      <UpdateLeads
        open={modal.isUpdateLeadsModalOpen}
        toggle={() => {
          toggleModal('isUpdateLeadsModalOpen')
          getData()
        }}
        row={selectedRow}
      />

      <Purchased
        open={modal.isPurchasedModalOpen}
        toggle={() => {
          toggleModal('isPurchasedModalOpen')
          getData()
        }}
        row={selectedRow}
      />

      <UploadFile
        open={modal.isUploadFileModal}
        toggle={() => {
          toggleModal('isUploadFileModal')
          getData()
        }}
        row={selectedRow}
      />
    </>
  )
}

export default TableLeads
