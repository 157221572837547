import { request } from '../config'

// versi function
function VehicleService() {
  const getDatatable = async (params) => {
    const response = await request.get(`/vehicle/manufacturers/pagination`, { params })
    return response.data
  }

  const findByIdManufacturer = async (manufacturer_id) => {
    const response = await request.get(`/vehicle/manufacturers/${manufacturer_id}`)
    return response.data
  }

  const updateManufacturer = async (manufacturer_id, data) => {
    const response = await request.put(`/vehicle/manufacturers/${manufacturer_id}`, data)
    return response.data
  }

  const removeManufacturer = async (manufacturer_id) => {
    const response = await request.delete(`/vehicle/manufacturers/${manufacturer_id}`)
    return response.data
  }

  const saveManufacturer = async (data) => {
    const response = await request.post(`/vehicle/manufacturers`, data)
    return response.data
  }

  const getManufacturer = async () => {
    const response = await request.get(`/vehicle/manufacturers`)
    return response.data
  }

  return {
    getDatatable,
    findByIdManufacturer,
    updateManufacturer,
    removeManufacturer,
    saveManufacturer,
    getManufacturer,
  }
}

export default VehicleService()
