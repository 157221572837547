import React, { useEffect, useState } from 'react'
import CustomBadge from '../../components/CustomBadge'
import { Button, Input, Table } from 'antd'
import CustomerService from '../../services/CustomerService'
import CustomPagination from '../../components/CustomPagination'
import OrderCustomer from './Modal/OrderCustomer'

const Customer = () => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [total, setTotal] = useState(0)
  const [params, setParams] = useState({
    page: 1,
    per_page: 10,
    sort: 'id',
    order: 'desc',
    filter: '',
  })
  const [modal, setModal] = useState({
    isOrderCustomerModalOpen: false,
  })
  const [selectedRow, setSelectedRow] = useState(null)

  useEffect(() => {
    if (params.filter.length >= 3) {
      getDataCustomer()
    }

    return setData([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  const getDataCustomer = async () => {
    setLoading(true)

    try {
      const response = await CustomerService.getDataCustomer(params)
      console.log(response)
      if (response.status === 200) {
        setTotal(response.meta.total)
        setData(response.data)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const toggleModal = (name) => {
    setModal((prev) => ({ ...prev, [name]: !prev[name] }))
  }

  const onSearch = (filter) => {
    setParams({ ...params, filter })
  }

  const onPageChange = (page, pageSize) => {
    const per_page = pageSize
    setParams({ ...params, page, per_page })
  }

  const onTableChange = (_pagination, _filters, sorter) => {
    let { columnKey: sort, order } = sorter
    sort = order ? sort : 'id' // jika order undefined default sort adalah column `id` dan order by `id` desc
    order = order === 'ascend' ? 'asc' : 'desc'
    setParams({ ...params, sort, order })
  }

  const handleRow = (record, index) => {
    if (index % 2 !== 0) {
      return 'bg-gray-200'
    }
  }

  const columns = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      width: 50,
      render: (no) => <p className="text-center">{no}</p>,
    },
    {
      title: 'Nama',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      render: (text) => <li className="text-gray-800 font-semibold text-sm">{text}</li>,
    },
    {
      title: 'Telepon',
      dataIndex: 'phone',
      key: 'phone',
      sorter: true,
      render: (email) => <li className="text-gray-800 text-sm">{email}</li>,
    },
    {
      title: 'Jenis Pelanggan',
      dataIndex: 'customer_type',
      key: 'customer_type',
      sorter: false,
      width: 180,
      render: (text) => <li className="text-gray-800 text-xs">{text}</li>,
    },
    {
      title: 'Action',
      key: 'action',
      align: 'center',
      render: (_, record) => (
        <Button
          icon={<i className="fa-solid fa-circle-info" />}
          className="bg-[#014f86] border-0 text-white text-xs rounded-md"
          onClick={() => {
            setSelectedRow(record)
            toggleModal('isOrderCustomerModalOpen')
          }}
        >
          Lihat Order
        </Button>
      ),
    },
  ]

  return (
    <>
      <CustomBadge formOrder title="Data Customer" titleParent="Customer" />

      <div className="search-user md:ml-2 my-5">
        <Input.Search
          className="md:col-start-1 md:w-72"
          placeholder="Minimal 3 Karakter"
          onSearch={onSearch}
        />
      </div>

      <div className="table-section-master-user relative w-full md:ml-2">
        <Table
          className="antialiased"
          rowKey="id"
          onChange={onTableChange}
          loading={loading}
          columns={columns}
          dataSource={data}
          pagination={false}
          scroll={{ x: true }}
          tableLayout="auto"
          rowClassName={handleRow}
        />
      </div>

      <div className="bg-white px-2 py-8 md:ml-2 w-full md:p-4 md:pb-4 md:pt-2">
        <CustomPagination {...params} onPageChange={onPageChange} total={total} />
      </div>

      <OrderCustomer
        open={modal.isOrderCustomerModalOpen}
        row={selectedRow}
        toggle={() => {
          toggleModal('isOrderCustomerModalOpen')
        }}
      />
    </>
  )
}

export default Customer
