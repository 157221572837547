import { request } from '../config'

// versi function
function ColorService() {
  // Get Data for Datatable
  const getDatatable = async (params, fiber_id) => {
    const response = await request.get(`/fibers/${fiber_id}/colors/pagination`, { params })
    return response.data
  }

  // insert data
  const save = async (data, fiber_id) => {
    const response = await request.post(`/fibers/${fiber_id}/colors`, data)
    return response
  }

  // update Data
  const update = async (data, fiber_id, color_id) => {
    const response = await request.put(`/fibers/${fiber_id}/colors/${color_id}`, data)

    return response.data
  }

  // remove data
  const remove = async (fiber_id, color_id) => {
    const response = await request.delete(`/fibers/${fiber_id}/colors/${color_id}`)

    return response.data
  }

  return {
    getDatatable,
    save,
    update,
    remove,
  }
}

export default ColorService()
