import { Button, Checkbox, Form, Input, notification, Radio, Select, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import {
  ROLE_ADMIN,
  ROLE_ADMIN_PRODUCTION,
  ROLE_CS_SUPERVISOR,
  ROLE_DEALMAKER,
  ROLE_FINANCE,
  ROLE_MARKETING,
} from '../../../../constants'
import UnitService from '../../../../services/UnitService'
import UserService from '../../../../services/UserService'

const AddUser = () => {
  const [form] = Form.useForm()
  const navigate = useNavigate()

  const [data, setData] = useState([])
  const [dataUnit, setDataUnit] = useState([])
  const [role_id, setRole_id] = useState(null)
  const [unit_id, setUnit_id] = useState(null)

  useEffect(() => {
    getListRole()
    getListUnit()
  }, [])

  const getListRole = async () => {
    try {
      const response = await UserService.listRole()
      if (response.status === 200) {
        setData(
          response.data.map((item) => ({
            value: item.id,
            label: item.name,
          }))
        )
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getListUnit = async () => {
    try {
      const response = await UnitService.all()
      if (response.status === 200) {
        setDataUnit(
          response.data.map((item) => ({
            value: item.id,
            label: item.name,
          }))
        )
      }
    } catch (error) {
      console.log(error)
    }
  }

  const allCheckedActive = () => {
    let data = dataUnit.map((item) => item.value)
    return form.setFieldsValue({
      units: data,
    })
  }

  const handleRoleChange = (value) => {
    setRole_id(value && value)
    if ([ROLE_ADMIN, ROLE_ADMIN_PRODUCTION, ROLE_MARKETING, ROLE_CS_SUPERVISOR].includes(value)) {
      allCheckedActive()
    } else {
      form.setFieldsValue({
        units: undefined,
      })
      setUnit_id(null)
    }
  }

  const handleUnitChange = (e) => {
    const value = e.target.value

    setUnit_id(value)
  }

  const onFinish = async (values) => {
    const payload = {
      ...values,
      units: unit_id !== null ? [unit_id] : values.units,
    }

    try {
      const response = await UserService.save(payload)
      if (response.status === 200) {
        notification.success({
          message: 'Berhasil menambahkan pengguna',
        })
      }
      navigate('/users')
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className="bg-white shadow-white-500/40 shadow-sm rounded-2xl h-fit w-full md:overflow-visible pt-4 md:pt-4">
      <div className="px-2 md:px-4">
        <div className="relative flex items-center shadow-cyan-300/40 shadow-lg -mt-12 bg-cyan-500 rounded-2xl w-fit h-16 px-5">
          <h2 className="text-base text-white font-medium">
            Tambah Pengguna{' '}
            <span>
              <i className="fa-solid fa-house-user mx-3" />
            </span>{' '}
            <span className="text-sm">Pengguna</span>
          </h2>
        </div>

        <div className="section-add-user mt-10">
          <Form form={form} onFinish={onFinish} layout="vertical" size="large">
            <div className="grid md:grid-cols-2 gap-2">
              <Form.Item
                label="Nama"
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Nama pengguna harus diisi',
                  },
                ]}
                required
              >
                <Input placeholder="Nama" />
              </Form.Item>

              <Form.Item
                label="E-mail"
                name="email"
                rules={[
                  {
                    required: true,
                    message: 'Email pengguna harus diisi',
                  },
                ]}
                required
              >
                <Input placeholder="Email" />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Password pengguna harus diisi',
                  },
                ]}
                required
              >
                <Input.Password placeholder="Password" />
              </Form.Item>

              <Form.Item
                label="Sebagai"
                name="role_id"
                rules={[
                  {
                    required: true,
                    message: 'Role pengguna harus diisi',
                  },
                ]}
                required
              >
                <Select placeholder="Pilih" options={data} onChange={handleRoleChange} allowClear />
              </Form.Item>

              {[ROLE_DEALMAKER, ROLE_FINANCE].includes(role_id) && (
                <Form.Item
                  label="Pilih Unit"
                  name="units"
                  rules={[
                    {
                      required: true,
                      message: 'Unit pengguna harus diisi',
                    },
                  ]}
                  required
                >
                  <Radio.Group onChange={handleUnitChange}>
                    {dataUnit.map((item, idx) => (
                      <Radio key={idx} value={item.value}>
                        {item.label}
                      </Radio>
                    ))}
                  </Radio.Group>
                </Form.Item>
              )}

              {[ROLE_ADMIN, ROLE_ADMIN_PRODUCTION, ROLE_MARKETING, ROLE_CS_SUPERVISOR].includes(
                role_id
              ) && (
                <Form.Item
                  label="Pilih Unit"
                  name="units"
                  rules={[
                    {
                      required: true,
                      message: 'Unit pengguna harus diisi',
                    },
                  ]}
                  required
                >
                  <Checkbox.Group options={dataUnit} />
                </Form.Item>
              )}
            </div>
            <div className="text-end">
              <Space>
                <Form.Item>
                  <Button
                    onClick={() => navigate('/users')}
                    className="border-0 text-base h-9 w-24 text-white font-medium bg-rose-500 rounded-lg"
                  >
                    Batalkan
                  </Button>
                </Form.Item>

                <Form.Item>
                  <Button
                    htmlType="submit"
                    className="border-0 text-base h-9 w-24 text-white font-medium bg-cyan-500 rounded-lg"
                  >
                    Simpan
                  </Button>
                </Form.Item>
              </Space>
            </div>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default AddUser
