/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Form, Input, Modal, Select } from 'antd'
import React, { useEffect } from 'react'

const DetailVehicleModel = (props) => {
  const { open, toggle, row } = props

  const [form] = Form.useForm()

  const year = new Date().getFullYear()
  const years = Array.from(new Array(70), (val, index) => year - index)

  useEffect(() => {
    form.setFieldsValue({
      ...row,
    })
  }, [row])

  //   useEffect(() => {
  //   }, [])

  const handleCancel = () => {
    toggle()
  }

  return (
    <Modal open={open} onCancel={handleCancel} destroyOnClose={true} footer={false}>
      <h2 className="text-base mb-5">Detail Tipe Mobil</h2>

      <Form form={form} layout="vertical">
        <div className="grid gap-2">
          <Form.Item
            label="Nama Mobil"
            name="vehicle_class_name"
            required
            rules={[
              {
                required: true,
                message: 'Nama kendaraan harus diisi',
              },
            ]}
          >
            <Input disabled placeholder="Isi nama mobil" />
          </Form.Item>

          <Form.Item
            label="Pabrikan"
            name="manufacturer_name"
            required
            rules={[
              {
                required: true,
                message: 'Pabrikan harus diisi',
              },
            ]}
          >
            <Select
              disabled
              showSearch
              placeholder="Pabrikan"
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
            />
          </Form.Item>

          <Form.Item
            label="Jenis Mobil"
            name="vehicle_class_name"
            required
            rules={[
              {
                required: true,
                message: 'Jenis Kendaraan harus diisi',
              },
            ]}
          >
            <Select
              disabled
              showSearch
              placeholder="Jenis Mobil"
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
            />
          </Form.Item>

          <Form.Item label="Mulai Tahun" name="year_from">
            <Select
              disabled
              placeholder="Mulai Tahun"
              options={years?.map((item) => ({
                value: item,
                label: item,
              }))}
            />
          </Form.Item>

          <Form.Item label="Sampai Tahun" name="year_to">
            <Select disabled placeholder="Sampai Tahun" />
          </Form.Item>
        </div>

        <div className="flex justify-end mt-4">
          <Form.Item>
            <Button className="border-0 bg-cyan-500 text-white" onClick={handleCancel}>
              Oke
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  )
}

export default DetailVehicleModel
