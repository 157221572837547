/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Input, Space, Table, Tag } from 'antd'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import Swal from 'sweetalert2'
import BButton from '../../../components/BButton'
import CustomBadge from '../../../components/CustomBadge'
import CustomPagination from '../../../components/CustomPagination'
import ColorService from '../../../services/ColorService'
import AddColor from './Modal/AddColor'
import EditColor from './Modal/EditColor'

const Color = () => {
  const navigate = useNavigate()
  const { id: fiberId } = useParams()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalEditOpen, setIsModalEditOpen] = useState(false)
  const [childData, setChildData] = useState({})
  const [data, setData] = useState([])
  const [total, setTotal] = useState(0)
  const [params, setParams] = useState({
    page: 1,
    per_page: 10,
    sort: 'id',
    order: 'asc',
    filter: '',
  })

  useEffect(() => {
    setQueryString()
  }, [])

  useEffect(() => {
    getData()
  }, [params])

  const getData = async () => {
    const response = await ColorService.getDatatable(params, fiberId)
    try {
      if (response.status === 200) {
        setData(response.data)
        setTotal(response.meta.total)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const setQueryString = () => {
    const newParams = {}

    Object.keys(params).forEach((key) => {
      if (params[key]) {
        newParams[key] = params[key]
      }
    })

    const qs = new URLSearchParams(newParams)

    navigate({
      pathname: window.location.pathname,
      search: '?' + qs,
    })
  }

  const onPageChange = (page, pageSize) => {
    const per_page = pageSize
    setParams({ ...params, page, per_page })
  }

  const showModal = () => {
    setIsModalOpen(true)
  }

  const showModalEdit = (data) => {
    setChildData(data)
    setIsModalEditOpen(true)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
    setIsModalEditOpen(false)
    getData()
  }

  const deleteColor = (colorId) => {
    Swal.fire({
      title: 'Apakah anda yakin?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await ColorService.remove(fiberId, colorId)
        if (response.status === 200) {
          Swal.fire('Berhasil!', '', 'success')
        }
        getData()
      }
    })
  }

  const onSearch = (filter) => {
    setParams({ ...params, filter: filter })
  }

  const columns = [
    {
      title: 'Nomor',
      dataIndex: 'no',
      key: 'no',
      render: (number) => <li className="list-none ml-1">{number}</li>,
    },
    {
      title: 'Nama',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Hex Code',
      dataIndex: 'hex_code',
      key: 'hex_code',
      render: (hex) => (
        <Tag className="rounded-md border-0" color={hex}>
          {hex}
        </Tag>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button
            className="text-yellow-500 border-yellow-500 rounded-lg font-medium"
            onClick={() => showModalEdit(record)}
          >
            <i className="fa-regular fa-pen-to-square mr-2" /> Ubah
          </Button>

          <Button
            className="text-rose-500 border-rose-500 rounded-lg font-medium"
            onClick={() => deleteColor(record.id)}
          >
            <i className="fa-regular fa-trash-can mr-2" /> Delete
          </Button>
        </Space>
      ),
    },
  ]

  return (
    <div className="bg-white shadow-white-500/40 shadow-sm rounded-2xl h-fit w-full md:overflow-visible pt-4 md:pt-4">
      <div className="section-product px-2 md:px-4">
        <CustomBadge title="Warna" titleParent="Produk" />

        <h2 className="text-xl text-gray-800 font-medium mt-10">Tabel Warna</h2>

        <div className="flex justify-between items-center mt-8">
          <div className="search-user">
            <Input.Search
              className="md:w-80"
              placeholder="Search"
              allowClear
              size="medium"
              onSearch={onSearch}
            />
          </div>

          <BButton onClick={showModal}>Tambah</BButton>
        </div>
      </div>

      <div className="table-color relative w-full mt-5">
        <Table
          rowKey="id"
          size="large"
          columns={columns}
          dataSource={data}
          pagination={false}
          scroll={{ x: true }}
          tableLayout="auto"
        />
      </div>

      <div className="section-pagination px-2 py-8 md:p-4">
        <CustomPagination {...params} onPageChange={onPageChange} total={total} />
      </div>

      <AddColor
        id={fiberId}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        getData={getData}
      />

      <EditColor
        id={fiberId}
        dataEditModal={childData}
        isModalEditOpen={isModalEditOpen}
        handleCancel={handleCancel}
        setIsModalEditOpen={setIsModalEditOpen}
        getData={getData}
      />
    </div>
  )
}

export default Color
