import { request } from '../config'

// versi function
function RolePermissionService() {
  const findById = async (role_id) => {
    const response = await request.get(`/role-permissions/${role_id}`)
    return response.data
  }

  const update = async (role_id, data) => {
    const response = await request.put(`/role-permissions/${role_id}`, data)
    return response.data
  }

  const roleStatusOptions = async (role_id) => {
    const response = await request.get(`/role-statuses/${role_id}`)
    return response.data
  }

  const updateRoleStatus = async (role_id, data) => {
    const response = await request.put(`/role-statuses/${role_id}`, data)
    return response.data
  }
  return {
    findById,
    update,
    roleStatusOptions,
    updateRoleStatus,
  }
}

export default RolePermissionService()
