import { Form, Input, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import AsyncSelect from 'react-select/async'
import CustomBadge from '../../../../components/CustomBadge'
import RegionalService from '../../../../services/RegionalService'

const FormCustomer = (props) => {
  const { form, district, subdistricts, provinceChange, districtChange } = props

  const [provinces, setProvinces] = useState([])

  useEffect(() => {
    getProvince()
  }, [])

  const getProvince = async () => {
    const response = await RegionalService.province()
    try {
      if (response.status === 200) {
        setProvinces(
          response.data.map((province) => ({
            value: province.id,
            label: province.name,
          }))
        )
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleSubdistrictsChange = (value) => {
    if (value) {
    } else {
      return value
    }
  }

  return (
    <>
      <div className="grid lg:grid-cols-3 gap-4">
        <div className="col-span-1 bg-white shadow-white-500/40 shadow-sm rounded-lg h-fit w-full md:overflow-visible pt-4 md:pt-4">
          <div className="section-data-order px-3">
            <CustomBadge titleOrder title="Data Pemesan" />
          </div>

          <div className="section-form-order px-5 pb-3 mt-5">
            <Form.Item
              label="Nama / No. Telepon"
              name="customer"
              rules={[
                {
                  required: true,
                  message: 'Customer wajib diisi',
                },
              ]}
              required
            >
              <AsyncSelect
                placeholder="Nama Pelanggan"
                isDisabled
                // loadOptions={loadCustomer}
                // onChange={onChangeCustomer}
              />
            </Form.Item>

            <Form.Item
              label="No. Telepon"
              name="customer_phone"
              required
              rules={[
                {
                  required: true,
                  message: 'No. Telepon wajib diisi',
                },
              ]}
            >
              <Input
                disabled
                onChange={(e) =>
                  form.setFieldsValue({
                    ...form.getFieldsValue,
                    phone: e.target.value.replace(/^0/gi, ''),
                  })
                }
                placeholder="8123xxx"
                addonBefore="+62"
                className="w-full"
              />
            </Form.Item>

            <Form.Item
              label="Platform"
              name="platform"
              required
              rules={[
                {
                  required: true,
                  message: 'Platform wajib diisi',
                },
              ]}
            >
              <Select placeholder="Pilih Platform" disabled allowClear />
            </Form.Item>

            <Form.Item
              label="Sumber Order"
              name="order_source"
              required
              rules={[
                {
                  required: true,
                  message: 'Sumber order wajib diisi',
                },
              ]}
            >
              <Select placeholder="Pilih Sumber Order" disabled />
            </Form.Item>

            <Form.Item label="Tanggal Order" name="order_at">
              <Input disabled />
            </Form.Item>
          </div>
        </div>

        <div className="col-span-2 bg-white shadow-white-500/40 shadow-sm rounded-lg h-fit w-full md:overflow-visible pt-4 md:pt-4">
          <div className="section-recipient-data px-3">
            <CustomBadge titleOrder title="Data Penerima" />
          </div>

          <div className="section-form-recipient px-5 pb-3 mt-5">
            <div className="grid md:grid-cols-2 gap-3">
              <Form.Item
                label="Nama"
                name="name"
                required
                rules={[
                  {
                    required: true,
                    message: 'Nama customer wajib diisi',
                  },
                ]}
              >
                <Input placeholder="Nama Customer" />
              </Form.Item>

              <Form.Item
                label="No. Telepon"
                name="phone"
                required
                rules={[
                  {
                    required: true,
                    message: 'No. Telepon wajib diisi',
                  },
                ]}
              >
                <Input
                  className="w-full"
                  addonBefore="+62"
                  placeholder="8123xxx"
                  onChange={(e) =>
                    form.setFieldsValue({
                      ...form.getFieldsValue,
                      phone: e.target.value.replace(/^0/gi, ''),
                    })
                  }
                />
              </Form.Item>

              <Form.Item label="Email" name="email">
                <Input placeholder="email@example.com" />
              </Form.Item>

              <Form.Item
                label="Kode Pos"
                name="postal_code"
                required
                rules={[
                  {
                    required: true,
                    message: 'Kode pos wajib diisi',
                  },
                ]}
              >
                <Input disabled placeholder="123xx" />
              </Form.Item>

              <Form.Item
                label="Provinsi"
                name="province_id"
                required
                rules={[
                  {
                    required: true,
                    message: 'Provinsi wajib diisi',
                  },
                ]}
              >
                <Select
                  disabled
                  placeholder="Pilih Provinsi"
                  onChange={provinceChange}
                  options={provinces}
                />
              </Form.Item>

              <Form.Item
                label="Kota / Kabupaten"
                name="district_id"
                required
                rules={[
                  {
                    required: true,
                    message: 'Kota/Kabupaten wajib diisi',
                  },
                ]}
              >
                <Select
                  disabled
                  allowClear
                  options={district}
                  onChange={districtChange}
                  placeholder="Pilih Kota / Kabupaten"
                />
              </Form.Item>

              <Form.Item
                label="Kecamatan"
                name="subdistrict_id"
                required
                rules={[
                  {
                    required: true,
                    message: 'Kecamatan wajib diisi',
                  },
                ]}
              >
                <Select
                  disabled
                  allowClear
                  placeholder="Pilih Kecamatan"
                  options={subdistricts}
                  onChange={handleSubdistrictsChange}
                />
              </Form.Item>

              <Form.Item
                label="Alamat Pengiriman"
                name="address"
                required
                rules={[
                  {
                    required: true,
                    message: 'Alamat wajib diisi',
                  },
                ]}
              >
                <Input.TextArea placeholder="Alamat" rows={4} />
              </Form.Item>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FormCustomer
