/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Form, Input, Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import VehicleService from '../../../../../services/VehicleService'

const EditVehicleClass = (props) => {
  const { open, toggle, row } = props

  const [form] = Form.useForm()

  const [vehicleClassId, setVehicleClassId] = useState(null)

  useEffect(() => {
    setVehicleClassId(row?.id)
    form.setFieldsValue({
      ...row,
    })
  }, [row])

  const handleCancel = () => {
    toggle()
  }

  const onFinish = async (values) => {
    try {
      const response = await VehicleService.updateVehicleClass(vehicleClassId, values)

      if (response.status === 200) {
        Modal.success({
          title: 'Berhasil',
          content: 'Berhasil update data kelas kendaraan',
        })
      }
      toggle()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Modal width={380} open={open} onCancel={handleCancel} destroyOnClose={true} footer={false}>
      <h2 className="text-base mb-5">Edit Kelas Kendaraan</h2>

      <Form form={form} onFinish={onFinish} layout="vertical">
        <Form.Item label="Kelas Kendaraan" name="name" required>
          <Input placeholder="Isi Jenis Kendaraan" />
        </Form.Item>

        <div className="flex justify-end gap-2">
          <Form.Item>
            <Button onClick={handleCancel} className="border-0 bg-red-500 text-white">
              Batal
            </Button>
          </Form.Item>

          <Form.Item>
            <Button htmlType="submit" className="border-0 bg-cyan-500 text-white">
              Simpan
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  )
}

export default EditVehicleClass
