import { request } from '../config'

// versi function
function PaymentService() {
  const setPayment = async (order_id, data) => {
    const response = await request.put(`/orders/${order_id}/pay`, data)
    return response.data
  }

  return {
    setPayment,
  }
}

export default PaymentService()
