import React, { useEffect, useState } from 'react'
import CustomBadge from '../../../components/CustomBadge'
import { Button, Card, Form, Select } from 'antd'
import ReportService from '../../../services/ReportService'
import OrderService from '../../../services/OrderService'
import AdvertiserService from '../../../services/AdvertiserService'

const Advertiser = () => {
  const [form] = Form.useForm()

  const [loading, setLoading] = useState(false)

  const [unitOptions, setUnitOptions] = useState([])
  const [picOptions, setPicOptions] = useState([])
  const [periodOptions, setPeriodOptions] = useState([])

  const [user_id, setUserId] = useState(null)
  const [unit_id, setUnitId] = useState(null)
  const [period, setPeriod] = useState(null)

  const [data, setData] = useState(null)

  useEffect(() => {
    getUnitOptions()
    getPeriodOptions()
  }, [])

  const getUnitOptions = async () => {
    setLoading(true)
    try {
      const response = await OrderService.getOrderFilterByUnit()
      setUnitOptions(
        response.data.map((filter) => ({
          value: filter.id,
          label: filter.name,
        }))
      )
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const getPeriodOptions = async () => {
    try {
      const response = await ReportService.getPeriodeOptions()
      if (response.status === 200) {
        setPeriodOptions(response.data.map((item) => ({ value: item.value, label: item.name })))
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleUnit = (value) => {
    try {
      if (value) {
        getPicOptions(value)
        form.resetFields(['user_id'])
      } else {
        form.resetFields(['user_id'])
        setPicOptions([])
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getPicOptions = async (unit_id) => {
    setLoading(true)
    try {
      const response = await OrderService.getOrderFilterByPic(unit_id)
      setPicOptions(
        response.data.map((filter) => ({
          value: filter.id,
          label: filter.name,
        }))
      )
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const toRupiah = (nominal) => {
    return nominal.toLocaleString('id', {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0,
    })
  }

  const handleExportData = async () => {
    setLoading(true)
    const data = {
      unit_id: unit_id,
      user_id: user_id,
      period: period,
    }

    try {
      const response = await ReportService.exportBudgetRatioReport(data)

      if (response.status === 200) {
        window.open(response.data.url, '_blank')
      }
    } catch (error) {
      setLoading(false)
      console.log()
    } finally {
      setLoading(false)
    }
  }

  const onValuesChange = (changedValues, allValues) => {
    setUserId(allValues.user_id)
    setUnitId(allValues.unit_id)
    setPeriod(allValues.period)
  }

  const onFinish = async ({ unit_id, user_id, period }) => {
    // setLoading(true)
    const payload = {
      unit_id,
      user_id,
      period,
    }

    try {
      const response = await AdvertiserService.getAdvertiserReport(payload)

      if (response.status === 200) {
        setData(response.data)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <CustomBadge formOrder title="Advertiser" titleParent="Laporan" />

      <Card className="md:ml-3">
        <Form layout="vertical" form={form} onFinish={onFinish} onValuesChange={onValuesChange}>
          <div className="grid md:grid-cols-3 gap-4">
            <Form.Item label="Unit" name="unit_id">
              <Select
                allowClear
                className="w-full"
                placeholder="Pilih Unit"
                options={unitOptions}
                onChange={handleUnit}
              />
            </Form.Item>

            <Form.Item label="List PIC" name="user_id">
              <Select allowClear className="w-full" placeholder="Pilih PIC" options={picOptions} />
            </Form.Item>

            <Form.Item label="Periode" name="period">
              <Select className="w-full" placeholder="Pilih Periode" options={periodOptions} />
            </Form.Item>
          </div>

          <div className="flex justify-end gap-2">
            <Form.Item>
              <Button className="text-white bg-rose-600 border-0">Batal</Button>
            </Form.Item>

            <Form.Item>
              <Button
                htmlType="submit"
                loading={loading}
                className="text-white bg-[#013a63] border-0"
              >
                Cari
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Card>

      {data && (
        <Card className="md:ml-3 my-5 antialiased border-0 relative">
          <div className="flex justify-between items-center mb-10">
            <h2 className="text-lg font-medium text-[#013a63]">Laporan Advertiser</h2>

            <Button
              loading={loading}
              className="text-white bg-[#013a63] border-0"
              onClick={handleExportData}
            >
              Download
            </Button>
          </div>

          <div className="advertiser-table overflow-x-auto">
            <table className="border-collapse table-auto w-full">
              <tbody>
                <tr className="bg-gray-100">
                  <td className="min-w-[18rem]">
                    <strong>{data.total.name}</strong>
                  </td>
                  <td></td>
                  {data.total.days.map((d, i) => {
                    return (
                      <td className="min-w-[10rem] text-center" key={i}>
                        <strong>{d}</strong>
                      </td>
                    )
                  })}
                </tr>

                <tr>
                  <td>
                    <strong>Budget</strong>
                  </td>
                  <td className="text-right">{toRupiah(data.total.budget.total)}</td>
                  {data.total.budget.per_day.map((pd, i) => {
                    return (
                      <td key={i} className="text-right">
                        {toRupiah(pd)}
                      </td>
                    )
                  })}
                </tr>
                <tr>
                  <td>
                    <strong>Leads</strong>
                  </td>
                  <td className="text-right">
                    {data.total.leads.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                  </td>
                  {data.total.leads.per_day.map((pd, i) => {
                    return (
                      <td key={i} className="text-right">
                        {pd.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                      </td>
                    )
                  })}
                </tr>
                <tr>
                  <td>
                    <strong>Cost per Leads</strong>
                  </td>
                  <td className="text-right">{toRupiah(data.total.cost_per_leads.total)}</td>
                  {data.total.cost_per_leads.per_day.map((pd, i) => {
                    return (
                      <td key={i} className="text-right">
                        {toRupiah(pd)}
                      </td>
                    )
                  })}
                </tr>
                <tr>
                  <td className="py-4" colSpan={data.total.days.length + 2}></td>
                </tr>

                {data.details.map((de, i) => {
                  return (
                    <React.Fragment key={i}>
                      <tr className="bg-gray-100 text-center">
                        <td className="text-left">
                          <strong>{de.name}</strong>
                        </td>
                        <td>
                          <strong>RESULT</strong>
                        </td>
                        {data.total.days.map((d, i) => {
                          return (
                            <td key={i}>
                              <strong>{d}</strong>
                            </td>
                          )
                        })}
                      </tr>
                      <tr>
                        <td>
                          <strong>Budget</strong>
                        </td>
                        <td className="text-right">{toRupiah(de.budget.total)}</td>
                        {de.budget.per_day.map((pd, i) => {
                          return (
                            <td key={i} className="text-right">
                              {toRupiah(pd)}
                            </td>
                          )
                        })}
                      </tr>
                      <tr>
                        <td>
                          <strong>Leads</strong>
                        </td>
                        <td className="text-right">
                          {de.leads.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                        </td>
                        {de.leads.per_day.map((pd, i) => {
                          return (
                            <td key={i} className="text-right">
                              {pd.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                            </td>
                          )
                        })}
                      </tr>
                      <tr>
                        <td>
                          <strong>Cost per Leads</strong>
                        </td>
                        <td className="text-right">{toRupiah(de.cost_per_leads.total)}</td>
                        {de.cost_per_leads.per_day.map((pd, i) => {
                          return (
                            <td key={i} className="text-right">
                              {toRupiah(pd)}
                            </td>
                          )
                        })}
                      </tr>
                      <tr>
                        <td className="py-4" colSpan={data.total.days.length + 2}></td>
                      </tr>
                    </React.Fragment>
                  )
                })}
              </tbody>
            </table>
          </div>
        </Card>
      )}
    </>
  )
}

export default Advertiser
