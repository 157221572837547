/* eslint-disable react-hooks/exhaustive-deps */
import { Button, DatePicker, Form, Input, Modal, Select, Space, Upload } from 'antd'
import React, { useEffect, useState } from 'react'
import ImageService from '../../../../services/ImageService'
import OrderService from '../../../../services/OrderService'
import PaymentChannelService from '../../../../services/PaymentChannelService'
import PaymentService from '../../../../services/PaymentService'
import moment from 'moment'

const PayoffSet = (props) => {
  const { open, toggle, row, getDataOrder } = props

  const [form] = Form.useForm()

  const { payment_channel_id } = form.getFieldsValue()

  const [payment, setPayment] = useState([])
  const [orderId, setOrderId] = useState(null)
  const [evidence, setEvidence] = useState([])
  const [loading, setLoading] = useState(false)
  const [payment_date, setPaymentDate] = useState([])

  useEffect(() => {
    setOrderId(row?.order_id)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [row])

  useEffect(() => {
    getMethodPayment()

    if (open)
      window.addEventListener('paste', async (e) => {
        const items = (e.clipboardData || e.originalEvent.clipboardData).items

        for (let index in items) {
          const item = items[index]
          if (item.kind === 'file') {
            const blob = item.getAsFile()
            const reader = new FileReader()

            reader.onload = (event) => {
              console.log(blob.size / 1000000 <= 10)
              if (open) {
                if (blob.size / 1000000 <= 10) {
                  handleImagePaste(blob)
                }
              }
            } // data url!
            reader.readAsArrayBuffer(blob)
          }
        }
      })
  }, [open])

  useEffect(() => {
    if (orderId) getDetailOrder()
  }, [orderId])

  const getDetailOrder = async () => {
    setLoading(true)
    const response = await OrderService.getDataByID(orderId)
    try {
      if (response.status === 200) {
        form.setFieldsValue({
          price: parseInt(
            response.data.grand_total - response.data.payment_amount
          ).toLocaleString(),
        })
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const getMethodPayment = async () => {
    try {
      const response = await PaymentChannelService.getPaymentChannel()
      if (response.status === 200) {
        setPayment([
          {
            label: 'Transfer Bank',
            options: response.data.map((channel) => ({
              value: channel.id,
              label: channel.name + ' - ' + channel.account_number + ' - ' + channel.account_name,
              verification_type: channel.verification_type,
            })),
          },
        ])
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleDatePaymentChange = (_, dateStrings) => {
    setPaymentDate(dateStrings)
  }

  const handleImagePaste = async (file) => {
    const formData = new FormData()
    formData.append('image', file)

    if (file) {
      try {
        setLoading(true)
        const response = await ImageService.paymentProof(formData)

        if (response.status === 200) {
          setEvidence([...evidence, response.data.data.url])
          setLoading(false)
        } else {
          throw new Error('Terjadi kesalahan pada saat upload bukti transfer.')
        }
      } catch (error) {
        setLoading(false)
      }
    }
  }

  const handleImageChange = async (file) => {
    const imageFile = file.file

    if (imageFile) {
      const formData = new FormData()
      formData.append('image', imageFile)

      const response = await ImageService.paymentProof(formData)

      try {
        if (response.status === 200) {
          setEvidence([...evidence, response.data.data.url])
        }
      } catch (error) {
        console.log(error)
        throw new Error('Terjadi kesalahan pada saat upload bukti transfer.')
      }
    } else {
      setEvidence([...evidence])
    }
  }

  const handleRemoveEvidence = async (image, idx) => {
    const newEvidence = evidence.filter((item, index) => index !== idx)
    setEvidence(newEvidence)
    isDisabled()
  }

  const isDisabled = () => {
    const validationForm = !!payment_channel_id && !!payment_date && !!evidence.length !== 0

    return validationForm
  }

  const handleCancel = () => {
    toggle()
    form.resetFields()
  }

  const disabledDate = (current) => {
    return moment().add(-10, 'days') >= current || moment().add(0, 'days') < current
  }

  const onFinish = async (values) => {
    const payload = {
      payment_date: payment_date,
      payment_channel_id: values.payment_channel_id,
      evidences: evidence,
    }

    console.log(payload)

    try {
      const response = await PaymentService.setPayment(orderId, payload)
      if (response.status === 200) {
        Modal.success({
          content: 'Berhasil set pelunasan',
        })
        form.resetFields()
        getDataOrder()
        toggle()
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Modal className="antialiased" width={800} open={open} onCancel={handleCancel} footer={false}>
      <h2 className="text-base font-semibold">Pelunasan Order {orderId}</h2>

      <Form form={form} onFinish={onFinish} layout="vertical" size="large" className="mt-10">
        <div className="grid md:grid-cols-2 gap-3">
          <Form.Item
            label="Tanggal Bayar"
            name="payment_date"
            rules={[
              {
                required: true,
                message: 'Tanggal Pembayaran harus diisi',
              },
            ]}
            required
          >
            <DatePicker
              onChange={handleDatePaymentChange}
              disabledDate={disabledDate}
              className="w-full"
            />
          </Form.Item>

          <Form.Item name="price" label="Nominal Bayar" required>
            <Input disabled prefix="Rp" className="w-full" />
          </Form.Item>

          <Form.Item
            label="Metode Pembayaran"
            name="payment_channel_id"
            rules={[
              {
                required: true,
                message: 'Metode Pembayaran harus diisi',
              },
            ]}
            required
          >
            <Select placeholder="Metode Pembayaran" className="w-full" options={payment} />
          </Form.Item>

          <Form.Item
            label="Bukti Transfer"
            rules={[
              {
                required: true,
                message: 'Bukti Transfer harus di Upload!',
              },
            ]}
            required
          >
            <Upload
              name="image"
              accept="image/*"
              // listType="picture-card"
              className="avatar-uploader"
              beforeUpload={() => {
                return false
              }}
              showUploadList={false}
              onChange={handleImageChange}
            >
              <Button className="w-52">
                <h3 className="text-sm font-normal">
                  {' '}
                  <i className="fa-solid fa-upload text-sm" /> Upload File
                </h3>
              </Button>
            </Upload>

            {evidence?.map((item, idx) => (
              <div key={idx} className="flex justify-center items-center h-fit w-36 my-2 ml-2">
                <img src={item} alt="" />

                <div
                  className="h-10 w-10 flex justify-center items-center ml-2 cursor-pointer"
                  onClick={() => handleRemoveEvidence(item, idx)}
                >
                  <i className="fa-regular fa-trash-can text-red-500 text-base" />
                </div>
              </div>
            ))}
          </Form.Item>
        </div>

        <Form.Item>
          <Space className="float-right" direction="horizontal">
            <Button key="back" onClick={handleCancel}>
              Batal
            </Button>

            <Button
              disabled={evidence.length === 0}
              htmlType="submit"
              type="primary"
              loading={loading}
            >
              Simpan
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default PayoffSet
