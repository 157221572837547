import { Button, Form, Input, Modal, notification, Select } from 'antd'
import React from 'react'
import Swal from 'sweetalert2'
import { CUSTOMER_AGEN, CUSTOMER_RETAIL } from '../../../../../constants'
import CustomerService from '../../../../../services/CustomerService'
import { phoneCodes } from '../../../../../helpers/phoneCode'

const { Option } = Select

const AddCustomer = (props) => {
  const {
    isModalOpen,
    setIsModalOpen,
    handleOk,
    provinceChange,
    districtChange,
    district,
    provinces,
    subdistricts,
    renderCustomer,
    prefix,
    setPrefix,
  } = props

  const [form] = Form.useForm()

  const handleCancel = () => {
    form.resetFields()
    setIsModalOpen(false)
  }

  const handleChangePrefix = (value) => {
    setPrefix(value)
  }

  const prefixSelector = (
    <Select
      value={prefix}
      onChange={handleChangePrefix}
      style={{ width: 'fit-content' }}
      showSearch
      filterOption={(input, option) =>
        (option?.searchable ?? '').toLowerCase().includes(input.toLowerCase())
      }
      options={phoneCodes.map((item) => ({
        value: item.dial_code,
        label: `${item.emoji} ${item.dial_code}`,
        searchable: `${item.name} ${item.dial_code}`,
        key: item.code,
      }))}
    />
  )

  const onFinish = async (values) => {
    const response = await CustomerService.addCustomer({
      ...values,
      phone: `${prefix}${values.phone}`,
    })

    try {
      if (response.status === 200) {
        notification.success({
          message: 'Berhasil',
          description: 'berhasil menambahkan customer',
          placement: 'top',
        })
        renderCustomer(response.data.id)
      }
      form.resetFields()
      setIsModalOpen(false)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Modal
      forceRender
      width={700}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={false}
    >
      <h2 className="text-xl text-gray-800 font-medium">Tambah Pelanggan</h2>

      <Form
        form={form}
        onFinish={onFinish}
        className="section-form-recipient mt-10"
        layout="vertical"
      >
        <div className="grid md:grid-cols-2 gap-3">
          <Form.Item
            label="Nama"
            name="name"
            required
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder="Nama Customer" />
          </Form.Item>

          <Form.Item
            label="No. Telepon"
            name="phone"
            required
            rules={[
              {
                required: true,
                message: 'No. telepon wajib diisi',
              },
              ({ getFieldValue }) => ({
                async validator(_, value) {
                  const data = {
                    phone: `+62${value}`,
                  }
                  const response = await CustomerService.checkExisting(data)

                  if (response.data.is_exist) {
                    Swal.fire({
                      icon: 'warning',
                      title: 'Peringatan!',
                      html: `<strong>Apakah ingin menggunakan customer ini?</strong>`,
                      showDenyButton: true,
                      confirmButtonText: 'Yes',
                      denyButtonText: `Ganti No. Telepon`,
                    }).then((result) => {
                      if (result.isConfirmed) {
                        if (response.status === 200) {
                          renderCustomer(response.data.customer.id)
                          setIsModalOpen(false)
                        }
                      } else if (result.isDenied) {
                        form.resetFields(['phone'])
                      }
                    })
                  }
                },
              }),
            ]}
          >
            <Input
              placeholder="8123456789"
              className="w-full"
              addonBefore={prefixSelector}
              onChange={(e) =>
                form.setFieldsValue({
                  ...form.getFieldsValue,
                  phone: e.target.value
                    ?.replace(/[^0-9]/gi, '') // remove +
                    .replace(/^0/gi, '') // remove first 0
                    .replace(/^62/gi, ''), // remove first 62,
                })
              }
            />
          </Form.Item>

          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                type: 'email',
                message: 'E-mail tidak sesuai format!',
              },
              {
                required: true,
              },
            ]}
            required
          >
            <Input placeholder="email@example.com" />
          </Form.Item>

          <Form.Item
            label="Jenis Pelanggan"
            name="customer_type_id"
            required
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select placeholder="Pilih Jenis Pelanggan" allowClear value="">
              <Option value={CUSTOMER_RETAIL}>Retail</Option>
              <Option value={CUSTOMER_AGEN}>Agen</Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Provinsi"
            name="province_id"
            required
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              placeholder="Pilih Provinsi"
              onChange={provinceChange}
              options={provinces}
              value=""
            />
          </Form.Item>

          <Form.Item
            label="Kota / Kabupaten"
            name="district_id"
            required
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              placeholder="Pilih Kota / Kabupaten"
              onChange={districtChange}
              options={district}
              value=""
              allowClear
            />
          </Form.Item>

          <Form.Item
            label="Kecamatan"
            name="subdistrict_id"
            required
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select placeholder="Pilih Kecamatan" options={subdistricts} allowClear value="" />
          </Form.Item>

          <Form.Item
            label="Kode Pos"
            name="postal_code"
            required
            rules={[
              {
                required: true,
                min: 5,
              },
            ]}
          >
            <Input maxLength={5} placeholder="12345" />
          </Form.Item>

          <Form.Item
            label="Alamat Pengiriman"
            name="address"
            required
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input.TextArea
              placeholder="Jl. Ngawen No.29, Kranggahan II, Kelurahan Trihanggo (Kecamatan dst tidak perlu dimasukkan di sini)"
              rows={4}
            />
          </Form.Item>
        </div>

        <div className="flex justify-end gap-2 mt-10">
          <Button className="border-0 bg-rose-500 text-white w-32" onClick={() => handleCancel()}>
            Cancel
          </Button>
          <Form.Item>
            <Button htmlType="submit" className="border-0 bg-cyan-500 text-white w-32">
              Simpan
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  )
}

export default AddCustomer
