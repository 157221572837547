/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Form, Input, Space } from 'antd'
import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router'
import Swal from 'sweetalert2'
import CategoryService from '../../../../services/CategoryService'

const EditCategory = () => {
  const [form] = Form.useForm()
  const { id } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    getDatabyId()
    // getCategoriesList()
  }, [])

  const getDatabyId = async () => {
    const response = await CategoryService.findById(id)
    try {
      if (response.status === 200) {
        form.setFieldsValue({
          ...response.data,
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const onSubmit = async (values) => {
    const data = {
      name: values.name,
      description: values.description,
    }

    const response = await CategoryService.update(id, data)

    try {
      if (response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Berhasil',
          text: 'Berhasil update Kategori',
        })
      }
      navigate('/categories')
    } catch (error) {
      console.log(error)
    }
  }

  // const getCategoriesList = async () => {
  //   const response = await CategoryService.list()
  //   try {
  //     if (response.status === 200) {
  //       setData(response.data)
  //     }
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  return (
    <div className="bg-white shadow-white-500/40 shadow-sm rounded-2xl h-fit w-full md:overflow-visible md:p-4">
      <div className="content">
        <div className="relative flex items-center shadow-cyan-300/40 shadow-lg -mt-12 bg-cyan-500 rounded-2xl w-fit h-14 px-5">
          <h2 className="text-base text-white font-medium">
            Edit Kategori
            <span>
              <i className="fa-solid fa-house-user mx-3" />
            </span>{' '}
            <span className="text-sm">Kategori</span>
          </h2>
        </div>
        <Form form={form} onFinish={onSubmit} layout="vertical">
          <div className="section-form-user mt-10">
            <div className="grid md:grid-cols-2 gap-4">
              <Form.Item label="Nama" name="name">
                <Input placeholder="Nama" />
              </Form.Item>

              {/* <Form.Item label="Harga Coret (jika harga jual lebih murah)">
                <Input placeholder="Harga" />
              </Form.Item>

              <Form.Item label="Jenis Produk">
                <Select placeholder="Pilih Jenis Produk">
                  {data?.map((item, index) => (
                    <Select.Option key={index}>{item.name}</Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Space>
                <Form.Item className="mr-10" label="Apakah Kategori Ini Ready Stok?">
                  <Switch />
                </Form.Item>

                <Form.Item label="Tampilkan di Web">
                  <Switch />
                </Form.Item>
              </Space> */}
            </div>
          </div>

          <Form.Item label="Deskripsi" name="description">
            <Input.TextArea className="rounded-lg" placeholder="deskripsi" rows={10} />
          </Form.Item>

          <div className="text-end">
            <Form.Item>
              <Space>
                <Button
                  onClick={() => navigate('/categories')}
                  className="border-0 text-base h-9 w-24 text-white font-medium bg-rose-500 rounded-lg"
                >
                  Batalkan
                </Button>

                <Button
                  htmlType="submit"
                  className="border-0 text-base h-9 w-24 text-white font-medium bg-cyan-500 rounded-lg"
                >
                  Simpan
                </Button>
              </Space>
            </Form.Item>
          </div>
        </Form>
      </div>
    </div>
  )
}

export default EditCategory
