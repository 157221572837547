import { Alert, Button, Form, Input } from 'antd'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import Vector from '../../../assets/img/Vector.png'
import logo2 from '../../../assets/img/logo-emirate.png'
import { login } from '../../../store/Auth/action'

const Login = () => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()

  const [error, setError] = useState(false)

  const onFinish = (values) => {
    const { email, password } = values
    dispatch(login(email, password))
  }

  return (
    <>
      {/* <div className="relative shadow-lg md:flex bg-gradient-to-r from-[#000428] to-[#004e92] md:bg-gradient-to-r md:from-[#fff] to-[#004e92] md:h-[40rem] md:w-[79rem] p-5 md:p-0 rounded-2xl">
        <div className="hidden md:relative md:rounded-l-2xl bg-gradient-to-r from-[#000428] to-[#004e92] md:h-full md:w-[40rem] md:flex md:justify-center md:items-center">
          <img src={logo} width={350} alt="Logo Emirate" />
        </div>

        <div className="md:flex md:justify-center md:items-center w-full">
          <div className="section-form-login w-[20rem]">
            {error && <Alert />}

            <img className="block md:hidden mb-5" src={logo2} alt="Logo Emirate" />
            <h2 className="hidden md:block text-center text-2xl font-medium mb-2">
              Emirate Member Log-in
            </h2>
            <h4 className="hidden md:block text-center text-sm text-gray-500 font-normal mb-5">
              Welcome to Emirate Dashboard
            </h4>

            <Form
              form={form}
              onFinish={onFinish}
              onValuesChange={() => setError(false)}
              layout="vertical"
            >
              <Form.Item
                name="email"
                label="E-mail"
                rules={[
                  {
                    required: true,
                    type: 'email',
                  },
                ]}
              >
                <Input className="h-[42px]" placeholder="Email" />
              </Form.Item>

              <Form.Item
                name="password"
                label="Password"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input.Password placeholder="Password" />
              </Form.Item>

              <Form.Item>
                <Button
                  htmlType="submit"
                  className="border-0 rounded-lg h-9 w-full bg-gradient-to-r from-[#2193b0] to-[#6dd5ed] md:bg-gradient-to-r md:from-[#000428] md:to-[#004e92] text-white font-medium"
                  // disabled={
                  //   !form.isFieldTouched(true) ||
                  //   !!form.getFieldError().filter(({ errors }) => errors.length)
                  //     .length
                  // }
                >
                  Sign in
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div> */}

      <div className="h-screen hidden md:block">
        <img className="inline-block h-full" src={Vector} alt="Logo Emirate" />
      </div>

      <div className="flex justify-center items-center w-full">
        <div className="section-form-login text-center w-[20rem]">
          {error && <Alert />}

          <img className="inline-block" width={300} src={logo2} alt="Logo Emirate" />

          <h4 className="text-center text-base text-gray-500 font-normal my-5">
            Welcome to Emirate Dashboard
          </h4>

          <Form
            form={form}
            onFinish={onFinish}
            onValuesChange={() => setError(false)}
            layout="vertical"
          >
            <Form.Item
              name="email"
              label="E-mail"
              rules={[
                {
                  required: true,
                  type: 'email',
                },
              ]}
            >
              <Input className="h-[42px]" placeholder="Email" />
            </Form.Item>

            <Form.Item
              name="password"
              label="Password"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input.Password placeholder="Password" />
            </Form.Item>

            <Form.Item>
              <Button
                htmlType="submit"
                className="border-0 rounded-lg h-9 w-full bg-gradient-to-r from-[#000428] to-[#004e92] text-white font-medium"
                // disabled={
                //   !form.isFieldTouched(true) ||
                //   !!form.getFieldError().filter(({ errors }) => errors.length)
                //     .length
                // }
              >
                Sign in
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
      <div className="absolute md:right-[30.5rem] 2xl:right-[43rem] bottom-0">
        © {new Date().getFullYear()} <span className="text-[#161563] font-bold">Emirate Group</span>
      </div>
    </>
  )
}

export default Login
