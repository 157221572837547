import React from 'react'

const CContainer = ({ children }) => {
  return (
    <div className="bg-white subpixel-antialiased shadow-white-500/40 shadow-sm rounded-2xl h-fit w-full md:overflow-visible pt-4 md:pt-4">
      {children}
    </div>
  )
}

export default CContainer
