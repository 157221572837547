import { Form, Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import CustomBadge from '../../../components/CustomBadge'
import { CUSTOMER_RETAIL, PAYMENT_STATUS_PAID } from '../../../constants'
import OrderService from '../../../services/OrderService'
import CustomerForm from './CustomerForm'
import PaymentFormOrder from './PaymentFormOrder'
import SummaryFormOrder from './SummaryFormOrder'
import TableFormOrder from './TableFormOrder'

const FormOrder = () => {
  const [form] = Form.useForm()

  const navigate = useNavigate()

  const { shipping_cost } = form.getFieldsValue()

  const [loading, setLoading] = useState(false)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [valuePlatform, setValuePlatform] = useState('')
  const [evidenceImagePaste, setEvidenceImagePaste] = useState(null)
  const [dataSource, setDataSource] = useState([])
  const [maxShippingDate, setMaxShippingDate] = useState('')
  // const [invoice_required, setInvoiceRequired] = useState(false)
  const [validateForm, setValidateForm] = useState(null)

  const [prefix, setPrefix] = useState('+62')

  const [invoice_required, setInvoiceRequired] = useState(false)

  // UNTUK FIELD ORDER
  const [nominalInstallation, setNominalInstallation] = useState(0)
  const [discount, setDiscount] = useState(0)
  const [payment_amount, setPaymentAmount] = useState(0)
  const [manual_cost, setManualCost] = useState(0) // Untuk manual input harga kurir
  const [evidence, setEvidence] = useState([])
  const [payment_date, setPaymentDate] = useState('')
  const [order_date, setOrderDate] = useState('')

  const [estimationDate, setEstimationDate] = useState('')
  const [valueBonus, setValueBonus] = useState([])
  const [customer_id, setCustomer_id] = useState(null)
  const [additional_cost, setAdditional_cost] = useState(0)
  const [customer_type_id, setCustomer_type_id] = useState('')

  const [courier_name, setCourier_name] = useState('')
  const [courier_service, setCourier_service] = useState('')
  const [verification_type, setVerification_type] = useState('')

  useEffect(() => {
    document.getElementById('evidences').addEventListener('paste', async (e) => {
      const items = await (e.clipboardData || e.originalEvent.clipboardData).items
      for (let index in items) {
        const item = items[index]
        if (item.kind === 'file') {
          const blob = item.getAsFile()
          const reader = new FileReader()
          reader.onload = (event) => {
            // console.log(blob.size / 1000000 <= 10)
            if (blob.size / 1000000 <= 10) {
              setEvidenceImagePaste(blob)
            }
          } // data url!
          reader.readAsArrayBuffer(blob)
        }
      }
    })
  }, [])

  // RESET KURIR KETIKA ADA PERUBAHAN ALAMAT ATAU PRODUCT
  const resetCourier = () => {
    form.setFieldsValue({
      courier_code: undefined,
      shipping_cost: undefined,
      service: undefined,
    })

    return form
  }

  // FORM PRODUCT
  const countWeight = () => {
    let totalWeight = dataSource.reduce(
      (accumulator, variant) => accumulator + variant.variant.weight * variant.qty,
      0
    )
    return totalWeight
  }

  const countTotal = (product) => {
    const addonsPrice = product.addons.reduce((accumulator, addon) => accumulator + addon.price, 0)
    return CUSTOMER_RETAIL === customer_type_id
      ? (product.variant.price +
          addonsPrice +
          parseInt(product.price_adjustment || 0) +
          product.price_design) *
          product.qty
      : (product.variant.agent_price +
          addonsPrice +
          parseInt(product.price_adjustment || 0) +
          product.price_design) *
          product.qty
  }

  const countSubtotal = () => {
    const subtotal = dataSource.reduce(
      (accumulator, product) => accumulator + countTotal(product),
      0
    )

    return subtotal
  }

  const grossTotal = () => {
    const subtotal = {
      subtotal: countSubtotal(),
      additional_cost: parseInt(additional_cost),
      shipping_cost: shipping_cost ? shipping_cost : parseInt(manual_cost),
    }

    return Object.values(subtotal).reduce((total, value) => total + value || 0, 0)
  }

  const grandTotal = () => {
    const subtotal = {
      grossTotal: grossTotal(),
      discount: parseInt(-discount),
    }

    return Object.values(subtotal).reduce((total, value) => total + value || 0, 0)
  }

  const onFinishFailed = ({ values, errorFields }) => {
    return Modal.error({
      title: 'Error',
      content: errorFields.map((field) => {
        return (
          <ul>
            <li className="list-disc">{field.errors[0]}</li>
          </ul>
        )
      }),
    })
  }

  const onFinish = async (values) => {
    setLoading(true)

    const phone = values.phone
      ?.replace(/[^0-9]/gi, '') // remove +
      .replace(/^0/gi, '') // remove first 0
      .replace(/^62/gi, '') // remove first 62

    const payload = {
      order_date: order_date,
      customer_id: customer_id,
      customer_type_id: customer_type_id,
      shipment: {
        total_weight: countWeight(),
        courier_code: values.courier_code,
        courier_name: ['manual'].includes(values.courier_code)
          ? values.courier_name
          : [
              'out_of_town',
              'homeservice_outside_the_city',
              'homeservice_semarang',
              'workshop_outside_the_city',
            ].includes(values.courier_code)
          ? `(${courier_name}) - ${values.courier_name}`
          : courier_name,
        courier_service: [
          'manual',
          'out_of_town',
          'homeservice_outside_the_city',
          'homeservice_semarang',
          'workshop_outside_the_city',
          'cod',
          'kantor',
          'homeservice',
          'workshop',
        ].includes(values.courier_code)
          ? values.service
          : ['cod', 'kantor', 'homeservice', 'workshop'].includes(values.courier_code)
          ? ''
          : courier_service,
        shipper_id: values.shipper_id,
        name: values.name,
        phone: `${prefix}${phone}`,
        email: values.email,
        customer_type_id: customer_type_id,
        address: values.address,
        province_id: values.province_id,
        district_id: values.district_id,
        subdistrict_id: values.subdistrict_id,
        postal_code: values.postal_code,
        tracking_number: values.tracking_number || '',
      },
      payment: {
        evidences: evidence,
        payment_date: payment_date || '',
        payment_amount:
          parseInt(values.payment_status_id) === PAYMENT_STATUS_PAID
            ? grandTotal()
            : parseInt(payment_amount),
        payment_status_id: values.payment_status_id,
        payment_channel_id: values.payment_channel_id || null,
      },
      verification_type: verification_type,
      platform: values.platform,
      order_source: values.order_source,
      invoice_marketplace: values.invoice_marketplace || '',
      max_shipping_date: maxShippingDate || '',
      installation_fee: 0,
      shipping_cost: [
        'manual',
        'out_of_town',
        'homeservice_outside_the_city',
        'homeservice_semarang',
        'workshop_outside_the_city',
        'cod',
        'kantor',
        'homeservice',
        'workshop',
      ].includes(values.courier_code)
        ? parseInt(manual_cost)
        : values.shipping_cost,
      discount: parseInt(discount),
      additional_cost: additional_cost || 0,
      additional_cost_note: values.additional_cost_note,
      subtotal: countSubtotal(),
      gross_amount: grossTotal(),
      tax_amount: 0,
      tax_rate: 0,
      grand_total: grandTotal(),
      description: values.description,
      bonus: valueBonus,
      items: dataSource.map((product) => ({
        product_id: product.id,
        product_variant_id: product.variant.id,
        product_vehicle_id: product.product_vehicle.id,
        year: product.year,
        material_id: product.material_id,
        fiber_id: product.fiber_id,
        color_id: product.color_id,
        material_variant_1_id: product.material_variant_1_id,
        fiber_variant_1_id: product.fiber_variant_1_id,
        color_variant_1_id: product.color_variant_1_id,
        material_variant_2_id: product.material_variant_2_id,
        fiber_variant_2_id: product.fiber_variant_2_id,
        color_variant_2_id: product.color_variant_2_id,
        design_id: product.design_id,
        logo: product.logo,
        transmission_type: product.transmission_type,
        fire_extinguisher: product.fire_extinguisher,
        lock_hole: product.lock_hole,
        // logo_id: product.logo_id,
        // transmission_type_id: product.transmission_type_id,
        // fire_extinguisher_id: product.fire_extinguisher_id,
        // lock_hole_id: product.lock_hole_id,
        qty: product.qty,
        weight: product.variant.weight,
        price_design: product.price_design,
        price:
          customer_type_id === CUSTOMER_RETAIL
            ? product.variant.price
            : product.variant.agent_price,
        price_adjustment: product.price_adjustment === undefined ? 0 : product.price_adjustment,
        price_addons: product.price_addons,
        subtotal: 0,
        note: product.note,
        estimation_dealmaker: estimationDate,
        addons: product.addons,
        images: product.images,
      })),
    }

    try {
      const response = await OrderService.insertOrder(payload)

      if (response.status === 200) {
        Modal.success({
          title: 'Berhasil',
          content: (
            <h2>
              Silahkan lakukan lacak pesanan melalui link{' '}
              <span
                onClick={() =>
                  window.open(
                    `https://track.emiratecarinterior.com/?order-number=${response.data?.order_number}`
                  )
                }
                className="text-blue-500 cursor-pointer"
              >
                {`https://track.emiratecarinterior.com/?order-number=${response.data?.order_number}`}
              </span>
            </h2>
          ),
          onOk() {
            navigate(`/table/orders`)
          },
          onCancel() {
            navigate(`/table/orders`)
          },
        })
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <CustomBadge formOrder title="Form Order" titleParent="Form Order" />

      <div className="section-orders lg:ml-3 mt-10 mb-5">
        <Form
          form={form}
          size="large"
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          scrollToFirstError={true}
        >
          <CustomerForm
            form={form}
            prefix={prefix}
            setPrefix={setPrefix}
            resetCourier={resetCourier}
            setOrderDate={setOrderDate}
            valuePlatform={valuePlatform}
            setCustomer_id={setCustomer_id}
            setValidateForm={setValidateForm}
            invoice_required={invoice_required}
            setValuePlatform={setValuePlatform}
            setMaxShippingDate={setMaxShippingDate}
            setInvoiceRequired={setInvoiceRequired}
            setCustomer_type_id={setCustomer_type_id}
          />

          <TableFormOrder
            form={form}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            resetCourier={resetCourier}
            dataSource={dataSource}
            setDataSource={setDataSource}
            validateForm={validateForm}
            customer_type_id={customer_type_id}
            countTotal={countTotal}
          />

          <div id="evidences">
            <SummaryFormOrder
              form={form}
              countWeight={countWeight}
              countSubtotal={countSubtotal}
              manual_cost={manual_cost}
              setManualCost={setManualCost}
              discount={discount}
              grossTotal={grossTotal}
              grandTotal={grandTotal}
              setDiscount={setDiscount}
              courier_name={courier_name}
              setValueBonus={setValueBonus}
              setCourier_name={setCourier_name}
              additional_cost={additional_cost}
              invoice_required={invoice_required}
              setAdditional_cost={setAdditional_cost}
              setEstimationDate={setEstimationDate}
              setCourier_service={setCourier_service}
              nominalInstallation={nominalInstallation}
              setNominalInstallation={setNominalInstallation}
            />

            <PaymentFormOrder
              form={form}
              loading={loading}
              evidence={evidence}
              isModalOpen={isModalOpen}
              setEvidence={setEvidence}
              valuePlatform={valuePlatform}
              setPaymentDate={setPaymentDate}
              setPaymentAmount={setPaymentAmount}
              evidenceImagePaste={evidenceImagePaste}
              setVerification_type={setVerification_type}
            />
          </div>
        </Form>
      </div>
    </>
  )
}

export default FormOrder
