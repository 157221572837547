import React, { useEffect, useState } from 'react'
import { Button, Form, Input, Modal, Select, notification } from 'antd'
import LeadsService from '../../../services/LeadsService'
import AsyncSelect from 'react-select/async'

const UpdateLeads = (props) => {
  const { open, toggle, row } = props

  const [form] = Form.useForm()

  const year = new Date().getFullYear()
  const years = Array.from(new Array(70), (val, index) => year - index)

  const [loading, setLoading] = useState(false)
  const [reason, setReason] = useState([])
  const [lead_id, setLeadId] = useState(row?.id)

  useEffect(() => {
    setLeadId(row?.id)
  }, [row])

  useEffect(() => {
    if (open) {
      getDataReason()

      const yearRegex = /\b\d{4}\b/
      const yearMatch = row?.customer_vehicle.match(yearRegex)
      const year = yearMatch ? yearMatch[0] : null

      form.setFieldsValue({
        ...row,
        vehicle: {
          label: row?.customer_vehicle,
          value: row?.customer_vehicle,
        },
        year: year,
      })
    }
  }, [form, row, open])

  const getDataReason = async () => {
    try {
      setLoading(true)
      const { data } = await LeadsService.getDataReasonManagementLeads()
      setReason(data.map((item) => ({ label: item, value: item })))
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const loadVehicleOptions = async (inputValue, callback) => {
    if (inputValue.length >= 2) {
      const response = await LeadsService.searchVehicle(inputValue)
      let options = []

      for (let row of response.data) {
        options.push({
          manufacturer: row.manufacturer.name,
          brand: row.name,
          label: `${row.manufacturer.name} ${row.name}`,
          value: `${row.manufacturer.name} ${row.name}`,
        })
      }
      callback(options)
    }
  }

  const onFinish = async (values) => {
    setLoading(true)
    const payload = {
      reason: values.reason,
      manufacturer: values.vehicle.manufacturer,
      brand: values.vehicle.brand,
      year: values.year,
    }

    try {
      const response = await LeadsService.updateLeads(payload, lead_id)

      if (response.status === 200) {
        notification.success({
          message: 'Berhasil',
          description: 'Data berhasil diupdate',
        })
        setLoading(false)
        toggle()
        form.resetFields()
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  return (
    <Modal
      title="Form Update Leads"
      open={open}
      onCancel={() => {
        toggle()
        form.resetFields()
      }}
      footer={false}
    >
      <Form form={form} onFinish={onFinish} className="mt-5" layout="vertical">
        <Form.Item
          name="reason"
          label="Alasan Belum Beli"
          required
          rules={[
            {
              required: true,
              message: 'Alasan Belum Beli harus diisi',
            },
          ]}
        >
          <Select placeholder="Pilih Alasan Belum Beli" options={reason} />
        </Form.Item>

        <Form.Item
          name="customer_vehicle_note"
          label="Catatan"
          required
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input.TextArea readOnly />
        </Form.Item>

        <Form.Item
          name="vehicle"
          label="Kendaraan Customer"
          required
          rules={[
            {
              required: true,
              message: 'Kendaraan harus diisi',
            },
          ]}
        >
          <AsyncSelect placeholder="Nama Kendaraan" loadOptions={loadVehicleOptions} />
        </Form.Item>

        <Form.Item
          name="year"
          label="Tahun"
          required
          rules={[
            {
              required: true,
              message: 'Tahun harus diisi',
            },
          ]}
        >
          <Select
            placeholder="Tahun"
            options={years?.map((item) => ({
              value: item,
              label: item,
            }))}
          />
        </Form.Item>

        <div className="flex justify-end gap-2">
          <Form.Item>
            <Button className="bg-rose-500 text-white" onClick={toggle}>
              Batal
            </Button>
          </Form.Item>

          <Form.Item>
            <Button loading={loading} className="bg-[#205295] text-white" htmlType="submit">
              Simpan
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  )
}

export default UpdateLeads
