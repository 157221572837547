import { Button, Card, Divider, Form, Input, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { FLOW_VERSION } from '../../constants'

export default function BroadcastMessage(props) {
  const [message, setMessage] = useState('')
  const [messageType, setMessageType] = useState('broadcastMessage')
  const [version, setVersion] = useState('')
  const [isConnected, setIsConnected] = useState(false)
  const [withVersion, setWithVersion] = useState(false)
  const [messageTypeOptions] = useState([
    { value: 'broadcastMessage', label: 'Broadcast Message' },
    { value: 'updateFlowVersion', label: 'Update Flow Version' },
  ])

  const [form] = Form.useForm()

  const socketIO = useSelector((state) => state.socket.io)

  useEffect(() => {
    setIsConnected(socketIO.connected || false)
  }, [socketIO])

  const handleSubmit = () => {
    socketIO.emit(messageType, { message, version })
    // form.resetFields();
  }

  return (
    <>
      <h1 className="antialiased font-semibold text-xl text-[#013a63]">Broadcast</h1>
      <Divider className="border-gray-150" />
      <Card className="shadow-lg">
        <div className="grid md:grid-cols-3 gap-8">
          <div></div>
          <Form layout="vertical" onFinish={handleSubmit} form={form}>
            <Form.Item label="Koneksi Server Broadcast">
              <Input
                readOnly
                value={isConnected ? 'connected' : 'disconnected'}
                className={
                  !isConnected ? 'border-red-500 text-red-500' : 'border-green-500 text-green-500'
                }
              />
            </Form.Item>
            <Form.Item label="Versi Saat Ini">
              <Input readOnly value={FLOW_VERSION} />
            </Form.Item>
            <Form.Item
              label="Jenis Broadcast"
              required
              rules={[
                {
                  required: true,
                  message: 'Jenis Broadcast wajib diisi',
                },
              ]}
            >
              <Select
                placeholder="Pilih Jenis Broadcast"
                value={messageType}
                onChange={(value) => {
                  setWithVersion(value === 'updateFlowVersion' ? true : false)
                  setMessageType(value)
                }}
              >
                {messageTypeOptions.map((type, key) => (
                  <Select.Option key={key} value={type.value}>
                    {type.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            {withVersion && (
              <Form.Item
                label="Versi Terbaru"
                name="version"
                required
                rules={[
                  {
                    required: true,
                    message: 'Versi Terbaru wajib diisi',
                  },
                ]}
              >
                <Input
                  placeholder="1.23.45"
                  onChange={(e) => setVersion(e.target.value)}
                  value={version}
                />
              </Form.Item>
            )}

            <Form.Item
              label="Pesan Broadcast"
              name="message"
              required
              rules={[
                {
                  required: true,
                  message: 'Pesan Broadcast wajib diisi',
                },
                {
                  message: 'Maksimal Pesan Broadcast 100 karakter',
                  max: 100,
                },
              ]}
            >
              <Input
                placeholder="Masukkan Pesan Broadcast di sini."
                rows={5}
                autoComplete="on"
                value={message}
                onChange={(e) => setMessage(e.target.value?.substring(0, 100))}
              />
            </Form.Item>

            <Form.Item className="text-center">
              <Button type="primary" htmlType="submit">
                {/* add icon kirim pesan */}
                <i className="fa-solid fa-broadcast-tower px-3"></i> Broadcast
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Card>
    </>
  )
}
