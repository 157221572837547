import { request } from '../config'

// versi function
function FiberVariantService() {
  // Get Data for Datatable
  const getDatatable = async (params, material_variant_id) => {
    const response = await request.get(
      `/material-variants/${material_variant_id}/fiber-variants/pagination`,
      {
        params,
      }
    )
    return response.data
  }

  // insert data
  const save = async (data, material_variant_id) => {
    const response = await request.post(
      `/material-variants/${material_variant_id}/fiber-variants`,
      data
    )
    return response
  }

  // update Data
  const update = async (data, material_variant_id, fiber_variant_id) => {
    const response = await request.put(
      `/material-variants/${material_variant_id}/fiber-variants/${fiber_variant_id}`,
      data
    )

    return response.data
  }

  // remove data
  const remove = async (material_variant_id, fiber_variant_id) => {
    const response = await request.delete(
      `/material-variants/${material_variant_id}/fiber-variants/${fiber_variant_id}`
    )

    return response.data
  }

  return {
    getDatatable,
    save,
    update,
    remove,
  }
}

export default FiberVariantService()
