import { Card, Col } from 'antd'
import { formatRp } from '../../../helpers/formatRp'

const PlatformCard = (props) => {
  const { data, title, span, onlyLeads } = props
  return (
    <Col span={span || 8}>
      <Card title={title} bordered={false}>
        <table className="w-full border border-spacing-5 text-center">
          {onlyLeads ? (
            <tbody>
              <tr>
                <td className="border text-lg">LEADS</td>
              </tr>
              <tr>
                <td className="border font text-2xl font-bold" rowSpan={3}>
                  {new Intl.NumberFormat('id-ID').format(data?.leads)}
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td className="border text-lg p-2" colSpan="2">
                  TOTAL DAILY BUDGET + PPN
                </td>
                <td className="border text-lg">LEADS</td>
              </tr>
              <tr>
                <td className="border font text-2xl font-bold p-2" colSpan="2">
                  {formatRp(data?.budget)}
                </td>
                <td className="border font text-2xl font-bold" rowSpan={3}>
                  {new Intl.NumberFormat('id-ID').format(data?.leads)}
                </td>
              </tr>
              <tr>
                <td className="border text-lg p-2" colSpan="2">
                  COST PER LEADS + PPN
                </td>
              </tr>
              <tr>
                <td className="border font text-2xl font-bold p-2" colSpan="2">
                  {formatRp(data?.cpl)}
                </td>
              </tr>
            </tbody>
          )}
        </table>
      </Card>
    </Col>
  )
}

export default PlatformCard
