import React, { useEffect, useRef, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Outlet } from 'react-router'
import SideNavbar from '../components/SideNavbar'
import TopNavbar from '../components/TopNavbar'

const DashboardLayout = () => {
  let sideRef = useRef()

  const isMobile = useMediaQuery({
    query: '(max-width: 450px)',
  })

  const [trigger, setTrigger] = useState(true)

  useEffect(() => {
    if (isMobile) {
      let handler = (e) => {
        if (sideRef.current && !sideRef.current.contains(e.target)) {
          setTrigger(false)
        }
      }

      document.addEventListener('mousedown', handler)

      return () => {
        document.addEventListener('mousedown', handler)
      }
    }
  })

  const openSide = () => {
    setTrigger(!trigger)
  }

  return (
    <>
      <SideNavbar sideRef={sideRef} trigger={trigger} openSide={openSide} />
      <div className="absolute w-full bg-gradient-to-r from-[#0A2647] to-[#0A2647] h-[400px]"></div>
      <div className="px-5">
        <div className={`relative ${trigger ? `md:ml-64` : ``}`}>
          <TopNavbar trigger={trigger} setTrigger={setTrigger} />
        </div>
        <div className={`relative ${trigger ? `md:ml-64` : ``} h-min-fit mt-10`}>
          <Outlet />
        </div>
      </div>
    </>
  )
}

export default DashboardLayout
