import { request } from '../config'

// versi function
function DesignService() {
  // Get Data for Datatable
  const getDatatable = async (params, products_id) => {
    const response = await request.get(`/products/${products_id}/designs/pagination`, { params })
    return response.data
  }

  // Get Data for Datatable
  const list = async () => {
    const response = await request.get('/designs')

    return response.data
  }

  // Show detail
  const findById = async (designs_id) => {
    const response = await request.get(`/designs/${designs_id}`)

    return response.data
  }

  // insert data
  const save = async (products_id, data) => {
    const response = await request.post(`/products/${products_id}/designs`, data)
    return response
  }

  // update Data
  const update = async (products_id, design_id, data) => {
    const response = await request.put(`/products/${products_id}/designs/${design_id}`, data)

    return response.data
  }

  // remove data
  const remove = async (products_id, design_id) => {
    const response = await request.delete(`/products/${products_id}/designs/${design_id}`)

    return response.data
  }

  return {
    getDatatable,
    list,
    findById,
    save,
    update,
    remove,
  }
}

export default DesignService()
