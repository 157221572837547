import { request } from '../config'

// versi function
function ProductService() {
  const getDatatable = async (params) => {
    const response = await request.get(`/products/pagination`, { params })
    return response.data
  }

  const findById = async (products_id) => {
    const response = await request.get(`/products/${products_id}`)
    return response.data
  }

  const findByIdAndVehicle = async (products_id, data) => {
    const response = await request.post(`/products/${products_id}`, data)
    return response.data
  }

  const save = async (data) => {
    const response = await request.post(`/products`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    return response.data
  }

  const searchProduct = async (filter) => {
    const response = await request.post(`/products/search`, filter)
    return response.data
  }

  const update = async (products_id, data) => {
    const response = await request.put(`/products/${products_id}`, data)
    return response.data
  }

  const remove = async (products_id) => {
    const response = await request.delete(`/products/${products_id}`)
    return response.data
  }

  const getDataProduct = async () => {
    const response = await request.get(`/products`)
    return response.data
  }

  return {
    getDatatable,
    getDataProduct,
    searchProduct,
    findById,
    findByIdAndVehicle,
    save,
    remove,
    update,
  }
}

export default ProductService()
