import React, { useEffect, useState } from 'react'
import CustomBadge from '../../../components/CustomBadge'
import { Button, Card, Form, Select, Table } from 'antd'
import ReportService from '../../../services/ReportService'
import OrderService from '../../../services/OrderService'
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js'
import dayjs from 'dayjs'
import { Line } from 'react-chartjs-2'
import { formatNumberID, formatRp } from '../../../helpers/formatRp'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement
)

const OmzetDaily = () => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)

  const [data, setData] = useState(null)

  const [unitOptions, setUnitOptions] = useState([])
  const [periodOptions, setPeriodOptions] = useState([])

  useEffect(() => {
    getUnitOptions()
    getPeriodOptions()
  }, [])

  const getUnitOptions = async () => {
    setLoading(true)
    try {
      const response = await OrderService.getOrderFilterByUnit()
      setUnitOptions(
        response.data.map((filter) => ({
          value: filter.id,
          label: filter.name,
        }))
      )
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const getPeriodOptions = async () => {
    try {
      const response = await ReportService.getPeriodeOptions()
      if (response.status === 200) {
        setPeriodOptions(response.data.map((item) => ({ value: item.value, label: item.name })))
      }
    } catch (error) {
      console.log(error)
    }
  }

  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      width: 150,
      render: (text) => dayjs(text).format('D MMM YYYY'),
    },
    {
      title: 'Total Budget Ads Adv',
      dataIndex: 'budget_adv',
      key: 'budget_adv',
      render: (text) => formatRp(text),
    },
    {
      title: 'Budget Ads Marketplace',
      dataIndex: 'budget_mp',
      key: 'budget_mp',
      render: (text) => formatRp(text),
    },
    {
      title: 'Leads',
      dataIndex: 'leads',
      key: 'leads',
      width: 50,
    },
    {
      title: 'Closing',
      dataIndex: 'deals',
      key: 'deals',
    },
    {
      title: 'Konversi',
      dataIndex: 'convertion',
      key: 'convertion',
    },
    {
      title: 'Omzet Scoreboard',
      dataIndex: 'omzet',
      key: 'omzet',
      render: (text) => formatRp(text),
    },
    {
      title: 'Rasio Ads (by budget)',
      dataIndex: 'ratio',
      key: 'ratio',
      render: (text) => `${parseFloat(text).toFixed(2)}%`,
    },
  ]

  const onFinish = async (values) => {
    const payload = {
      unit_id: values.unit_id,
      period: values.period,
    }

    try {
      setLoading(true)
      const { status, data } = await ReportService.getDataOmzetDaily(payload)

      if (status === 200) {
        // setUnitName(unitOptions.find((unit) => unit.value === selectedUnit)?.label);
        setData({
          data: data,
          charts: {
            labels: data.map((item) => dayjs(item.date).format('D MMM YYYY')),
            datasets: [
              {
                borderColor: 'rgb(75, 192, 192)',
                backgroundColor: 'rgba(75, 192, 192, 0.75)',
                label: 'Omzet',
                data: data.map((item) => item.omzet),
              },
              {
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.75)',
                label: 'Budget',
                data: data.map((item) => item.budget),
              },
            ],
          },
        })
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const handleRow = (record, index) => {
    if (index % 2 !== 0) {
      return 'bg-gray-100'
    }
  }

  return (
    <>
      <CustomBadge formOrder title="Omzet Daily" titleParent="Laporan" />

      <Card className="md:ml-3">
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <div className="grid md:grid-cols-2 gap-4">
            <Form.Item
              label="Periode"
              name="period"
              required
              rules={[
                {
                  required: true,
                  message: 'Periode harus diisi',
                },
              ]}
            >
              <Select className="w-full" placeholder="Pilih Periode" options={periodOptions} />
            </Form.Item>

            <Form.Item
              label="Unit"
              name="unit_id"
              required
              rules={[
                {
                  required: true,
                  message: 'Unit harus diisi',
                },
              ]}
            >
              <Select
                allowClear
                className="w-full"
                placeholder="Pilih Unit"
                options={unitOptions}
                // onChange={handleUnit}
              />
            </Form.Item>
          </div>

          <div className="flex justify-end gap-2">
            <Form.Item>
              <Button className="text-white bg-rose-600 border-0">Batal</Button>
            </Form.Item>

            <Form.Item>
              <Button
                htmlType="submit"
                loading={loading}
                className="text-white bg-[#013a63] border-0"
              >
                Cari
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Card>

      {data && !loading && (
        <>
          <Card title="Trend Budget & Omzet" className="md:ml-3 mt-4">
            <Line
              data={data?.charts}
              options={{
                responsive: true,
                interaction: {
                  mode: 'index',
                  intersect: true,
                },
                plugins: {
                  tooltip: {
                    callbacks: {
                      label: function (context) {
                        let label = context.dataset.label || ''

                        if (label) {
                          label += ': '
                        }
                        if (context.parsed.y !== null) {
                          label += formatRp(context.parsed.y)
                        }
                        return label
                      },
                    },
                  },
                  legend: {
                    position: 'top',
                  },
                  title: {
                    display: false,
                    text: 'Chart.js Line Chart',
                  },
                },
                scales: {
                  x: {
                    beginAtZero: true,
                  },
                  y: {
                    beginAtZero: true,
                    ticks: {
                      callback: function (value) {
                        return 'Rp ' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
                      },
                    },
                  },
                },
              }}
              id="chart-omzet-dark"
              className="chart-canvas"
            />
          </Card>

          <Card title="Daily Budget & Omzet" className="table-section-product md:ml-3 mt-4">
            <Table
              className="antialiased"
              rowKey="date"
              loading={loading}
              columns={columns}
              dataSource={data?.data}
              pagination={false}
              scroll={{ x: true }}
              style={{
                marginTop: 10,
              }}
              tableLayout="auto"
              rowClassName={handleRow}
              summary={(pageData) => {
                let totalBudgetAdv = 0
                let totalBudgetMp = 0
                let totalOmzet = 0
                let totalLeads = 0
                let totalDeals = 0

                pageData.forEach(({ budget_adv, budget_mp, omzet, leads, deals }) => {
                  totalBudgetAdv += budget_adv
                  totalBudgetMp += budget_mp
                  totalOmzet += omzet
                  totalLeads += leads
                  totalDeals += deals
                })

                const averageRatio = ((totalBudgetAdv + totalBudgetMp) / totalOmzet) * 100

                const totalConvertion =
                  totalLeads > 0
                    ? formatNumberID(((totalDeals / totalLeads) * 100).toFixed(2)) + '%'
                    : '~%'

                return (
                  <>
                    <Table.Summary.Row className="bg-gray-200">
                      <Table.Summary.Cell colSpan={1} className="text-lg font-semibold">
                        Total
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <span className="font-bold">{formatRp(totalBudgetAdv)}</span>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <span className="font-bold">{formatRp(totalBudgetMp)}</span>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <span className="font-bold">{totalLeads}</span>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <span className="font-bold">{totalDeals}</span>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <span className="font-bold">{totalConvertion}</span>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <span className="font-bold">{formatRp(totalOmzet)}</span>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <span className="font-bold">{averageRatio.toFixed(2)}%</span>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </>
                )
              }}
            />
          </Card>
        </>
      )}
    </>
  )
}

export default OmzetDaily
