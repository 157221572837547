import { Button, Form, Input, Modal } from 'antd'
import React, { useEffect } from 'react'
import Swal from 'sweetalert2'
import PriceService from '../../../services/PriceService'

const UpdatePrice = (props) => {
  const { productId, isModalEditOpen, setIsModalEditOpen, getData, childData } = props

  const [form] = Form.useForm()

  useEffect(() => {
    form.setFieldsValue({
      ...childData,
    })
  }, [form, childData])

  const onSubmit = async (values) => {
    const data = {
      weight: parseInt(values.weight),
      price: parseInt(values.price),
      agent_price: parseInt(values.agent_price),
      product_id: parseInt(productId),
    }

    const response = await PriceService.update(productId, childData.id, data)
    try {
      if (response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Berhasil',
          text: 'Berhasil update Harga',
        })
      }
      getData()
      form.resetFields()
      setIsModalEditOpen(false)
    } catch (error) {
      console.log(error)
    }
  }

  const handleCancel = () => {
    form.resetFields()
    setIsModalEditOpen(false)
  }

  return (
    <Modal
      forceRender
      title="Update Harga Produk"
      footer={false}
      open={isModalEditOpen}
      onCancel={handleCancel}
    >
      <Form className="mt-10" form={form} onFinish={onSubmit} layout="vertical">
        <Form.Item label="Produk" name="product_name">
          <Input type="text" className="rounded-md h-10" disabled />
        </Form.Item>
        <Form.Item label="Baris" name="row">
          <Input type="text" className="rounded-md h-10" disabled />
        </Form.Item>
        <Form.Item label="Ukuran" name="size">
          <Input type="text" className="rounded-md h-10" disabled />
        </Form.Item>
        <Form.Item label="Berat (gram)" name="weight">
          <Input type="number" className="rounded-md h-10" placeholder="Silahkan isi berat" />
        </Form.Item>
        <Form.Item label="Harga (Rp)" name="price">
          <Input type="number" className="rounded-md h-10" placeholder="Silahkan isi harga" />
        </Form.Item>
        <Form.Item label="Harga Agen (Rp)" name="agent_price">
          <Input type="number" className="rounded-md h-10" placeholder="Silahkan isi harga agen" />
        </Form.Item>

        <Form.Item>
          <div className="flex justify-end gap-3">
            <Button className="bg-rose-500 text-white rounded-lg" onClick={() => handleCancel()}>
              Batalkan
            </Button>
            <Button htmlType="submit" className="bg-cyan-500 text-white rounded-lg">
              Simpan
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default UpdatePrice
