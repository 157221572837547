import { Button, DatePicker, Form, Input, Modal, notification } from 'antd'
import React, { useEffect, useState } from 'react'
import OrderService from '../../../../services/OrderService'

const ChangeEstimation = (props) => {
  const { open, toggle, row } = props
  const [itemId, setItemId] = useState(null)
  const [estimation, setEstimation] = useState(null)
  const [form] = Form.useForm()

  useEffect(() => {
    setItemId(row?.item_id)
    form.resetFields()
  }, [row, form])

  const handleCancel = () => {
    form.resetFields()
    setItemId(null)
    setEstimation(null)
    toggle()
  }

  const onFinish = async (values) => {
    const body = {
      reason: values.reason,
      estimation: estimation,
      item_id: itemId,
    }

    const response = await OrderService.changeEstimation(body)

    try {
      if (response.status === 200) {
        notification.success({
          message: 'Berhasil',
          description: 'berhasil ganti estimasi',
          placement: 'top',
        })
      }
      form.resetFields()
      setItemId(null)
      setEstimation(null)
      toggle()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Modal forceRender open={open} centered={true} onCancel={handleCancel} footer={false}>
      {row && <h2 className="text-xl text-gray-800 font-medium">Ganti Estimasi Selesai</h2>}

      <Form
        form={form}
        onFinish={onFinish}
        className="section-form-recipient mt-10"
        layout="vertical"
      >
        <div className="grid md:grid-cols-1 gap-3">
          <Form.Item
            label="Estimasi Selesai Tanggal ?"
            name="estimation"
            required
            rules={[
              {
                required: true,
              },
            ]}
          >
            <DatePicker
              onChange={(_, dateString) => setEstimation(dateString)}
              placement="bottomLeft"
            />
          </Form.Item>
        </div>
        <div className="grid md:grid-cols-2 gap-3">
          <Form.Item
            label="Alasan"
            name="reason"
            required
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder="Antrian banyak" />
          </Form.Item>
        </div>

        <div className="flex justify-end gap-2 mt-10">
          <Button className="border-0 bg-rose-500 text-white w-32" onClick={() => handleCancel()}>
            Cancel
          </Button>
          <Form.Item>
            <Button htmlType="submit" className="border-0 bg-cyan-500 text-white w-32">
              Simpan
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  )
}

export default ChangeEstimation
