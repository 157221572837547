import { request } from '../config'

// versi function
function OrderService() {
  const getDatatableOrder = async (params) => {
    const response = await request.get(`/orders/pagination`, { params })
    return response.data
  }

  const insertOrder = async (data) => {
    const response = await request.post(`/orders`, data)

    return response.data
  }

  const getDataByID = async (orderId) => {
    const response = await request.get(`/orders/${orderId}`)

    return response.data
  }

  const getDataByItem = async (item_id) => {
    const response = await request.get(`/orders/detail/${item_id}`)

    return response.data
  }

  const getPlatforms = async () => {
    const response = await request.get('/platforms')

    return response.data
  }

  const getBonuses = async () => {
    const response = await request.get('/bonuses')

    return response.data
  }

  const editOrder = async (data, order_id) => {
    const response = await request.put(`/orders/${order_id}`, data)

    return response.data
  }

  const getOrderSources = async () => {
    const response = await request.get('/order-sources')

    return response.data
  }

  const getOrderFilterByPayment = async () => {
    const response = await request.get('/payment-options')

    return response.data
  }

  const getOrderFilterByStatusProduction = async () => {
    const response = await request.get('/status-options')

    return response.data
  }

  const getStatusListForChangeStatus = async () => {
    const response = await request.get('flow/production-statuses')
    return response.data
  }

  const getOrderFilterByColumn = async () => {
    const response = await request.get('/column-options')

    return response.data
  }

  const nextStatus = async (body) => {
    const response = await request.post('/flow/next', body)

    return response?.data
  }
  const verifyOrder = async (body) => {
    const response = await request.put('/flow/verify', body)

    return response?.data
  }
  const setTrackingNumber = async (body) => {
    const response = await request.put('/flow/awb', body)

    return response?.data
  }

  const setEstimation = async (body) => {
    const response = await request.put('/flow/estimation', body)

    return response?.data
  }

  const changeEstimation = async (body) => {
    const response = await request.put('/flow/change-estimation', body)

    return response?.data
  }

  const pending = async (body) => {
    const response = await request.put('/flow/pending', body)

    return response?.data
  }

  const changeStatus = async (body) => {
    const response = await request.put('/flow/change-status', body)

    return response?.data
  }

  const continueToPrevious = async (body) => {
    const response = await request.put('/flow/continue', body)

    return response?.data
  }

  const deleteOrder = async (orderId) => {
    const response = await request.delete(`/orders/${orderId}`)

    return response?.data
  }

  const getOrderFilterByUnit = async () => {
    const response = await request.get('/units')

    return response.data
  }

  const getOrderFilterByPic = async (unit_id) => {
    const response = await request.get(`/units/${unit_id}/pic`)

    return response.data
  }

  return {
    getDatatableOrder,
    getDataByID,
    getDataByItem,
    insertOrder,
    getPlatforms,
    editOrder,
    getOrderSources,
    getBonuses,
    getOrderFilterByPayment,
    getOrderFilterByColumn,
    getOrderFilterByStatusProduction,
    nextStatus,
    verifyOrder,
    setTrackingNumber,
    setEstimation,
    changeEstimation,
    pending,
    continueToPrevious,
    changeStatus,
    getStatusListForChangeStatus,
    deleteOrder,
    getOrderFilterByUnit,
    getOrderFilterByPic,
  }
}

export default OrderService()
