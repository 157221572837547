import { Button, Form, Input, Select, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import Swal from 'sweetalert2'
import CustomBadge from '../../../../components/CustomBadge'
import PaymentChannelService from '../../../../services/PaymentChannelService'
import UnitService from '../../../../services/UnitService'

const AddPaymentChannel = () => {
  const [form] = Form.useForm()
  const [banks, setBanks] = useState([])
  const [units, setUnits] = useState([])
  const navigate = useNavigate()

  useEffect(() => {
    async function getBanks() {
      const response = await PaymentChannelService.bankList()
      setBanks(response.data)
    }

    async function getUnits() {
      const response = await UnitService.all()
      setUnits(response.data)
    }

    getBanks()
    getUnits()
  }, [])

  const onSubmit = async (values) => {
    const data = {
      unit_id: values.unit_id,
      name: values.name,
      account_name: values.account_name,
      account_number: values.account_number,
    }

    const response = await PaymentChannelService.save(data)
    try {
      if (response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Berhasil',
          text: 'Berhasil menambahkan Channel Pembayaran',
        })
      }
      navigate('/payment-channels')
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className="bg-white subpixel-antialiased shadow-white-500/40 shadow-sm rounded-2xl h-fit w-full md:overflow-visible pt-4 md:pt-4">
      <div className="section-product px-2 md:px-4 pb-2">
        <CustomBadge title="Tambah" titleParent="Channel Pembayaran" />

        <div className="section-form-payment-channels w-1/2 mt-10">
          <Form form={form} onFinish={onSubmit} layout="vertical">
            <Form.Item
              label="Unit"
              name="unit_id"
              required
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select placeholder="Pilih Unit">
                {units?.map((item, index) => (
                  <Select.Option key={index} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Bank Provider"
              name="name"
              required
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select placeholder="Bank Provider">
                {banks?.map((item, index) => (
                  <Select.Option key={index} value={item.name}>
                    <Space>
                      <span role="img" aria-label={item.name}>
                        <img width="50px" src={item.logo} alt={item.name} />
                      </span>
                    </Space>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Nomor Rekening" name="account_number">
              <Input placeholder="1234567890" />
            </Form.Item>
            <Form.Item label="Nama Pemilik" name="account_name">
              <Input placeholder="Nama Pemilik" />
            </Form.Item>

            <Form.Item>
              <Space className="flex justify-end mt-10">
                <Button
                  className="border-0 text-white text-lg h-fit font-medium bg-rose-500"
                  onClick={() => navigate('/payment-channels')}
                >
                  Batalkan
                </Button>

                <Button
                  className="border-0 text-white text-lg h-fit font-medium bg-cyan-500"
                  htmlType="submit"
                >
                  Simpan
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default AddPaymentChannel
