import { request } from '../config'

// versi function
function ShipperService() {
  // Show List
  const list = async () => {
    const response = await request.get(`/shippers`)
    return response.data
  }
  return {
    list,
  }
}

export default ShipperService()
