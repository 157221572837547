import { Spin } from 'antd'
import React from 'react'

const PageLoading = () => {
  return (
    <div className="w-full h-full flex justify-center items-center">
      <Spin tip="Loading..." />
    </div>
  )
}

export default PageLoading
