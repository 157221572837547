/* eslint-disable react-hooks/exhaustive-deps */
import { Card, Checkbox, Form, Input, Select } from 'antd'
import React, { useEffect, useState } from 'react'
// import CourierService from '../../../../services/CourierService'
import OrderService from '../../../../services/OrderService'
import { formatRp } from '../../../../helpers/formatRp'

const FormSummary = (props) => {
  const { form, data } = props

  const [bonusOptions, setBonusOptions] = useState([])

  const isCourier = !['manual', 'kantor', 'cod', 'homeservice', 'workshop'].includes(
    data?.shipment?.courier_code
  )

  const isManual = ['manual'].includes(data?.shipment?.courier_code)

  const isAnotherInput = ['kantor', 'cod', 'homeservice', 'workshop'].includes(
    data?.shipment?.courier_code
  )

  useEffect(() => {
    // getCourier()
    getBonus()
  }, [])

  const getBonus = async () => {
    const response = await OrderService.getBonuses()
    try {
      if (response.status === 200) {
        setBonusOptions(
          response.data.map((bonus) => ({
            value: bonus.name,
            label: bonus.name,
            style: {
              width: '80%',
              marginTop: '10px',
              marginBottom: '5px',
              marginLeft: '0.1rem',
              marginRight: 0,
              textAlign: 'start',
              fontSize: '12px',
            },
          }))
        )
      }
    } catch (error) {
      console.log(error)
    }
  }

  // const getCourier = async () => {
  //   const response = await CourierService.list()
  //   try {
  //     if (response.status === 200) {
  //       setCouriers(
  //         response.data.map((courier) => ({
  //           value: courier.courier_code,
  //           label: courier.name,
  //         }))
  //       )
  //     }
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  // const courierChange = async (value, options) => {
  //   // setCourier_name(options && options.label)
  //   const dataCost = {
  //     origin: data.shipment.shipper.subdistrict.id,
  //     destination: data.shipment.subdistrict.id, //DARI FIELD SUBDISTRICT_ID CUSTOMER
  //     weight: countWeight(),
  //     courier: value,
  //   }
  //   console.log(dataCost)

  //   if (value) {
  //     const response = await CourierService.getShippingCost(dataCost)
  //     try {
  //       if (response.status === 200) {
  //         setShippingCost(
  //           response.data.map((cost) => ({
  //             value: cost.cost,
  //             label: cost.service,
  //           }))
  //         )
  //       }
  //     } catch (error) {
  //       console.log(error)
  //     }

  //     // RESET LAYANAN DAN BIAYA ONGKIR
  //     // KETIKA ADA PERUBAHAN KURIR
  //     setShipping_cost(0)
  //     form.setFieldsValue({
  //       service: undefined,
  //     })
  //   } else {
  //     // RESET LAYANAN DAN BIAYA ONGKIR
  //     // KETIKA KURIR BELUM DIPILIH
  //     setShipping_cost(0)
  //     form.setFieldsValue({
  //       service: undefined,
  //     })
  //     return value
  //   }
  // }

  // const costChange = (value, options) => {
  //   setShipping_cost(parseInt(value).toLocaleString('id-ID'))
  // }

  // const handleDiscountChange = (e) => {
  //   setDiscount(e.target.value.replace(/\D/g, ''))

  //   if (e.target.value === '' || e.target.value === null) {
  //     setDiscount(0)
  //   }
  // }

  // const handleAdditionalCostChange = (e) => {
  //   setAdditional_cost(e.target.value.replace(/\D/g, ''))

  //   if (e.target.value === '' || e.target.value === null) {
  //     setAdditional_cost(0)
  //   }
  // }

  // const countSubTotal = () => {
  //   let subTotal = data.items?.reduce((accumulator, product) => accumulator + product?.subtotal, 0)
  //   return subTotal
  // }

  // const grandTotal = () => {
  //   const subtotal = {
  //     subtotal: countSubTotal(),
  //     discount: parseInt(-data.discount),
  //     additional_cost: parseInt(data.additional_cost),
  //     shipping_cost: parseInt(data.shipping_cost),
  //   }

  //   return Object.values(subtotal).reduce((total, value) => total + value || 0, 0)
  // }

  // const countWeight = () => {
  //   let totalWeight = data.items?.reduce(
  //     (accumulator, variant) => accumulator + variant.variant.weight * variant.qty,
  //     0
  //   )
  //   return totalWeight
  // }

  // const handleBonusChange = (values) => {
  //   console.log(values)
  //   setBonus(values)
  // }

  // const columns = [
  //   {
  //     title: 'Deskripsi',
  //     dataIndex: 'desc',
  //     key: '1',
  //   },
  //   {
  //     title: 'Alamat',
  //     dataIndex: 'address',
  //     key: '2',
  //   },
  //   {
  //     title: 'Action',
  //     dataIndex: 'action',
  //     key: '3',
  //   },
  //   {
  //     title: 'Total',
  //     dataIndex: 'total',
  //     key: '4',
  //     align: 'right',
  //   },
  // ]

  // const dataTable = [
  //   {
  //     key: '1',
  //     desc: <h2 className="text-sm text-rose-500">Subtotal</h2>,
  //     action: 'Rp',
  //     total: <>{countSubTotal()?.toLocaleString('id-ID')}</>,
  //   },
  //   {
  //     key: '2',
  //     desc: <h2 className="text-sm text-rose-500">Total Berat</h2>,
  //     total: `${countWeight() / 1000} Kg`,
  //   },
  //   {
  //     key: '3',
  //     desc: <h2 className="text-sm text-rose-500 leading-10">Ongkir</h2>,
  //     action: (
  //       <div className="grid md:grid-cols-2 gap-3">
  //         <Form.Item
  //           name="courier_code"
  //           required
  //           rules={[
  //             {
  //               required: true,
  //               message: 'Kurir wajib diisi',
  //             },
  //           ]}
  //         >
  //           <Select
  //             disabled
  //             allowClear
  //             options={couriers}
  //             placeholder="Kurir"
  //             onChange={courierChange}
  //           />
  //         </Form.Item>

  //         <Form.Item
  //           name="service"
  //           // required
  //           // rules={[
  //           //   {
  //           //     required: true,
  //           //     message: 'Layanan wajib diisi',
  //           //   },
  //           // ]}
  //         >
  //           <Select
  //             disabled
  //             allowClear
  //             placeholder="Layanan"
  //             onChange={costChange}
  //             options={shippingCost}
  //           />
  //         </Form.Item>
  //       </div>
  //     ),
  //     total: (
  //       <Form.Item>
  //         <Input prefix="Rp" value={shipping_cost?.toLocaleString('id-ID') || 0} disabled />
  //       </Form.Item>
  //     ),
  //   },
  //   {
  //     key: '4',
  //     desc: <h2 className="text-sm text-rose-500 leading-10">Diskon Order</h2>,
  //     total: (
  //       <Form.Item>
  //         <Input
  //           disabled
  //           prefix="Rp"
  //           type="text"
  //           onChange={handleDiscountChange}
  //           value={parseInt(discount).toLocaleString('id-ID')}
  //         />
  //       </Form.Item>
  //     ),
  //   },
  //   {
  //     key: '5',
  //     desc: <h2 className="text-sm text-rose-500 leading-10">Biaya Lain-lain</h2>,
  //     action: (
  //       <Form.Item name="additional_cost_note">
  //         <Input disabled placeholder="Catatan biaya" />
  //       </Form.Item>
  //     ),
  //     total: (
  //       <Form.Item>
  //         <Input
  //           disabled
  //           prefix="Rp"
  //           type="text"
  //           value={parseInt(additional_cost).toLocaleString('id-ID')}
  //           onChange={handleAdditionalCostChange}
  //         />
  //       </Form.Item>
  //     ),
  //   },
  //   {
  //     key: '6',
  //     desc: <h2 className="text-sm text-rose-500 leading-10">Bonus</h2>,
  //     total: (
  //       <div className="mt-3 md:mt-0 section-checkbox-form-order">
  //         <Checkbox.Group value={bonus} onChange={handleBonusChange} options={bonusOptions} />
  //       </div>
  //     ),
  //   },
  //   {
  //     key: '7',
  //     desc: <h2 className="text-sm text-rose-500">Keterangan</h2>,

  //     total: (
  //       <Form.Item name="description">
  //         <Input.TextArea className="w-52 md:w-full" rows={3} placeholder="Keterangan" />
  //       </Form.Item>
  //     ),
  //   },
  //   // {
  //   //   key: '7',
  //   //   desc: <h2 className="text-sm text-rose-500 leading-6">Gross Total</h2>,
  //   //   action: <h2 className="text-right">Rp</h2>,
  //   //   total: <>{grossTotal()?.toLocaleString('id-ID')}</>,
  //   // },
  //   // {
  //   //   key: '8',
  //   //   desc: <h2 className="text-sm text-rose-500 leading-16">PPN 0%</h2>,
  //   //   action: <h2 className="text-right">Rp</h2>,
  //   //   total: '0',
  //   // },
  //   {
  //     key: '8',
  //     desc: <h2 className="text-sm text-rose-500 leading-8">Grand Total</h2>,
  //     action: <h2 className="text-right leading-8">Rp</h2>,
  //     total: <h2 className="text-2xl font-medium">{grandTotal().toLocaleString('id-ID')}</h2>,
  //   },
  // ]

  return (
    <Card className="summary rounded-xl shadow-lg border-0 antialiased my-5">
      {/* <div className="section-summary-form mt-5">
        <Table
          className="px-2"
          rowKey="key"
          showHeader={false}
          dataSource={dataTable}
          columns={columns}
          pagination={false}
          tableLayout="auto"
          scroll={{ x: '50vw' }}
        />
      </div> */}

      <div className="flex justify-between items-center bg-gray-100 p-4 border-y">
        <h2 className="text-sm text-red-500 font-medium">Total Berat</h2>
        <h2 className="text-sm font-medium">{data?.shipment?.total_weight / 1000} Kg</h2>
      </div>

      <br />

      <div className="flex justify-between items-center p-4">
        <h2 className="text-sm text-red-500 font-medium">Subtotal</h2>
        <h2 className="text-sm font-medium">{formatRp(data?.subtotal)}</h2>
      </div>

      <br />

      <div className="bg-gray-100 border-y p-4">
        <h2 className="text-sm text-red-500 font-medium">Kurir</h2>

        <div className="grid md:grid-cols-2 gap-2 mt-3">
          <Form.Item
            label="Alamat pengirim"
            name="shipper_id"
            required
            rules={[
              {
                required: true,
                message: 'Alamat pengirim wajib diisi',
              },
            ]}
          >
            <Select disabled allowClear placeholder="Alamat Pengirim" />
          </Form.Item>

          <Form.Item
            label="Kurir"
            name="courier_code"
            required
            rules={[
              {
                required: true,
                message: 'Kurir wajib diisi',
              },
            ]}
          >
            <Select disabled allowClear placeholder="Kurir" />
          </Form.Item>

          {isCourier && (
            <>
              <Form.Item
                label="Layanan"
                name="courier_service"
                required
                rules={[
                  {
                    required: true,
                    message: 'Layanan wajib diisi',
                  },
                ]}
              >
                <Select disabled allowClear placeholder="Layanan" />
              </Form.Item>

              <Form.Item label="Biaya Pengiriman" name="shipping_cost">
                <Input prefix="Rp" disabled />
              </Form.Item>
            </>
          )}

          {isManual && (
            <>
              <Form.Item
                label="Nama Kurir"
                name="courier_name"
                required
                rules={[
                  {
                    required: true,
                    message: 'Layanan wajib diisi',
                  },
                ]}
              >
                <Input placeholder="Nama Kurir" />
              </Form.Item>

              <Form.Item
                label="Layanan"
                name="courier_service"
                required
                rules={[
                  {
                    required: true,
                    message: 'Layanan wajib diisi',
                  },
                ]}
              >
                <Input placeholder="Nama Service" />
              </Form.Item>

              <Form.Item
                label="Biaya Pengiriman"
                name="shipping_cost"
                rules={[
                  {
                    required: true,
                    message: 'Harga wajib diisi',
                  },
                ]}
              >
                <Input
                  prefix="Rp"
                  type="text"
                  disabled
                  onChange={(e) => {
                    form.setFieldsValue({
                      shipping_cost: parseInt(e.target.value.replace(/\D/g, '') || 0),
                    })
                  }}
                />
              </Form.Item>
            </>
          )}

          {isAnotherInput && (
            <Form.Item
              label="Biaya Pengiriman"
              name="shipping_cost"
              rules={[
                {
                  required: true,
                  message: 'Harga wajib diisi',
                },
              ]}
            >
              <Input prefix="Rp" type="text" />
            </Form.Item>
          )}

          {/* <Form.Item label="Resi" name="tracking_number">
            <Input placeholder="Resi" />
          </Form.Item> */}
        </div>
      </div>

      <br />

      <div className="p-4">
        <Form.Item
          label={<h2 className="text-sm text-red-500 font-medium">Diskon</h2>}
          name="discount"
        >
          <Input disabled prefix="Rp" type="text" />
        </Form.Item>
      </div>

      <br />

      <div className="bg-gray-100 border-y p-4">
        <Form.Item
          label={<h2 className="text-sm font-medium text-red-500">Biaya lain-lain</h2>}
          name="additional_cost"
        >
          <Input disabled prefix="Rp" type="text" />
        </Form.Item>

        <Form.Item name="additional_cost_note">
          <Input.TextArea disabled rows={3} maxLength={250} placeholder="Catatan biaya" />
        </Form.Item>
      </div>

      <br />

      <div className="p-4">
        <Form.Item
          label={<h2 className="text-sm font-medium text-red-500">Bonus / Keterangan</h2>}
          name="bonus"
        >
          <Checkbox.Group options={bonusOptions} />
        </Form.Item>

        <Form.Item name="description">
          <Input.TextArea rows={3} maxLength={250} placeholder="Keterangan" />
        </Form.Item>
      </div>

      <br />

      <div className="flex justify-between items-center bg-gray-100 p-4 border-y">
        <h2 className="text-xl text-red-500 font-bold">Grand Total</h2>
        <h2 className="text-xl font-bold">{formatRp(data?.grand_total)}</h2>
      </div>
    </Card>
  )
}

export default FormSummary
