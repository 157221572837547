/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Form, Input, Modal, notification } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import ReportService from '../../../../services/ReportService'

const DetailBudgetDaily = (props) => {
  const { date, selectedUserId: user_id, open, setIsOpen, getData: refreshData } = props

  const [form] = Form.useForm()

  const [loading, setLoading] = useState(false)
  const [detailBudgetDaily, setDetailBudgetDaily] = useState({})

  useEffect(() => {
    if (open) {
      getDataBudget(date, user_id)
    }
  }, [date, open, user_id])

  const getDataBudget = async (date, user_id) => {
    setLoading(true)
    try {
      const response = await ReportService.getDetailBudgetDaily(date, user_id)

      if (response.status === 200) {
        setLoading(false)
        setDetailBudgetDaily({ date, user_id, ...response.data })
        form.setFieldsValue({
          ...response.data,
        })
      }
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const handleClose = () => {
    setIsOpen(false)
    setDetailBudgetDaily({})
    form.resetFields()

    refreshData()
  }

  const onFinish = async (values) => {
    setLoading(true)

    const budget = {
      budget_fb: values.budget_fb || 0,
      budget_google: values.budget_google || 0,
      budget_messenger: values.budget_messenger || 0,
      budget_tiktok: values.budget_tiktok || 0,
      budget_youtube: values.budget_youtube || 0,
      budget_dm: values.budget_dm || 0,
      additional_budget: values.additional_budget || 0,
      additional_deals: values.additional_deals || 0,
      additional_omzet: values.additional_omzet || 0,
    }

    const payload = {
      id: detailBudgetDaily.id,
      user_id: detailBudgetDaily.user_id,
      ...budget,
    }

    try {
      const response = await ReportService.updateBudgetDailyPerUser(payload)
      if (response.status === 200) {
        notification.success({
          message: 'Success',
          description: 'Data berhasil diupdate',
        })
        handleClose()
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal
      forceRender
      title={`Budget ${moment(detailBudgetDaily.date).format('DD/MM/YYYY')} ${
        detailBudgetDaily?.user?.name
      }`}
      open={open}
      onCancel={handleClose}
      footer={null}
    >
      <Form layout="vertical" className="mt-10" form={form} onFinish={onFinish}>
        <div className="grid md:grid-cols-2 gap-4">
          <Form.Item label="Budget Facebook Ads" name="budget_fb">
            <Input
              prefix="Rp"
              placeholder="Budget Facebook Ads"
              onChange={(e) => {
                form.setFieldsValue({
                  budget_fb: parseInt(e.target.value.replace(/\D/g, '') || 0),
                })
              }}
            />
          </Form.Item>

          <Form.Item label="Budget Youtube Ads" name="budget_youtube">
            <Input
              prefix="Rp"
              placeholder="Budget Youtube Ads"
              onChange={(e) => {
                form.setFieldsValue({
                  budget_youtube: parseInt(e.target.value.replace(/\D/g, '') || 0),
                })
              }}
            />
          </Form.Item>

          <Form.Item label="Budget Google Ads" name="budget_google">
            <Input
              prefix="Rp"
              placeholder="Budget Google Ads"
              onChange={(e) => {
                form.setFieldsValue({
                  budget_google: parseInt(e.target.value.replace(/\D/g, '') || 0),
                })
              }}
            />
          </Form.Item>

          <Form.Item label="Budget Tiktok Ads" name="budget_tiktok">
            <Input
              prefix="Rp"
              placeholder="Budget Tiktok Ads"
              onChange={(e) => {
                form.setFieldsValue({
                  budget_tiktok: parseInt(e.target.value.replace(/\D/g, '') || 0),
                })
              }}
            />
          </Form.Item>

          <Form.Item label="Budget DM Ads" name="budget_dm">
            <Input
              prefix="Rp"
              placeholder="Budget DM Ads"
              onChange={(e) => {
                form.setFieldsValue({
                  budget_dm: parseInt(e.target.value.replace(/\D/g, '') || 0),
                })
              }}
            />
          </Form.Item>

          <Form.Item label="Budget Messenger Ads" name="budget_messenger">
            <Input
              prefix="Rp"
              placeholder="Budget DM Ads"
              onChange={(e) => {
                form.setFieldsValue({
                  budget_messenger: parseInt(e.target.value.replace(/\D/g, '') || 0),
                })
              }}
            />
          </Form.Item>

          <Form.Item label="Budget Ads Tambahan" name="additional_budget">
            <Input
              prefix="Rp"
              placeholder="Budget Ads Tambahan"
              onChange={(e) => {
                form.setFieldsValue({
                  additional_budget: parseInt(e.target.value.replace(/\D/g, '') || 0),
                })
              }}
            />
          </Form.Item>

          <Form.Item label="Omzet di luar Flow" name="additional_omzet">
            <Input
              prefix="Rp"
              placeholder="Omzet di luar Flow"
              onChange={(e) => {
                form.setFieldsValue({
                  additional_omzet: parseInt(e.target.value.replace(/\D/g, '') || 0),
                })
              }}
            />
          </Form.Item>

          <Form.Item label="Deal" name="additional_deals">
            <Input
              placeholder="Deal"
              onChange={(e) => {
                form.setFieldsValue({
                  additional_deals: parseInt(e.target.value.replace(/\D/g, '') || 0),
                })
              }}
            />
          </Form.Item>
        </div>

        <Form.Item>
          <Button htmlType="submit" loading={loading} className="text-white bg-[#013a63] border-0">
            Simpan
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default DetailBudgetDaily
