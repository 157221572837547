import { Button, Form, Input, Modal } from 'antd'
import React from 'react'
import { ChromePicker } from 'react-color'
import Swal from 'sweetalert2'
import ColorVariantService from '../../../../services/ColorVariantService'

const AddColorVariantModal = (props) => {
  const { id: fiberVariantId, isModalOpen, setIsModalOpen, getData } = props

  const [form] = Form.useForm()

  const onSubmit = async (values) => {
    const data = {
      fiber_variant_id: parseInt(fiberVariantId),
      name: values.name,
      hex_code: values.hex_code?.hex,
    }

    if (values.hex_code === undefined) {
      Swal.fire({
        icon: 'warning',
        text: 'Silahkan geser pointer picker warna terlebih dahulu',
      })
    } else {
      const response = await ColorVariantService.save(data, fiberVariantId)
      try {
        if (response.status === 200) {
          Swal.fire({
            icon: 'success',
            title: 'Berhasil',
            text: 'Berhasil menambahkan warna variasi',
          })
        }
        form.resetFields()
        getData()
        setIsModalOpen(false)
      } catch (error) {
        console.log(error)
      }
    }
  }

  const handleClose = () => {
    form.resetFields()
    setIsModalOpen(false)
  }

  return (
    <>
      <Modal
        forceRender
        title="Tambah Warna Variasi"
        footer={false}
        open={isModalOpen}
        onCancel={handleClose}
      >
        <Form form={form} onFinish={onSubmit} layout="vertical">
          <Form.Item
            label="Warna"
            name="name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input className="rounded-md h-10" placeholder="Isi nama warna" />
          </Form.Item>

          <Form.Item label="Hex Code" valuePropName="color" name="hex_code">
            <ChromePicker />
          </Form.Item>

          <Form.Item>
            <div className="flex justify-end gap-3">
              <Button className="bg-rose-500 text-white rounded-lg" onClick={() => handleClose()}>
                Batalkan
              </Button>
              <Button htmlType="submit" className="bg-cyan-500 text-white rounded-lg">
                Simpan
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default AddColorVariantModal
