/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Input, Space, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import Swal from 'sweetalert2'
import BButton from '../../../components/BButton'
import CustomBadge from '../../../components/CustomBadge'
import CustomPagination from '../../../components/CustomPagination'
import LogoService from '../../../services/LogoService'
import AddLogo from './Modal/AddLogo'
import DetailLogo from './Modal/DetailLogo'
import EditLogo from './Modal/EditLogo'

const Logo = () => {
  const navigate = useNavigate()
  const { id } = useParams()

  const [isModalAddOpen, setIsModalAddOpen] = useState(false)
  const [isModalDetailOpen, setIsModalDetailOpen] = useState(false)
  const [isModalEditOpen, setIsModalEditOpen] = useState(false)
  const [childData, setChildData] = useState({})
  const [data, setData] = useState([])
  const [total, setTotal] = useState(0)
  const [params, setParams] = useState({
    page: 1,
    per_page: 10,
    sort: 'id',
    order: 'asc',
    filter: '',
  })

  useEffect(() => {
    setStateParams()
  }, [])

  useEffect(() => {
    getData()
  }, [params])

  const getData = async () => {
    const response = await LogoService.getDatatable(params, id)
    try {
      if (response.status === 200) {
        setData(response.data)
        setTotal(response.meta.total)
        setQueryString()
      }
    } catch (error) {
      console.log(error)
    }
  }

  const onSearch = (filter) => setParams({ ...params, filter })

  const onPageChange = (page, pageSize) => {
    const per_page = pageSize
    setParams({ ...params, page, per_page })
  }

  const onTableChange = (_pagination, _filters, sorter) => {
    let { columnKey: sort, order } = sorter
    sort = order ? sort : 'id' // jika order undefined default sort adalah column `id` dan order by `id` desc
    order = order === 'ascend' ? 'asc' : 'desc'
    setParams({ ...params, sort, order })
  }

  const setStateParams = () => {
    const qs = new URLSearchParams(window.location.search)
    setParams({
      page: qs.get('page') || params.page,
      per_page: qs.get('per_page') || params.per_page,
      sort: qs.get('sort') || params.sort,
      order: qs.get('order') || params.order,
      filter: qs.get('filter') || params.filter,
    })
  }

  const setQueryString = () => {
    const newParams = {}

    Object.keys(params).forEach((key) => {
      if (params[key]) {
        newParams[key] = params[key]
      }
    })

    const qs = new URLSearchParams(newParams)

    navigate({
      pathname: window.location.pathname,
      search: '?' + qs,
    })
  }

  const showModal = () => {
    setIsModalAddOpen(true)
  }

  const showModalEdit = (data) => {
    setChildData(data)
    setIsModalEditOpen(true)
  }

  const showModalDetail = (data) => {
    setChildData(data)
    setIsModalDetailOpen(true)
  }

  const handleCancel = () => {
    setIsModalAddOpen(false)
    setIsModalEditOpen(false)
    setIsModalDetailOpen(false)
    getData()
  }

  const deleteLogo = (logoId) => {
    Swal.fire({
      title: 'Do you want delete this designs?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await LogoService.remove(id, logoId)
        if (response.status === 200) {
          Swal.fire('Berhasil!', '', 'success')
        }
        getData()
      }
    })
  }

  const columns = [
    {
      title: 'Nomor',
      dataIndex: 'no',
      key: 'no',
      render: (number) => <li className="list-none ml-1">{number}</li>,
    },
    {
      title: 'Logo',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button
            className="text-yellow-600 border-yellow-600 rounded-lg font-medium"
            onClick={() => showModalEdit(record)}
          >
            <i className="fa-regular fa-pen-to-square mr-2" /> Ubah
          </Button>

          <Button
            className="text-cyan-600 border-cyan-600 rounded-lg font-medium"
            onClick={() => showModalDetail(record)}
          >
            <i className="fa-solid fa-circle-info mr-2" /> Detail
          </Button>

          <Button
            className="text-rose-600 border-rose-600 rounded-lg font-medium"
            onClick={() => deleteLogo(record.id)}
          >
            <i className="fa-regular fa-trash-can mr-2" /> Delete
          </Button>
        </Space>
      ),
    },
  ]

  return (
    <div className="bg-white shadow-white-500/40 shadow-sm rounded-2xl h-fit w-full md:overflow-visible pt-4 md:pt-4">
      <div className="section-product px-2 md:px-4">
        <CustomBadge title="Design Produk" titleParent="Produk" />

        <h2 className="text-xl text-gray-700 font-medium mt-10">Tabel Design Produk</h2>

        <div className="flex justify-between items-center mt-8">
          <div className="search-user">
            <Input.Search
              className="md:w-80"
              placeholder="Search"
              allowClear
              size="medium"
              onSearch={onSearch}
            />
          </div>

          <BButton onClick={showModal}>Tambah</BButton>
        </div>
      </div>

      <div className="table-color relative w-full mt-5">
        <Table
          rowKey="id"
          size="large"
          onChange={onTableChange}
          columns={columns}
          dataSource={data}
          pagination={false}
          scroll={{ x: true }}
          tableLayout="auto"
        />
      </div>

      <div className="section-pagination px-2 py-8 md:p-4">
        <CustomPagination {...params} onPageChange={onPageChange} total={total} />
      </div>

      <AddLogo
        id={id}
        isModalAddOpen={isModalAddOpen}
        handleCancel={handleCancel}
        setIsModalAddOpen={setIsModalAddOpen}
        getData={getData}
      />

      <EditLogo
        id={id}
        childData={childData}
        isModalEditOpen={isModalEditOpen}
        handleCancel={handleCancel}
        setIsModalEditOpen={setIsModalEditOpen}
        getData={getData}
      />

      <DetailLogo
        childData={childData}
        isModalDetailOpen={isModalDetailOpen}
        handleCancel={handleCancel}
        setIsModalDetailOpen={setIsModalDetailOpen}
      />
    </div>
  )
}

export default Logo
