import { request } from '../config'

// versi function
function CategoryService() {
  // Get Data for Datatable
  const getDatatable = async (params) => {
    const response = await request.get(`/categories/pagination`, { params })
    return response.data
  }

  const list = async () => {
    const response = await request.get('/categories')

    return response.data
  }

  // Show detail
  const findById = async (categories_id) => {
    const response = await request.get(`/categories/${categories_id}`)

    return response.data
  }

  // insert data
  const save = async (data) => {
    const response = await request.post(`/categories`, data)

    return response
  }

  // update Data
  const update = async (categories_id, data) => {
    const response = await request.put(`/categories/${categories_id}`, data)

    return response.data
  }

  // remove data
  const remove = async (categories_id) => {
    const response = await request.delete(`/categories/${categories_id}`)

    return response.data
  }

  return {
    getDatatable,
    list,
    findById,
    save,
    update,
    remove,
  }
}

export default CategoryService()
