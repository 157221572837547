// debounceHelper.js
const debounce = (func, delay) => {
  let timer
  return function (...args) {
    clearTimeout(timer)
    timer = setTimeout(() => func(...args), delay)
  }
}

export default debounce
