import { Avatar, Popover, Space } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import Profile from '../../assets/img/profile.avif'

const TopNavbar = ({ trigger, setTrigger }) => {
  const navigate = useNavigate()
  const { user } = useSelector((state) => state.auth)

  return (
    <nav className="relative flex flex-wrap items-center justify-between px-0 py-5 mx-1 transition-all ease-in shadow-none duration-250 rounded-2xl md:flex-nowrap md:justify-start">
      <div className="bg-transparent bg-blend-color-burn rounded-xl flex justify-between gap-2 md:justify-between items-center w-full px-4 py-4 mx-auto flex-wrap-inherit">
        <h6
          onClick={() => setTrigger(!trigger)}
          className="mb-0 font-bold text-white capitalize cursor-pointer"
        >
          <i className={`fa-solid ${trigger ? `fa-bars-staggered` : `fa-bars`} text-lg`} />
        </h6>
        <Popover
          className="antialiased"
          placement="bottom"
          content={
            <>
              <Space className="cursor-pointer">
                <i className="text-xl fa-solid fa-user-tie mr-2" />
                <h2 className="text-base text-slate-700">Profil Saya</h2>
              </Space>
              <br />
              <br />
              <Space
                className="cursor-pointer"
                onClick={() => {
                  navigate('/logout')
                }}
              >
                <i className="text-xl fa-solid fa-arrow-right-from-bracket mr-1" />
                <h2 className="text-base text-slate-700">Logout</h2>
              </Space>
            </>
          }
          title={<h2 className="text-lg text-slate-700">Welcome</h2>}
          trigger="click"
        >
          <Space className="cursor-pointer px-2 py-1">
            <h4 className="text-sm text-gray-200 font-medium">Hi, {user.name}</h4>
            <Avatar
              // className="bg-green-500"
              size={30}
              src={Profile}
              // icon={<i className="text-2xl text-slate-900 fa-solid fa-user-secret" />}
            />
          </Space>
        </Popover>
      </div>
    </nav>
  )
}

export default TopNavbar
