import { request } from '../config'

// versi function
function ImageService() {
  const uploadImage = async (image) => {
    const response = await request.post(`/upload/test/upload`, image, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return response
  }

  const uploadAddonsImage = async (image) => {
    const response = await request.post(`/upload/addons/image`, image, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return response
  }

  const paymentProof = async (image) => {
    const response = await request.post(`/upload/orders/payments`, image, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return response
  }

  const scanBarcode = async (data) => {
    const response = await request.post(`/flow/scan`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return response
  }

  return {
    uploadImage,
    uploadAddonsImage,
    paymentProof,
    scanBarcode,
  }
}

export default ImageService()
