import { ConfigProvider } from 'antd'
import locale from 'antd/locale/id_ID'
import moment from 'moment'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRoutes } from 'react-router'
import { BrowserRouter } from 'react-router-dom'
import Swal from 'sweetalert2'
import ability from '../ability/ability'
import store from '../store'
import { routeList } from './routeList'
import { API_URL } from '../config'
import { io } from 'socket.io-client'
import { disconnectSocket, loadSocket } from '../store/Socket/action'
import ReceiveBroadcast from '../ReceiveBroadcast'

const RouteRender = () => {
  ability.update(store.getState().auth.permissions)
  const dispatch = useDispatch()

  const { token, permissions, expires_at, user } = useSelector((state) => state.auth)

  const checkExpiredToken = (expires_at) => {
    let x = moment()
    let y = moment(expires_at)
    if (x.isAfter(y)) {
      localStorage.clear()
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Sesi telah habis, silahkan login kembali!',
      })
    }
    return x.isAfter(y)
  }

  const expired = expires_at ? checkExpiredToken(expires_at) : false
  const isLoggedIn = token !== null && permissions.length > 0 && !expired ? true : false

  useEffect(() => {
    window.addEventListener('load', scrollToTop)
  }, [])

  // buatkan scoket on connected dan on disconnect setelah component di mount
  useEffect(() => {
    const socket = io(API_URL)
    socket.on('connect', () => {
      // untuk set socket supaya bisa diakses di manapun
      // trigger ketika connect ke server
      socket.emit('user_connected', user, (response) => {
        console.log(response)
        dispatch(loadSocket(socket))
      })
    })

    socket.on('disconnect', () => {
      dispatch(disconnectSocket())
    })

    return () => {
      socket.off('connect')
      socket.off('disconnect')
    }
  }, [dispatch, user])

  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Optional: Use smooth scrolling behavior
    })
  }

  const element = useRoutes(routeList(isLoggedIn))

  return <React.Fragment>{element}</React.Fragment>
}

export default function RouteComponent() {
  return (
    <ConfigProvider locale={locale}>
      <BrowserRouter>
        <ReceiveBroadcast />
        <RouteRender />
      </BrowserRouter>
    </ConfigProvider>
  )
}
