import { Button } from 'antd'
import React from 'react'

const BButton = ({ children, onClick }) => {
  return (
    <Button className="border-0 text-white bg-green-600" onClick={onClick}>
      <i className="fa-regular fa-plus font-semibold mr-2" /> {children}
    </Button>
  )
}

export default BButton
