/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Form, Input, Modal } from 'antd'
import React, { useEffect } from 'react'
import Swal from 'sweetalert2'
import MaterialVariantService from '../../../../services/MaterialVariantService'

const EditMaterialVariant = (props) => {
  const {
    id: productId,
    isModalEditOpen,
    handleCancel,
    setIsModalEditOpen,
    getData,
    childData,
  } = props

  const [form] = Form.useForm()

  useEffect(() => {
    form.setFieldsValue({
      ...childData,
    })
  }, [childData])

  const onSubmit = async (values) => {
    const data = {
      product_id: parseInt(productId),
      name: values.name,
    }

    const response = await MaterialVariantService.update(data, productId, childData.id)
    try {
      if (response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Berhasil',
          text: 'Berhasil update bahan variasi',
        })
      }
      getData()
      setIsModalEditOpen(false)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Modal
      forceRender
      title="Edit Bahan Variasi"
      footer={false}
      open={isModalEditOpen}
      onCancel={handleCancel}
    >
      <Form form={form} onFinish={onSubmit} layout="vertical">
        <Form.Item label="Bahan" name="name">
          <Input className="rounded-md h-10" placeholder="Isi nama bahan" />
        </Form.Item>

        <Form.Item>
          <div className="flex justify-end gap-3">
            <Button className="bg-rose-500 text-white rounded-lg" onClick={handleCancel}>
              Batalkan
            </Button>
            <Button htmlType="submit" className="bg-cyan-500 text-white rounded-lg">
              Simpan
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default EditMaterialVariant
