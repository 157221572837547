import { request } from '../config'

// versi function
function ReportService() {
  // Show List
  const downloadDataFinance = async (params) => {
    const response = await request.get(`/reports/finance`, { params })
    return response.data
  }

  const getPeriodeOptions = async () => {
    const response = await request.get('/reports/budget-daily/period-options')
    return response.data
  }

  const getDailyBudgetReport = async (params) => {
    const response = await request.get('/reports/budget-daily', { params })
    return response.data
  }

  const getDetailBudgetDaily = async (date, user_id) => {
    const response = await request.get('/reports/budget-daily/detail', {
      params: { date, user_id },
    })
    return response.data
  }

  const updateBudgetDailyPerUser = async (data) => {
    const response = await request.put('/reports/budget-daily/update', data)
    return response.data
  }

  const downloadDataProgress = async (params) => {
    const response = await request.get(`/reports/progress`, { params })
    return response.data
  }

  const columnOptionsProgress = async () => {
    const response = await request.get(`/reports/progress/column-options`)
    return response.data
  }

  const getRatioBudgetReport = async (params) => {
    const response = await request.get('/reports/budget-ratio', { params })
    return response.data
  }

  const exportBudgetRatioReport = async (params) => {
    const response = await request.get('/reports/budget-ratio/export', { params })
    return response.data
  }

  const exportBudgetDailyReport = async (params) => {
    const response = await request.get('/reports/budget-daily/export', { params })
    return response.data
  }

  const getDataOmzetDaily = async (params) => {
    const response = await request.get('/reports/omzet-daily', { params })
    return response.data
  }

  return {
    downloadDataFinance,
    columnOptionsProgress,
    downloadDataProgress,
    getPeriodeOptions,
    getDailyBudgetReport,
    getDetailBudgetDaily,
    updateBudgetDailyPerUser,
    getRatioBudgetReport,
    exportBudgetRatioReport,
    exportBudgetDailyReport,
    getDataOmzetDaily,
  }
}

export default ReportService()
