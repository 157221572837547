/* eslint-disable react-hooks/exhaustive-deps */
import { Col, QRCode, Row } from 'antd'
// import html2canvas from 'html2canvas'
import moment from 'moment'
import { useReactToPrint } from 'react-to-print'
// import QRCode from 'qrcode.react'
import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router'
import alertIcon from '../../../../assets/img/alert-icon.png'
import logoBarcode from '../../../../assets/img/logo-emirate-veritcal.png'
import logo from '../../../../assets/img/logo-emirate.png'
import OrderService from '../../../../services/OrderService'

import { UNIT_AVENGERS, UNIT_CAR_MAT, UNIT_KAIZEN, UNIT_SEAT_COVER } from '../../../../constants'
import './PrintLabel.css'
import { defaultState } from './defaultState'

const PrintLabel = () => {
  const { id } = useParams()
  const labelRef = useRef()
  const [data, setData] = useState(defaultState)
  const [order, setOrder] = useState(defaultState.order)
  const [shipment, setShipment] = useState(defaultState.order.shipment)

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    const response = await OrderService.getDataByItem(id)

    const resData = response.data
    setData(resData)
    setOrder(resData.order)
    setShipment(resData.order.shipment)

    getCanvas()
  }

  const renderText = (item) => {
    let vehicle = item.vehicle.vehicle_model.name
    let manufacturer = item.vehicle.vehicle_model.manufacturer.name
    let productName = item.product.name
    let productRow = item.variant.row
    let year = item.year
    let colorName = item.color.name

    return [productName, productRow, colorName, ':', manufacturer, vehicle, year]
      .map((e) => e)
      .join(' ')
  }

  const renderDetailPesanan = () => {
    let items = order?.items

    return items.map((item, key) => {
      console.log(item)
      return (
        <tr key={key}>
          <td className="px-2" style={{ background: '#ddd' }}>
            <p>{renderText(item)}</p>
          </td>
          <td
            style={{
              background: '#ddd',
              textAlign: 'center',
            }}
          >
            {item.qty}
          </td>
          <td className="px-2" style={{ background: '#ddd' }}>
            {item.design ? `Desain: ${item.design.name}` : ''}
            <br />
            {item.note}
          </td>
        </tr>
      )
    })
  }

  const getCanvas = async () => {
    setTimeout(() => {
      handlePrint()
      // const content = document.getElementById('print-label-component')
      // content.setAttribute('style', 'margin: 1000px')
      // const mywindow = window.open('', 'Print')
      // console.log(content)
      // html2canvas(content).then((canvas) => {
      //   const border = document.createElement('div')
      //   border.setAttribute('id', 'border')
      //   mywindow.document.body.appendChild(border)
      //   mywindow.document.getElementById('border').appendChild(canvas)
      //   mywindow.print()
      //   window.close()
      // })
    }, 2000)
  }

  const handlePrint = useReactToPrint({
    content: () => labelRef.current,
    documentTitle: 'Print Label',
  })

  const getBackground = (unitId) => {
    switch (unitId) {
      case UNIT_SEAT_COVER:
        return '#120202'
      case UNIT_CAR_MAT:
        return '#20409a'
      case UNIT_KAIZEN:
        return '#F7418F'
      case UNIT_AVENGERS:
        return '#964B00'
      default:
        return '#120202'
    }
  }

  return (
    <div className="container m-5">
      <div ref={labelRef} className="px-2">
        <Row>
          <Col span="24">
            <br />
            <Row>
              <Col span="12" className="text-left">
                {/* <div className="w-24 h-24 bg-[#D2A54E] flex items-center justify-center"> */}
                <img className="text-left object-contain w-40" src={logo} alt="" />
                {/* </div> */}
              </Col>
              <Col span="12" className="text-xs text-right" style={{ padding: '10px' }}>
                {moment(data?.order.order_date).format('DD-MM-YYYY')} <br />
                <span style={{ fontWeight: 'bolder' }}>
                  {[shipment?.courier_name.toUpperCase(), shipment?.courier_service]
                    .filter((e) => e)
                    .join(' - ')}{' '}
                  - {data?.awb} |{' '}
                  {parseInt(order.shipping_cost).toLocaleString('id', {
                    style: 'currency',
                    currency: 'IDR',
                    minimumFractionDigits: 0,
                    // maximumFractionDigits: 0
                  })}
                </span>
                <br />
                {`${data?.order.order_number} ${data?.order.dealmaker.name}`}
                <br />
              </Col>
            </Row>
            <Row className="my-3">
              <Col span="11">
                <p style={{ fontSize: 12 }}>
                  <strong>Kepada</strong>
                  <br />
                  <strong>{shipment.name}</strong>
                  <br />
                  {shipment.address}, {shipment.subdistrict.name}, {shipment.district.type}{' '}
                  {shipment.district.name}, {shipment.province.name}, {shipment.postal_code}
                  <br />
                  HP : {shipment.phone}
                </p>
              </Col>
              <Col span="2"></Col>
              <Col span="11">
                <p style={{ fontSize: 12 }}>
                  <strong>Pengirim</strong> <br />
                  <strong>{shipment.shipper.name}</strong> <br />
                  {shipment.shipper.address}, {shipment.shipper.subdistrict.name},{' '}
                  {shipment.shipper.district.type} {shipment.shipper.district.name},{' '}
                  {shipment.shipper.province.name}
                  <br />
                  HP : {shipment.shipper.phone}
                </p>
              </Col>
            </Row>

            <div>
              <table id="table-order-items" style={{ width: '100%' }}>
                <thead>
                  <tr
                    style={{
                      background: getBackground(data.order.unit?.id),
                      width: '100%',
                    }}
                  >
                    <th style={{ width: '45%' }}>Pesanan</th>
                    <th style={{ width: '5%' }}>Qty</th>
                    <th style={{ width: '45%' }}>Catatan</th>
                  </tr>
                </thead>
                <tbody>
                  {renderDetailPesanan()}
                  <tr>
                    <td style={{ background: '#ddd', padding: '0.5rem' }}>
                      <strong>Jumlah Pesanan</strong>
                    </td>
                    <td
                      style={{
                        textAlign: 'center',
                        background: '#ddd',
                        color: '#E62131',
                        fontWeight: 'bolder',
                      }}
                    >
                      {order.items.map((a) => a.qty).reduce((a, b) => a + b)}
                    </td>
                    <td style={{ background: '#ddd', padding: '0.5rem' }}></td>
                  </tr>
                </tbody>
              </table>

              <Row>
                <Col
                  span="22"
                  style={{
                    zIndex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                  }}
                >
                  <div className="grid md:grid-cols-2">
                    <div>
                      <span>
                        <strong>Status Pembayaran:</strong> {order.payment_status.name}
                      </span>
                      <br />
                      <span className="flex gap-1">
                        <strong>Maksimal Tanggal Pengiriman:</strong>{' '}
                        {order?.max_shipping_date ? (
                          <p className="text-red-500 font-semibold leading-0">
                            {moment(order.max_shipping_date).format('LLLL')} WIB
                          </p>
                        ) : (
                          '-'
                        )}
                      </span>
                    </div>

                    <div>
                      <strong>Keterangan:</strong> {order?.description || '-'}
                      <br />
                      <strong>Bonus:</strong> {order?.bonus?.join(', ') || '-'}
                    </div>
                  </div>
                  <br />
                  <Row>
                    <Col span="3">
                      <p>
                        <img src={alertIcon} alt="" style={{ maxHeight: '90px' }} />
                      </p>
                    </Col>
                    <Col span="21">
                      <p
                        style={{
                          whiteSpace: 'nowrap',
                          zIndex: '100',
                          fontWeight: 'bold',
                          color: '#FF0A0A',
                          fontSize: '14px',
                          paddingLeft: '10px',
                        }}
                      >
                        PENTING!!!
                        <br />
                        HARAP VIDEOKAN PRODUK SAAT MEMBUKA PAKET UNTUK KLAIM
                        <br />
                        APABILA ADA KEKURANGAN. TERIMA KASIH.
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <br />
        <div className="w-36 h-4 bg-[#D2A54E] mt-1 mb-2"></div>
        {/* <hr style={{ marginTop: 0 }} /> */}

        <table id="table-item-production" style={{ width: '100%', height: '85%', fontSize: '9px' }}>
          <div className="grid md:grid-cols-2 gap-1 section-label-order">
            <tbody>
              <div className="grid md:grid-cols-2 gap-1">
                <div>
                  <tr className="border">
                    <td>Platform</td>
                    <td>{order.platform}</td>
                  </tr>
                  <tr className="border-x border-b">
                    <td>Flow ID</td>
                    <td className="font-bold text-red-500">{data.item_id}</td>
                  </tr>
                  <tr className="border">
                    <td>Nomor Order</td>
                    <td className="font-bold text-red-500">{order.order_number}</td>
                  </tr>
                  <tr>
                    <td>PIC Dealmaker</td>
                    <td>{order.dealmaker.name}</td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        width: '95px',
                      }}
                    >
                      Produk
                    </td>
                    <td>{[data.product.name, data.variant.row].map((e) => e).join(' ')}</td>
                  </tr>
                  <tr>
                    <td>Tipe / Tahun Mobil</td>
                    <td>
                      {[
                        data.vehicle.vehicle_model.manufacturer.name,
                        data.vehicle.vehicle_model.name,
                        data.year,
                      ].join(' ')}
                    </td>
                  </tr>
                  <tr>
                    <td>Pemesan</td>
                    <td>{order.customer.name}</td>
                  </tr>
                  <tr>
                    <td>Penerima</td>
                    <td>{order.shipment.name}</td>
                  </tr>
                  <tr>
                    <td>Lubang Pengunci</td>
                    <td>{data.lock_hole || '-'}</td>
                  </tr>
                  <tr>
                    <td>APAR</td>
                    <td>{data.fire_extinguisher || '-'}</td>
                  </tr>
                  <tr>
                    <td>Design</td>
                    <td
                      style={{
                        width: '100px',
                      }}
                    >
                      {data.design?.name || '-'}
                    </td>
                  </tr>
                  <tr>
                    <td>Logo</td>
                    <td>{data.logo || '-'}</td>
                  </tr>
                </div>

                <div>
                  <tr>
                    <td>Material Dasar</td>
                    <td>{data.material.name}</td>
                  </tr>
                  <tr>
                    <td>Serat Dasar</td>
                    <td>{data.fiber?.name}</td>
                  </tr>

                  <tr>
                    <td>Warna Dasar</td>
                    <td>{data.color.name}</td>
                  </tr>
                  <tr>
                    <td>Material Variasi 1</td>
                    <td>{data.material_variant_1?.name || '-'}</td>
                  </tr>
                  <tr>
                    <td>Serat Variasi 1</td>
                    <td>{data.fiber_variant_1?.name || '-'}</td>
                  </tr>
                  <tr>
                    <td>Warna Variasi 1</td>
                    <td>{data.color_variant_1?.name || '-'}</td>
                  </tr>
                  <tr>
                    <td>Material Variasi 2</td>
                    <td>{data.material_variant_2?.name || '-'}</td>
                  </tr>
                  <tr>
                    <td>Serat Variasi 2</td>
                    <td>{data.fiber_variant_2?.name || '-'}</td>
                  </tr>
                  <tr>
                    <td>Warna Variasi 2</td>
                    <td>{data.color_variant_2?.name || '-'}</td>
                  </tr>
                  <tr>
                    <td>Jenis Transmisi</td>
                    <td>{data.transmission_type || '-'}</td>
                  </tr>
                  <tr>
                    <td>Tambahan</td>
                    <td
                      style={{
                        width: '100px',
                      }}
                    >
                      {data.addons?.map((addon) => (
                        <>
                          {addon.name}
                          <br />
                        </>
                      ))}
                    </td>
                  </tr>
                  <tr>
                    <td>Status Pembayaran</td>
                    <td>{order.payment_status.name}</td>
                  </tr>
                </div>
              </div>

              <div className="mt-2">
                <tr>
                  <td
                    style={{
                      width: '25%',
                    }}
                  >
                    Catatan PIC Dealmaker
                  </td>
                  <td
                    style={{
                      width: '100%',
                    }}
                    className="font-bold text-red-500"
                  >
                    {data.note || '-'}
                  </td>
                </tr>

                <tr>
                  <td>Catatan Resep</td>
                  <td>{data.production_note}</td>
                </tr>
              </div>
            </tbody>

            <div className="flex items-center justify-center gap-3 mt-2">
              <QRCode type="svg" icon={logoBarcode} iconSize={35} size={200} value={id} />
              <p className="font-semibold">{id}</p>
            </div>
          </div>
        </table>

        <div className="mt-52 p-2">
          <div className="grid grid-cols-2 gap-2">
            {data.images?.map((item) => (
              <img
                className="aspect-16/9 mt-5"
                src={item.image}
                alt=""
                width={'100%'}
                style={{
                  maxHeight: '500px',
                }}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PrintLabel
