import { request } from '../config'

function AdvertiserService() {
  const getPeriodeOptions = async () => {
    const response = await request.get('/reports/advertiser/period-options')
    return response.data
  }

  const getAdvertiserReport = async (params) => {
    const response = await request.get('/reports/advertiser', { params })
    return response.data
  }

  const exportAdvertiserReport = async (params) => {
    const response = await request.get('/reports/advertiser/export', { params })
    return response.data
  }

  return {
    getPeriodeOptions,
    getAdvertiserReport,
    exportAdvertiserReport,
  }
}

export default AdvertiserService()
