import { Button, DatePicker, Form, Input, Select, Switch } from 'antd'
import React, { useEffect, useState } from 'react'
import CustomBadge from '../../../components/CustomBadge'
import OrderService from '../../../services/OrderService'
import ReportService from '../../../services/ReportService'

const Finance = () => {
  const [form] = Form.useForm()

  const [loading, setLoading] = useState(false)
  const [unitOptions, setUnitOtions] = useState([])
  const [filterColumn, setFilterColumn] = useState([])
  const [filterPayment, setFilterPayment] = useState([])
  const [filterStatusProduction, setFilterStatusProduction] = useState([])
  const [params, setParams] = useState({
    filter_column: '',
    start_date: '',
    end_date: '',
    filter_text: '',
    payment_status_id: null,
    status_type_id: null,
    with_deleted: false,
    unit_id: null,
  })

  useEffect(() => {
    getDataFilterByColumn()
    getDataFilterByPayment()
    getDataFilterByStatusProduction()
    getUnitOptions()
  }, [])

  const getDataFilterByPayment = async () => {
    const response = await OrderService.getOrderFilterByPayment()
    try {
      setFilterPayment(
        response.data.map((filter) => ({
          value: filter.value,
          label: filter.name,
        }))
      )
    } catch (error) {
      console.log(error)
    }
  }

  const getDataFilterByStatusProduction = async () => {
    const response = await OrderService.getOrderFilterByStatusProduction()
    try {
      setFilterStatusProduction(
        response.data.map((filter) => ({
          value: filter.value,
          label: filter.name,
        }))
      )
    } catch (error) {
      console.log(error)
    }
  }

  const getDataFilterByColumn = async () => {
    const response = await OrderService.getOrderFilterByColumn()
    try {
      setFilterColumn(
        response.data.map((filter) => ({
          value: filter.value,
          label: filter.name,
        }))
      )
    } catch (error) {
      console.log(error)
    }
  }

  const dateFilterChange = (_, dateStrings) => {
    setParams({
      ...params,
      start_date: dateStrings && dateStrings[0],
      end_date: dateStrings && dateStrings[1],
    })
  }

  const filterPaymentChange = async (value) => {
    setParams({ ...params, payment_status_id: value })
  }

  const filterStatusProductionChange = async (value) => {
    setParams({ ...params, status_type_id: value })
  }

  const filterColumnChange = async (value) => {
    setParams({ ...params, filter_column: value })
  }

  const handleFilterKeyword = async (e) => {
    setParams({ ...params, filter_text: e.target.value })
  }

  const handleFilterCheckbox = async (value) => {
    setParams({ ...params, with_deleted: value })
  }

  const handleChangeUnit = async (value) => {
    setParams({ ...params, unit_id: value })
  }

  const getUnitOptions = async () => {
    const response = await OrderService.getOrderFilterByUnit()
    try {
      setUnitOtions(
        response.data.map((filter) => ({
          value: filter.id,
          label: filter.name,
        }))
      )
    } catch (error) {
      console.log(error)
    }
  }

  const exportData = async () => {
    try {
      setLoading(true)
      const response = await ReportService.downloadDataFinance(params)
      if (response?.status === 200) {
        window.open(response?.data?.url, '_blank')
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="bg-white shadow-white-500/40 shadow-sm rounded-2xl h-fit w-full md:overflow-visible mt-10 mb-10 pt-4 md:pt-4">
      <div className="section-report-finance antialiased px-2 md:px-4">
        <CustomBadge title="Keuangan" titleParent="Laporan" />

        <Form
          form={form}
          onFinish={exportData}
          layout="vertical"
          className="antialiased mt-8"
          size="large"
        >
          <div className="grid md:grid-cols-3 gap-2">
            <Form.Item>
              <label>Tanggal Order</label>
              <DatePicker.RangePicker
                placeholder={['Dari', 'Sampai']}
                onChange={dateFilterChange}
                className="w-full"
              />
            </Form.Item>

            <Form.Item>
              <label>Pembayaran</label>
              <Select
                placeholder="Semua"
                options={filterPayment}
                onChange={filterPaymentChange}
                allowClear
              />
            </Form.Item>

            <Form.Item>
              <label>Status Produksi</label>
              <Select
                placeholder="Semua"
                options={filterStatusProduction}
                onChange={filterStatusProductionChange}
                allowClear
              />
            </Form.Item>

            <Form.Item name="unit_id">
              <label>Unit</label>
              <Select
                placeholder="Pilih Unit"
                options={unitOptions}
                allowClear
                onChange={handleChangeUnit}
              />
            </Form.Item>

            <Form.Item>
              <label>Cari Berdasarkan</label>
              <Select
                placeholder="Pilih Kolom Pencarian"
                options={filterColumn}
                onChange={filterColumnChange}
                allowClear
              />
            </Form.Item>

            <Form.Item className="mt-[1.4rem]">
              <Input placeholder="keyword" onChange={handleFilterKeyword} />
            </Form.Item>

            <Form.Item label="Termasuk yang dihapus ?" name="with_deleted">
              <Switch
                checkedChildren="Ya"
                unCheckedChildren="Tidak"
                onChange={(v) => handleFilterCheckbox(v)}
              />
            </Form.Item>
          </div>

          <div className="flex justify-end">
            <Form.Item>
              <Button
                onClick={exportData}
                loading={loading}
                className="border-0 bg-cyan-500 text-white font-medium w-fit"
              >
                Export Data
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </div>
  )
}

export default Finance
