import { Table } from 'antd'
import React from 'react'

const TeamTable = (props) => {
  const columns = [
    {
      title: 'Rank',
      dataIndex: 'rank',
      key: 'rank',
    },
    {
      title: 'Nama',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Total Omzet',
      dataIndex: 'total_omzet',
      key: 'total_omzet',
    },
    {
      title: 'Leads',
      dataIndex: 'leads',
      key: 'leads',
    },
    {
      title: 'Deal',
      dataIndex: 'deal',
      key: 'deal',
    },
    {
      title: 'Konversi',
      dataIndex: 'convertion',
      key: 'convertion',
    },
    {
      title: 'Rasio Ads/Omzet',
      dataIndex: 'ratio',
      key: 'ratio',
    },
  ]

  function getHeaderBackgroundColor(type) {
    if (type === 'stp') {
      return 'bg-blue-400'
    }

    if (type === 'cs_great') {
      return 'bg-red-400'
    }

    if (type === 'cs_excellent') {
      return 'bg-red-600'
    }

    if (type === 'emirate') {
      return 'bg-black'
    }

    if (type === 'carmat') {
      return 'bg-green-300'
    }

    if (type === 'cmi') {
      return 'bg-yellow-400'
    }
  }

  return (
    <div className="bg-white mt-3">
      <div
        className={`h-16 ${getHeaderBackgroundColor(props.type)} flex justify-center items-center`}
      >
        <h2 className="text-xl text-white font-semibold">{props.name}</h2>
      </div>

      <h3 className="text-lg my-3 px-3">Level Training</h3>
      <Table columns={columns} />
    </div>
  )
}

export default TeamTable
