import { request } from '../config'

// versi function
function PaymentChannelService() {
  const getDatatable = async (params) => {
    const response = await request.get(`/payment-channels/pagination`, { params })
    return response.data
  }

  const findById = async (id) => {
    const response = await request.get(`/role-permissions/${id}`)
    return response.data
  }

  const getStatusPayment = async () => {
    const response = await request.get(`/payment-statuses`)
    return response.data
  }

  const getPaymentChannel = async () => {
    const response = await request.get(`/payment-channels`)
    return response.data
  }

  const bankList = async () => {
    const response = await request.get(`/payment-channels/bank`)
    return response.data
  }

  const save = async (data) => {
    const response = await request.post(`/payment-channels`, data)
    return response.data
  }

  const update = async (id, data) => {
    const response = await request.put(`/payment-channels/${id}`, data)
    return response.data
  }

  const getBatchPaymentTemplate = async () => {
    const response = await request.get(`/orders/batch/payment`)
    return response.data
  }

  const uploadBatchPayment = async (data) => {
    const response = await request.put(`/orders/batch/payment`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return response.data
  }

  const remove = async (id) => {
    const response = await request.delete(`/payment-channels/${id}`)
    return response.data
  }

  return {
    getDatatable,
    getStatusPayment,
    getPaymentChannel,
    findById,
    bankList,
    save,
    update,
    getBatchPaymentTemplate,
    uploadBatchPayment,
    remove,
  }
}

export default PaymentChannelService()
