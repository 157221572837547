import { request } from '../config'

// versi function
function RegionalService() {
  const province = async () => {
    const response = await request.get(`/provinces`)
    return response.data
  }

  const districtByProvince = async (province_id) => {
    const response = await request.get(`/provinces/${province_id}/districts`)
    return response.data
  }

  const subdistrictByDistrict = async (district_id) => {
    const response = await request.get(`/districts/${district_id}/subdistricts`)
    return response.data
  }

  return {
    province,
    districtByProvince,
    subdistrictByDistrict,
  }
}

export default RegionalService()
