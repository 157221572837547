/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Form, Image, Input, InputNumber, Modal, Upload } from 'antd'
import React, { useEffect } from 'react'
import Swal from 'sweetalert2'
import DesignService from '../../../../services/DesignService'

const EditDesign = (props) => {
  const { id, isModalEditOpen, handleCancel, setIsModalEditOpen, getData, childData } = props
  const [form] = Form.useForm()

  useEffect(() => {
    form.setFieldsValue({
      ...childData,
    })
  }, [childData])

  const normFile = (e) => {
    console.log('Upload event:', e)

    if (Array.isArray(e)) {
      return e
    }

    return e?.fileList
  }

  const onSubmit = async (values) => {
    const data = {
      name: values.name,
      price: values.price,
      product_id: parseInt(id),
    }

    const response = await DesignService.update(id, childData.id, data)
    try {
      if (response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Berhasil',
          text: 'Berhasil update design produk',
        })
      }
      getData()
      setIsModalEditOpen(false)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Modal
      forceRender
      title="Edit Design Produk"
      footer={false}
      open={isModalEditOpen}
      onCancel={handleCancel}
    >
      <Form className="mt-10" form={form} onFinish={onSubmit} layout="vertical">
        <Form.Item
          label="Nama"
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input className="rounded-md h-10" placeholder="Custom" />
        </Form.Item>
        <Form.Item
          label="Harga"
          name="price"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber
            className="rounded-md h-10 w-full"
            defaultValue={0}
            formatter={(value) => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value.replace(/Rp\s?|(,*)/g, '')}
          />
        </Form.Item>

        <div className="grid md:grid-cols-2">
          <Form.Item
            label="Gambar"
            valuePropName="file"
            name="image"
            getValueFromEvent={normFile}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Upload
              accept="image/*"
              listType="picture-card"
              name="file"
              beforeUpload={() => {
                return false
              }}
              maxCount={1}
            >
              <div>
                <i className="text-xl fa-solid fa-upload" />
                <h2 className="text-sm text-gray-800 font-medium">Upload</h2>
              </div>
            </Upload>
          </Form.Item>

          <Form.Item label="Gambar sebelumnya">
            <Image width={200} src={childData.image} />
          </Form.Item>
        </div>

        <Form.Item>
          <div className="flex justify-end gap-3">
            <Button
              className="bg-rose-500 text-white rounded-lg"
              onClick={() => setIsModalEditOpen(false)}
            >
              Batalkan
            </Button>
            <Button htmlType="submit" className="bg-cyan-500 text-white rounded-lg">
              Simpan
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default EditDesign
