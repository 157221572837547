/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Input, Select, Space, Table } from 'antd'
import _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import ReactSelect from 'react-select'
import AsyncSelect from 'react-select/async'
import CustomBadge from '../../../../components/CustomBadge'
import { CUSTOMER_RETAIL, UNIVERSAL } from '../../../../constants'
import debounce from '../../../../helpers/debounce'
import { formatRp } from '../../../../helpers/formatRp'
import ProductService from '../../../../services/ProductService'
import AddAddons from './Modal/AddAddons'

const TableFormOrder = (props) => {
  const {
    form,
    dataSource,
    setDataSource,
    // validateForm,
    customer_type_id,
    resetCourier,
    isModalOpen,
    setIsModalOpen,
  } = props

  const objectRef = useRef(null)

  // const isDisabledField = validateForm === null

  const generateUniqueKey = () => {
    return parseInt(Math.random().toString(36).substr(2, 9), 36) // Generates a random alphanumeric string
  }

  // const [fiber_options${idx}, setFiberOptions] = useState([])
  const [productIdx, setProductIdx] = useState(null)
  const [selectedRow, setSelectedRow] = useState(null)

  const [selectedProductId, setSelectedProductId] = useState(null)
  const [objects, setObjects] = useState([])
  const [productOptions, setProductOptions] = useState([{ value: '', label: 'Pilih Produk' }])

  useEffect(() => {
    const getProducts = async () => {
      const response = await ProductService.getDataProduct()
      const options = response.data.map((product) => ({
        value: product.id,
        label: product.name,
        objects: product?.objects || [],
      }))
      setProductOptions(options)
    }

    getProducts()
  }, [])

  const loadProduct = async (searchCustomer, callback) => {
    if (searchCustomer.length >= 3) {
      const response = await ProductService.searchProduct({
        product_id: selectedProductId,
        filter: searchCustomer,
      })
      let options = []
      for (let product of response.data) {
        const { product_id, product_vehicle_id, size, row } = product
        options.push({
          value: [product_id, product_vehicle_id, size, row].join('|'),
          label: product.name,
        })
      }
      callback(options)
    }
  }

  useEffect(() => {
    const loadDefaultObjects = (selectedProductId) => {
      let objects = []
      if (selectedProductId) {
        objects = productOptions.find((item) => item.value === selectedProductId)?.objects || []
        objects = objects.map((item) => ({
          label: item.name,
          value: [item.product_id, item.product_vehicle_id, item.size, item.row].join('|'),
        }))
      }

      return objects
    }

    if (selectedProductId) {
      setObjects(loadDefaultObjects(selectedProductId))
    }
  }, [selectedProductId])

  const onChangeProduct = async ({ value }) => {
    const [product_id, product_vehicle_id, size, row] = value.split('|')
    const { data } = await ProductService.findByIdAndVehicle(product_id, {
      product_vehicle_id,
      row,
      size,
    })

    const newData = {
      ...data,
      key: generateUniqueKey(),
      qty: 1,
      note: '',
      addons: [],
      images: [],
      price_adjustment: 0,
      price_addons: 0,
      price_design: 0,
      year: '',
      color_options: [],
      color_id: null,
      color_variant_1_id: null,
      color_variant_2_id: null,
      fiber_id: null,
      fiber_variant_1_id: null,
      fiber_variant_2_id: null,
      material_id: null,
      material_variant_1_id: null,
      material_variant_2_id: null,
      design_id: null,
      logo: null,
      lock_hole: null,
      fire_extinguisher: null,
      transmission_type: null,
      // lock_hole_id: null,
      // fire_extinguisher_id: null,
      // logo_id: null,
      // transmission_type_id: null,
    }
    setDataSource([...dataSource, newData])

    // reset pencarian object
    setSelectedProductId(null)

    resetCourier()
  }

  const increaseQuantity = (e, idx) => {
    const dataIdx = dataSource.filter((item, index) => index !== idx)

    if (dataIdx) {
      setDataSource((dataSource) =>
        dataSource.map((item, index) => (index === idx ? { ...item, qty: item.qty + 1 } : item))
      )
      resetCourier()
    } else {
      setDataSource((dataSource) => [...dataSource])
    }
  }

  const decreaseQuantity = (_, record, idx) => {
    const dataIdx = dataSource.some((item, index) => index === idx)

    if (dataIdx) {
      setDataSource((dataSource) =>
        dataSource.map((item, index) => (index === idx ? { ...item, qty: item.qty - 1 } : item))
      )

      if (record.qty <= 1) {
        setDataSource((dataSource) =>
          dataSource.map((item, index) => (index === idx ? { ...item, qty: 1 } : item))
        )
      }

      resetCourier()
    } else {
      setDataSource((dataSource) => [...dataSource])
    }
  }

  const handleDelete = (record, idx) => {
    const newData = dataSource.filter((item, index) => item.key !== record.key)

    resetCourier()
    form.resetFields([
      `product_year${record?.key}`,
      `logo${record?.key}`,
      `transmission${record?.key}`,
      `warna_dasar${record?.key}`,
      `material_dasar${record?.key}`,
      `serat_material${record?.key}`,
      `design${record?.key}`,
      `locking_hole_${record?.key}`,
      `fire_extinguisher_${record?.key}`,
    ])
    setDataSource(newData)
  }

  // PENYESUAIAN HARGA
  const priceAdjustmentChange = (e, idx) => {
    const value = e.target.value.replace(/[^0-9-]/g, '').replace(/(?!^-)-/g, '')

    const dataIdx = dataSource.filter((item, index) => index === idx)

    if (dataIdx) {
      setDataSource((dataSource) =>
        dataSource.map((item, index) =>
          index === idx ? { ...item, price_adjustment: value } : item
        )
      )
    } else {
      setDataSource((dataSource) => [...dataSource])
    }

    // Tidak boleh kosong, dan harus 0
    if (value === '' || value === null) {
      setDataSource((dataSource) =>
        dataSource.map((item, index) =>
          index === idx ? { ...item, price_adjustment: undefined } : item
        )
      )
    }

    if (value === '-') {
      setDataSource((dataSource) =>
        dataSource.map((item, index) =>
          index === idx ? { ...item, price_adjustment: undefined } : item
        )
      )
    }
  }

  const sortByName = (a, b) => {
    let x = a.name.toLowerCase()
    let y = b.name.toLowerCase()
    if (x > y) return 1
    if (x < y) return -1
    return 0
  }

  const showModal = (idx) => {
    setProductIdx(idx)
    setIsModalOpen(true)
  }
  const handleOk = () => {
    setProductIdx(null)
    setIsModalOpen(false)
  }
  const handleCancel = () => {
    setProductIdx(null)
    setIsModalOpen(false)
  }

  const handleYearChange = (value, product) => {
    product.year = value?.toString() || ''
  }

  const handleNoteProductChange = (e, product) => {
    const value = e.target.value
    product.note = value?.toString() || ''
  }

  const handleMaterial = (value, product, idx) => {
    product.material_id = value || null

    product.fiber_options = product.material_options.find(
      (item, index) => item.id === value
    ).fiber_options

    // // Display Value ketika Serat Material hanya ada 1 pilihan
    // product.fiber_options?.map((item, index) =>
    //   index < 1
    //     ? (form.setFieldsValue({
    //         [`serat_material${product.key}`]: item.id,
    //       }),
    //       (product.fiber_id = item.id),
    //       (product.color_options = product.fiber_options.find(
    //         (result, index) => result.id === item.id
    //       ).color_options))
    //     : form.setFieldsValue({
    //         [`serat_material${product.key}`]: null,
    //       })
    // )

    setDataSource((dataSource) => [...dataSource])
  }

  // const defaultValueMaterial = (product) => {
  //   product.material_options.map((item, idx) => (idx < 1 ? (product.material_id = item.id) : null))
  // }

  const handleFiberMaterial = (value, product, idx) => {
    product.fiber_id = value || null

    product.color_options = product.fiber_options.find(
      (item, index) => item.id === value
    ).color_options

    // Display Value ketika Opsi Warna hanya ada 1 pilihan
    // product.color_options?.map((item, index) =>
    //   index < 1
    //     ? form.setFieldsValue({
    //         [`warna_dasar${product.key}`]: item.id,
    //       })
    //     : form.setFieldsValue({
    //         [`warna_dasar${product.key}`]: null,
    //       })
    // )

    setDataSource((dataSource) => [...dataSource])
  }

  const handleColorByFiberMaterial = (value, product) => {
    product.color_id = value || null
  }

  const handleVariantOneMaterial = (value, product, idx) => {
    product.material_variant_1_id = value || null

    product.fiber_one_variant_options = product.material_variant_options.find(
      (item, index) => item.id === value
    ).fiber_variant_options

    // Display Value ketika Serat Material hanya ada 1 pilihan
    // product.fiber_one_variant_options?.map((item, index) =>
    //   index < 1
    //     ? (form.setFieldsValue({
    //         [`serat_material_variant${product.key}`]: item.id,
    //       }),
    //       (product.fiber_variant_1_id = item.id),
    //       (product.color_one_variant_options = product.fiber_one_variant_options.find(
    //         (result, index) => result.id === item.id
    //       ).color_variant_options))
    //     : form.setFieldsValue({
    //         [`serat_material_variant${product.key}`]: null,
    //       })
    // )

    setDataSource((dataSource) => [...dataSource])
  }

  const handleVariantOneFiberMaterial = (value, product, idx) => {
    product.fiber_variant_1_id = value || null

    product.color_one_variant_options = product.fiber_one_variant_options.find(
      (item, index) => item.id === value
    ).color_variant_options

    // Display Value ketika Opsi Warna hanya ada 1 pilihan
    // product.color_one_variant_options?.map((item, index) =>
    //   index < 1
    //     ? form.setFieldsValue({
    //         [`variant_color_1${product.key}`]: item.id,
    //       })
    //     : form.setFieldsValue({
    //         [`variant_color_1${product.key}`]: null,
    //       })
    // )

    setDataSource((dataSource) => [...dataSource])
  }

  const handleVariantTwoMaterial = (value, product, idx) => {
    product.material_variant_2_id = value || null

    product.fiber_two_variant_options = product.material_variant_options.find(
      (item, index) => item.id === value
    ).fiber_variant_options

    // Display Value ketika Serat Material hanya ada 1 pilihan
    // product.fiber_two_variant_options?.map((item, index) =>
    //   index < 1
    //     ? (form.setFieldsValue({
    //         [`serat_material_variant_2_${product.key}`]: item.id,
    //       }),
    //       (product.fiber_variant_2_id = item.id),
    //       (product.color_two_variant_options = product.fiber_two_variant_options.find(
    //         (result, index) => result.id === item.id
    //       ).color_variant_options))
    //     : form.setFieldsValue({
    //         [`serat_material_variant_2_${product.key}`]: null,
    //       })
    // )

    setDataSource((dataSource) => [...dataSource])
  }

  const handleVariantTwoFiberMaterial = (value, product, idx) => {
    product.fiber_variant_2_id = value || null

    product.color_two_variant_options = product.fiber_two_variant_options.find(
      (item, index) => item.id === value
    ).color_variant_options

    // Display Value ketika Opsi Warna hanya ada 1 pilihan
    // product.color_two_variant_options?.map((item, index) =>
    //   index < 1
    //     ? form.setFieldsValue({
    //         [`variant_color_2${product.key}`]: item.id,
    //       })
    //     : form.setFieldsValue({
    //         [`variant_color_2${product.key}`]: null,
    //       })
    // )

    setDataSource((dataSource) => [...dataSource])
  }

  // const handleColorChange = (value, product) => {
  //   product.color_id = value || null
  // }

  const handleColorVariantOneChange = (value, product) => {
    product.color_variant_1_id = value || null
  }

  const handleColorVariantTwoChange = (value, product) => {
    product.color_variant_2_id = value || null
  }

  const handleLockHole = (value, product) => {
    product.lock_hole = value || null
  }

  const handleFireExtinguisher = (value, product) => {
    product.fire_extinguisher = value || null
  }

  const handleLogoChange = (value, product) => {
    product.logo = value || null
  }

  const handleTransmissionChange = (value, product) => {
    product.transmission_type = value || null
  }

  // PENYESUAIAN HARGA
  const handleDesignChange = (value, idx, product) => {
    product.design_id = value || null

    const design = product.design_options.find((e) => e.id === value)

    const dataIdx = dataSource.filter((item, index) => index === idx)

    if (dataIdx) {
      setDataSource((dataSource) =>
        dataSource.map((item, index) =>
          index === idx ? { ...item, price_design: design.price } : item
        )
      )
    } else {
      setDataSource((dataSource) => [...dataSource])
    }

    // Tidak boleh kosong, dan harus 0
    if (value === '' || value === null) {
      setDataSource((dataSource) =>
        dataSource.map((item, index) => (index === idx ? { ...item, price_design: 0 } : item))
      )
    }
  }

  const addonsPrice = (product) => {
    if (product && product.addons) {
      const totalAddonsPrice = product.addons.reduce(
        (accumulator, addon) => accumulator + addon.price,
        0
      )
      product.price_addons = totalAddonsPrice
      return totalAddonsPrice
    }
  }

  const columns = [
    {
      title: 'Produk',
      dataIndex: 'name',
      key: 'name',
      render: (name, record) => {
        const text = [
          record.name,
          record.product_vehicle?.vehicle_model?.manufacturer?.name,
          record.product_vehicle?.vehicle_model?.name,
          record.product_vehicle?.row,
        ]
          .filter((e) => e)
          .join(' ')
        return (
          <h2 className="text-xs w-16 md:w-full md:text-sm font-medium text-gray-800">{text}</h2>
        )
      },
    },
    // {
    //   title: 'Harga (Rp)',
    //   dataIndex: 'price',
    //   key: 'price',
    //   align: 'center',
    //   render: (_, record) => (
    //     <h2>
    //       {record.variant && CUSTOMER_RETAIL === customer_type_id
    //         ? formatRp(record.variant?.price) || 0
    //         : formatRp(record.variant?.agent_price) || 0}
    //     </h2>
    //   ),
    // },
    {
      title: 'Total (Rp)',
      dataIndex: 'total_price',
      key: 'total_price',
      align: 'center',
      render: (total, record) => {
        return (
          <h2 className="font-semibold">
            {record.variant && CUSTOMER_RETAIL === customer_type_id
              ? formatRp(
                  (record.variant.price +
                    addonsPrice(record) +
                    parseInt(record.price_adjustment || 0) +
                    record.price_design) *
                    record.qty
                )
              : formatRp(
                  (record.variant.agent_price ||
                    0 +
                      addonsPrice(record) +
                      parseInt(record.price_adjustment || 0) +
                      record.price_design) * record.qty
                )}
          </h2>
        )
      },
    },
    {
      title: 'Addon',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: '10%',
      render: (_, record, index) => (
        <>
          <Space direction="vertical">
            {record && (
              <>
                <div
                  onClick={() => {
                    setSelectedRow(record)
                    showModal(index)
                  }}
                  className="cursor-pointer flex justify-center items-center border-0 text-white w-7 h-7 bg-blue-500 rounded-lg"
                >
                  <i className="fa-solid fa-puzzle-piece text-white " />
                </div>

                <AddAddons
                  record={selectedRow}
                  handleOk={handleOk}
                  dataSource={dataSource}
                  productIdx={productIdx}
                  isModalOpen={isModalOpen}
                  setIsModalOpen={setIsModalOpen}
                  handleCancel={handleCancel}
                  setDataSource={setDataSource}
                />
              </>
            )}

            {dataSource.length >= 1 ? (
              <div
                className="cursor-pointer flex justify-center items-center border-0 text-white w-7 h-7 bg-rose-500 rounded-lg"
                onClick={() => handleDelete(record, index)}
              >
                <i className="fa-solid fa-trash-can" />
              </div>
            ) : null}
          </Space>
        </>
      ),
    },
  ]

  return (
    <>
      <div className="bg-white subpixel-antialiased shadow-white-500/40 shadow-sm rounded-lg h-fit w-full md:overflow-visible pt-4 md:pt-4 mt-10">
        <div className="section-form-title-produk px-3">
          <CustomBadge titleOrder title="Produk" />
        </div>

        <div className="section-table-form my-5 p-3">
          <Form.Item>
            <ReactSelect
              className="z-50"
              isSearchable
              isDisabled={customer_type_id ? false : true}
              options={productOptions}
              name="selected_product_id"
              value={productOptions.find((item) => item.value === selectedProductId) || null}
              onChange={({ value }) => {
                setSelectedProductId(value)
                objectRef.current?.focus()
              }}
              placeholder={customer_type_id ? 'Pilih Produk' : 'Pilih customer dulu'}
            />
          </Form.Item>

          <Form.Item>
            <AsyncSelect
              ref={objectRef}
              value={null}
              isDisabled={customer_type_id && selectedProductId ? false : true}
              loadOptions={debounce(loadProduct, 500)}
              onChange={onChangeProduct}
              defaultOptions={objects}
              placeholder={
                customer_type_id && selectedProductId
                  ? 'Cari Objek / Tipe Kendaraan'
                  : 'Pilih produk dulu'
              }
            />
          </Form.Item>
        </div>
      </div>

      <div className="table-section-product relative w-full">
        <Table
          rowKey="key"
          columns={columns}
          pagination={false}
          className="antialiased"
          rowClassName={(record, index) => (index % 2 === 0 ? 'bg-white' : 'bg-gray-100')}
          dataSource={dataSource ? dataSource : []}
          expandable={{
            expandedRowRender: (record, index) => {
              const isUniversal =
                record.product_vehicle?.vehicle_model?.manufacturer_id === UNIVERSAL
              const start = record.product_vehicle?.vehicle_model?.year_from || 1990
              const end = record.product_vehicle?.vehicle_model?.year_to || new Date().getFullYear()

              return (
                <div className="form-item-product grid md:grid-cols-3 gap-2 md:px-8">
                  <Form.Item
                    label="Tahun Mobil"
                    name={`product_year${record.key}`}
                    required={!isUniversal}
                    rules={[
                      {
                        required: !isUniversal,
                        message: 'Tahun wajib diisi',
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      disabled={isUniversal}
                      placeholder="Pilih Tahun"
                      onChange={(e) => handleYearChange(e, record)}
                      options={_.range(end, start - 1, -1).map((item) => ({
                        value: item,
                        label: item,
                      }))}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Desain"
                    name={`design${record.key}`}
                    required={record.enable_design}
                    rules={[
                      {
                        required: record.enable_design,
                        message: 'Desain wajib diisi',
                      },
                    ]}
                  >
                    <Select
                      // allowClear
                      disabled={!record.enable_design}
                      showSearch
                      placeholder="Pilih Desain"
                      onChange={(e) => handleDesignChange(e, index, record)}
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      options={record.design_options.sort(sortByName).map((design) => ({
                        value: design.id,
                        label:
                          design.name +
                          (design.price > 0
                            ? ` (Rp ${design.price?.toLocaleString('id-ID')})`
                            : ''),
                      }))}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Logo"
                    name={`logo${record.key}`}
                    required={record.enable_logo}
                    rules={[
                      {
                        required: record.enable_logo,
                        message: 'Logo wajib diisi',
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      showSearch
                      disabled={!record.enable_logo}
                      placeholder="Pilih Logo"
                      onChange={(e) => handleLogoChange(e, record)}
                      options={record.logo_options.map((item) => ({
                        value: item.name,
                        label: item.name,
                      }))}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Material"
                    name={`material_dasar${record.key}`}
                    required
                    rules={[
                      {
                        required: true,
                        message: 'Material Dasar wajib diisi',
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Pilih Material Dasar"
                      onChange={(e) => handleMaterial(e, record, index)}
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      options={record.material_options.sort(sortByName).map((item) => ({
                        value: item.id,
                        label: item.name,
                      }))}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Jenis Serat"
                    name={`serat_material${record.key}`}
                    required
                    rules={[
                      {
                        required: true,
                        message: 'Serat Material Dasar wajib diisi',
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Pilih Serat Dasar"
                      onChange={(e) => handleFiberMaterial(e, record, index)}
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      options={record.fiber_options?.sort(sortByName).map((item) => ({
                        value: item.id,
                        label: item.name,
                      }))}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Warna"
                    name={`warna_dasar${record.key}`}
                    required
                    rules={[
                      {
                        required: true,
                        message: 'Warna Dasar wajib diisi',
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      showSearch
                      placeholder="Pilih Warna Dasar"
                      onChange={(e) => handleColorByFiberMaterial(e, record)}
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      options={record.color_options?.sort(sortByName).map((item) => ({
                        value: item.id,
                        label: item.name,
                      }))}
                    />
                  </Form.Item>

                  {record.enable_variant_1 && (
                    <>
                      <Form.Item label="Material Variasi 1">
                        <Select
                          allowClear
                          showSearch
                          placeholder="Pilih Material Variasi 1"
                          disabled={!record.enable_variant_1}
                          onChange={(e) => handleVariantOneMaterial(e, record, index)}
                          filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                          }
                          options={record.material_variant_options
                            ?.sort(sortByName)
                            .map((item) => ({
                              value: item.id,
                              label: item.name,
                            }))}
                        />
                      </Form.Item>

                      <Form.Item
                        label="Serat Variasi 1"
                        name={`serat_material_variant${record.key}`}
                      >
                        <Select
                          allowClear
                          showSearch
                          placeholder="Pilih Serat Variasi 1"
                          disabled={!record.enable_variant_1}
                          onChange={(e) => handleVariantOneFiberMaterial(e, record, index)}
                          filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                          }
                          options={record.fiber_one_variant_options
                            ?.sort(sortByName)
                            .map((item) => ({
                              value: item.id,
                              label: item.name,
                            }))}
                        />
                      </Form.Item>

                      <Form.Item label="Warna Variasi 1" name={`variant_color_1${record.key}`}>
                        <Select
                          allowClear
                          showSearch
                          placeholder="Pilih Warna Variasi 1"
                          disabled={!record.enable_variant_1}
                          onChange={(e) => handleColorVariantOneChange(e, record)}
                          filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                          }
                          options={record.color_one_variant_options
                            ?.sort(sortByName)
                            .map((item) => ({
                              value: item.id,
                              label: item.name,
                            }))}
                        />
                      </Form.Item>
                    </>
                  )}

                  {record.enable_variant_2 && (
                    <>
                      <Form.Item label="Material Dasar 2">
                        <Select
                          allowClear
                          showSearch
                          placeholder="Pilih Material Variasi 2"
                          disabled={!record.enable_variant_2}
                          onChange={(e) => handleVariantTwoMaterial(e, record, index)}
                          filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                          }
                          options={record.material_variant_options.sort(sortByName).map((item) => ({
                            value: item.id,
                            label: item.name,
                          }))}
                        />
                      </Form.Item>

                      <Form.Item
                        label="Serat Variasi 2"
                        name={`serat_material_variant_2_${record.key}`}
                      >
                        <Select
                          allowClear
                          showSearch
                          placeholder="Pilih Serat Variasi 2"
                          disabled={!record.enable_variant_2}
                          onChange={(e) => handleVariantTwoFiberMaterial(e, record, index)}
                          filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                          }
                          options={record.fiber_two_variant_options
                            ?.sort(sortByName)
                            .map((item) => ({
                              value: item.id,
                              label: item.name,
                            }))}
                        />
                      </Form.Item>

                      <Form.Item label="Warna Variasi 2" name={`variant_color_2${record.key}`}>
                        <Select
                          allowClear
                          showSearch
                          placeholder="Pilih Warna Variasi 2"
                          disabled={!record.enable_variant_2}
                          onChange={(e) => handleColorVariantTwoChange(e, record)}
                          filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                          }
                          options={record.color_two_variant_options
                            ?.sort(sortByName)
                            .map((item) => ({
                              value: item.id,
                              label: item.name,
                            }))}
                        />
                      </Form.Item>
                    </>
                  )}

                  <Form.Item
                    label="Lubang Pengunci"
                    name={record.enable_lock_hole ? `locking_hole_${record.key}` : false}
                    required={record.enable_lock_hole}
                    rules={[
                      {
                        required: record.enable_lock_hole,
                        message: 'Lubang Pengunci wajib diisi',
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      showSearch
                      placeholder="Pilih Lubang Pengunci"
                      disabled={!record.enable_lock_hole}
                      onChange={(e) => handleLockHole(e, record, index)}
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      options={record.lock_hole_options?.sort(sortByName).map((item) => ({
                        value: item.name,
                        label: item.name,
                      }))}
                    />
                  </Form.Item>

                  {record.enable_fire_extinguisher && (
                    <Form.Item
                      label="APAR"
                      name={
                        record.enable_fire_extinguisher ? `fire_extinguisher_${record.key}` : false
                      }
                      required={record.enable_fire_extinguisher}
                      rules={[
                        {
                          required: record.enable_fire_extinguisher,
                          message: 'APAR wajib diisi',
                        },
                      ]}
                    >
                      <Select
                        allowClear
                        showSearch
                        placeholder="Pilih APAR"
                        disabled={!record.enable_fire_extinguisher}
                        onChange={(e) => handleFireExtinguisher(e, record, index)}
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={record.fire_extinguisher_options?.sort(sortByName).map((item) => ({
                          value: item.name,
                          label: item.name,
                        }))}
                      />
                    </Form.Item>
                  )}

                  <Form.Item
                    label="Jenis Transmisi"
                    name={`transmission${record.key}`}
                    required={record.enable_transmission}
                    rules={[
                      {
                        required: record.enable_transmission,
                        message: 'Transmisi wajib diisi',
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      showSearch
                      placeholder="Pilih Transmisi"
                      disabled={!record.enable_transmission}
                      onChange={(e) => handleTransmissionChange(e, record)}
                      options={record.transmission_options.map((item) => ({
                        value: item.name,
                        label: item.name,
                      }))}
                    />
                  </Form.Item>

                  <Form.Item label="Penyesuaian Harga (Bisa Minus) Rp">
                    <Input
                      type="text"
                      prefix="Rp"
                      placeholder="0"
                      value={record.price_adjustment}
                      onChange={(e) => priceAdjustmentChange(e, index)}
                    />
                  </Form.Item>

                  <Form.Item label="Kuantitas">
                    <Input
                      className="text-center"
                      value={record.qty}
                      addonBefore={
                        <i
                          className="fa-solid fa-minus"
                          onClick={() => decreaseQuantity(_, record, index)}
                        />
                      }
                      addonAfter={
                        <i
                          className="fa-solid fa-plus"
                          onClick={() => increaseQuantity(_, index)}
                        />
                      }
                    />
                  </Form.Item>

                  {record.addons.length !== 0 && (
                    <Form.Item label="Tambahan (Addons)">
                      {record.addons.map((addon) => (
                        <h2 className="text-base leading-10">
                          {addon.name} - {formatRp(addon.price)}
                        </h2>
                      ))}
                    </Form.Item>
                  )}

                  <Form.Item label="Harga Dasar (Rp)">
                    <Input
                      readOnly
                      value={
                        record.variant && CUSTOMER_RETAIL === customer_type_id
                          ? formatRp(record.variant?.price) || 0
                          : formatRp(record.variant?.agent_price) || 0
                      }
                    />
                  </Form.Item>

                  <Form.Item label="Total (Rp)">
                    <Input
                      readOnly
                      value={
                        record.variant && CUSTOMER_RETAIL === customer_type_id
                          ? formatRp(
                              (record.variant.price +
                                addonsPrice(record) +
                                parseInt(record.price_adjustment || 0) +
                                record.price_design) *
                                record.qty
                            )
                          : formatRp(
                              (record.variant.agent_price ||
                                0 +
                                  addonsPrice(record) +
                                  parseInt(record.price_adjustment || 0) +
                                  record.price_design) * record.qty
                            )
                      }
                    />
                  </Form.Item>

                  <Form.Item label="Tambah Catatan">
                    <Input.TextArea
                      maxLength={250}
                      placeholder="Catatan max 255 karakter "
                      onChange={(e) => handleNoteProductChange(e, record)}
                    />
                  </Form.Item>
                </div>
              )
            },
          }}
          scroll={{ x: 'auto' }}
          tableLayout="auto"
        />
      </div>
    </>
  )
}

export default TableFormOrder
