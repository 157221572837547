import { Button, DatePicker, Form, Input, Modal, notification } from 'antd'
import React, { useEffect, useState } from 'react'
import LeadsService from '../../../../services/LeadsService'
import moment from 'moment'

const FormLeadsNonForm = (props) => {
  const { date, selectedUserId: user_id, open, setIsOpen, getData: refreshData } = props

  const [form] = Form.useForm()

  const [loading, setLoading] = useState(false)
  const [detailLeadsNonForm, setDetailLeadsNonForm] = useState({})

  useEffect(() => {
    if (open) {
      getDataLeadsNonForm(date, user_id)
    }
  }, [date, open, user_id])

  const getDataLeadsNonForm = async (date, user_id) => {
    setLoading(true)
    try {
      const response = await LeadsService.getDetailLeadsNonForm({ date, user_id })
      console.log(response)

      if (response.status === 200) {
        setLoading(false)
        setDetailLeadsNonForm({ date, user_id, ...response.data })
        form.setFieldsValue({
          ...response.data,
        })
      }
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const handleClose = () => {
    setIsOpen(false)
    setDetailLeadsNonForm({})
    form.resetFields()

    refreshData()
  }

  const onFinish = async (values) => {
    setLoading(true)

    const data = {
      messenger_facebook: values.messenger_facebook || 0,
      dm_instagram: values.dm_instagram || 0,
      dm_tiktok: values.dm_tiktok || 0,
      share_non_form: values.share_non_form || 0,
      organik: values.organik || 0,
      tiktok_shop: values.tiktok_shop || 0,
      offline: values.offline || 0,
      marketplace: values.marketplace || 0,
      date_leads: moment(detailLeadsNonForm.date).format('YYYY-MM-DD'),
    }

    const payload = {
      id: detailLeadsNonForm.id,
      user_id: detailLeadsNonForm.user_id,
      ...data,
    }

    try {
      const response = await LeadsService.leadsNonFormUpdate(payload)

      if (response.status === 200) {
        notification.success({
          message: 'Success',
          description: 'Data berhasil diupdate',
        })
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
      handleClose()
    }
  }

  return (
    <Modal
      forceRender
      title={`Leads ${moment(detailLeadsNonForm.date).format('DD/MM/YYYY')} ${
        detailLeadsNonForm?.user?.name
      }`}
      open={open}
      onCancel={handleClose}
      footer={null}
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <div className="grid md:grid-cols-2 gap-2 mt-5">
          <Form.Item label="Messenger Facebook" name="messenger_facebook">
            <Input
              placeholder="Messenger Facebook"
              onChange={(e) => {
                form.setFieldsValue({
                  messenger_facebook: parseInt(e.target.value.replace(/\D/g, '') || 0),
                })
              }}
            />
          </Form.Item>

          <Form.Item label="DM Instagram" name="dm_instagram">
            <Input
              placeholder="Dm Instagram"
              onChange={(e) => {
                form.setFieldsValue({
                  dm_instagram: parseInt(e.target.value.replace(/\D/g, '') || 0),
                })
              }}
            />
          </Form.Item>

          <Form.Item label="DM TikTok" name="dm_tiktok">
            <Input
              placeholder="Dm TikTok"
              onChange={(e) => {
                form.setFieldsValue({
                  dm_tiktok: parseInt(e.target.value.replace(/\D/g, '') || 0),
                })
              }}
            />
          </Form.Item>

          <Form.Item label="Share Non Form" name="share_non_form">
            <Input
              placeholder="Share Non Form"
              onChange={(e) => {
                form.setFieldsValue({
                  share_non_form: parseInt(e.target.value.replace(/\D/g, '') || 0),
                })
              }}
            />
          </Form.Item>

          <Form.Item label="Organik" name="organik">
            <Input
              placeholder="Organik"
              onChange={(e) => {
                form.setFieldsValue({
                  organik: parseInt(e.target.value.replace(/\D/g, '') || 0),
                })
              }}
            />
          </Form.Item>

          <Form.Item label="TikTok Shop" name="tiktok_shop">
            <Input
              placeholder="TikTok Shop"
              onChange={(e) => {
                form.setFieldsValue({
                  tiktok_shop: parseInt(e.target.value.replace(/\D/g, '') || 0),
                })
              }}
            />
          </Form.Item>

          <Form.Item label="Offline" name="offline">
            <Input
              placeholder="Offline"
              onChange={(e) => {
                form.setFieldsValue({
                  offline: parseInt(e.target.value.replace(/\D/g, '') || 0),
                })
              }}
            />
          </Form.Item>

          <Form.Item label="Marketplace" name="marketplace">
            <Input
              placeholder="Marketplace"
              onChange={(e) => {
                form.setFieldsValue({
                  marketplace: parseInt(e.target.value.replace(/\D/g, '') || 0),
                })
              }}
            />
          </Form.Item>
        </div>

        <Form.Item>
          <Button htmlType="submit" loading={loading} className="text-white bg-[#013a63] border-0">
            Simpan
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default FormLeadsNonForm
