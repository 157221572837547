/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Form, Input, Modal } from 'antd'
import React, { useEffect } from 'react'
import Swal from 'sweetalert2'
import FiberService from '../../../../services/FiberService'

const EditFiber = (props) => {
  const {
    id: materialId,
    isModalEditOpen,
    handleCancel,
    setIsModalEditOpen,
    getData,
    childData,
  } = props

  const [form] = Form.useForm()

  useEffect(() => {
    form.setFieldsValue({
      ...childData,
    })
  }, [childData])

  const onSubmit = async (values) => {
    const data = {
      material_id: parseInt(materialId),
      name: values.name,
    }

    const response = await FiberService.update(data, materialId, childData.id)
    try {
      if (response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Berhasil',
          text: 'Berhasil update serat',
        })
      }
      getData()
      setIsModalEditOpen(false)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Modal
      forceRender
      title="Edit Serat"
      footer={false}
      open={isModalEditOpen}
      onCancel={handleCancel}
    >
      <Form form={form} onFinish={onSubmit} layout="vertical">
        <Form.Item label="Nama Serat" name="name">
          <Input className="rounded-md h-10" placeholder="Isi nama Serat" />
        </Form.Item>

        <Form.Item>
          <div className="flex justify-end gap-3">
            <Button className="bg-rose-500 text-white rounded-lg" onClick={handleCancel}>
              Batalkan
            </Button>
            <Button htmlType="submit" className="bg-cyan-500 text-white rounded-lg">
              Simpan
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default EditFiber
