import { request } from '../config'

// versi function
function CustomerService() {
  const addCustomer = async (data) => {
    const response = await request.post(`/customers`, data)

    return response.data
  }

  const findCustomerById = async (customer_id) => {
    const response = await request.get(`/customers/${customer_id}`)

    return response.data
  }

  const searchCustomer = async (filter) => {
    const response = await request.post(`/customers/search`, filter)

    return response.data
  }

  const getDataCustomer = async (params) => {
    const response = await request.get(`/customers/pagination`, { params })

    return response.data
  }

  const checkExisting = async (filter) => {
    const response = await request.post(`/customers/check`, filter)

    return response.data
  }

  const getCustomer = async (id) => {
    const response = await request.get(`/customers/${id}`)

    return response.data
  }

  const getOrderListByCustomer = async (id) => {
    const response = await request.get(`/customers/${id}/orders`)

    return response.data
  }

  const customerTypeList = async () => {
    const response = await request.get(`/customers/types`)

    return response.data
  }

  return {
    addCustomer,
    getDataCustomer,
    findCustomerById,
    searchCustomer,
    getCustomer,
    checkExisting,
    customerTypeList,
    getOrderListByCustomer,
  }
}

export default CustomerService()
