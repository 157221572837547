/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Form, Input, Modal, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import CContainer from '../../../../../components/CContainer'
import CustomBadge from '../../../../../components/CustomBadge'
import ManufacturerService from '../../../../../services/ManufacturerService'
import VehicleService from '../../../../../services/VehicleService'

const newYearsTo = [
  {
    value: 'Present',
    label: 'Present',
  },
]

const EditVehicleModel = () => {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const { id } = useParams()

  const year = new Date().getFullYear()
  const years = Array.from(new Array(70), (val, index) => year - index)
  const yearTo = Array.from(new Array(70), (val, index) => year - index)

  const [yearsTo, setYearsTo] = useState([
    ...newYearsTo,
    ...yearTo?.map((item) => ({
      value: item,
      label: item,
    })),
  ])
  const [manufacturer, setManufacturer] = useState([])
  const [vehicleClass, setVehicleClass] = useState([])

  useEffect(() => {
    getDetail()
    getDataManufacturer()
    getDataVehicleClass()
  }, [])

  const getDetail = async () => {
    try {
      const response = await VehicleService.findByIdVehicleModels(id)

      if (response.status === 200) {
        form.setFieldsValue({
          ...response.data,
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getDataManufacturer = async () => {
    try {
      const response = await ManufacturerService.getManufacturer()

      if (response.status === 200) {
        setManufacturer(
          response.data.map((item) => ({
            value: item.id,
            label: item.name,
          }))
        )
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getDataVehicleClass = async () => {
    try {
      const response = await VehicleService.getVehicleClass()

      if (response.status === 200) {
        setVehicleClass(
          response.data.map((item) => ({
            value: item.id,
            label: item.name,
          }))
        )
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleYearFrom = (value) => {
    if (value === year) {
      setYearsTo([...newYearsTo])
    } else {
      setYearsTo([
        ...newYearsTo,
        ...yearTo?.map((item) => ({
          value: item,
          label: item,
        })),
      ])
    }
  }

  const onFinish = async (values) => {
    const payload = {
      name: values.name,
      manufacturer_id: values.manufacturer_id,
      vehicle_class_id: values.vehicle_class_id,
      year_from: values.year_from,
      year_to: values.year_to,
    }

    try {
      const response = await VehicleService.updateVehicleModels(id, payload)

      if (response.status === 200) {
        Modal.success({
          title: 'Berhasil',
          content: 'Update Tipe Mobil berhasil',
        })
      }
      navigate('/master-product/vehicle/models')
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <CContainer>
      <div className="section-product px-2 md:px-4 pb-2">
        <CustomBadge title="Edit Tipe Mobil" titleParent="Tipe Mobil" />

        <div className="section-form-product mt-10">
          <Form form={form} onFinish={onFinish} layout="vertical">
            <div className="grid md:grid-cols-3 gap-2">
              <Form.Item
                label="Pabrikan"
                name="manufacturer_id"
                required
                rules={[
                  {
                    required: true,
                    message: 'Pabrikan harus diisi',
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Pabrikan"
                  options={manufacturer}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                />
              </Form.Item>

              <Form.Item
                label="Nama Mobil"
                name="name"
                required
                rules={[
                  {
                    required: true,
                    message: 'Nama kendaraan harus diisi',
                  },
                ]}
              >
                <Input placeholder="Isi nama mobil" />
              </Form.Item>

              <Form.Item
                label="Jenis Mobil"
                name="vehicle_class_id"
                required
                rules={[
                  {
                    required: true,
                    message: 'Jenis Kendaraan harus diisi',
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Jenis Mobil"
                  options={vehicleClass}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                />
              </Form.Item>

              <Form.Item label="Mulai Tahun" name="year_from">
                <Select
                  placeholder="Mulai Tahun"
                  onChange={handleYearFrom}
                  options={years?.map((item) => ({
                    value: item,
                    label: item,
                  }))}
                />
              </Form.Item>

              <Form.Item label="Sampai Tahun" name="year_to">
                <Select placeholder="Sampai Tahun" options={yearsTo} />
              </Form.Item>
            </div>

            <div className="flex justify-end gap-3 mt-4">
              <Form.Item>
                <Button
                  className="border-0 bg-rose-500 text-white"
                  onClick={() => navigate('/master-product/vehicle/models')}
                >
                  Batal
                </Button>
              </Form.Item>
              <Form.Item>
                <Button htmlType="submit" className="border-0 bg-cyan-500 text-white">
                  Simpan
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
    </CContainer>
  )
}

export default EditVehicleModel
