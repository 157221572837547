/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import Swal from 'sweetalert2'
import { Button, Form, Input, Modal } from 'antd'
import LogoService from '../../../../services/LogoService'

const EditLogo = (props) => {
  const { id, isModalEditOpen, handleCancel, setIsModalEditOpen, getData, childData } = props
  const [form] = Form.useForm()

  useEffect(() => {
    form.setFieldsValue({
      ...childData,
    })
  }, [childData])

  const onSubmit = async (values) => {
    const data = {
      name: values.name,
      product_id: parseInt(id),
    }

    const response = await LogoService.update(id, childData.id, data)
    try {
      if (response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Berhasil',
          text: 'Berhasil update logo produk',
        })
      }
      getData()
      setIsModalEditOpen(false)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Modal forceRender title="Edit Logo Produk" footer={false} open={isModalEditOpen} onCancel={handleCancel}>
      <Form className="mt-10" form={form} onFinish={onSubmit} layout="vertical">
        <Form.Item
          label="Nama"
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input className="rounded-md h-10" placeholder="Custom" />
        </Form.Item>

        <Form.Item>
          <div className="flex justify-end gap-3">
            <Button
              className="bg-rose-500 text-white rounded-lg"
              onClick={() => setIsModalEditOpen(false)}
            >
              Batalkan
            </Button>
            <Button htmlType="submit" className="bg-cyan-500 text-white rounded-lg">
              Simpan
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default EditLogo
