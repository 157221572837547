import { Button, Form, Input, Space } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router'
import Swal from 'sweetalert2'
import CustomBadge from '../../../../components/CustomBadge'
import CategoryService from '../../../../services/CategoryService'

const AddCategory = () => {
  const [form] = Form.useForm()
  const navigate = useNavigate()

  const onSubmit = async (values) => {
    const data = {
      name: values.name,
      description: values.description,
    }

    const response = await CategoryService.save(data)
    try {
      if (response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Berhasil',
          text: 'Berhasil menambahkan kategori',
        })
      }
      navigate('/categories')
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className="bg-white subpixel-antialiased shadow-white-500/40 shadow-sm rounded-2xl h-fit w-full md:overflow-visible pt-4 md:pt-4">
      <div className="section-product px-2 md:px-4 pb-2">
        <CustomBadge title="Tambah Kategori" titleParent="Kategori" />

        <div className="section-form-categories w-1/2 mt-10">
          <Form form={form} onFinish={onSubmit} layout="vertical">
            <Form.Item label="Nama Kategori" name="name">
              <Input placeholder="Kategori" />
            </Form.Item>

            <Form.Item label="Deskripsi" name="description">
              <Input.TextArea placeholder="Deskripsi" rows={4} />
            </Form.Item>

            <Form.Item>
              <Space className="flex justify-end mt-10">
                <Button
                  className="border-0 text-white text-lg h-fit font-medium bg-rose-500"
                  onClick={() => navigate('/categories')}
                >
                  Batalkan
                </Button>

                <Button
                  className="border-0 text-white text-lg h-fit font-medium bg-cyan-500"
                  htmlType="submit"
                >
                  Simpan
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default AddCategory
