import { request } from '../config'

// versi function
function DashboardService() {
  // Get Data for Datatable
  const getDashboard = async () => {
    const response = await request.get(`/dashboard`)
    return response.data
  }

  return {
    getDashboard,
  }
}

export default DashboardService()
