import { Button, Form, Input, Modal } from 'antd'
import React from 'react'
import ManufacturerService from '../../../../../services/ManufacturerService'

const AddManufacturer = (props) => {
  const { open, toggle } = props

  const [form] = Form.useForm()

  const handleCancel = () => {
    toggle()
  }

  const onFinish = async (values) => {
    try {
      const response = await ManufacturerService.saveManufacturer(values)

      if (response.status === 200) {
        Modal.success({
          title: 'Berhasil',
          content: 'Berhasil menambahkan data pabrikan',
        })
      }
      toggle()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Modal width={380} open={open} onCancel={handleCancel} destroyOnClose={true} footer={false}>
      <h2 className="text-base mb-5">Tambah Pabrikan</h2>

      <Form form={form} onFinish={onFinish} layout="vertical">
        <Form.Item label="Pabrikan" name="name" required>
          <Input placeholder="Isi pabrikan" />
        </Form.Item>

        <div className="flex justify-end gap-2">
          <Form.Item>
            <Button onClick={handleCancel} className="border-0 bg-red-500 text-white">
              Batal
            </Button>
          </Form.Item>

          <Form.Item>
            <Button htmlType="submit" className="border-0 bg-cyan-500 text-white">
              Simpan
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  )
}

export default AddManufacturer
